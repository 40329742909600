export function pluralize(numeric: number, titles: [string, string, string], didNotSaveNumeric?: boolean) {
  const numericUpToHundred = Math.abs(numeric) % 100;
  const numericUpToTen = Math.abs(numeric) % 10;
  const text = didNotSaveNumeric ? '' : `${String(numeric)} `;
  const cases = [2, 0, 1, 1, 1, 2];

  if ((numericUpToHundred > 4 && numericUpToHundred < 20) || numericUpToTen >= 5) {
    return text + titles[2];
  }

  return text + titles[cases[numericUpToTen]];
}
