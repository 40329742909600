import { IAgentSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { AGENT_TYPES } from '../constants/agentTypes';

export const getAgentType = (agent: IAgentSchema): AGENT_TYPES => {
  const { isSubAgent, accountType } = agent;

  if ((accountType && ['agency', 'managementCompany', 'rentDepartment'].includes(accountType)) || isSubAgent) {
    return AGENT_TYPES.Agency;
  }

  // TODO как получить компанию?
  // if ((accountType && ['managementCompany'].includes(accountType))) {
  //   return AGENT_TYPES.Company;
  // }

  return AGENT_TYPES.Agent;
};
