import { connect } from 'react-redux';

import { IReclaimModalDispatchProps, IReclaimModalProps, ReclaimModal } from './index';
import { closeModal, submitReclaimConfirm } from '../../../store/popups/reclaim';

import type { DispatchReduxAction, IState } from '../../../store';

function mapStatetoProps(state: IState): IReclaimModalProps {
  return {
    errorMessage: state.reclaimModal.errorMessage,
    isOpen: state.reclaimModal.isOpen,
    realtyOfferId: state.offerData.offer.id,
    statusFetch: state.reclaimModal.statusFetch,
  };
}

function mapDispatchToProps(dispatch: DispatchReduxAction): IReclaimModalDispatchProps {
  return {
    closeReclaimModal: () => {
      dispatch(closeModal());
    },
    submitReclaimConfirm: (host: string, realtyOfferId: number) => {
      dispatch(submitReclaimConfirm(host, realtyOfferId));
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ReclaimModalContainer = connect<IReclaimModalProps, IReclaimModalDispatchProps, any>(
  mapStatetoProps,
  mapDispatchToProps,
)(ReclaimModal);
