/* eslint-disable @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-explicit-any */
import cx from 'clsx';
import * as React from 'react';

import * as styles from './index.css';

export interface IOfferCardAsideState {
  hidden: boolean;
  switchedToRelativeAntimation: boolean;
  switchedToStickyAntimation: boolean;
}

export interface IOfferCardAsideStoreProps {
  height: number;
  isNavigationActive: boolean;
  availableCompactMode: boolean;
}

export interface IOfferCardAsideDispatchProps {
  setHeight(height: number): void;
  setCompactMode(isEnable: boolean): void;
}

type IOfferCardAsideProps = IOfferCardAsideStoreProps & IOfferCardAsideDispatchProps;

export class OfferCardAside extends React.Component<
  React.PropsWithChildren<IOfferCardAsideProps>,
  IOfferCardAsideState
> {
  private container: HTMLDivElement | null;

  public constructor(props: IOfferCardAsideProps) {
    super(props);

    this.state = {
      hidden: false,
      switchedToRelativeAntimation: props.availableCompactMode,
      switchedToStickyAntimation: false,
    };
  }

  public componentDidMount() {
    const { height, setHeight, setCompactMode, availableCompactMode } = this.props;
    const rect = (this.container as HTMLDivElement).getBoundingClientRect();

    if (availableCompactMode) {
      (this.container as HTMLDivElement).addEventListener('animationend', () => {
        if (this.state.hidden) {
          if (this.state.switchedToRelativeAntimation) {
            setCompactMode(true);
            this.setState({
              hidden: false,
              switchedToStickyAntimation: true,
              switchedToRelativeAntimation: false,
            });
          } else {
            setCompactMode(false);
            this.setState({
              hidden: false,
              switchedToStickyAntimation: false,
              switchedToRelativeAntimation: true,
            });
          }
        }
      });

      window.addEventListener('resize', this.switchCompactMode);
      window.addEventListener('scroll', this.switchCompactMode);
    }

    if (height !== rect.height) {
      setHeight(rect.height);
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('scroll', this.switchCompactMode);
    window.removeEventListener('resize', this.switchCompactMode);
  }

  public render() {
    const { isNavigationActive, availableCompactMode, children } = this.props;

    return (
      <div
        data-name="OfferCardAside"
        ref={ref => (this.container = ref)}
        className={cx(
          styles['container'],
          isNavigationActive && styles['container--active'],
          !availableCompactMode && styles['container--sticky'],
          this.state.hidden && styles['container--hidden'],
          !this.state.hidden && styles['container--not-hidden'],
          this.state.switchedToStickyAntimation && styles['container--sticky'],
          this.state.switchedToRelativeAntimation && styles['container--relative'],
        )}
      >
        {children}
      </div>
    );
  }

  private switchCompactMode = () => {
    const rect = this.container!.getBoundingClientRect();
    const parentRect = (this.container!.parentNode as any).getBoundingClientRect();

    const visibleContainerHeight = rect.height + parentRect.top;
    const halfOfContainerHeight = rect.height / 2;

    if (
      visibleContainerHeight < halfOfContainerHeight &&
      this.state.switchedToRelativeAntimation &&
      !this.state.hidden
    ) {
      this.setState({ hidden: true });
    }

    if (
      rect.height + parentRect.top >= halfOfContainerHeight &&
      !this.state.switchedToRelativeAntimation &&
      !this.state.hidden
    ) {
      this.setState({ hidden: true });
    }
  };
}
