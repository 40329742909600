import { connect } from 'react-redux';

import { PopupFeedbackSent } from './PopupFeedbackSent';
import { closeFeedbackSentPopup } from '../../../actions/feedback';

import type { DispatchReduxAction, IState } from '../../../../store';

export const PopupFeedbackSentContainer = connect(
  (state: IState) => ({
    isOpen: state.feedback.isFeedbackSentPopupOpened,
  }),
  (dispatch: DispatchReduxAction) => ({
    onClose() {
      dispatch(closeFeedbackSentPopup());
    },
  }),
)(PopupFeedbackSent);
