import { numberToPrettyString } from '@cian/utils/lib/shared/number_helpers';

import { ISimilarOffer, ISimilarOfferUnderground } from 'shared/store/similar';
import { INearbyNewbuildingOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';
import { isArrayWithItems } from 'shared/utils';
import { CurrencySymbol } from 'shared/utils/formatters';

export const transformSimilarNewObjects = (similarNewObjects: INearbyNewbuildingOfferSchema[]): ISimilarOffer[] => {
  return similarNewObjects.map(item => ({
    address: item.addressStr as string,
    cianId: item.id as number,
    formattedPrice: getPrice(item),
    gaVariant: '',
    photoUrl: item.photoUrl,
    publishedUserId: item.publishedUserId,
    realtyId: 0,
    title: getTitle(item),
    totalPriceRur: 0,
    underground: getUnderground(item),
    url: `/sale/flat/${item.id}`,
    isFromBuilder: true,
  }));
};

function getTitle(item: INearbyNewbuildingOfferSchema): string {
  if (!item.roomTitle) {
    return '';
  }

  const title: Array<string> = [item.roomTitle];

  const area: Array<string | null> = [
    item.totalArea ? numberToPrettyString(Number(item.totalArea)) : null,
    item.totalArea ? numberToPrettyString(Number(item.livingArea)) : null,
    item.totalArea ? numberToPrettyString(Number(item.kitchenArea)) : null,
  ].filter(Boolean);

  if (isArrayWithItems<number>(area)) {
    title.push(area.join('/').concat('\u00A0кв.м.'));
  }

  const floor: Array<number | null> = [item.floor, item.floorn].filter(Boolean);

  if (isArrayWithItems<number>(floor)) {
    title.push(`этаж\u00A0${floor.join('/')}`);
  }

  return title.join(', ');
}

function getUnderground(item: INearbyNewbuildingOfferSchema): ISimilarOfferUnderground | null {
  if (!item.undergroundInfo) {
    return null;
  }

  return {
    color: item.undergroundInfo.lineColor as string,
    iconType: item.undergroundInfo.type,
    name: item.undergroundInfo.name as string,
  };
}

function getPrice(item: INearbyNewbuildingOfferSchema): string {
  return `${numberToPrettyString(Number(item.priceRur))} ${CurrencySymbol.Rur}`;
}
