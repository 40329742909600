import { Button } from '@cian/ui-kit';
import { IconActionCompareAdd16, IconActionCompareAdded16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

interface IComparisonControlNewProps {
  added: boolean;
  disabled: boolean;
  onClick(): void;
}

export function ComparisonControlNew({ added, disabled, onClick }: IComparisonControlNewProps) {
  return (
    <Button
      theme="stroke_secondary"
      beforeIcon={
        added ? (
          <IconActionCompareAdded16 color="icon-secondary-default" />
        ) : (
          <IconActionCompareAdd16 color={disabled ? 'gray60_100' : 'icon-secondary-default'} />
        )
      }
      onClick={onClick}
      disabled={disabled}
      size="XS"
    >
      {added ? 'Добавлено в сравнение' : 'Сравнить'}
    </Button>
  );
}
