import { defaultTo } from 'ramda';

import { IAgentSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { getMasterAgent } from './getMasterAgent';
import { getMasterAgentAvatar } from './getMasterAgentAvatar';
import { IAgent } from '../types/offerData';

export function getAgentAvatar(agent: IAgentSchema | IAgent) {
  if (agent.isSubAgent) {
    const masterAgent = getMasterAgent(agent);

    return masterAgent ? getMasterAgentAvatar(masterAgent) : '';
  }

  return defaultTo('', agent.absoluteAvatarUrl);
}
