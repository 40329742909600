import { ILogger } from '@cian/logger';
import { ModalWindow, Spinner } from '@cian/ui-kit';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import * as styles from './Panorama.css';
import { EPanoramaStatus } from './types';
import { loadImapApi } from '../../utils/loadImapApi';

export interface IPanoramaProps {
  logger: ILogger;
  initialCoordinates: [number, number];
  panoramaUrl?: string;
  onOpen?(): void;
  onClose?(): void;
  onStatusChange?(status: EPanoramaStatus): void;
}

const noop = () => {};

export const Panorama: React.FC<React.PropsWithChildren<IPanoramaProps>> = ({
  panoramaUrl,
  logger,
  initialCoordinates,
  onOpen,
  onClose,
  onStatusChange,
}) => {
  const panoramaApi = React.useRef<IMap.IMap | null>(null);
  const [containerId, setContainerId] = React.useState<string | null>(null);
  const panoramaComponent = React.useRef<IMap.Panorama | null>(null);
  const [status, setStatusRaw] = React.useState(EPanoramaStatus.Initial);

  const setStatus = React.useCallback(
    (status: EPanoramaStatus) => {
      setStatusRaw(status);

      if (onStatusChange) {
        onStatusChange(status);
      }
    },
    [onStatusChange],
  );

  const handlePanoramaInitialized = React.useCallback(() => {
    if (panoramaComponent.current) {
      panoramaComponent.current.setFOV(70);
    }

    setStatus(EPanoramaStatus.Succeed);
  }, [setStatus]);

  const handlePanoramaNotFound = React.useCallback(() => {
    logger.warning('PANORAMA_NOT_FOUND', { initialCoordinates, domain: 'Panorama#loadImapApi' });

    setStatus(EPanoramaStatus.NotFound);
  }, [initialCoordinates, logger, setStatus]);

  React.useEffect(() => {
    setContainerId(`panorama_${Math.random().toString(36).substr(2, 8)}`);

    if (onOpen) {
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!panoramaUrl) {
      return;
    }

    setStatus(EPanoramaStatus.Loading);

    let canceled = false;
    loadImapApi(panoramaUrl)
      .then(imap => {
        /* istanbul ignore next */
        if (canceled) {
          return;
        }

        panoramaApi.current = imap;

        if (!imap.Detector.webgl || !imap.Detector.EXT_frag_depth) {
          setStatus(EPanoramaStatus.Unsupported);

          return;
        }

        if (containerId) {
          panoramaComponent.current = new imap.Panorama(containerId, '12084a70-54d6-4ac7-bf35-90e5b282892f', {
            housesHighlight: false,
            initpoint: initialCoordinates,
            logoEnable: false,
            mapEnable: false,
            mapVisible: false,
            toolbar: { visible: false },
          });

          panoramaComponent.current.addEventListener('INITIALIZED', handlePanoramaInitialized);
          panoramaComponent.current.addEventListener('PANORAMA_NOT_FOUND', handlePanoramaNotFound);
        }
      })
      .catch(ex => {
        logger.error(ex, { initialCoordinates, domain: 'Panorama#loadImapApi' });

        setStatus(EPanoramaStatus.Failed);
      });

    return () => {
      canceled = true;

      if (panoramaComponent.current) {
        panoramaComponent.current.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerId, panoramaUrl]);

  return ReactDOM.createPortal(
    <ModalWindow
      title="Панорама района"
      content={
        <div className={styles['container']}>
          <div id={containerId || undefined} className={styles['panorama']} />
          {status === EPanoramaStatus.Loading && (
            <div className={styles['wrapper']}>
              <Spinner size={16} />
            </div>
          )}
          {status === EPanoramaStatus.NotFound && (
            <div className={styles['wrapper']}>
              Для этого двора мы пока не успели отснять панораму, но уже занимаемся её подготовкой
            </div>
          )}
          {status === EPanoramaStatus.Unsupported && (
            <div className={styles['wrapper']}>
              Данная версия браузера не поддерживает отображение панорам. Обновите его или воспользуйтесь последней
              версией Google Chrome
            </div>
          )}
          {status === EPanoramaStatus.Failed && (
            <div className={styles['wrapper']}>
              Возникла непредвиденная ошибка. Обновите страницу и откройте панораму снова
            </div>
          )}
        </div>
      }
      open
      onClose={onClose || noop}
    />,
    document.body,
  );
};
