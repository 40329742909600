import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectOffer } from 'shared/selectors';
import { selectAdfoxParams } from 'shared/selectors/adfox';
import { selectIsTestMode } from 'shared/selectors/selectIsTestMode';
import { IPParams } from 'shared/types/adfox';
import {
  getPuidAreaTarget,
  getRailwayAndHighwaysPuids,
  getSpecialPriceTargetPuidForAdFox,
  getSpecialRentPriceTargetPuidForAdFox,
} from 'shared/utils/adfox';

import type { TAdfoxContextData } from '../../types';

type TPuids = TAdfoxContextData['params'];

export function useAdfoxPuids(pParams: IPParams): TPuids {
  const offer = useSelector(selectOffer);
  const isTestMode = useSelector(selectIsTestMode);
  const adfoxRequestedParams = useSelector(selectAdfoxParams);

  return useMemo((): TPuids => {
    const puids: TPuids = {
      ...adfoxRequestedParams,
      puid6: getSpecialPriceTargetPuidForAdFox(offer.bargainTerms.prices.rur),
      puid33: getSpecialRentPriceTargetPuidForAdFox(offer.bargainTerms.price),
      ...getRailwayAndHighwaysPuids(offer),
      ...pParams,
    };

    if (isTestMode) {
      puids.puid32 = 'test';
    }

    if (offer.totalArea) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      puids['puid27'] = getPuidAreaTarget(offer.totalArea)!;
    }

    return puids;
  }, [adfoxRequestedParams, isTestMode, offer, pParams]);
}
