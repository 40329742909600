import * as React from 'react';

export const PlusIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="10" height="10" viewBox="0 0 10 10">
    <path
      fillRule="evenodd"
      d="M5 0a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2H5.999L6 9a1 1 0 1 1-2 0l-.001-3H1a1 1 0 1 1 0-2h3V1a1 1 0 0 1 1-1z"
    />
  </svg>
);
