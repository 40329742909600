import { Button, ModalWindow, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './PhoneWarningModal.css';

export interface IPhoneWarningModalProps {
  content: string;
  onClose(): void;
  onConfirm(): void;
  open: boolean;
  title: string;
}

export const PhoneWarningModal: React.FC<React.PropsWithChildren<IPhoneWarningModalProps>> = ({
  content,
  onClose,
  onConfirm,
  open,
  title,
}) => {
  return (
    <ModalWindow title={title} maxWidth={478} open={open} onClose={onClose}>
      <div className={styles['container']}>
        <UIText1>{content}</UIText1>

        <div className={styles['buttons-container']}>
          <div className={styles['button']}>
            <Button theme="fill_primary" size="XS" onClick={onConfirm} type="button" fullWidth>
              Добавить телефон
            </Button>
          </div>
          <div className={styles['button']}>
            <Button theme="fill_white_primary" size="XS" onClick={onClose} type="button" fullWidth>
              Отменить
            </Button>
          </div>
        </div>
      </div>
    </ModalWindow>
  );
};
