import { createSelector } from '@reduxjs/toolkit';

import { EBestPlaceReportStatus } from 'shared/types/bestPlaceAnalytics';

import { selectStatus } from './selectStatus';

export const errorStatuses = [
  EBestPlaceReportStatus.BillingServerError,
  EBestPlaceReportStatus.UnknownServerError,
  EBestPlaceReportStatus.EmailError,
  EBestPlaceReportStatus.RequestUrlError,
  EBestPlaceReportStatus.UserTypeError,
  EBestPlaceReportStatus.ReportPaidError,
  EBestPlaceReportStatus.PaymentCancelled,
  EBestPlaceReportStatus.ReportPaidError,
];

export const selectStatusBarHasError = createSelector(selectStatus, status => {
  return status !== null ? errorStatuses.includes(status) : false;
});
