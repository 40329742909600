import { ca } from '@cian/analytics';

export function trackSubscribePopupOpen() {
  ca('eventSite', {
    action: 'Open',
    category: 'subscription',
    label: 'From_card_ChangePrice',
    name: 'oldevent',
  });
}
