import { EAccountType, IAgentSchema } from '../../repositories/offer-card/entities/agent/AgentSchema';
import { IOfferSchema } from '../../repositories/offer-card/entities/offer/OfferSchema';
import { EStatus } from '../../repositories/offer-card/entities/offer_rosreestr_check/OfferRosreestrCheckSchema';

type TagType = EAccountType.ManagementCompany | EAccountType.RentDepartment | 'yeoman' | null;

export interface ICommercialOwnerShield {
  readonly showOwner: boolean;
  readonly showRosreestrVerified: boolean;
  readonly tagType: TagType;
}

export class CommercialOwnerShield implements ICommercialOwnerShield {
  public static readonly noneInstance = new CommercialOwnerShield(false, false, null);

  public static getInstance(offer: IOfferSchema, agent: IAgentSchema): CommercialOwnerShield {
    const { commercialOwnership, rosreestrCheck } = offer;
    const { accountType } = agent;

    if (!commercialOwnership?.isFromOwner) {
      return CommercialOwnerShield.noneInstance;
    }

    const isRosreestCheckSuccess = rosreestrCheck?.status === EStatus.Success;

    switch (accountType) {
      case EAccountType.ManagementCompany:
      case EAccountType.RentDepartment: {
        return new CommercialOwnerShield(true, isRosreestCheckSuccess, accountType);
      }

      default: {
        return new CommercialOwnerShield(true, isRosreestCheckSuccess, 'yeoman');
      }
    }
  }

  public readonly showOwner: boolean;
  public readonly showRosreestrVerified: boolean;
  public readonly tagType: TagType;

  private constructor(showOwner: boolean, showRosreestrVerified: boolean, tagType: TagType) {
    this.showOwner = showOwner;
    this.showRosreestrVerified = showRosreestrVerified;
    this.tagType = tagType;
  }
}
