import { connect, IAdFoxBannerOnErrorError } from '@cian/adfox-component';
import { withAdfoxPostViewAnalytics } from '@cian/postview-analytics-utils';
import { clone } from 'ramda';
import React, { JSX, memo, useCallback, useMemo, useState } from 'react';

import { useAdfoxContext } from 'shared/context/AdfoxContext';

import { OwnTemplates } from '../adfox/templates/OwnTemplates';

const AdfoxBannerConnected = connect(OwnTemplates);

type TAdFoxBannerProps = {
  position?: number;
};

export const AdfoxBanner = memo<TAdFoxBannerProps>(function AdfoxBanner(props) {
  const { position } = props;

  const { size, params, postViewAnalyticsData, isHidden: isAdfoxBannerHidden } = useAdfoxContext();

  /**
   * @description для премиум тарифа не должны показывать рекламные баннеры
   */
  const [isHidden, setIsHidden] = useState<boolean>(isAdfoxBannerHidden);

  const handleStub = useCallback<VoidFunction>(() => {
    setIsHidden(true);
  }, []);

  const handleError = useCallback<(error: IAdFoxBannerOnErrorError) => void>(() => {
    setIsHidden(true);
  }, []);

  return useMemo<JSX.Element | null>(() => {
    if (isHidden) {
      return null;
    }

    let postViewAnalytics = postViewAnalyticsData;

    if (position != null) {
      postViewAnalytics = clone(postViewAnalytics);

      postViewAnalytics.banner.position = position;
    }

    const Banner = withAdfoxPostViewAnalytics(
      AdfoxBannerConnected,
      {
        lazyLoad: { fetchMargin: 0.5, mobileScaling: 1 },
        params,
        onStub: handleStub,
        onError: handleError,
        ...size,
      },
      postViewAnalytics,
    );

    return <Banner />;
  }, [handleError, handleStub, isHidden, params, position, postViewAnalyticsData, size]);
});
