import * as React from 'react';

export const IconCancel = (props: React.HTMLAttributes<SVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <circle cx="8" cy="8" r="8" fill="currentColor" />
      {/* tslint:disable-next-line:max-line-length */}
      <path
        fill="#FFF"
        d="M8 7.11L4.89 4 4 4.89 7.11 8 4 11.11l.89.89L8 8.89 11.11 12l.89-.89L8.89 8 12 4.89 11.11 4 8 7.11z"
      />
    </g>
  </svg>
);
