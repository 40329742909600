import { ca } from '@cian/analytics';

export function trackPrintButtonClicked() {
  ca('event', {
    action: 'print_open_tools',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}

export function trackRemoveButtonClicked() {
  ca('event', {
    action: 'OfferPage',
    category: 'card',
    label: 'ClickDeleteButton',
    name: 'oldevent',
  });
}

export function trackUpgradeToPremiumClick() {
  ca('eventSite', {
    action: 'premiun',
    category: 'Promote_ad_click',
    label: 'Owner',
    name: 'oldevent',
    useLastProducts: true,
  });
}

export function trackUpgradeToTop3Click() {
  ca('eventSite', {
    action: 'top3',
    category: 'Promote_ad_click',
    label: 'Owner',
    name: 'oldevent',
    useLastProducts: true,
  });
}

export function trackUpgradeToPromotionClick() {
  ca('eventSite', {
    action: 'promotion',
    category: 'Promote_ad_click',
    label: 'Owner',
    name: 'oldevent',
    useLastProducts: true,
  });
}
