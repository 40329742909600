import * as React from 'react';

import { Headline } from './Headline';
import { Lines } from './Lines';
import * as styles from '../BookingInfo.css';
import { IBookingInfoSection } from '../types';

export function Sections({ children: sections }: { children: IBookingInfoSection[] | undefined }) {
  if (!sections || !sections.length) {
    return null;
  }

  return (
    <>
      {sections.map(({ headLine, lines }, index) => (
        <div className={styles['section']} key={headLine?.text || index}>
          <Headline headLine={headLine} />
          <Lines lines={lines} />
        </div>
      ))}
    </>
  );
}
