import * as React from 'react';

import * as styles from './styles.css';
import { NewbuildingBuilderLabel } from '../NewbuildingBuilderLabel';

interface INewbuildingBuilderStretchBannerProps {
  name: string;
  logo: React.ReactNode;
  background: React.ReactNode;
  isReliable: boolean;
  workYears: React.ReactNode;
  newbuildingWithOffers: React.ReactNode;
  offersCount: React.ReactNode;
  builderUrl: string;
  onClick(): void;
}

export function NewbuildingBuilderStretchBanner({
  name,
  logo,
  background,
  isReliable,
  workYears,
  newbuildingWithOffers,
  offersCount,
  builderUrl,
  onClick,
}: INewbuildingBuilderStretchBannerProps) {
  return (
    <a href={builderUrl} rel="noreferrer" target="_blank" className={styles['container']} onClick={onClick}>
      {background}
      <div className={styles['content']}>
        <div className={styles['left']}>
          <div className={styles['developer']}>
            <div className={styles['developer-preview']}>{logo}</div>
            <div className={styles['developer-content']}>
              <div className={styles['developer-subtitle']}>Застройщик</div>
              <div className={styles['developer-title']}>{name}</div>
            </div>
          </div>

          <div className={styles['factoids']}>
            {workYears}
            {newbuildingWithOffers}
            {offersCount}
          </div>
        </div>

        {isReliable && (
          <div className={styles['right']}>
            <NewbuildingBuilderLabel />
          </div>
        )}
      </div>
    </a>
  );
}
