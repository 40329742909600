import { Button, ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

const failureIcon = require('./assets/failure.svg') as string;

export interface IFailureStateProps {
  open: boolean;
  onClose(): void;
}

export const Failure = ({ open, onClose }: IFailureStateProps) => {
  return (
    <ModalWindow
      content={
        <div className={styles['container']}>
          <img src={failureIcon} />
          <h1 className={styles['title']}>Вы уже отправляли анкету по этому объявлению</h1>
          <div className={styles['text']}>Ответ собственника придёт вам в чат</div>
          <Button onClick={onClose}>Хорошо</Button>
        </div>
      }
      onClose={onClose}
      open={open}
    />
  );
};
