import { connect } from 'react-redux';

import { IOfferRefusedDispatchProps, IOfferRefusedProps, IOfferRefusedStoreProps, OfferRefused } from './';
import { openModal } from '../../store/popups/reclaim';

import type { DispatchReduxAction, IState } from '../../store';

export const mapStateToProps = (state: IState): IOfferRefusedStoreProps => ({
  isImported: state.offerData.offer.isImported,
  isOpenModalReclaim: state.reclaimModal.isOpen,
  newEdit: state.offerData.offer.newEdit,
  offences: state.offerData.offences,
  offerUserId: state.offerData.offer.userId,
  publishedUserId: state.offerData.offer.publishedUserId,
  status: state.offerData.offer.status,
  statusFetchModalReclaim: state.reclaimModal.statusFetch,
  userId: state.offerData.user && state.offerData.user.realtyUserId,
  userConfirmedFix: state.offerData.offer.userConfirmedFix,
});

export const mapDispatchToProps = (dispatch: DispatchReduxAction): IOfferRefusedDispatchProps => ({
  openReclaimModal: () => {
    dispatch(openModal());
  },
});

export const OfferRefusedContainer = connect<IOfferRefusedStoreProps, IOfferRefusedDispatchProps, IOfferRefusedProps>(
  mapStateToProps,
  mapDispatchToProps,
)(OfferRefused);
