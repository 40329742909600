import React from 'react';

import * as styles from './seoLink.css';

interface ISeoLinksProps {
  linkIndex: number;
  uri: string;
  name: string;
  onClick(): void;
}

export const SeoLink = (props: ISeoLinksProps) => {
  return (
    <a data-testid="seoLink" className={styles['link']} href={props.uri} onClick={props.onClick}>
      {props.name}
    </a>
  );
};
