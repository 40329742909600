import { connect } from 'react-redux';

import { FeedbackPopup } from './FeedbackPopup';
import { closeFeedbackPopup } from '../../../actions/feedback';

import type { DispatchReduxAction, IState } from '../../../../store';

const mapStateToProps = (state: IState) => ({
  isOpen: state.feedback.isPopupOpened,
});

const mapDispatchToProps = (dispatch: DispatchReduxAction) => ({
  onClose() {
    dispatch(closeFeedbackPopup());
  },
});

export const FeedbackPopupContainer = connect(mapStateToProps, mapDispatchToProps)(FeedbackPopup);
