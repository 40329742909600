import { propOr } from 'ramda';

import { IAgentSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IState } from '../store';
import { getAgentName, getProfileLink } from '../utils/agent';
import { getMasterAgentAvatar } from '../utils/getMasterAgentAvatar';
import { getMasterAgentId } from '../utils/getMasterAgentId';

export const getMasterOrSelfName = (state: IState) => {
  const { agent, offer } = state.offerData;

  if (agent) {
    if (agent.isSubAgent) {
      return agent.companyName;
    }

    return getAgentName(agent, offer);
  }

  return undefined;
};

export const getMasterOrSelfAvatar = (state: IState) => {
  const { agent } = state.offerData;

  if (agent) {
    if (agent.isSubAgent) {
      return (agent.masterAgent && getMasterAgentAvatar(agent.masterAgent)) || '';
    }

    return propOr<string, IAgentSchema, string>('', 'absoluteAvatarUrl', agent);
  }

  return '';
};

export const getMasterOrSelfLink = (state: IState) => {
  const { agent, offer, newObject } = state.offerData;

  if (agent?.isSubAgent) {
    return agent.masterAgent ? `/company/${getMasterAgentId(agent.masterAgent)}` : undefined;
  }

  return getProfileLink(agent, offer, propOr(null, 'developer', newObject));
};
