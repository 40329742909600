import { ca } from '@cian/analytics';

export function trackModalOpen() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'card',
    action: 'hover_sopr',
    label: 'deal_popup',
  });
}
