import { useVisitedOffersContext } from '@cian/frontend-visited-offers-widget';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectOfferId } from 'shared/selectors';

export const VisitedOffersUpdater = () => {
  const { isLoaded, setOfferVisited, isOfferVisited } = useVisitedOffersContext();
  const offerId = useSelector(selectOfferId);

  React.useEffect(() => {
    if (isLoaded && !isOfferVisited(offerId)) {
      setOfferVisited(offerId);
    }
  }, [isLoaded, offerId, setOfferVisited, isOfferVisited]);

  return null;
};
