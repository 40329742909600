import * as React from 'react';

import { TOfferStatus } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as styles from './index.css';

export interface IOfferUnpublished {
  status: TOfferStatus;
}

export class OfferUnpublished extends React.Component<IOfferUnpublished, {}> {
  public render() {
    /**
     * Если объявление в любом статусе отличном от "опубликовано" выводим плашку.
     */
    if (this.props.status === 'published') {
      return null;
    }

    return <div className={styles['container']}>Объявление снято с публикации</div>;
  }
}
