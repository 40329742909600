import * as React from 'react';

import * as styles from './Marquee.css';

export interface IMarqueeProps {
  text: string | undefined;
  scrollOffset?: number;
}

export const Marquee: React.FC<IMarqueeProps> = ({ text, scrollOffset = 0 }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const handleMouseOver = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (containerRef.current) {
      const target = e.target as HTMLDivElement;
      const containerWith = containerRef.current.offsetWidth;
      const childWith = target.offsetWidth;

      const diff = childWith - containerWith;

      if (diff > 0) {
        target.style.transition = `transform ${diff / 100}s linear`;
        target.style.transform = `translate(-${diff}px, 0)`;
      }
    }
  }, []);

  const handleMouseLeave = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLSpanElement;

    if (target) {
      target.style.transform = '';
      target.style.transition = '';
    }
  }, []);

  if (!text) {
    return null;
  }

  return (
    <div className={styles['container']}>
      <div
        ref={containerRef}
        className={styles['marquee']}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        <span style={{ paddingRight: scrollOffset }} className={styles['text']}>
          {text}
        </span>
      </div>
    </div>
  );
};
