import { Button, ModalWindow } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import { TStatusFetch } from 'shared/store/popups/reclaim';

import * as styles from './index.css';

export interface IReclaimModalProps {
  errorMessage: string;
  isOpen: boolean;
  realtyOfferId: number;
  statusFetch: TStatusFetch;
}

export interface IReclaimModalDispatchProps {
  closeReclaimModal(): void;
  submitReclaimConfirm(host: string, realtyOfferId: number): void;
}

export type IProps = IReclaimModalProps & IReclaimModalDispatchProps;

const INTERVAR_FOR_CLOSE_MODAL = 5000;

export class ReclaimModal extends React.Component<IProps, {}> {
  private timerForCloseModal: number | undefined;

  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.statusFetch === 'ok') {
      this.timerForCloseModal = window.setTimeout(() => this.props.closeReclaimModal(), INTERVAR_FOR_CLOSE_MODAL);
    }
  }

  public render() {
    return (
      <ModalWindow
        open={this.props.isOpen}
        onClose={this.props.closeReclaimModal}
        content={
          <>
            <div className={styles['head']}>{this.renderHeadMessage(this.props.statusFetch)}</div>
            <div
              className={cx([
                styles['error'],
                this.props.statusFetch === 'error' && styles['error--visible'],
                this.props.statusFetch === 'ok' && styles['error--hide'],
              ])}
            >
              {this.props.errorMessage}
            </div>
            {this.props.statusFetch !== 'ok' && (
              <div className={styles['body']}>
                <Button
                  theme="fill_primary"
                  loading={this.props.statusFetch === 'isLoad'}
                  size="XS"
                  onClick={this.handleConfirm}
                >
                  Подтвердить
                </Button>
                <Button size="XS" theme="fill_secondary" onClick={this.handleCloseModal}>
                  Отмена
                </Button>
              </div>
            )}
          </>
        }
      />
    );
  }

  private handleCloseModal = () => {
    if (this.timerForCloseModal) {
      clearTimeout(this.timerForCloseModal);
    }

    this.props.closeReclaimModal();
  };

  private renderHeadMessage = (statusFetch: TStatusFetch) => {
    if (statusFetch === 'ok') {
      return (
        <div>
          Объявление будет переопубликовано
          <br />
          при следующем импорте
        </div>
      );
    }

    return (
      <div className={styles['title']}>
        Вы подтверждаете, что объявление
        <br />
        в базе исправлено в соответствии
        <br />с требованиями модератора?
      </div>
    );
  };

  private handleConfirm = () => {
    this.props.submitReclaimConfirm(window.location.host, this.props.realtyOfferId);
  };
}
