import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CommentsButtonNew } from '../../components/CommentsButtonNew';
import { trackOpenCommentsBlock as trackCommentsBlockOpen } from '../../components/comments/button/tracking';
import { getCommentBlock, selectIsAuthenticated } from '../../selectors';
import { changeEdditing, closeComments, openComments, openCommentsAfterAuth } from '../../store/comments';
import { callAuthenticationPopup } from '../../utils/authentication';

export function CommentsButtonContainer() {
  const dispatch = useDispatch();
  const { isOpen, commentText } = useSelector(getCommentBlock);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const clickHandle = React.useCallback(() => {
    if (isOpen) {
      if (commentText) {
        return;
      } else {
        dispatch(closeComments());
      }
    } else {
      if (!isAuthenticated) {
        callAuthenticationPopup({ source: 'note', viewData: { headingType: 'addNote' } });
        dispatch(openCommentsAfterAuth());
      } else {
        dispatch(changeEdditing(true));
        dispatch(openComments());
      }
      trackCommentsBlockOpen();
    }
  }, [isOpen, commentText]);

  return <CommentsButtonNew commented={isOpen} onClick={clickHandle} />;
}
