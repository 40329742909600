import cx from 'clsx';
import { Field } from 'formik';
import * as React from 'react';

import { BottomArrowIcon } from './BottomArrowIcon';
import { TopArrowIcon } from './TopArrowIcon';
import * as styles from '../../index.css';
import { useFieldHasErrors } from '../utils';

export const Comment = () => {
  const [isCommentOpen, setIsCommentOpen] = React.useState(false);
  const invalid = useFieldHasErrors('comment');
  const toggleIsCommentOpen = React.useCallback(() => setIsCommentOpen(!isCommentOpen), [isCommentOpen]);

  return (
    <div className={cx(styles['field'], styles['span-columns'])}>
      <div onClick={toggleIsCommentOpen} className={cx(styles['label-comment'], invalid && styles['label-invalid'])}>
        <span className={styles['comment-text']}>Комментарий</span>
        {isCommentOpen ? TopArrowIcon : BottomArrowIcon}
      </div>
      {isCommentOpen && (
        <Field name="comment" type="text" as="textarea" className={styles['textarea']} rows={6} maxLength="500" />
      )}
    </div>
  );
};
