import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { SuperAgentLevelView } from 'shared/components/AgencyBrandingAsideCard/SuperAgentLevelView';
import { selectOffer } from 'shared/selectors';
import { selectIsQualityLevelInfoShow } from 'shared/selectors/offerData/selectIsQualityLevelInfoShow';

import { trackOpenTooltip } from './tracking';
import { useApplicationContext } from '../ApplicationContext';

export const SuperAgentLevelViewContainer: React.FC = () => {
  const qualityLevelInfo = useSelector(selectIsQualityLevelInfoShow, shallowEqual);
  const offer = useSelector(selectOffer, shallowEqual);
  const { config } = useApplicationContext();
  const list = config.get<string>('moderation.QualityLevelTooltip') || '';
  const tooltip = list.split(',');

  if (!qualityLevelInfo.showSuperAgent && !qualityLevelInfo.showSuperHost) {
    return null;
  }

  const handleOpenTooltip = () => {
    trackOpenTooltip(offer.id, qualityLevelInfo.showSuperAgent);
  };

  return (
    <SuperAgentLevelView
      isSuperAgent={!!qualityLevelInfo.showSuperAgent}
      tooltip={tooltip}
      onOpenTooltip={handleOpenTooltip}
    />
  );
};
