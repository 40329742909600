import * as React from 'react';

export const IconComments = (props: React.HTMLAttributes<SVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79 51">
    <g fill="none" fillRule="nonzero">
      {/* tslint:disable-next-line:max-line-length */}
      <path
        fill="#2B87DB"
        d="M11.211 43.831l.662.293c2.385 1.055 5.034 1.622 7.778 1.64 6.379.038 12.077-2.93 14.912-7.556l2.302 1.411c-3.344 5.457-9.933 8.889-17.23 8.844a22.312 22.312 0 0 1-8.18-1.585l-6.505 4.15-.258-8.6C1.716 39.407.027 35.579.002 31.523c-.058-9.358 8.703-16.798 19.46-16.732 3.165.02 6.229.687 8.988 1.931l-1.11 2.462c-2.415-1.09-5.106-1.676-7.894-1.693-9.343-.057-16.792 6.269-16.744 14.016.021 3.475 1.543 6.774 4.256 9.365l.4.383.148 4.94 3.705-2.363z"
      />
      {/* tslint:disable-next-line:max-line-length */}
      <path
        fill="#E7526E"
        d="M69.523 35.164l.425-.382c3.938-3.54 6.16-8.03 6.212-12.741.121-10.498-10.582-19.013-24.054-18.86-13.493.158-24.438 8.956-24.56 19.483-.122 10.499 10.583 19.016 24.056 18.859 3.953-.044 7.776-.835 11.227-2.286l.628-.264 5.797 3.452.269-7.261zm2.262 11.91l-8.546-5.089c-3.603 1.422-7.545 2.193-11.606 2.238-14.869.173-26.928-9.422-26.786-21.59C24.987 10.493 37.227.654 52.075.481 66.943.312 79 9.905 78.86 22.072c-.06 5.324-2.468 10.36-6.681 14.326l-.394 10.677z"
      />
    </g>
  </svg>
);
