import * as React from 'react';
import Slider, { Settings } from 'react-slick';

import './slick.css';

interface ICustomSliderProps extends Settings {
  children: React.ReactNode;
}

export const CustomSlider = React.forwardRef<Slider, ICustomSliderProps>(({ children, ...rest }, ref) => {
  return (
    <Slider {...rest} ref={ref}>
      {children}
    </Slider>
  );
});

CustomSlider.displayName = 'CustomSlider';
