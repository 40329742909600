import { ca } from '@cian/analytics';

export type TTrackingAction = 'top3' | 'premium' | 'color' | 'standard';

export const TrackingAction = {
  Color: 'color' as TTrackingAction,
  Premium: 'premium' as TTrackingAction,
  Top3: 'top3' as TTrackingAction,
  Standard: 'standard' as TTrackingAction,
};

export const trackClick = (action: TTrackingAction) => {
  ca('event', {
    action,
    category: 'PromoVAS',
    label: 'from_card_label',
    name: 'oldevent',
  });
};
