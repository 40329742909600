import { Button, ModalWindow, RadioGroup } from '@cian/ui-kit';
import * as React from 'react';

import { IChangePrintOptions } from 'shared/store/popups/print';

import * as styles from './index.css';
import { trackPrint } from './tracking';

export interface IPrintModalProps {
  isCommentsVisible: boolean;
  isOpen: boolean;
  isPhonesVisible: boolean;
  isPrintModeEnabled: boolean;
  changePrintOptions(options: IChangePrintOptions): void;
  toggle(isOpen?: boolean): void;
}

const printPhonesValues = [
  {
    label: 'С телефонами',
    value: 1,
  },
  {
    label: 'Без телефонов',
    value: 0,
  },
];

const printCommentValues = [
  {
    label: 'С комментарием',
    value: 1,
  },
  {
    label: 'Без комментария',
    value: 0,
  },
];

export class PrintModal extends React.PureComponent<IPrintModalProps, {}> {
  public render() {
    return (
      <ModalWindow
        content={
          <form onSubmit={this.handleSubmit} className={styles['form']}>
            <div className={styles['options-container']}>
              <div className={styles['options']}>
                <RadioGroup
                  name="phones"
                  value={Number(this.props.isPhonesVisible)}
                  onChange={this.handlePrintPhonesChange}
                  options={printPhonesValues}
                />
              </div>
              <div className={styles['options']}>
                <RadioGroup
                  name="comments"
                  value={Number(this.props.isCommentsVisible)}
                  onChange={this.handlePrintCommentsChange}
                  options={printCommentValues}
                />
              </div>
            </div>
            <div className={styles['actions']}>
              <Button size="XS" onClick={trackPrint}>
                Распечатать
              </Button>
              <Button size="XS" onClick={this.handleClickOnCloseButton} theme="fill_secondary">
                Отменить
              </Button>
            </div>
          </form>
        }
        title="Распечатать объявление"
        open={this.props.isOpen}
        onClose={this.close}
      />
    );
  }

  private handleClickOnCloseButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.close();
  };

  private close = () => {
    this.props.toggle(false);
  };

  private handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.props.changePrintOptions({
      isPrintModeEnabled: true,
    });
    this.close();
    setTimeout(() => {
      window.print();
      this.props.changePrintOptions({
        isPrintModeEnabled: false,
      });
    }, 500);
  };

  private handlePrintPhonesChange = (_: React.ChangeEvent, isPhonesVisible: number) => {
    this.props.changePrintOptions({
      isPhonesVisible: isPhonesVisible === 1,
    });
  };

  private handlePrintCommentsChange = (_: React.ChangeEvent, isCommentsVisible: number) => {
    this.props.changePrintOptions({
      isCommentsVisible: isCommentsVisible === 1,
    });
  };
}
