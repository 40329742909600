import { ca } from '@cian/analytics';

import { IAgentSchema, IOfferSchema, IUserSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IRosreestrInfo } from '../../types/offerData';
import { getIsCommercialByOffer } from '../../utils/getIsCommercialByOffer';
import { getCustomUrl } from '../../utils/tracking/getCustomUrl';
import { getPage } from '../../utils/tracking/getPage';
import { getPartner } from '../../utils/tracking/getPartner';
import { getUser } from '../../utils/tracking/getUser';
import { getProducts } from '../../utils/tracking/get_products';

export type TRealtorLabel = 'to_realtorCard_name' | 'to_realtorCard_qty' | 'to_realtorCard_ava';

export type TBuilderLabel =
  | 'to_developerCard_name'
  | 'to_developerCard_ready_JK'
  | 'to_developerCard_in_progress_JK'
  | 'to_developerCard_more';

interface ITrackShowPhoneClick {
  offer: IOfferSchema;
  agent: IAgentSchema | null;
  abGroup: number;
  user: IUserSchema | null;
  kpId: number | null;
  isBot?: boolean;
  rosreestrInfo: IRosreestrInfo | null;
}

export function trackClickShowPhone({ offer, agent, abGroup, user, kpId, isBot, rosreestrInfo }: ITrackShowPhoneClick) {
  const products = getProducts({ agent, offer, kpId, rosreestrInfo });

  const isNeedToEnrichEvent = getIsCommercialByOffer(offer);

  ca('eventEbc', {
    action: `Open_card_agent_block${isBot ? '_bot' : ''}`,
    category: 'Phones',
    label: getCustomUrl(offer, agent),
    name: 'oldevent',
    ...(isNeedToEnrichEvent && { event: 'oldevent' }),
    page: {
      ...getPage(agent, offer),
      ...(isNeedToEnrichEvent && { offerType: 'offer' }),
    },
    partner: getPartner(user, offer),
    products: [
      {
        ...products,
        variant: products.variant ? products.variant.split('/') : [],
      },
    ],
    user: getUser(user, abGroup),
  });
}
