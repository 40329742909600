import { useSelector } from 'react-redux';

import { IDeveloperLayoutProps, IDeveloperStats } from '../../../../components/BottomAuthorBlockNew';
import { selectIsAgencyBrandingAvailable } from '../../../../selectors';
import { getIsFromBuilder } from '../../../../selectors/newObject';
import { selectIsNewbuildingPremiumRate } from '../../../../selectors/newbuildingPremiumRate';
import { getNewbuildingsListQueryStringUrl } from '../../../../utils/formatters';
import { selectCompanyFoundationTime, selectDeveloper } from '../../../AuthorAsideBrandContainer/selectors/selectors';

export const useDeveloperProps = (): IDeveloperLayoutProps | null => {
  const developer = useSelector(selectDeveloper);
  const isPremiumNewbuilding = useSelector(selectIsNewbuildingPremiumRate);
  const isAgencyBrandingAvailable = useSelector(selectIsAgencyBrandingAvailable);
  const isFromBuilder = useSelector(getIsFromBuilder);

  const companyFoundationTime = useSelector(selectCompanyFoundationTime);

  if (!developer || !isFromBuilder || isPremiumNewbuilding || isAgencyBrandingAvailable) {
    return null;
  }

  const stats: IDeveloperStats[] = [];

  if (companyFoundationTime) {
    stats.push({
      title: 'Год основания: ',
      value: `${companyFoundationTime}`,
    });
  }

  if (developer.stats && developer.stats.done) {
    stats.push({
      title: 'Сдано: ',
      value: `${developer.stats.done.text ?? 'Нет сданных ЖК'}`,
      link: getNewbuildingsListQueryStringUrl(developer.stats.done.qs as string),
    });
  }

  if (developer.stats && developer.stats.progress) {
    stats.push({
      title: 'Строится: ',
      value: `${developer.stats.progress.text ?? 'Нет строящихся ЖК'}`,
      link: getNewbuildingsListQueryStringUrl(developer.stats.progress.qs as string),
    });
  }

  return {
    stats,
    name: developer.name,
    logoUrl: developer.logoUrl,
    developerUrl: developer.url,
  };
};
