import { numberToPrettyString } from '@cian/utils/lib/shared/number_helpers';
import { isNil } from 'ramda';

import { ECoworkingOfferType } from 'shared/types/api-models/offer-card/v1/get-offer-data';
import { getIsWorkplaceInCoworking } from 'shared/utils/validators';

import { IPriceSelectorOptions } from './types';
import { IState } from '../../store';
import { FDealType, getDealType } from '../../utils/deal_type';
import { CurrencySymbol, getPriceRentTimeSuffix, withNoBreakSpaces } from '../../utils/formatters';

export function selectCommercialPriceAside(state: IState, options: IPriceSelectorOptions = {}): string | null {
  const {
    offerData: { offer },
  } = state;

  const { useSharePrefix, currencySign: currencySignOption } = options;

  const dealType = getDealType(offer);
  const postfix = getPriceRentTimeSuffix(offer);
  const sharePrefix = useSharePrefix ? 'за ' : '';
  const currencySign = currencySignOption || CurrencySymbol.Rur;
  const { priceTotalPerMonthRur } = offer;

  if (dealType & FDealType.Rent) {
    if (!isNil(priceTotalPerMonthRur)) {
      if (!isNil(offer.minArea) && !isNil(offer.minPriceTotalPerMonthRur)) {
        const from = withNoBreakSpaces(numberToPrettyString(Math.round(offer.minPriceTotalPerMonthRur)));
        /** Для равных цен у коммерческих мультиобъявлений выводим только цену "от" */
        if (offer.minPriceTotalPerMonthRur === offer.priceTotalPerMonthRur) {
          return `${from} ${currencySign}${postfix}`;
        }
        const to = withNoBreakSpaces(numberToPrettyString(Math.round(priceTotalPerMonthRur)));

        return `${from} – ${to} ${currencySign}${postfix}`;
      } else {
        const {
          bargainTerms: { priceForWorkplace },
          coworkingOfferType,
          priceTotalPerMonthRur,
        } = offer;

        const isWorkplaceInCoworking = getIsWorkplaceInCoworking(coworkingOfferType);
        const isFlexibleOffice = coworkingOfferType === ECoworkingOfferType.Office;

        const isCoworkingAlternativeBehavior: boolean =
          !!coworkingOfferType && (isFlexibleOffice || isWorkplaceInCoworking);

        const priceValue =
          isCoworkingAlternativeBehavior && priceForWorkplace ? priceForWorkplace : priceTotalPerMonthRur;

        const price = `${numberToPrettyString(Math.round(priceValue as number)).replace(' ', '\u00a0')}`;

        return `${sharePrefix}${price} ${currencySign}${postfix}`;
      }
    }

    return null;
  }

  if (!isNil(offer.priceTotalRur)) {
    if (!isNil(offer.minPriceTotalRur)) {
      const from = withNoBreakSpaces(numberToPrettyString(Math.round(offer.minPriceTotalRur)));
      const to = withNoBreakSpaces(numberToPrettyString(Math.round(offer.priceTotalRur)));

      return `${from} – ${to} ${currencySign}`;
    }
    const price = withNoBreakSpaces(numberToPrettyString(Math.round(offer.priceTotalRur)));

    return `${sharePrefix}${price} ${currencySign}`;
  }

  return null;
}
