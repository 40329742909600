import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { AgentLifeTime } from 'shared/components/BottomAuthorBlockNew';
import { selectOfferAgent } from 'shared/selectors';
import { getAgentExperienceCIAN } from 'shared/utils/agent';

export const AgentLifeTimeContainer: React.FC = () => {
  const agent = useSelector(selectOfferAgent, shallowEqual);
  const lifeTime = agent && getAgentExperienceCIAN(agent);

  if (!lifeTime) {
    return null;
  }

  return (
    <li>
      <AgentLifeTime lifeTime={lifeTime} />
    </li>
  );
};
