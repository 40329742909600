import { Overline, UIHeading3 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

export interface INewbuildingPremiumBuilderLogoProps {
  logoUrl?: string | null;
  builderName: string;
  url: string;
  onClick(): void;
}

export const NewbuildingPremiumBuilderLogo: React.FC<React.PropsWithChildren<INewbuildingPremiumBuilderLogoProps>> = ({
  logoUrl,
  builderName,
  url,
  onClick,
}) => {
  return (
    <div className={style['logo']} data-testid="NewbuildingPremiumBuilderLogo">
      <a data-testid="builder-logo-link" href={url} target="_blank" rel="noreferrer" onClick={onClick}>
        <div
          className={style['img']}
          style={logoUrl ? { backgroundImage: `url(${logoUrl})` } : { backgroundColor: `#f1f2f4` }}
        />

        <Overline>Застройщик</Overline>
        <span className={style['builder']}>
          <UIHeading3 color="text-main-default" as="h3">
            {builderName}
          </UIHeading3>
        </span>
      </a>
    </div>
  );
};
