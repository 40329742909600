interface IGetPhoneButtonTextParams {
  isDealRent: boolean;
  isNewbuildingFromBuilder: boolean;
  isSuburbanFromBuilder: boolean;
}

export const getPhoneButtonText = ({
  isDealRent,
  isNewbuildingFromBuilder,
  isSuburbanFromBuilder,
}: IGetPhoneButtonTextParams): string => {
  if (isDealRent) {
    return 'Назначить просмотр';
  } else if (isNewbuildingFromBuilder || isSuburbanFromBuilder) {
    return 'Контакты застройщика';
  }

  return 'Показать телефон';
};
