import { selectIsOfferWithMultiAds, selectOffer } from './offerData';
import { IState } from '../store';
import { getFormattedCoworkingTitle } from '../utils/getFormattedCoworkingTitle';
import {
  getCommercialAreaForMultiAds,
  getCommercialBuildingClass,
  getOfferTitle,
  getSquareInformation,
  getTitlePrefix,
} from '../utils/offer_helpers';
import { getOfferType, isCommercial } from '../utils/offer_type';

export const selectOfferTitle = (state: IState) => {
  const offer = selectOffer(state);
  const offerType = getOfferType(offer);
  const businessShoppingCenter = state.offerData.businessShoppingCenter;
  const commercialBuildingClass = getCommercialBuildingClass(offer.building, businessShoppingCenter);
  const isCommercialOfferWithMultiAds = selectIsOfferWithMultiAds(state);
  const title = getOfferTitle(offer);
  const squareInformation = getSquareInformation(offer);
  const multiAdsSquareInformation = getCommercialAreaForMultiAds(offer.minArea, offer.maxArea);
  const titlePrefix = getTitlePrefix(offer);

  if (offer.coworkingOfferType) {
    const coworkingTitle = getFormattedCoworkingTitle({
      title,
      squareInformation,
      workplaceCount: offer.workplaceCount,
      coworkingOfferType: offer.coworkingOfferType,
    });

    return `${titlePrefix} ${coworkingTitle}`;
  }

  if (isCommercial(offerType) && commercialBuildingClass) {
    return `${titlePrefix} ${title} ${commercialBuildingClass}`;
  }

  if (isCommercialOfferWithMultiAds && multiAdsSquareInformation) {
    return `${titlePrefix} ${title}, ${multiAdsSquareInformation}`;
  }

  if (!isCommercialOfferWithMultiAds && squareInformation) {
    return `${titlePrefix} ${title}, ${squareInformation}`;
  }

  return `${titlePrefix} ${title}`;
};
