import * as React from 'react';

import * as styles from './styles.css';
import { PriceItem } from '../PriceItem';

interface IPricesBlockProps {
  estimationPrice: React.ReactChild;
  estimationTooltip?: React.ReactChild | null;
  offerPrice?: React.ReactChild | null;
  offerTooltip?: React.ReactChild | null;
  estimationLabel: string;
  offerLabel: string;
}

export const PricesBlock: React.FC<IPricesBlockProps> = props => {
  const { estimationPrice, estimationTooltip, offerPrice, offerTooltip, offerLabel, estimationLabel } = props;

  return (
    <div className={styles['container']}>
      <div className={styles['item']}>
        <PriceItem
          testId="valuation_estimationPrice"
          price={estimationPrice}
          caption={estimationLabel}
          captionTooltip={estimationTooltip}
        />
      </div>
      {offerPrice && (
        <div className={styles['item']}>
          <PriceItem
            testId="valuation_offerPrice"
            price={offerPrice}
            caption={offerLabel}
            priceTooltip={offerTooltip}
          />
        </div>
      )}
    </div>
  );
};

PricesBlock.displayName = 'PricesBlock';
