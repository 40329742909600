import { ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import { QuestionBlockContainer } from './QuestionBlock';
import * as styles from './styles/styles.css';
import { InfoBlockContainer } from '../../InfoBlock';

interface IFeedbackPopupProps {
  isOpen: boolean;
  onClose(): void;
}

export function FeedbackPopup({ isOpen, onClose }: IFeedbackPopupProps) {
  return (
    <ModalWindow
      open={isOpen}
      onClose={onClose}
      content={
        <div className={styles['body']}>
          <InfoBlockContainer />
          <QuestionBlockContainer />
        </div>
      }
    />
  );
}
