import { LinkButton } from '@cian/ui-kit';
import * as React from 'react';

import { Link } from 'shared/common/components/link';

import * as styles from './index.css';
import { trackPrimaryActionButtonClick, trackSecondaryActionButtonClick } from '../tracking';

export interface ITop3Props {
  isVisible: boolean;
  /** Ссылка смены размещения */
  recoveryLink: string | null;
  /** Флаг активности ML-ранжирования */
  isMlSearchEnabled: boolean;
}

export const Top3 = (props: ITop3Props) => {
  const { isVisible, recoveryLink, isMlSearchEnabled } = props;

  if (!isVisible || !recoveryLink) {
    return null;
  }

  return (
    <article className={styles['container']}>
      <h3 className={styles['title']}>Быстрее с {isMlSearchEnabled ? 'Топ' : 'Топ-3'}</h3>
      <div className={styles['description']}>
        {isMlSearchEnabled
          ? `Топ-объявления самые заметные в результатах поиска за счёт больших фотографий и брендирования.
            Такие объявления показываются в 3 раза чаще обычных.`
          : `Размещение в Топ-3 позволяет получить в пять раз
            больше звонков и&nbsp;привлечь максимум внимания к нужному объекту.`}
      </div>
      <footer className={styles['actions']}>
        <span className={styles['primary-action']}>
          <LinkButton
            target="_blank"
            href={`${recoveryLink}?buy=104`}
            onClick={() => trackPrimaryActionButtonClick('top3')}
          >
            Купить {isMlSearchEnabled ? 'Топ' : 'Топ-3'}
          </LinkButton>
        </span>
        <Link href={recoveryLink} onClick={trackSecondaryActionButtonClick} target="_blank">
          Сменить тип размещения
        </Link>
      </footer>
    </article>
  );
};
