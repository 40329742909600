/* istanbul ignore next */
import { parseCookies } from '@cian/utils';

import { IOfferSchema, IUserSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { ISoprPartner } from './types';

export function getPartner(user: IUserSchema | null, offer: IOfferSchema): ISoprPartner {
  const cookies = parseCookies(document.cookie);

  return {
    CriteoEmail: user && user.ga && user.ga.emailMd5 ? user.ga.emailMd5 : '',
    CriteoNewCustomer: !cookies['_CIAN_GK'] ? '1' : '0',
    CriteoProducts: [],
    FB_City: offer.trackingData && offer.trackingData.fbCity ? offer.trackingData.fbCity : '',
    FB_Region: offer.trackingData && offer.trackingData.fbRegion ? offer.trackingData.fbRegion : '',
  };
}
