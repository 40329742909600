import * as React from 'react';

export const IconOk = (props: React.HTMLAttributes<SVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <rect fill="currentColor" width="32" height="32" rx="16" ry="16"></rect>
    {/* tslint:disable-next-line:max-line-length */}
    <path
      fill="#ffffff"
      d="M16 16.23a3.69 3.69 0 1 0-3.69-3.69A3.69 3.69 0 0 0 16 16.23zM16 11a1.53 1.53 0 1 1-1.53 1.53A1.53 1.53 0 0 1 16 11zM17.49 19.24a6.91 6.91 0 0 0 2.14-.89 1.08 1.08 0 0 0-1.15-1.83 4.69 4.69 0 0 1-5 0 1.08 1.08 0 0 0-1.15 1.83 6.92 6.92 0 0 0 2.14.89l-2.03 2.06A1.08 1.08 0 0 0 14 22.83l2-2 2 2a1.08 1.08 0 0 0 1.53-1.53z"
    ></path>
  </svg>
);
