import { Button, NumberInput, UIText2 } from '@cian/ui-kit';
import { IconActionChevronRight16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './styles.css';

export interface IPriceRequestFormProps {
  discountPrice: string;
  onClick(): void;
  onChange: (value: number | undefined) => void;
}

export function PriceRequestForm({ discountPrice, onClick, onChange }: IPriceRequestFormProps) {
  return (
    <div className={styles['container']}>
      <UIText2>Предложите свою цену</UIText2>
      <div className={styles['form']}>
        <div className={styles['input']}>
          <NumberInput placeholder={`Например, ${discountPrice}`} onChange={value => onChange(value)} size="M" />
        </div>
        <div className={styles['button']}>
          <Button
            theme="fill_secondary"
            size="M"
            beforeIcon={<IconActionChevronRight16 color="icon-main-default" />}
            onClick={onClick}
          />
        </div>
      </div>
    </div>
  );
}
