import { Select } from '@cian/ui-kit';
import clsx from 'clsx';
import { Field, useFormikContext } from 'formik';
import * as React from 'react';

import * as childrenInfoStyles from './styles.css';
import { IFormValues } from '../../DealRentModal';
import * as styles from '../../index.css';
import { Label } from '../Label';
import { useFieldHasErrors } from '../utils';

export interface IChildrenInfoItemProps {
  index: number;
  options: string[];
}

export const ChildrenInfoItem = ({ index, options }: IChildrenInfoItemProps) => {
  const { setFieldValue } = useFormikContext<IFormValues>();
  const name = `childrenInfo[${index}]`;
  const invalid = useFieldHasErrors(name as 'childrenInfo');
  const onChange = React.useCallback((value: string) => setFieldValue(name, value), [setFieldValue, name]);
  const selectOptions = React.useMemo(() => options.map(value => ({ value, label: value })), [options]);

  return (
    <div key={name} className={clsx(styles['field'], childrenInfoStyles['field'])}>
      <Label invalid={invalid}>{`Ребенок ${index + 1}`}</Label>
      <Field name={name}>
        {() => <Select options={selectOptions} onChange={onChange} title="Выберите возраст" invalid={invalid} />}
      </Field>
    </div>
  );
};
