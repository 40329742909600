import { UIHeading4, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';
import { trackShowThisIsShareBanner } from './tracking';

export interface IDisclaimerProps {
  testId: string;
  header: string;
  title: string;
  sendAnalytics?: boolean;
}

export const Disclaimer: React.FC<IDisclaimerProps> = ({ testId, header, title, sendAnalytics }) => {
  React.useEffect(() => {
    if (sendAnalytics) {
      trackShowThisIsShareBanner();
    }
  }, [sendAnalytics]);

  return (
    <div data-testid={testId} className={style['item']}>
      <UIHeading4>{header}</UIHeading4>
      <UIText2>{title}</UIText2>
    </div>
  );
};
