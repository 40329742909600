import { IAuthor, EAccountType } from 'shared/types/api-models/offer-card/v1/identicalOffers';

import { IAuthorView } from '../../../types/identicalOffersView';

interface IFormatAccountTypeParams {
  isHomeowner: boolean;
  accountType?: EAccountType | null;
}

export function formatAuthor({ id, accountType, isHomeowner, avatarUrl, name, ...author }: IAuthor): IAuthorView {
  return {
    ...author,
    id: formatAuthorId(id),
    avatarUrl: avatarUrl || null,
    name: name || null,
    accountType: formatAccountType({ isHomeowner, accountType }),
  };
}

export function formatAuthorId(id: number): string {
  return `ID ${id}`;
}

export function formatAccountType({ isHomeowner, accountType }: IFormatAccountTypeParams): string {
  if (isHomeowner) {
    return 'Собственник';
  }

  switch (accountType) {
    case EAccountType.Agency:
      return 'Агентство недвижимости';
    case EAccountType.ManagementCompany:
      return 'Управляющая компания';
    case EAccountType.RentDepartment:
      return 'Отдел аренды';
    case EAccountType.Specialist:
      return 'Специалист';
    default:
      return '';
  }
}
