import { Radio } from '@cian/ui-kit';
import * as React from 'react';

import { TDealType } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as styles from './styles.css';
import { AddedUserInfo } from '../AddedUserInfo';
import { GetPrice } from '../GetPrice';
import { Other } from '../Other';
import { OtherSite } from '../OtherSite';

export enum EReason {
  Avans = 'Avans',
  Closed = 'Closed',
  OtherSite = 'OtherSite',
  NotSatisfiedCian = 'NotSatisfiedCian',
  Nonliquid = 'Nonliquid',
  Temporary = 'Temporary',
  Other = 'Other',
}

export interface IReason {
  label: string;
  value: EReason;
}

export interface IReasonsProps {
  value?: string;
  isAgent?: boolean | null;
  dealType: TDealType;
  infoFromUser: string;
  onChange(value: string): void;
  handleChangeInfoFromUser(value: string): void;
}

const REASONS: IReason[] = [
  {
    label: 'Внесен аванс/подготовка к сделке',
    value: EReason.Avans,
  },
  {
    label: 'Сделка закрыта',
    value: EReason.Closed,
  },
  {
    label: 'Продал с другого сайта',
    value: EReason.OtherSite,
  },
  {
    label: 'Не устраивает Циан',
    value: EReason.NotSatisfiedCian,
  },
  {
    label: 'Неликвидный объект',
    value: EReason.Nonliquid,
  },
  {
    label: 'Удаляю временно, восстановлю позже',
    value: EReason.Temporary,
  },
  {
    label: 'Другая причина',
    value: EReason.Other,
  },
];
const REASONS_FOR_SALE = [EReason.Avans, EReason.OtherSite, EReason.NotSatisfiedCian];

export function Reasons({ value, infoFromUser, dealType, isAgent, onChange, handleChangeInfoFromUser }: IReasonsProps) {
  const isViewExtendedReasosn = dealType === 'sale' && isAgent;
  /**
   * Для продажи и агентов выводим полный список,
   * для аренды отфильтровываем из него расширенные значения для продажи и агентов
   */
  const reasons = isViewExtendedReasosn ? REASONS : REASONS.filter(reason => !REASONS_FOR_SALE.includes(reason.value));

  const handleChangeReason = React.useCallback(
    (value: string) => {
      handleChangeInfoFromUser('');
      onChange(value);
    },
    [handleChangeInfoFromUser, onChange],
  );

  return (
    <>
      {reasons.map(reason => {
        return (
          <React.Fragment key={reason.value}>
            <div className={styles['reason']}>
              <Radio
                name="reasons"
                label={reason.label}
                value={reason.value}
                onChange={e => handleChangeReason(e.currentTarget.value)}
              />
            </div>
            {isViewExtendedReasosn && value === reason.value && (
              <>
                {value === EReason.Closed && (
                  <AddedUserInfo
                    title="Расскажете о цене сделки?"
                    text="Мы собираем обезличенную информацию о сделках, чтобы подготовить отчет о состоянии рынка недвижимости"
                    input={<GetPrice value={infoFromUser} onChange={handleChangeInfoFromUser} />}
                  />
                )}
                {value === EReason.OtherSite && (
                  <AddedUserInfo
                    text="Расскажете на каком сайте продали?"
                    input={<OtherSite value={infoFromUser} onChange={handleChangeInfoFromUser} />}
                  />
                )}
                {value === EReason.Other && (
                  <AddedUserInfo
                    text="Расскажете подробнее?"
                    input={<Other value={infoFromUser} onChange={handleChangeInfoFromUser} />}
                  />
                )}
              </>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}
