import { numberToPrettyString } from '@cian/utils/lib/shared/number_helpers';

import { IPriceSelectorOptions } from './types';
import { IState } from '../../store';
import { FDealType, getDealType, isRent } from '../../utils/deal_type';
import { CurrencySymbol, getPriceRentTimeSuffix } from '../../utils/formatters';
import { getRentPrice } from '../../utils/price';

export function selectResidentialPriceAside(state: IState, options: IPriceSelectorOptions = {}): string {
  const {
    featureToggle: { utilitiesTermsSettings },
    offerData: { offer },
  } = state;
  const dealType = getDealType(offer);
  const postfix = getPriceRentTimeSuffix(offer);
  const { useSharePrefix, currencySign: currencySignOption } = options;
  const sharePrefix = useSharePrefix ? 'за ' : '';
  const rentPrefix = dealType === FDealType.RentDaily ? 'от ' : '';
  const currencySign = currencySignOption || CurrencySymbol.Rur;

  return isRent(dealType)
    ? `${rentPrefix}${getRentPrice(offer, utilitiesTermsSettings, currencySign)}${postfix}`
    : `${sharePrefix}${numberToPrettyString(Math.round(offer.bargainTerms.prices.rur))} ${currencySign}${postfix}`;
}
