import * as React from 'react';

import * as styles from './LabelsLayoutNew.css';

interface ILabelsLayoutProps {
  children: React.ReactNode;
}

export function LabelsLayoutNew({ children }: ILabelsLayoutProps) {
  if (!children) {
    return null;
  }

  return <div className={styles['container']}>{children}</div>;
}
