import { IConfig } from '@cian/config/shared';

import { trackTelegramClick, trackWhatsappClick } from '../analytics';

interface IMessangerLink {
  type: 'telegram' | 'whatsapp';
  url: string;
  onClick?(): void;
}

export function prepareMessangers(config: IConfig): IMessangerLink[] {
  const result: IMessangerLink[] = [];
  const whatsappLink = config.get<string>('newbuildingConsultant.whatsappLink');
  const telegramLink = config.get<string>('newbuildingConsultant.telegramLink');

  if (whatsappLink) {
    result.push({
      type: 'whatsapp',
      url: whatsappLink,
      onClick: trackWhatsappClick,
    });
  }

  if (telegramLink) {
    result.push({
      type: 'telegram',
      url: telegramLink,
      onClick: trackTelegramClick,
    });
  }

  return result;
}
