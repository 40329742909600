import { Button } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './index.css';
import { trackSafeRentShow } from '../../store/deal-rent/tracking';

export interface IDealRentButtonProps {
  isAvailable: boolean;
  isLoading: boolean;
  buttonTitle: string;
  offerId: number;
  open(): void;
}

export const DealRentButton = ({ isAvailable, isLoading, buttonTitle, offerId }: IDealRentButtonProps) => {
  React.useEffect(() => {
    if (isAvailable) {
      trackSafeRentShow(offerId);
    }
  }, [isAvailable, offerId]);

  return isAvailable ? (
    <div className={styles['container']}>
      <Button size="XS" theme="fill_secondary" loading={isLoading}>
        {buttonTitle}
      </Button>
    </div>
  ) : null;
};
