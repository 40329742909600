import { IState } from 'shared/store';
import { IBestPlaceAnalyticsState } from 'shared/types/bestPlaceAnalytics';

type TSelectBestPlaceAnalyticsReportPrices = Pick<IBestPlaceAnalyticsState, 'amount' | 'discount' | 'discountPrice'>;

export const selectBestPlaceAnalyticsReportPrices = (state: IState): TSelectBestPlaceAnalyticsReportPrices => {
  const { amount, discount, discountPrice } = state.bestPlaceAnalytics;

  return { amount, discount, discountPrice };
};
