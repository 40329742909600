import { IUserSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IUserEBC } from '../../ebc/track_similar';

/**
 * Возвращает объект пользователя в формате требуемом SOPR_EBEC
 */
export function getUserEBC(user: IUserSchema | null, abGroup: number = 100): IUserEBC {
  return {
    userId: user && user.isAuthenticated ? Number(user.realtyUserId) : undefined,
    isAuthorized: Boolean(user && user.isAuthenticated),
    abGroup,
  };
}
