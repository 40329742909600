import { Input } from '@cian/ui-kit';
import * as React from 'react';

import { IPageData } from 'shared/store';

import { IconOk, IconVk } from './icons';
import * as style from './style.css';

export interface ISharingPopupProps {
  pageData: IPageData;
  onClick: (social: 'vk' | 'ok') => void;
}

export const SharingPopup: React.FC<React.PropsWithChildren<ISharingPopupProps>> = ({ pageData, onClick }) => {
  return (
    <div>
      <div className={style['social-links']}>
        <div data-testid="VkSocialLink" className={style['vk']} onClick={() => onClick('vk')}>
          <IconVk />
        </div>
        <div data-testid="OkSocialLink" className={style['ok']} onClick={() => onClick('ok')}>
          <IconOk />
        </div>
      </div>

      <div className={style['input-link']}>
        <Input
          /* tslint:disable-next-line:max-line-length */
          value={`https://${pageData.subdomain}.cian.ru/${pageData.dealType}/${pageData.offerType}/${pageData.offerId}`}
          readOnly
        />
      </div>
    </div>
  );
};
