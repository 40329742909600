import { UIHeading5, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

export interface IAddedUserInfoProps {
  title?: string;
  text: string;
  input: React.ReactNode;
}

export function AddedUserInfo({ title, text, input }: IAddedUserInfoProps) {
  return (
    <div className={styles['container']}>
      {title && (
        <div className={styles['title']}>
          <UIHeading5>{title}</UIHeading5>
        </div>
      )}
      <div className={styles['text']}>
        <UIText2>{text}</UIText2>
      </div>
      {input}
    </div>
  );
}
