import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTitle } from './utils/useTitle';
import { OfferStats } from '../../components/OfferMetaData/OfferStats';
import { OfferStatsPopupContainer } from '../../components/offer_stats_popup/container';
import { selectIsArchived, selectStats } from '../../selectors';
import { offerStatsPopupOpen } from '../../store/offer_stats_popup';

export const OfferStatsContainer = () => {
  const dispatch = useDispatch();
  const offerStats = useSelector(selectStats);
  const isOfferArchived = useSelector(selectIsArchived);
  const statsTitle = useTitle();

  const handleOfferStatsPopupOpen = React.useCallback(() => {
    dispatch(offerStatsPopupOpen());
  }, [dispatch]);

  if (isOfferArchived || !offerStats) {
    return null;
  }

  return (
    <>
      <OfferStats onClick={handleOfferStatsPopupOpen}>{statsTitle}</OfferStats>
      <OfferStatsPopupContainer />
    </>
  );
};
