import { IState } from '../../store';
import { isDailyrent as checkIsDailyrent } from '../../utils/offer_helpers';
import { selectOffer } from '../offerData';
import { selectIsOfferPublished } from '../offerData/selectIsOfferPublished';
import { selectIsAuctionBankObject } from '../selectIsAuctionBankObject';

export const selectIsUserSubscriptionToPriceChangeEnabled = (state: IState): boolean => {
  const offer = selectOffer(state);
  const subscriptionInfo = !!state.offerData.subscription;
  const isPublished = selectIsOfferPublished(state);
  const isCoworking = !!offer.coworkingOfferType;
  const isAuctionBankObject = selectIsAuctionBankObject(state);
  // не показываем изменение цены для посуточных объявок
  const isDailyrent = checkIsDailyrent(offer.category);

  return isPublished && subscriptionInfo && !isCoworking && !isAuctionBankObject && !isDailyrent;
};
