import * as React from 'react';
import { useSelector } from 'react-redux';

import * as styles from './MortgageTemplate.css';
import { selectTrackingPage, selectTrackingUser } from '../../../../selectors';
import { TAdFoxTemplatesParams } from '../OwnTemplates';
import { trackMortgageInRentClick, trackMortgageInRentShow } from '../mortgage_in_rent/tracking';

export const MortgageTemplate: React.FC<TAdFoxTemplatesParams> = ({ url, image }) => {
  const page = useSelector(selectTrackingPage);
  const user = useSelector(selectTrackingUser);

  const onClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      trackMortgageInRentClick(url, user, page);

      window.open(url);
    },
    [url, user, page],
  );

  React.useEffect(() => {
    trackMortgageInRentShow(url, user, page);
  }, []);

  return (
    <div
      onClick={onClick}
      style={{ backgroundImage: `url(${image})` }}
      className={styles['mortgage-template-wrapper']}
    />
  );
};
