import * as React from 'react';

import * as styles from './textWithLink.css';
import { trackLeaveFeedbackClick } from '../../tracking';

interface ITextWithLinkProps {
  onClick(): void;
}

export function TextWithLink({ onClick }: ITextWithLinkProps) {
  const handleClick = () => {
    trackLeaveFeedbackClick();

    onClick();
  };

  return (
    <>
      Если захотите оставить жалобу,{' '}
      <button type="button" className={styles['button']} onClick={handleClick}>
        напишите нам
      </button>
    </>
  );
}
