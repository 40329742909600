import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingOnlineStatus } from 'shared/components/NewbuildingOnlineStatus';
import { offerChatSelector, selectIsChatsEnabled } from 'shared/selectors/offerChat';

import { getBuilderOnlineStatusText } from './utils/getBuilderOnlineStatusText';
import { NewbuildingPushToCallStatusContainer } from '../NewbuildingPushToCallStatusContainer';

export const NewbuildingOnlineStatusContainer = () => {
  const { isOnline } = useSelector(offerChatSelector);
  const isChatsEnabled = useSelector(selectIsChatsEnabled);
  const builderOnlineStatusText = getBuilderOnlineStatusText(isOnline);

  if (isChatsEnabled) {
    return <NewbuildingOnlineStatus isOnline={isOnline} onlineStatusText={builderOnlineStatusText} />;
  }

  return <NewbuildingPushToCallStatusContainer />;
};
