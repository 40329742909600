import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { ArticleParagraph1, Button, ModalWindow, UIHeading2, LinkButton } from '@cian/ui-kit';
import { IconFunctionalBullet16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './SubsidisedMortgageModal.css';
import image from './assets/image.svg';

interface ISubsidisedMortgageModalProps {
  onClose(): void;
  onShow(buttonsList: string[]): void;
  onWriteMessage(): void;
  onShowPhone(): void;
  onSubmitApplication(): void;
  onLearnMore(): void;
  title: string;
  learnMoreUrl: string;
  texts: string[];
  isChatEnabled: boolean;
}

export const SubsidisedMortgageModal: React.FC<ISubsidisedMortgageModalProps> = ({
  onClose,
  onShow,
  onWriteMessage,
  onShowPhone,
  onLearnMore,
  title,
  texts,
  learnMoreUrl,
  isChatEnabled,
  onSubmitApplication,
}) => {
  const handleShown = React.useCallback(() => {
    onShow(['apply', isChatEnabled ? 'chat' : 'call']);
  }, [onShow, isChatEnabled]);

  return (
    <ModalWindow
      open
      onClose={onClose}
      portal
      size="XS"
      width={656}
      content={
        <ActionAfterViewObserver callback={handleShown} triggerOnce>
          <div className={styles['container']}>
            <img className={styles['image']} src={image} />
            <UIHeading2>{title}</UIHeading2>
            <ul className={styles['list']}>
              {texts.map((text, index) => (
                <li key={`text-${index}`} className={styles['item']}>
                  <IconFunctionalBullet16 color="icon-main-default" />
                  <ArticleParagraph1>{text}</ArticleParagraph1>
                </li>
              ))}
            </ul>
            <LinkButton
              theme="fill_white_primary"
              href={learnMoreUrl}
              target="_blank"
              rel="noreferrer"
              size="XS"
              onClick={onLearnMore}
            >
              Подробнее
            </LinkButton>
          </div>
        </ActionAfterViewObserver>
      }
      footer={
        <div className={styles['buttons']}>
          <Button size="L" onClick={onSubmitApplication}>
            Подать заявку
          </Button>
          {isChatEnabled ? (
            <Button size="L" theme="fill_secondary" onClick={onWriteMessage}>
              Написать в чат
            </Button>
          ) : (
            <Button size="L" theme="fill_white_primary" onClick={onShowPhone}>
              Показать телефон
            </Button>
          )}
        </div>
      }
    />
  );
};
