import { connect } from 'react-redux';

import { QuestionBlock } from './QuestionBlock';
import { closeFeedbackPopup, openFeedbackSentPopup } from '../../../../actions/feedback';

import type { DispatchReduxAction } from '../../../../../store';

export const QuestionBlockContainer = connect(undefined, (dispatch: DispatchReduxAction) => ({
  onYesButtonClick() {
    dispatch(closeFeedbackPopup());
    dispatch(openFeedbackSentPopup());
  },
  onDidNotPickUpPhoneButtonClick() {
    dispatch(closeFeedbackPopup());
    dispatch(openFeedbackSentPopup());
  },
}))(QuestionBlock);
