import { numberToPrettyString } from '@cian/utils';

import { IPrice, ECurrency, EPeriod } from 'shared/types/api-models/offer-card/v1/identicalOffers';

export function formatPrice(price: IPrice): string {
  const { value, currency, period } = price;
  const formattedValue = numberToPrettyString(value);
  const formattedCurrency = formatCurrency(currency);
  const formattedPeriod = period ? `/${formatPeriod(period)}` : '';

  return `${formattedValue} ${formattedCurrency}${formattedPeriod}`;
}

export function formatCurrency(currency: ECurrency): string {
  switch (currency) {
    case ECurrency.Eur:
      return '€';
    case ECurrency.Usd:
      return '$';
    case ECurrency.Rur:
    default:
      return '₽';
  }
}

export function formatPeriod(period: EPeriod): string {
  switch (period) {
    case EPeriod.Day:
      return 'сут.';
    case EPeriod.Month:
      return 'мес.';
    default:
      return '';
  }
}
