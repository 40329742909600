import { useBooleanState } from '@cian/react-utils';
import * as React from 'react';

import { ComplaintModal } from 'shared/components/complaint';

import { ComplaintButtonNew } from '../../components/ComplaintButtonNew';
import { HASH_URL_COMPLAIN } from '../../constants';

export function ComplaintButtonNewContainer() {
  const { setFalse, setTrue, state } = useBooleanState(false);

  React.useEffect(() => {
    if (window && window.location.hash === HASH_URL_COMPLAIN) {
      setTrue();
    }
  }, []);

  return (
    <ComplaintButtonNew onModalOpen={setTrue} modal={<ComplaintModal openModal={state} onCloseModal={setFalse} />} />
  );
}
