import { ca } from '@cian/analytics';

export function trackSubscriptionAuthorBrandInViewport() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'card',
    action: 'contact_show',
  });
}

type TAuthorBrandTarget = 'to_realtorCard_name' | 'to_realtorCard_ava' | 'to_realtorCard_sub';

export const trackGoToAgentCard = (target: TAuthorBrandTarget) => () => {
  ca('eventSite', {
    action: 'to_realtorCard_agent_block',
    category: 'card',
    label: target,
    name: 'oldevent',
  });
};
