import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { TransportAccessibilityEntry } from 'shared/components/TransportAccessibilityEntry';
import { transportAccessibilityRateSelector } from 'shared/selectors/newbuilding/transportAccessibilityRateSelector';

import { trackTransportAccessibilityEntryShown } from './tracking';
import { useMapEvents } from './useMapEvents';
import { useApplicationContext } from '../ApplicationContext';

export const TransportAccessibilityEntryContainer = () => {
  const { config } = useApplicationContext();

  const isEnabled = config.get<boolean>('Builders.OfferCardTransportResponsibilityEntry.Enabled');

  const rate = useSelector(transportAccessibilityRateSelector);

  const onClick = useMapEvents();

  if (!rate || !isEnabled) {
    return null;
  }

  return (
    <ActionAfterViewObserver callback={trackTransportAccessibilityEntryShown} triggerOnce>
      <TransportAccessibilityEntry rate={rate} onClick={onClick} />
    </ActionAfterViewObserver>
  );
};
