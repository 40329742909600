import * as React from 'react';

import * as styles from './styles.css';

export interface IMainNewProps {
  title: React.ReactNode;
  geo: React.ReactNode;
  toolbar: React.ReactNode;
  comment: React.ReactNode;
  stretchBanner: React.ReactNode;
  transportAccessibility: React.ReactNode;
}

export function MainNew({ title, geo, toolbar, comment, stretchBanner, transportAccessibility }: IMainNewProps) {
  return (
    <section className={styles['container']}>
      <div className={styles['header']}>
        <div className={styles['header-information']}>
          {title}
          {geo}
          {transportAccessibility}
          {toolbar}
        </div>
      </div>
      {comment}
      {stretchBanner}
    </section>
  );
}
