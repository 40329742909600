import { LinkButton } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';
import { IBookButton } from './types';

export const BookButton = (props: IBookButton) => {
  const { url, title, onClick } = props;

  return (
    <div className={styles['book-button-container']}>
      <LinkButton
        onClick={onClick}
        theme="fill_primary"
        href={url}
        size="M"
        fullWidth
        target="_blank"
        rel="noopener noreferrer"
      >
        {title}
      </LinkButton>
    </div>
  );
};
