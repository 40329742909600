import * as React from 'react';

// TODO: обновить иконку IconMapMCD316 в ui-kit и использовать ее, а эту удалить
/* istanbul ignore next */
export const IconMapMCD316: React.FC = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.00003 2H-0.00463867V4H1L1.00223 14L3.00003 14C6.31372 14 8.99997 11.3137 8.99997 7.99999C8.99997 4.68633 6.31372 2 3.00003 2ZM3.00002 12C5.20912 12 6.99997 10.2091 6.99997 7.99999C6.99997 5.79087 5.20912 4 3.00003 4L3.00002 12Z"
      fill="currentColor"
    />
    <path
      d="M10.9999 6.21959V8.00001C10.9999 8.00001 11.6774 8.00001 12 8.1C12.8341 8.35851 13.5 9.11712 13.5 10C13.5 11.2185 12.7173 12 11.5 12C10.7955 12 10.2527 11.681 9.70369 11.2895C9.71117 11.2969 9.02233 11.9777 9.00004 12C8.29294 12.7071 8.29402 12.7082 8.29402 12.7082C9.11698 13.5285 10.351 14 11.5 14C13.8037 14 15.5 12.3063 15.5 10C15.5 8.46563 14.5497 7.1116 13.233 6.38854L14.5046 4L14.5049 2.00312L8.99988 2.00317V4.00317L12.3353 4.00317L10.9999 6.21959Z"
      fill="currentColor"
    />
  </svg>
);
