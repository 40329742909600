import {
  IconFinanceArrowBoldDown12,
  IconFinanceArrowBoldUp12,
  IconFinanceDynamicChart16,
} from '@cian/ui-kit-design-tokens/icons';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';

interface IPriceHistoryButtonProps {
  trend?: 'inc' | 'dec' | null;
}

export function PriceHistoryButton({ trend }: IPriceHistoryButtonProps) {
  const TrendIcon = trend === 'inc' ? IconFinanceArrowBoldUp12 : IconFinanceArrowBoldDown12;

  return (
    <button type="button" className={styles['button']} title="История цены">
      <span className={styles['main']}>
        <IconFinanceDynamicChart16 />
      </span>
      {trend && (
        <span className={cx(styles['trend'], trend === 'inc' && styles['inc'])}>
          <TrendIcon color="current_color" />
        </span>
      )}
    </button>
  );
}
