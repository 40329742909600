import { ContactLabels } from '@cian/contact-card-component';
import { IconToggleStarOn16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

interface IAgentReviews {
  title: string;
}

const AgentReviewsComponent: React.FC<React.PropsWithChildren<IAgentReviews>> = ({ title }) => {
  return (
    <ContactLabels
      labels={[
        {
          icon: <IconToggleStarOn16 color="icon-warning-default" />,
          title,
        },
      ]}
    />
  );
};

export const AgentReviews = React.memo(AgentReviewsComponent);
