import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectUser } from 'shared/selectors';

import { openChat, openChatAfterAuth, TOpenChatParameters } from '../actions/chat';
import { callAuthenticationPopup } from '../utils/authentication';

export function useChats() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const handleOpenChat = React.useCallback(
    (params: TOpenChatParameters | void) => {
      if (!user || !user.isAuthenticated) {
        dispatch(openChatAfterAuth(params));
        callAuthenticationPopup();

        return;
      }

      dispatch(openChat(params));
    },
    [dispatch, user],
  );

  return React.useMemo(
    () => ({
      handleOpenChat,
    }),
    [handleOpenChat],
  );
}
