import { UIHeading5 } from '@cian/ui-kit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as styles from './ComparisonNotificationContainer.css';
import { trackClickPopup, trackPopupLimit } from './tracking';
import { ClearComparisonNotificationType } from '../../actions/comparison/comparisonNotification';
import { ComparisonNotification } from '../../components/ComparisonNotification';
import { NOTIFICATION_EXPIRATION_DELAY } from '../../constants/offersComparison';
import { getOffersComparisonNotification } from '../../selectors/offersComparison/offersComparisonNotification';
import { EComparisonNotificationCategory, IComparisonNotification } from '../../types/comparisonNotification';

const ComparisonNotificationContainer = () => {
  const [timeoutId, setNotificationTimeout] = React.useState<ReturnType<typeof setTimeout> | null>(null);
  const dispatch = useDispatch();
  const notification = useSelector(getOffersComparisonNotification) as IComparisonNotification;

  const isLimitNotification = React.useMemo(
    () => notification?.category === EComparisonNotificationCategory.Limit || false,
    [notification],
  );

  React.useEffect(() => {
    if (isLimitNotification) {
      trackPopupLimit();
    }

    setNotificationTimeout(
      setTimeout(() => {
        dispatch({
          type: ClearComparisonNotificationType,
        });
      }, NOTIFICATION_EXPIRATION_DELAY),
    );

    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, [notification]);

  const handleLinkClick = React.useCallback(() => {
    trackClickPopup({ category: notification?.category });

    setNotificationTimeout(
      setTimeout(() => {
        dispatch({
          type: ClearComparisonNotificationType,
        });
      }, NOTIFICATION_EXPIRATION_DELAY),
    );
  }, [notification]);

  const handleClose = React.useCallback(() => {
    dispatch({
      type: ClearComparisonNotificationType,
    });
  }, []);

  const handleMouseEnter = React.useCallback(() => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
  }, [timeoutId]);

  const control = notification?.url ? (
    <UIHeading5 color="text-inverted-default">
      <a href={notification.url} className={styles['link']} onClick={handleLinkClick} target="_blank" rel="noreferrer">
        Перейти
      </a>
    </UIHeading5>
  ) : (
    <UIHeading5 color="text-inverted-default">
      <button onClick={handleClose} className={styles['button']}>
        Понятно
      </button>
    </UIHeading5>
  );

  if (!notification || notification.text.length === 0) {
    return null;
  }

  return <ComparisonNotification control={control} text={notification.text} onMouseEnter={handleMouseEnter} />;
};

// eslint-disable-next-line import/no-default-export
export default ComparisonNotificationContainer;
