import { Button, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './ModalComplainsLoadError.css';

type ComplainsLoadErrorProps = {
  isLoading: boolean;
  refetch(): void;
};

export const ModalComplainsLoadError: React.FC<ComplainsLoadErrorProps> = props => {
  const { refetch, isLoading } = props;

  return (
    <div>
      <UIText2>Возможно, проблема с интернет-соединением. Попробуйте ещё раз</UIText2>
      <div className={styles['refresh-button']}>
        <Button disabled={isLoading} fullWidth={false} loading={isLoading} size="XS" onClick={refetch}>
          Обновить
        </Button>
      </div>
    </div>
  );
};
