import * as React from 'react';
import { useSelector } from 'react-redux';

import { getMasterOrSelfLink } from 'shared/selectors/agentSelectors';

import { getName, getNameUrl } from './utils';
import { SubscriptionAuthorName } from '../../../components/subscriptionBrand';
import { selectAgencyName, selectAgentName } from '../../../selectors';
import { selectAgentUrl, selectIsSubAgent } from '../../../selectors/offerData/agent';
import { trackGoToAgentCard } from '../SubscriptionAuthorBrand/tracking';

export const SubscriptionAuthorNameContainer = () => {
  const isSubAgent = useSelector(selectIsSubAgent);
  const agencyName = useSelector(selectAgencyName);
  const agentName = useSelector(selectAgentName);
  const agencyUrl = useSelector(getMasterOrSelfLink);
  const agentUrl = useSelector(selectAgentUrl);

  const name = getName({ isSubAgent, agentName, agencyName });
  const nameUrl = getNameUrl({ isSubAgent, agentUrl, agencyUrl: agencyUrl || null });

  const handleLinkClick = trackGoToAgentCard(isSubAgent ? 'to_realtorCard_sub' : 'to_realtorCard_name');

  return <SubscriptionAuthorName name={name} nameUrl={nameUrl} handleLinkClick={handleLinkClick} />;
};
