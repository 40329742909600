import * as React from 'react';
import { useSelector } from 'react-redux';

import { InfoLayout } from 'shared/components/BottomAuthorBlockNew';

import { AgentInfoContainer } from './AgentInfoContainer';
import { DeveloperInfoContainer } from './DeveloperInfoContainer';
import { HomeownerInfoContainer } from './HomeownerInfoContainer';
import { selectIsAgencyBottomBrandingAvailable, selectIsAgencyBrandingAvailable } from '../../selectors';
import { selectIsNewbuildingPremiumRate } from '../../selectors/newbuildingPremiumRate';
import { AuthorPremiumContainer } from '../AuthorPremiumContainer';
import { SubscriptionAuthorBrandContainer } from '../subscriptionBrand';

export const BottomAuthorBlockContainer = () => {
  const isAgencyBrandingAvailable = useSelector(selectIsAgencyBrandingAvailable);
  const isAgencyBottomBrandingAvailable = useSelector(selectIsAgencyBottomBrandingAvailable);
  const isNewbuildingPremium = useSelector(selectIsNewbuildingPremiumRate);

  if (isAgencyBrandingAvailable && isAgencyBottomBrandingAvailable) {
    return <SubscriptionAuthorBrandContainer />;
  }

  if (isNewbuildingPremium) {
    return <AuthorPremiumContainer />;
  }

  return (
    <InfoLayout id="author">
      <HomeownerInfoContainer />
      <AgentInfoContainer />
      <DeveloperInfoContainer />
    </InfoLayout>
  );
};
