import { TTransportTypes } from 'shared/types/TransportTypes';

export function getTransportTypeFromRegionId(regionId: number): TTransportTypes | null {
  switch (regionId) {
    case -1: // Москва и область
    case 1: // Москва
    case 4593: // Московская область
      return 'mow';
    case -2: // СПб и область
    case 2: // СПб
    case 4588: // Ленинградская область
      return 'spb';
    case 4612: // Свердловская область
    case 4743: // Екатеринбург
      return 'ekb';
    case 4618: // республика Татарстан
    case 4777: // Казань
      return 'kzn';
    case 4598: // Новосибирская область
    case 4897: // Новосибирск
      return 'nsk';
    case 4608: // Самарская область
    case 4966: // Самара
      return 'smr';
    case 4596: // Нижегородская область
    case 4885: // Нижний Новгород
      return 'nn';
    default:
      return null;
  }
}
