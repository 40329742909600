import { useBooleanState } from '@cian/react-utils';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { TourFrame } from 'shared/components/TourFrame';
import { TourLayout } from 'shared/components/TourLayout';
import { TourSlide } from 'shared/components/TourSlide';
import { selectTourData } from 'shared/selectors/offerData/tour/selectTourData';

import { track3DTourBlockClick } from './tracking';

export function TourSlideContainer() {
  const { state: isOpen, setTrue: openTour } = useBooleanState();

  const { tourLink, tourBannerImageUrl } = useSelector(selectTourData);

  const handleTourShow = React.useCallback(() => {
    openTour();
    track3DTourBlockClick();
  }, [openTour]);

  if (!tourLink || !tourBannerImageUrl) {
    return null;
  }

  return (
    <>
      {!isOpen && (
        <TourSlide>
          <TourLayout onClick={handleTourShow} tourBannerImageUrl={tourBannerImageUrl} />
        </TourSlide>
      )}
      {isOpen && <TourFrame tourUrl={tourLink} />}
    </>
  );
}
