import { Tabs } from '@cian/ui-kit/prev';
import * as React from 'react';

import * as styles from './ContentTabs.css';

export interface IContentTab {
  title: string;
  value: string;
  component: React.ReactNode;
}

interface IContentTabsProps {
  tabs: IContentTab[];
  onTabChange?: (value: string) => void;
}

export const ContentTabs: React.FC<IContentTabsProps> = ({ tabs, onTabChange = () => {} }) => {
  const [activeTab, setActiveTab] = React.useState(tabs[0].value);

  React.useEffect(() => {
    setActiveTab(tabs[0].value);
  }, [tabs]);

  const activeTabComponent = tabs.find(({ value }) => value === activeTab)?.component;

  if (!activeTabComponent) {
    return null;
  }

  const onChange = (value: string) => {
    setActiveTab(value);
    onTabChange(value);
  };

  return (
    <div className={styles['container']}>
      <div className={styles['tabs-container']}>
        <Tabs activeTab={activeTab} onChange={onChange} tabs={tabs} />
      </div>
      {activeTabComponent}
    </div>
  );
};
