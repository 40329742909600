import { closePhoneWarning } from './actions';
import { IThunkActionCreator } from '../../store';

const PERSONAL_AREA_LINK_KEY = 'customer.baseUrl';

export const openPersonalArea = (): IThunkActionCreator => {
  return (dispatch, getState, { config, logger }) => {
    try {
      const personalAreaLink = config.getStrict<string>(PERSONAL_AREA_LINK_KEY);

      if (window) {
        window.open(personalAreaLink, '_blank', 'noopener noreferrer')?.focus();
      }

      dispatch(closePhoneWarning());
    } catch (error) {
      logger.error(error);
    }
  };
};
