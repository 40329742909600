import { FormikBag, withFormik } from 'formik';
import * as React from 'react';
import { array, number, object, string, StringSchema } from 'yup';

import { ISaveFormRequest } from '../../../repositories/deal-rent-announcements/v1/save-form';
import { withAnalytics } from '../../../utils/withAnalytics';
import { FirstStep, IFirstStepStateProps } from '../../components/FirstStep';

export interface IFirstStepProps {
  open: boolean;
  initialFormState: ISaveFormRequest;
  onClose(): void;
  onClickNext(values: ISaveFormRequest): void;
}

const FirstStepWithAnalytics = withAnalytics<IFirstStepStateProps>(
  {
    category: 'SafeRent',
    label: 'renter_form_first',
  },
  FirstStep,
);

const handleSubmit = (
  values: ISaveFormRequest,
  { props: { onClickNext } }: FormikBag<IFirstStepProps, ISaveFormRequest>,
) => {
  onClickNext(values);
};

const mapPropsToValues = ({ initialFormState }: IFirstStepProps): ISaveFormRequest => ({
  ...initialFormState,
});

const personsDescriptionValidator = (personsCount: number, schema: StringSchema) =>
  personsCount > 1 ? schema.required() : schema.nullable();

const petsInfoValidator = (petsCount: number, schema: StringSchema) =>
  petsCount > 0 ? schema.required() : schema.nullable();

const FirstStepWithFormik = withFormik<IFirstStepProps, ISaveFormRequest>({
  mapPropsToValues,
  validationSchema: object({
    personsCount: number().min(1).required(),
    personsInfo: string().when('personsCount', personsDescriptionValidator).nullable(),
    childrenCount: number().min(0).required(),
    childrenInfo: array().of(string().required()),
    petsCount: number().min(0).required(),
    petsInfo: string().when('petsCount', petsInfoValidator),
  }),
  handleSubmit,
  enableReinitialize: true,
})(FirstStepWithAnalytics);

export const FirstStepContainer: React.FC<React.PropsWithChildren<IFirstStepProps>> = props => {
  return <FirstStepWithFormik {...props} />;
};
