/**
 * @jest-environment jsdom
 */
import { UIHeading5 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as style from './style.css';

export interface INavigationProps {
  active: boolean;
  fixed: boolean;
  transition: boolean;
  description: string;
  showRightContent: boolean;
  navigateLinks: React.ReactElement;
  sharingButton: React.ReactElement;
}

export const Navigation: React.FC<React.PropsWithChildren<INavigationProps>> = ({
  active,
  fixed,
  transition,
  description,
  showRightContent,
  navigateLinks,
  sharingButton,
}) => {
  return (
    <div
      data-testid="FixedNavigation"
      data-navigation-type={fixed ? 'fixed' : 'static'}
      className={cx(
        style['container'],
        fixed && style['fixed'],
        fixed && active && style['active'],
        fixed && transition && style['transition'],
      )}
    >
      <div className={style['wrapper']}>
        {navigateLinks}

        {showRightContent && (
          <div className={style['aside']}>
            <UIHeading5 color="text-primary-default">{description}</UIHeading5>
            <div className={style['buttons']}>
              <div className={style['button']}>{sharingButton}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
