import { connect } from 'react-redux';

import { ITop3Props, Top3 } from './index';
import { isMlSearchEnabled } from '../../../../selectors/isMlSearchEnabled';
import { IState } from '../../../../store/index';

export const mapStateToProps = (state: IState): ITop3Props => {
  const {
    offerData: { offer },
  } = state;

  return {
    isVisible: Boolean(state.offerStatsPopup.dataStats && state.offerStatsPopup.dataStats.top3_is_available),
    recoveryLink: offer.recoveryLink,
    isMlSearchEnabled: isMlSearchEnabled(state),
  };
};

export const Top3Container = connect<ITop3Props>(mapStateToProps)(Top3);
