import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ISaveFormRequest } from '../../../repositories/deal-rent-announcements/v1/save-form';
import { getRenterFromState } from '../../../selectors/renterForm/selectors';
import { close, saveForm, updateCurrentStep, updateForm, closeSuccess, closeFailure } from '../../../store/renterForm';
import { trackEvent } from '../../../utils/tracking/trackingEvent';
import { withAnalytics } from '../../../utils/withAnalytics';
import { IFailureStateProps, Failure } from '../../components/Failure';
import { IOnboardingProps, Onboarding } from '../../components/Onboarding';
import { ISuccessStateProps, Success } from '../../components/Success';
import { ECurrentStep } from '../../constants';
import { useIsOpenOnboarding } from '../../utils/useIsOpenOnboarding';
import { FirstStepContainer } from '../FirstStep';
import { FourthStepContainer } from '../FourthStep';
import { SecondStepContainer } from '../SecondStep';
import { ThirdStepContainer } from '../ThirdStep';

const OnboardingWithAnalytics = withAnalytics<IOnboardingProps>(
  {
    category: 'SafeRent',
    label: 'renter_form_onboarding',
  },
  Onboarding,
);

const SuccessWithAnalytics = withAnalytics<ISuccessStateProps>(
  {
    category: 'SafeRent',
    label: 'renter_form_success',
  },
  Success,
);

const FailureWithAnalytics = withAnalytics<IFailureStateProps>(
  {
    category: 'SafeRent',
    label: 'renter_form_failure',
  },
  Failure,
);

export const RenterFormContainer = () => {
  const dispatch = useDispatch();

  const { isOpen, initialFormState, currentStep, isSuccessModal, isFailureModal } = useSelector(getRenterFromState);

  const { isOpenOnboarding, closeOnboarding } = useIsOpenOnboarding(true);

  const selectFirstStep = React.useCallback(() => {
    dispatch(updateCurrentStep(ECurrentStep.First));
    closeOnboarding();
  }, [dispatch, closeOnboarding]);

  const selectSecondStep = React.useCallback(
    (values?: ISaveFormRequest) => {
      if (values) {
        dispatch(updateForm({ form: values }));
      } else {
        trackEvent('SafeRent', `renter_form_back_${ECurrentStep.Third}`, 'click');
      }
      dispatch(updateCurrentStep(ECurrentStep.Second));
    },
    [dispatch],
  );

  const selectThirdStep = React.useCallback(
    (values?: ISaveFormRequest) => {
      if (values) {
        dispatch(updateForm({ form: values }));
      } else {
        trackEvent('SafeRent', `renter_form_back_${ECurrentStep.Fourth}`, 'click');
      }
      dispatch(updateCurrentStep(ECurrentStep.Third));
    },
    [dispatch],
  );

  const onSaveForm = React.useCallback(
    (values: ISaveFormRequest) => {
      dispatch(saveForm({ ...values, phone: `+7${values.phone}` }));
    },
    [dispatch],
  );

  const onClose = React.useCallback(
    (step?: ECurrentStep) => {
      if (isSuccessModal) {
        dispatch(closeSuccess());

        return;
      }

      if (isFailureModal) {
        dispatch(closeFailure());

        return;
      }

      dispatch(close());
      closeOnboarding();
      trackEvent('SafeRent', `renter_form_close_${step}`, 'click');
    },
    [dispatch, closeOnboarding, isSuccessModal, isFailureModal],
  );

  if (isSuccessModal) {
    return <SuccessWithAnalytics open onClose={onClose} />;
  }

  if (isFailureModal) {
    return <FailureWithAnalytics open onClose={onClose} />;
  }

  if (!isOpen) {
    return null;
  }

  let step = currentStep;
  if (currentStep === ECurrentStep.Onboarding && !isOpenOnboarding) {
    step = ECurrentStep.First;
  }

  switch (step) {
    case ECurrentStep.Onboarding:
      return (
        <OnboardingWithAnalytics
          open={true}
          onClick={selectFirstStep}
          onClose={() => {
            onClose(ECurrentStep.Onboarding);
          }}
        />
      );
    case ECurrentStep.First:
      return (
        <FirstStepContainer
          open={true}
          onClickNext={selectSecondStep}
          onClose={() => {
            onClose(ECurrentStep.First);
          }}
          initialFormState={initialFormState}
        />
      );
    case ECurrentStep.Second:
      return (
        <SecondStepContainer
          open={true}
          onClickBack={selectFirstStep}
          onClickNext={selectThirdStep}
          onClose={() => {
            onClose(ECurrentStep.Second);
          }}
          initialFormState={initialFormState}
        />
      );
    case ECurrentStep.Third:
      return (
        <ThirdStepContainer
          onClickBack={selectSecondStep}
          onClose={() => {
            onClose(ECurrentStep.Third);
          }}
          onSaveForm={onSaveForm}
        />
      );
    case ECurrentStep.Fourth:
      return (
        <FourthStepContainer
          onClickBack={selectThirdStep}
          onClose={() => {
            onClose(ECurrentStep.Fourth);
          }}
          onSaveForm={onSaveForm}
        />
      );
    default:
      return null;
  }
};

RenterFormContainer.displayName = 'RenterFormContainer';
