import * as React from 'react';

export interface IFavoriteButtonContext {
  placement?: 'main' | 'gallery' | 'navigation';
  popupPivotX?: 'right' | 'left';
  customClass?: string;
}

const DEFAULT: IFavoriteButtonContext = {
  placement: 'main',
  popupPivotX: undefined,
  customClass: undefined,
};

export const FavoritesButtonContext = React.createContext(DEFAULT);
