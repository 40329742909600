import { ca } from '@cian/analytics';

export function trackViewQRCode() {
  ca('eventSite', {
    action: 'hover',
    category: 'Card',
    label: 'open_qr_code',
    name: 'oldevent',
    useLastProducts: true,
  });
}
