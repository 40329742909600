import { UIHeading1, UIText1 } from '@cian/ui-kit';
import { IconProductTrust12 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { AuthorFact, IAuthorFactProps } from './components/AuthorFact';
import { AuthorLink } from './components/AuthorLink';
import * as styles from './styles.css';
import { trackClickCardBuilder } from './tracking';

export interface IAuthorPremiumProps {
  offerId: number;
  builderId: number;
  title: string;
  isReliable: boolean;
  logoUrl?: string | null;
  description?: string | null;
  facts?: IAuthorFactProps[] | null;
  authorUrl?: string | null;
}

export const AuthorPremium: React.FC<React.PropsWithChildren<IAuthorPremiumProps>> = ({
  offerId,
  builderId,
  logoUrl,
  title,
  facts,
  isReliable,
  description,
  authorUrl,
}) => {
  const onClick = React.useCallback(() => {
    trackClickCardBuilder(offerId, builderId);
  }, [offerId, builderId]);

  return (
    <section className={styles['container']} id="author">
      {logoUrl && <div className={styles['logo']} style={{ backgroundImage: `url(${logoUrl})` }} />}
      <UIText1 fontWeight="bold" color="text-primary-default">
        Автор объявления
      </UIText1>
      <a href={authorUrl as string} className={styles['title']} onClick={onClick}>
        <UIHeading1 color="text-primary-default" as="h1">
          {title}
        </UIHeading1>
      </a>
      {isReliable && (
        <div className={styles['status']}>
          <IconProductTrust12 color="icon-main-default" />
          <span className={styles['status-title']}>Надежный застройщик</span>
        </div>
      )}
      {facts && facts.length !== 0 && (
        <div className={styles['facts']} data-testid="AuthorPremiumFacts">
          {facts.map((fact, index) => (
            <div key={index} className={styles['fact']}>
              <AuthorFact {...fact} />
            </div>
          ))}
        </div>
      )}

      {description && (
        <UIText1>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: description }}
          ></span>
        </UIText1>
      )}
      {authorUrl && (
        <div className={styles['link']}>
          <AuthorLink url={authorUrl} text={'Подробнее о застройщике'} onClick={onClick} />
        </div>
      )}
    </section>
  );
};
