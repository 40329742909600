import { useSelector } from 'react-redux';

import { selectDailyrentActionType } from 'shared/selectors/dailyrentActionType';
import { EActionType } from 'shared/types/dailyrentActionButtonsType';

export const useRenderContactButtons = () => {
  const actionType = useSelector(selectDailyrentActionType);

  if (!actionType) {
    return { showPhoneButton: true, showChatButton: true };
  }

  const isContactActionType = actionType === EActionType.Contact;
  const isOnlineBookingActionType = [EActionType.OtaCheckDates, EActionType.OtaBooking].includes(actionType);

  // показываем кнопку телефона/звонка, если с автором посуточного объявления можно связаться по телефону
  // показываем кнопку чата, если автору посуточного объявления можно только написать
  return { showPhoneButton: isContactActionType, showChatButton: isOnlineBookingActionType };
};
