import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';

import { SimilarOffers } from 'shared/components/SimilarOffersRedesigned';
import {
  EtrackOfferClicksLabels,
  EtrackOffersShowActions,
  trackOffersShow,
  TProductPartial,
} from 'shared/ebc/track_similar';
import { EModelTypes, ISimilarState } from 'shared/store/similar';
import { IOfferData } from 'shared/types/offerData';
import { getVariants } from 'shared/utils/ebc';
import { getPageEBC } from 'shared/utils/tracking/get_page_ebc';
import { getUserEBC } from 'shared/utils/tracking/get_user_ebc';

interface ISimilarOffersFactoryParams {
  offerData: IOfferData;
  withoutCompetitors: boolean;
  similarOffers: ISimilarState;
  abGroup: number;
}

export function SimilarOffersFactory({
  offerData,
  withoutCompetitors,
  similarOffers,
  abGroup,
}: ISimilarOffersFactoryParams) {
  const user = getUserEBC(offerData.user, abGroup);
  const page = getPageEBC(offerData.offer.id, offerData.breadcrumbs);

  const SimilarOffersWrapper = ({
    modelType,
    title,
    disableExternalContainerStyles,
  }: {
    modelType: EModelTypes;
    title?: string | React.ReactNode;
    disableExternalContainerStyles?: boolean;
  }) => {
    const modelVersion = similarOffers[modelType].modelVersion;
    const similarOffersList = similarOffers[modelType].list;

    const products = React.useMemo(
      () =>
        similarOffersList.map((similarOffer, index): TProductPartial => {
          let variant;

          /* istanbul ignore else */
          if (similarOffer.gaVariant) {
            variant = getVariants(similarOffer.gaVariant);
          }

          return {
            id: similarOffer.cianId,
            position: index + 1,
            ownerId: similarOffer.publishedUserId || undefined,
            variant,
          };
        }),
      [similarOffersList],
    );

    const onVisibilityChange = React.useCallback(() => {
      const eventAction = withoutCompetitors
        ? EtrackOffersShowActions.show_others_offers
        : EtrackOffersShowActions.show_similar_offers;

      /* istanbul ignore else */
      if (products.length) {
        trackOffersShow({
          action: eventAction,
          label:
            !withoutCompetitors || modelType === EModelTypes.HistoryBased
              ? EtrackOfferClicksLabels[modelType]
              : undefined,
          products,
          modelVersion,
          user,
          page,
        });
      }
    }, [modelVersion, modelType, products]);

    return (
      <ActionAfterViewObserver callback={onVisibilityChange} percentVisible={0.7} triggerOnce>
        <SimilarOffers
          agent={offerData.agent}
          offer={offerData.offer}
          similarOffers={similarOffersList}
          withoutCompetitors={withoutCompetitors}
          modelVersion={modelVersion}
          modelType={modelType}
          user={user}
          page={page}
          title={title}
          disableExternalContainerStyles={disableExternalContainerStyles}
        />
      </ActionAfterViewObserver>
    );
  };

  return SimilarOffersWrapper;
}
