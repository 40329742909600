import * as React from 'react';
import { useSelector } from 'react-redux';

import { TextWithLinkContainer } from 'shared/FeedbackExperiment/components/PopupWithForm/TextWithLinkRedesigned';
import { PhonesOpenedInfo } from 'shared/components/PhonesOpenedInfo/PhonesOpenedInfo';
import { selectIsEnabledCallTracking, getAgentAvailability } from 'shared/selectors';
import { selectorShowWarningMessage } from 'shared/selectors/contacts/selectorShowWarningMessage';
import { selectWorkTimeInfo } from 'shared/selectors/newObject';
import { selectIsDeveloper } from 'shared/selectors/offerData/agent';
import { getWorkTime } from 'shared/utils/getWorkTime';

export const PhonesOpenedInfoContainer = () => {
  const isEnabledCallTracking = useSelector(selectIsEnabledCallTracking);
  const workTimeInfo = useSelector(selectWorkTimeInfo);
  const agentAvailability = useSelector(getAgentAvailability);
  const showWarningMessage = useSelector(selectorShowWarningMessage);
  const isDeveloper = useSelector(selectIsDeveloper);

  const scheduleText = React.useMemo(() => `${isDeveloper ? 'Офис' : 'Агент'} работает`, [isDeveloper]);

  return (
    <PhonesOpenedInfo
      availability={agentAvailability?.title || null}
      schedule={getWorkTime(workTimeInfo, scheduleText)}
      calltracking={isEnabledCallTracking ? 'Этот номер только для звонков и не подходит для сообщений и смс' : null}
      feedback={<TextWithLinkContainer />}
      warningMessage={showWarningMessage ? 'Ничего не платите до просмотра и подписания договора' : null}
    />
  );
};
