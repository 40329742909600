import { Button, Outside, Spinner } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import { TStatusFetch } from 'shared/store/comments';
import { TDealType, TOfferType } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IconCancel } from './icons/icon_cancel';
import * as styles from './index.css';
import { trackSaveComment } from './tracking';

export interface ICommentProps {
  commentText: string;
  commentTextFetched: string;
  dealType: TDealType;
  editing: boolean;
  isCommentsVisible: boolean;
  messageError: string;
  offerId: number;
  offerType: TOfferType;
  statusFetch: TStatusFetch;
}

export interface ICommentDispatch {
  changeEdditing(editing: boolean): void;
  clearError(): void;
  closeComments(): void;
  setCommentText(commentText: string): void;
  submitComment(
    host: string,
    text: string,
    action: string,
    oid: number,
    dealType: TDealType,
    offerType: TOfferType,
  ): void;
}

export type IProps = ICommentProps & ICommentDispatch;

const FONT_SIZE_WITH_LINE_HEIGHT = 18;

export class Comment extends React.Component<IProps, {}> {
  private textareaBlock: HTMLDivElement | null;
  private textareaHtml: HTMLTextAreaElement | null;
  private hiddenDiv: HTMLDivElement | null;

  public componentDidMount() {
    if (this.textareaBlock && this.textareaHtml && typeof this.textareaHtml.value !== 'undefined') {
      this.hiddenDiv = document.createElement('div');

      if (this.props.editing) {
        this.textareaHtml.focus();
      }

      this.hiddenDiv.classList.add(styles['hiddendiv']);
      this.textareaHtml.classList.add(styles['noscroll']);

      this.textareaBlock.appendChild(this.hiddenDiv);

      this.hiddenDiv.innerText = this.textareaHtml.value;

      const newHeight = this.textareaHtml.value.endsWith('\n')
        ? this.hiddenDiv.offsetHeight + FONT_SIZE_WITH_LINE_HEIGHT
        : this.hiddenDiv.offsetHeight;

      this.textareaHtml.style.height = `${newHeight}px`;
    }
  }

  public render() {
    return (
      <Outside onOutside={this.handleOutsideClick}>
        <div className={styles['comment']}>
          <div
            ref={textareaBlock => (this.textareaBlock = textareaBlock)}
            className={cx([
              styles['text_block'],
              this.props.isCommentsVisible && styles['print_mode'],
              this.props.editing ? styles['text_block--editing'] : styles['text_block--pointer'],
            ] as string[])}
            title={!this.props.editing ? 'Редактировать' : ''}
            onClick={this.handleTextareaBlockClick}
          >
            <textarea
              ref={textarea => (this.textareaHtml = textarea)}
              value={this.props.commentText}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => this.handleValueChange(e.target.value)}
              placeholder="Ваш комментарий"
            />
            <div className={styles['cancel']} onClick={this.handleCancelComment} title="Удалить">
              <IconCancel />
            </div>
          </div>
          <div className={styles['button_submit']}>
            <div>
              {this.props.statusFetch === 'isLoad' && (
                <div className={styles['preloader']}>
                  <Spinner size={16} />
                </div>
              )}
              {this.props.editing && (
                <Button theme="fill_primary" size="XS" onClick={this.handleSubmit}>
                  Сохранить
                </Button>
              )}
            </div>
          </div>
          <div className={cx(styles['error'], this.props.messageError.length > 0 && styles['error--open'])}>
            {this.props.messageError}
          </div>
        </div>
      </Outside>
    );
  }

  private handleValueChange = (newValue: string) => {
    if (this.textareaBlock && this.textareaHtml && this.hiddenDiv) {
      this.props.clearError();
      this.hiddenDiv.innerText = newValue;

      const newHeight = newValue.endsWith('\n')
        ? this.hiddenDiv.offsetHeight + FONT_SIZE_WITH_LINE_HEIGHT
        : this.hiddenDiv.offsetHeight;

      this.textareaHtml.style.height = `${newHeight}px`;

      this.props.setCommentText(newValue);
    }
  };

  private handleSubmit = () => {
    if (this.props.commentText.length > 0) {
      trackSaveComment();
    }

    this.props.submitComment(
      window.location.host,
      this.props.commentText,
      this.props.commentText.length === 0 ? 'delete' : 'save',
      this.props.offerId,
      this.props.dealType,
      this.props.offerType,
    );
  };

  private handleTextareaBlockClick = () => {
    if (!this.props.editing) {
      this.props.changeEdditing(true);
    }
  };

  private handleCancelComment = () => {
    this.props.setCommentText('');
    this.props.submitComment(
      window.location.host,
      '',
      'delete',
      this.props.offerId,
      this.props.dealType,
      this.props.offerType,
    );
    this.props.closeComments();
  };

  private handleOutsideClick = () => {
    if (this.props.commentText.length === 0 && this.props.commentTextFetched.length === 0) {
      this.props.closeComments();

      return;
    }

    if (this.props.commentText.length > 0 && this.props.commentText !== this.props.commentTextFetched) {
      this.props.submitComment(
        window.location.host,
        this.props.commentText,
        'save',
        this.props.offerId,
        this.props.dealType,
        this.props.offerType,
      );

      return;
    }

    if (this.props.commentText !== this.props.commentTextFetched) {
      this.props.setCommentText(this.props.commentTextFetched);
      this.props.changeEdditing(false);

      return;
    }

    if (this.props.commentText === this.props.commentTextFetched) {
      this.props.changeEdditing(false);

      return;
    }

    this.props.closeComments();
  };
}
