import { createSelector } from '@reduxjs/toolkit';

import { selectTourBannerImageUrl } from './selectTourBannerImageUrl';
import { selectTourLink } from './selectTourLink';
import { selectTourLinkWithoutFullscreen } from './selectTourLinkWithoutFullscreen';

export const selectTourData = createSelector(
  selectTourLink,
  selectTourLinkWithoutFullscreen,
  selectTourBannerImageUrl,
  (tourLink, tourLinkWithoutFullscreen, tourBannerImageUrl) => ({
    tourLink,
    tourLinkWithoutFullscreen,
    tourBannerImageUrl,
  }),
);
