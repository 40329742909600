import * as React from 'react';

import * as styles from './geo.css';

interface IGeoProps {
  addressString: string;
  address: React.ReactElement;
  undergrounds: React.ReactElement;
  highways: React.ReactElement;
}

export function Geo(props: IGeoProps) {
  return (
    <div className={styles['geo']}>
      <span {...{ itemProp: 'name', content: props.addressString }} />
      <address className={styles['address']}>
        <div className={styles['address-line']}>{props.address}</div>
        {props.undergrounds}
        {props.highways}
      </address>
    </div>
  );
}
