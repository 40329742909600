import { IIdenticalOffer } from 'shared/types/api-models/offer-card/v1/identicalOffers';

import { formatAuthor } from './formatAuthor';
import { formatMedia } from './formatMedia';
import { formatPrice } from './formatPrice';
import { IIdenticalOfferView } from '../../../types/identicalOffersView';

export function mapIdenticalOffers(offers: IIdenticalOffer[]): IIdenticalOfferView[] {
  return offers.map(({ price, author, media, ...offer }) => ({
    ...offer,
    price: formatPrice(price),
    author: formatAuthor(author),
    media: formatMedia(media),
  }));
}
