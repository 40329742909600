import { useSelector } from 'react-redux';

import { selectAgent } from 'shared/selectors/selectAgent';
import { extractUndefinedValue } from 'shared/utils/extractUndefinedValue';

export function useAgentSelector() {
  return useSelector(selectAgent);
}

export function useFormattedPublisherType<DefaultPublisherType extends string | undefined>(
  defaultPublisherType?: DefaultPublisherType,
): DefaultPublisherType extends string ? string : string | null;
export function useFormattedPublisherType(defaultPublisherType?: string): string | null {
  return extractUndefinedValue(useAgentSelector()?.formattedPublisherType ?? defaultPublisherType);
}
