import { Spinner, Input } from '@cian/ui-kit';
import { Field, useFormikContext } from 'formik';
import * as React from 'react';

import { SendCodeButton } from './SendCodeButton';
import { SendCodeRepeat } from './SendCodeRepeat';
import { IValues as IValuesSendCode } from '../../../../api/deal_rent/send-validation-code';
import { IFormValues } from '../../DealRentModal';
import * as styles from '../../index.css';
import { Label } from '../Label';
import { useFieldHasErrors } from '../utils';

export interface ISMSCode {
  secondsToRetry: number;
  isLoading: boolean;
  sendValidationCode(values: IValuesSendCode): void;
}

export const SMSCode = ({ sendValidationCode, secondsToRetry, isLoading }: ISMSCode) => {
  const invalid = useFieldHasErrors('smsCode');
  const { values } = useFormikContext<IFormValues>();

  const [isCodeSended, changeIsCodeSended] = React.useState(false);

  const sendCode = React.useCallback(() => {
    sendValidationCode({ phone: '+7' + values.phone });
    changeIsCodeSended(true);
  }, [values.phone]);

  React.useEffect(() => {
    changeIsCodeSended(false);
  }, [values.phone]);

  if (isLoading) {
    return (
      <div className={styles['preloader']}>
        <Spinner size={16} />
      </div>
    );
  }
  if (isCodeSended) {
    return (
      <div className={styles['field']}>
        <Label invalid={invalid}>Код из СМС</Label>
        <div className={styles['sms-code']}>
          <Field name="smsCode" as={Input} custom={{ invalid, size: 'XS' }} />
          <SendCodeRepeat sendValidationCode={sendCode} secondsToRetry={secondsToRetry} />
        </div>
      </div>
    );
  }

  return <SendCodeButton sendValidationCode={sendCode} />;
};
