import * as React from 'react';

import { trackEvent } from '../tracking/trackingEvent';

export interface IAnalyticsParams {
  label: string;
  category: string;
}

export function withAnalytics<T>(
  { category, label }: IAnalyticsParams,
  Component: React.ComponentType<React.PropsWithChildren<T>>,
) {
  const ComponentWithAnalytics: React.FC<React.PropsWithChildren<T>> = (wrappedProps: T) => {
    React.useEffect(() => {
      trackEvent(category, label, 'show');
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <Component {...(wrappedProps as any)} />;
  };

  return ComponentWithAnalytics;
}
