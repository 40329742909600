import { Input } from '@cian/ui-kit';
import { numberToPrettyString } from '@cian/utils';
import * as React from 'react';

import * as styles from './styles.css';

export interface IGetPriceProps {
  value: string;
  onChange(value: string): void;
}

export function GetPrice({ value, onChange }: IGetPriceProps) {
  const handleChange = React.useCallback(
    (value: string) => {
      const clearValue = value.replace(/\s+/g, '');

      if (/[^\d]/g.test(clearValue)) {
        return;
      }

      onChange(clearValue);
    },
    [onChange],
  );

  return (
    <div className={styles['input']}>
      <Input
        value={value ? numberToPrettyString(Number(value), ' ') : ''}
        onChange={(_, value) => handleChange(value)}
        placeholder="3 000 000"
        rightAdornment="₽"
      />
    </div>
  );
}
