import { isNil } from 'ramda';

import { IAgentSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IAgent } from '../types/offerData';

export const createAgentIdName = ({ isSubAgent, id, parentCianId }: IAgentSchema | IAgent) => {
  const isMasterAgentIdExist = typeof parentCianId !== 'undefined' && !isNil(parentCianId);

  const agentId = isSubAgent && isMasterAgentIdExist ? parentCianId : id;

  return `ID ${agentId}`;
};
