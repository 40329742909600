import { OwnerBadge } from '@cian/owner-badge-component';
import * as React from 'react';

import * as styles from './NewSafeRentBlock.css';
import { trackSafeRentBlockShow, trackSafeRentBlockHover, trackOfferLinkClick } from './tracking';
import { Link } from '../Link';

export const NewSafeRentBlock = () => {
  /* istanbul ignore next */
  if (typeof window !== 'undefined') {
    trackSafeRentBlockShow();
  }

  return (
    <div className={styles['block']}>
      <OwnerBadge isVerified />

      <div className={styles['title']}>
        Собственник сдает квартиру
        <br />с сервисом Сдай / Сними
      </div>

      <div className={styles['link-wrapper']}>
        <Link url="#safe-rent" onMouseOver={trackSafeRentBlockHover}>
          Что это значит?
        </Link>
      </div>
      <a
        className={styles['terms']}
        onClick={trackOfferLinkClick}
        href="https://www.cian.ru/legal-documents/pravila_polzovaniya_servisom_sdajsnimi_20_0"
        target="_blank"
        rel="noopener noreferrer"
      >
        Оферта сервиса
      </a>
    </div>
  );
};
