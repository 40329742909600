import cx from 'clsx';
import * as React from 'react';

import * as styles from '../index.css';

export interface IErrorProps {
  error: string | null;
}

export const Error = ({ error }: IErrorProps) => <div className={cx(styles['error'])}>{error}</div>;
