import { UIText1 } from '@cian/ui-kit';
import { plural } from '@cian/utils/lib/shared/plural';
import * as React from 'react';

export interface IMoreOffersProps {
  offersLink: string;
  offersCount: number;
}

export const MoreOffers: React.FC<React.PropsWithChildren<IMoreOffersProps>> = ({ offersLink, offersCount }) => {
  return (
    <li>
      <a href={offersLink} target="_blank" rel="noreferrer">
        <UIText1 color="text-main-default">
          {offersCount} {plural(Number(offersCount), ['объявление', 'объявления', 'объявлений'])}
        </UIText1>
      </a>
    </li>
  );
};
