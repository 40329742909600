import * as React from 'react';

import * as styles from './index.css';
import { TgbAsideBannerContainer } from '../../containers/Adfox/TgbAsideBannerContainer';

export const AsideBanners: React.FC = () => {
  return (
    <div className={styles['container']}>
      <TgbAsideBannerContainer />
    </div>
  );
};
