import { ca } from '@cian/analytics';

export function trackOfferRemoved(offerId: number, label: string) {
  ca('event', {
    action: 'delete',
    category: 'AD_DeleteReason',
    event: 'oldevent',
    label,
    oid: offerId,
  });
}

export function trackOfferMoveBasket(action: string, label: string) {
  ca('eventSite', {
    name: 'oldevent',
    action,
    label,
    category: 'AD_Delete_reason',
    useLastProducts: true,
  });
}
