import { Select, Input } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './index.css';
import { getCurrentDate, getTimeIntervals } from './utils';

export interface IEntryFieldProps {
  entry: string;
  setEntry(date: string): void;
}

export const EntryField = ({ entry, setEntry }: IEntryFieldProps) => {
  const currentDate = React.useMemo(getCurrentDate, []);

  const [date, setDate] = React.useState(currentDate);

  const intervals = React.useMemo(() => getTimeIntervals(date), [date]);

  const changeDate = React.useCallback((e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setDate(value);
  }, []);
  const changeEntry = React.useCallback(
    (value: string) => {
      if (value) {
        setEntry(String(value));
      }
    },
    [setEntry],
  );

  React.useEffect(() => {
    setEntry(intervals[0].value);
  }, [intervals, setEntry]);

  return (
    <div className={styles['entry-field']}>
      <Input
        name="date"
        value={date}
        onChange={changeDate}
        // @ts-ignore
        type="date"
        min={currentDate}
      />
      <Select value={entry} options={intervals} onChange={changeEntry} />
    </div>
  );
};
