import { ca } from '@cian/analytics';

export function trackMortgageSidebarBannerClick() {
  ca('eventSite', {
    action: 'click',
    category: 'BannerIpoteka',
    label: 'link_under_show_phone',
    name: 'oldevent',
  });
}
