import { Button } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { useRenderContactButtons } from 'shared/hooks/useRenderContactButtons';

import { trackShowPhoneClick } from './tracking';
import { DynamicCalltrackingButton } from '../../components/DynamicCalltrackingButton';
import { Phones } from '../../components/Phones';
import { getAbCallButtonText, getPhones, offerSelector, selectOfferAgent } from '../../selectors';
import { getDynamicCalltrackingSiteBlockId } from '../../selectors/getDynamicCalltrackingSiteBlockId';
import { formatNumber, getPhoneLink } from '../../utils/phone';
import { CalltrackingBadgeContainer } from '../CalltrackingBadge';

interface IContactsButtonContainerProps {
  placeType: string;
}

export function ContactsButtonContainer({ placeType }: IContactsButtonContainerProps) {
  const [isOpenPhones, setOpenPhones] = React.useState(false);

  const siteBlockId = useSelector(getDynamicCalltrackingSiteBlockId);
  const buttonText = useSelector(getAbCallButtonText);
  const offer = useSelector(offerSelector);
  const phones = useSelector(getPhones);
  const agent = useSelector(selectOfferAgent);

  const { showPhoneButton } = useRenderContactButtons();

  const formattedPhones = React.useMemo(
    () =>
      phones.map(phone => ({
        number: formatNumber(phone),
        link: getPhoneLink(phone),
      })),
    [phones],
  );

  const handleOpenPhone = React.useCallback(() => {
    setOpenPhones(true);
    trackShowPhoneClick(placeType, offer, agent);
  }, [agent, offer, placeType]);

  if (!phones.length || !showPhoneButton) {
    return null;
  }

  return (
    <div>
      {siteBlockId ? (
        <DynamicCalltrackingButton
          onClick={handleOpenPhone}
          isOpen={isOpenPhones}
          buttonText={buttonText}
          siteBlockId={siteBlockId}
          placeType={placeType}
          offerId={offer.id}
          phone={formattedPhones[0].number}
        />
      ) : isOpenPhones ? (
        <Phones phones={formattedPhones} />
      ) : (
        <Button onClick={handleOpenPhone} theme="fill_primary" size="M" fullWidth>
          {buttonText}
        </Button>
      )}
      {isOpenPhones && <CalltrackingBadgeContainer />}
    </div>
  );
}
