import { useEventCallback } from '@cian/react-utils';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { ContactButtonContainer } from 'shared/containers/BottomAuthorBlockNew/ContactButtonContainer/ContactButtonContainer';
import { SuperAgentLevelViewContainer } from 'shared/containers/SuperAgentLevelViewContainer';

import { HomeownerInfo } from '../../../components/BottomAuthorBlockNew';
import { offerSelector, selectAgentName, selectIsByHomeowner, selectOfferAgent } from '../../../selectors';
import { selectAgentAvatarUrl } from '../../../selectors/offerData/agent';
import { AuthorSendMessageContainer } from '../../AuthorSendMessage';
import { PhonesOpenedInfoContainer } from '../PhonesOpenedInfoContainer';

export const HomeownerInfoContainer: React.FC = () => {
  const { homeownerProofs } = useSelector(offerSelector);
  const agent = useSelector(selectOfferAgent);
  const isByHomeowner = useSelector(selectIsByHomeowner);

  const avatar = useSelector(selectAgentAvatarUrl);
  const userName = useSelector(selectAgentName);

  const [isPhonesOpened, setPhonesOpened] = React.useState(false);

  const handlePhonesOpen = useEventCallback(() => {
    setPhonesOpened(true);
  });

  if (!agent || !isByHomeowner) {
    return null;
  }

  return (
    <HomeownerInfo
      avatar={avatar}
      userName={userName}
      homeownerProofs={homeownerProofs}
      contactButton={
        <ContactButtonContainer
          isPhonesOpened={isPhonesOpened}
          handlePhonesOpen={handlePhonesOpen}
          electronicTradingTestId="electronic-trading-button-homeowner"
        />
      }
      phonesShown={isPhonesOpened}
      chatButton={<AuthorSendMessageContainer />}
      phonesOpenedInfo={<PhonesOpenedInfoContainer />}
      superAgentElement={<SuperAgentLevelViewContainer />}
    />
  );
};
