import { IconActionClose12 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './styles.css';

interface IProps {
  onClick(): void;
}

export function Close({ onClick }: IProps) {
  return (
    <div className={styles['container']} onClick={onClick}>
      <IconActionClose12 />
    </div>
  );
}
