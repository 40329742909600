import { IIconProps } from '@cian/ui-kit-design-tokens/icons';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './index.css';

interface ITab {
  className?: string;
  isActive: boolean;
  icon: React.FC<React.PropsWithChildren<IIconProps>>;
  onClick(): void;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const Tab = React.forwardRef<HTMLLIElement, ITab>(
  ({ onClick, className, isActive, icon: Icon, children, style }, ref) => {
    return (
      <li
        className={cx([styles['tab'], className, isActive && styles['active']])}
        onClick={onClick}
        style={style}
        ref={ref}
      >
        <Icon />
        {children}
      </li>
    );
  },
);

Tab.displayName = 'Tab';
