import * as React from 'react';
import { useSelector } from 'react-redux';

import { BrandingTitle } from '../../../components/similar_offers/components/BrandingTitle';
import { selectAgencyName } from '../../../selectors';
import { getMasterOrSelfAvatar, getMasterOrSelfLink } from '../../../selectors/agentSelectors';

export const SimilarOffersTitleContainer: React.FC = () => {
  const companyUrl = useSelector(getMasterOrSelfLink) || '';
  const agencyName = useSelector(selectAgencyName);
  const avatarUrl = useSelector(getMasterOrSelfAvatar);

  return <BrandingTitle companyName={agencyName} avatarUrl={avatarUrl} companyUrl={companyUrl} />;
};
