import { ca } from '@cian/analytics';

import { getSourceParams } from '../../utils/getSourceParams';
import { ISoprProducts } from '../../utils/tracking/types';

export function trackFavoriteClick(action: string, label: string, products?: ISoprProducts) {
  const sourceParams = getSourceParams();

  ca('eventSite', {
    action,
    category: 'favorite',
    label,
    name: 'oldevent',
    useLastProducts: true,
    products,
    page: {
      pageType: 'Card',
      siteType: 'desktop',
      extra: {
        pageTypeSource: sourceParams?.source,
        listingTypeSource: sourceParams?.listingType,
      },
    },
  });
}
