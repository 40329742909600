import * as React from 'react';

import * as styles from './addressItem.css';

interface IAddressItemProps {
  url: string;
  text: string;
  onClick(): void;
}
export const AddressItem = ({ url, text, onClick }: IAddressItemProps) => {
  return (
    <a className={styles['address']} href={url} onClick={onClick}>
      {text}
    </a>
  );
};
