import { ca } from '@cian/analytics';

import { IAgentSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';
import { IOffer } from 'shared/types/offerData';
import { getDealTypeSopr } from 'shared/utils/tracking/getDealTypeSopr';

import { getCategory } from '../../utils/tracking/getCategory';
import { getCustomUrl } from '../../utils/tracking/getCustomUrl';

export const trackOnlineStatusShown = (offer: IOffer, agent: IAgentSchema | null) => {
  const category = getCategory(offer);

  const dealType = getDealTypeSopr(offer);

  ca('eventSite', {
    name: 'oldevent',
    category: 'Offer_Owner_Online',
    action: 'show_sopr',
    label: getCustomUrl(offer, agent),
    products: [
      {
        brand: '',
        category,
        cianId: offer.id,
        dealType,
        hiddenBase: false,
        id: offer.id,
        position: 1,
        price: offer.bargainTerms.price,
        published: true,
        // Всегда передаем 1
        quantity: 1,
        variant: [],
      },
    ],
  });
};
