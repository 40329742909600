import { UIText2, Link } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

interface ITransportAccessibilityEntryProps {
  rate: string;
  onClick: React.MouseEventHandler<HTMLElement>;
}

export const TransportAccessibilityEntry = ({ rate, onClick }: ITransportAccessibilityEntryProps) => {
  return (
    <div className={style['container']} data-testid="TransportAccessibilityEntry">
      <div className={style['icon']} />
      <div className={style['text']}>
        <UIText2 display="inline">
          Транспортная доступность {rate} <span className={style['secondary']}>из 10</span>
        </UIText2>
        <Link fontSize="S" onClick={onClick} href="#mapsSection">
          Подробнее
        </Link>
      </div>
    </div>
  );
};
