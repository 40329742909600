import { ca } from '@cian/analytics';

export const trackDealRentBlockShow = () => {
  ca('event', {
    name: 'oldevent',
    category: 'card',
    action: 'show',
    label: 'cian_assistant',
  });
};
