import { ca } from '@cian/analytics';

export function trackMortgageWidgetAnchorClick() {
  ca('eventSite', {
    action: 'click_head_price_widget',
    category: 'card',
    label: 'Mortgage_calculators',
    name: 'oldevent',
    useLastProducts: true,
  });
}
