import { ca } from '@cian/analytics';
import { Button } from '@cian/ui-kit/button';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openBookingCalendar, setError, toggleCalendarModal } from 'shared/actions/dailyrentBookingCalendar';
import { selectOfferId } from 'shared/selectors';
import { selectDailyrentBookingCalendarOpen } from 'shared/selectors/dailyrentBookingCalendar';

const trackOnlineBookingCheckDatesButtonClick = (cianId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'DailyrentButton',
    action: 'Click',
    label: 'ToCalendarCheckDates',
    page: {
      pageType: 'Card',
    },
    products: [{ id: cianId }],
  });
};

export const OnlineBookingCheckDatesButtonContainer = () => {
  const dispatch = useDispatch();
  const calendarOpen = useSelector(selectDailyrentBookingCalendarOpen);
  const offerId = useSelector(selectOfferId);

  const handleClick = React.useCallback(() => {
    if (!calendarOpen) {
      dispatch(openBookingCalendar());
      trackOnlineBookingCheckDatesButtonClick(offerId);

      return;
    }

    dispatch(setError(''));
    dispatch(toggleCalendarModal(false));

    return;
  }, [calendarOpen, offerId]);

  return (
    <Button onClick={handleClick} theme="fill_primary" size="M" fullWidth>
      Проверить даты
    </Button>
  );
};
