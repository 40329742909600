import * as React from 'react';

export const IconColorized = (props: React.HTMLAttributes<SVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
    {/* tslint:disable-next-line:max-line-length */}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.33 9.5l-.5-.5 3.3-3.3c.1-.1.2-.3.1-.5h-1.1c-.1 0-.2-.1-.3-.2-.1-.1-.1-.2 0-.3l.1-.3-.3.1c-.1 0-.3 0-.3-.1-.2.1-.3 0-.3-.2l.1-.4-.4.1c-.1 0-.2 0-.3-.1-.1-.1-.1-.2-.1-.3l.1-.2-1.5.8c-.2.1-.4 0-.5-.1-.1-.1-.1-.3 0-.4l.9-1.3h-.2c-.1 0-.2 0-.3-.1-.1 0-.1-.1-.1-.2V.8c-.2 0-.4 0-.5.1l-3.3 3.3-.4-.5L8.83.4c.3-.4 1-.4 1.5 0 .1.1.1.2.1.2v.9l.6-.1c.1 0 .3 0 .4.1.1.1.1.3 0 .4l-.6.8.9-.5c.1-.1.3-.1.4 0 .1.1.2.2.1.4l-.1.4h.5c.1 0 .3 0 .3.1s.1.2.1.3l-.1.4.4-.1c.1 0 .3 0 .4.1.1.1.1.2.1.4l-.2.5h.8c.1 0 .2 0 .2.1.4.4.4 1.1 0 1.6l-3.3 3.1zm-10.805 5c.4.4.9.5 1.4.5 1.1-.2 2.3-1.4 3.1-3.2.2-.5.6-.8 1-.9.9-.2 2 .5 2.6 1.1.1.1.4.1.5 0l.7-.7-1-1c-.1-.1-.1-.3 0-.5.1-.1.3-.1.5 0l1 1 .3-.3c.1-.1.1-.4 0-.5l-5.7-5.6c-.1-.1-.4-.1-.5 0l-1.4 1.4c-.1.2-.1.4 0 .5.7.7 1.3 1.8 1.1 2.6-.1.4-.4.8-.9 1-1.8.8-3 2-3.2 3.1-.1.6.1 1.1.5 1.5zm.3-1.8c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4z"
    />
  </svg>
);
