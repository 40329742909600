import { UIHeading1 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';
import { CollapseButton } from '../CollapseButton';

export interface IFeaturesLayoutProps {
  title: string;
  onOpen?(): void;
  showOpenButton?: boolean;
  children: React.ReactNode;
}

export const FeaturesLayout: React.FC<React.PropsWithChildren<IFeaturesLayoutProps>> = ({
  title,
  children,
  onOpen,
  showOpenButton,
}) => {
  return (
    <div className={style['container']}>
      <UIHeading1 as="h2" color="text-primary-default">
        {title}
      </UIHeading1>

      <div className={style['wrapper']}>{children}</div>

      {showOpenButton && onOpen && (
        <div className={style['collapse-button']}>
          <CollapseButton onClick={onOpen} isCollapsed collapsedText="Вся инфраструктура" />
        </div>
      )}
    </div>
  );
};
