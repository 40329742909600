import { UIText1 } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { Description } from '../../components/DescriptionV2/Description';
import { Shutter } from '../../components/Shutter';
import { selectDescription } from '../../selectors/offerData/selectDescription';
import { DisclaimerContainer } from '../Disclaimer';

export const DescriptionContainer = () => {
  const description = useSelector(selectDescription);

  if (!description) {
    return null;
  }

  return (
    <Description>
      <Shutter maxHeight={150}>
        <UIText1 whiteSpace="pre-wrap">{description}</UIText1>
      </Shutter>

      <DisclaimerContainer />
    </Description>
  );
};
