import * as React from 'react';
import { useSelector } from 'react-redux';

import { WarningsFlexible } from '../../components/WarningsFlexible';
import { IState } from '../../store';

export const WarningsFlexibleContainer = () => {
  const warningMessage = useSelector((state: IState) => state.offerData.offer.moderationInfo?.warningMessage);

  if (!warningMessage) {
    return null;
  }

  const props = {
    warningMessage,
  };

  return <WarningsFlexible {...props} />;
};
