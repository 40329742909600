import { IConsumer, IEvent, createConsumer, createSubscriber } from '@cian/events-log';
import { IMicrofrontendManifest } from '@cian/mf-registry/shared';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useApplicationContext } from 'shared/containers/ApplicationContext';
import { offerSelector } from 'shared/selectors';
import { getComplaints } from 'shared/services/getComplaints';

const MICROFRONTEND_NAME = 'moderation-complain-frontend';
let consumer: IConsumer;
let complainManifest: IMicrofrontendManifest | null = null;

type UseModerationComplaintProps = {
  onCloseModal(): void;
};

//instanbul ignore next
export const useModerationComplaint = ({ onCloseModal }: UseModerationComplaintProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { httpApi, microfrontendsRegistry } = useApplicationContext();
  const offer = useSelector(offerSelector);
  const registryMicrofront = !!microfrontendsRegistry.microfrontend(MICROFRONTEND_NAME);
  const [manifest, setManifest] = useState<IMicrofrontendManifest | null>(complainManifest);
  const effectHandler = (): void => {
    consumer ??= createConsumer({ topic: 'complaintsModal' });

    /* istanbul ignore next */
    const complaintsSubscriber = createSubscriber((event: IEvent<unknown>) => {
      if (event.type === 'popup_close') {
        consumer.unsubscribe(complaintsSubscriber);
        onCloseModal();
      }
    });

    consumer.subscribe(complaintsSubscriber);
  };

  useEffect(() => {
    if (!registryMicrofront) {
      getComplaints({ httpApi }).then(({ manifest, isError }) => {
        if (manifest) {
          microfrontendsRegistry.register(manifest);
          setManifest(manifest);
          complainManifest = manifest;
        }

        setIsError(isError);
      });
    } else {
      setIsLoading(false);
    }
    effectHandler();
  }, [manifest, registryMicrofront, microfrontendsRegistry]);

  const refetch = () => {
    setIsLoading(true);
    getComplaints({ httpApi }).then(() => {});
  };

  return {
    isLoading,
    refetch,
    manifest,
    isError,
    offerId: offer.id,
  };
};
