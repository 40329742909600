import * as React from 'react';

import icon from './assets/discount.svg';
import { trackMortgageWidgetAnchorClick } from './tracking';
import { EntryPointRow } from '../EntryPointRow';

interface IUiData {
  redirectPath: string;
  linkText: string;
  icon: string;
}

interface IMortgageWidgetPromoDiscountProps {
  mortgagePromoDesign: string | null;
}

const dataMap: Record<string, IUiData> = {
  'vtb-hochu-stavky': {
    redirectPath:
      '/ipoteka-main/?cian_source=cian&cian_medium=trap&cian_term=card&cian_content=under_price_block_widget_desc',
    linkText: 'Ипотека со скидкой 3%',
    icon,
  },
  'promo-domrf-9.99': {
    redirectPath:
      '/ipoteka-main/?cian_source=cian&cian_medium=trap&cian_term=card&cian_content=under_price_block_widget_desc_s30p&cian_campaign=save_30_percents',
    linkText: 'Сэкономьте на ипотеке до 30%',
    icon,
  },
};

const defaultData: IUiData = {
  redirectPath:
    '/ipoteka-main/?cian_source=cian&cian_medium=trap&cian_term=card&cian_content=under_price_block_widget_desc',
  linkText: 'Ипотека',
  icon,
};

export const MortgageWidgetPromoDiscount: React.FC<IMortgageWidgetPromoDiscountProps> = ({ mortgagePromoDesign }) => {
  // istanbul ignore next
  const data = mortgagePromoDesign !== null ? dataMap[mortgagePromoDesign] ?? defaultData : defaultData;

  const handleAnchorClick = () => {
    trackMortgageWidgetAnchorClick();
  };

  return (
    <EntryPointRow dark onClick={handleAnchorClick} imgSrc={data.icon} title={data.linkText} link={data.redirectPath} />
  );
};
