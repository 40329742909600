import cx from 'clsx';
import * as React from 'react';

import {
  EBackground,
  IWarningMessageSchema,
} from 'shared/repositories/offer-card/entities/moderation_info/WarningMessageSchema';

import * as styles from './WarningsFlexible.css';

export interface IWarningsFlexibleProps {
  warningMessage: IWarningMessageSchema | null;
}

export const WarningsFlexible = ({ warningMessage }: IWarningsFlexibleProps) => {
  if (!warningMessage) {
    return null;
  }

  const { title, text, background, helpInfo } = warningMessage;
  const getMessage = (text?: string | null): React.ReactNode | string => {
    if (!text) {
      return '';
    }

    const pattern = 'пожаловаться';
    const patternStart = text.toLowerCase().lastIndexOf(pattern);
    const patternEnd = patternStart + pattern.length;

    if (patternStart === -1) {
      return text;
    }

    return (
      <>
        {text.slice(0, patternStart)}
        <span className={styles['tooltip-wrapper']}>
          <span className={styles['warning_info_link']}>{text.slice(patternStart, patternEnd)}</span>
        </span>
        {text.slice(patternEnd)}
      </>
    );
  };

  return (
    <div className={cx(styles['container'], getWarningBackground(background))}>
      <div className={styles['messages_container']}>
        <div className={styles['warning_title_with_icon']}>{title}</div>
        <div className={styles['warning_info']}>
          {getMessage(text)}&nbsp;
          {helpInfo && (
            <a className={styles['warning_info_link']} href={helpInfo.helpInfoLink}>
              {helpInfo.helpInfoText}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

function getWarningBackground(backgroundType?: string | null): string {
  switch (backgroundType) {
    case EBackground.Red:
      return styles['red'];
    default:
      return styles['gray'];
  }
}
