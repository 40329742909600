import { UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

export interface IOfferSummaryInfoItemProps {
  name: string;
  value?: string | number;
}

export const OfferSummaryInfoItem: React.FC<React.PropsWithChildren<IOfferSummaryInfoItemProps>> = ({
  name,
  value,
}) => {
  return (
    <div data-testid="OfferSummaryInfoItem" className={styles['item']}>
      <UIText1 display="block" color="gray60_100" as="p">
        {name}
      </UIText1>
      <UIText1 display="block" color="text-primary-default" as="p">
        {value}
      </UIText1>
    </div>
  );
};
