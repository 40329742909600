import { ECoworkingOfferType } from 'shared/types/api-models/offer-card/v1/get-offer-data';

const workplaceInCoworkingCollection = new Set<ECoworkingOfferType>([
  ECoworkingOfferType.FlexibleWorkplace,
  ECoworkingOfferType.FixedWorkplace,
]);

export function getIsWorkplaceInCoworking(coworkingOfferType: ECoworkingOfferType | null | undefined): boolean {
  if (coworkingOfferType == null) {
    return false;
  }

  return workplaceInCoworkingCollection.has(coworkingOfferType);
}
