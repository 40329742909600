import { ModalWindow } from '@cian/ui-kit';
import cx from 'clsx';
import { FormikProps } from 'formik';
import * as React from 'react';

import {
  Comment,
  Name,
  ActivityScope,
  IsAgent,
  PersonsDescription,
  Persons,
  Pets,
  PetsInfo,
  Children,
  ChildrenInfo,
  Submit,
  Error,
  Phone,
  SMSCode,
  IsTermAccepted,
} from './Fields';
import * as styles from './index.css';
import { IValues as IValuesSendCode } from '../../api/deal_rent/send-validation-code';
import { ISaveFormRequest } from '../../repositories/deal-rent-announcements/v1/save-form';
import { EDealRentVersion } from '../../repositories/offer-card/entities/offer/OfferSchema';

export interface IFormValues extends ISaveFormRequest {
  termAccepted?: false;
}
export interface IDealRentModalStateProps {
  isOpen: boolean;
  error: string | null;
  isLoading: boolean;
  phoneConfirmed: boolean;
  isSendSMSLoading: boolean;
  initialState: IFormValues;
  secondsToRetry: number;
  freeDealRent: boolean;
  dealVersion: EDealRentVersion;
}
export interface IDealRentModalDispathProps {
  closeModal(): void;
  sendValidationCode(values: IValuesSendCode): void;
  saveForm(values: ISaveFormRequest): void;
  changePhoneConfirmed(value: boolean): void;
}
export interface IDealRentModalProps
  extends IDealRentModalStateProps,
    IDealRentModalDispathProps,
    FormikProps<IFormValues> {}

export const DealRentModal = ({
  isOpen,
  closeModal,
  error,
  isLoading,
  sendValidationCode,
  secondsToRetry,
  values,
  handleSubmit,
  phoneConfirmed,
  resetForm,
  isSendSMSLoading,
  changePhoneConfirmed,
  touched,
  freeDealRent,
  dealVersion,
}: IDealRentModalProps) => {
  const onClose = React.useCallback(() => {
    resetForm();
    closeModal();
  }, [resetForm, closeModal]);

  React.useEffect(() => {
    changePhoneConfirmed(false);
  }, [values.phone]);

  return (
    <ModalWindow
      onClose={onClose}
      open={isOpen}
      content={
        <form className={styles['container']} onSubmit={handleSubmit}>
          <div className={cx(styles['title'], styles['span-columns'])}>Заполните анкету</div>
          <Persons />
          {(values.personsCount || 1) > 1 ? <PersonsDescription /> : <div />}
          <Children />
          <ChildrenInfo />
          <Pets />
          {(values.petsCount || 0) > 0 ? <PetsInfo /> : <div />}
          <Comment />
          <Name />
          <ActivityScope />
          <IsAgent />
          <div className={cx(styles['sms-container'], styles['span-columns'])}>
            <Phone />
            {!phoneConfirmed && (
              <SMSCode
                sendValidationCode={sendValidationCode}
                secondsToRetry={secondsToRetry}
                isLoading={isSendSMSLoading}
              />
            )}
          </div>
          <IsTermAccepted freeDealRent={freeDealRent} dealVersion={dealVersion} />
          <div className={cx(styles['field'], styles['span-columns'], styles['submit-container'])}>
            <Error error={error} />
            {(phoneConfirmed || touched.phone) && <Submit isLoading={isLoading} />}
          </div>
        </form>
      }
    />
  );
};
