import { ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import { AsyncActionStatus, TAsyncActionStatus } from 'shared/store/common';
import { RemoveModalFrame, TRemoveModalFrame } from 'shared/store/popups/remove';
import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { ArchiveContainer } from './frames/archive/container';
import { InitialContainer } from './frames/initial/container';
import { RemoveContainer } from './frames/remove/container';
import { RequestReviewContainer } from './frames/request_review/container';

export interface IRemoveModalProps {
  activeFrame: TRemoveModalFrame;
  isOpen: boolean;
  offer: IOfferSchema;
  status: TAsyncActionStatus;

  close(): void;
}

export class RemoveModal extends React.PureComponent<IRemoveModalProps, {}> {
  public componentDidUpdate() {
    if (this.props.status === AsyncActionStatus.Completed) {
      window.location.reload();
    }
  }

  public render() {
    return <ModalWindow open={this.props.isOpen} onClose={this.props.close} content={this.showActiveFrame()} />;
  }

  private showActiveFrame = () => {
    switch (this.props.activeFrame) {
      case RemoveModalFrame.Archive:
        return <ArchiveContainer />;
      case RemoveModalFrame.Remove:
        return <RemoveContainer />;
      case RemoveModalFrame.RequestReview:
        return <RequestReviewContainer />;
      default:
        return <InitialContainer />;
    }
  };
}
