import { connect } from 'react-redux';

import { IInitialProps, Initial } from './index';
import { DispatchReduxAction } from '../../../../../store';
import { changeActiveFrame, toggle, TRemoveModalFrame } from '../../../../../store/popups/remove';

function mapDispatchToProps(dispatch: DispatchReduxAction): IInitialProps {
  return {
    changeActiveFrame: (newActiveFrame: TRemoveModalFrame) => dispatch(changeActiveFrame(newActiveFrame)),
    close: () => dispatch(toggle(false)),
  };
}

export const InitialContainer = connect<{}, IInitialProps, {}>(() => ({}), mapDispatchToProps)(Initial);
