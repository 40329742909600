import { OwnerBadge } from '@cian/owner-badge-component';
import { IconFunctionalSuccess16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './DealRentBlock.css';
import { trackDealRentBlockShow } from './tracking';

const dealRentIcon = require('./assets/icon.svg');

const advList = [
  'Собственник прошел идентификацию',
  'Первый звонок принимает Циан, а затем арендатор и собственник общаются напрямую',
];

export const DealRentBlock = () => {
  React.useEffect(trackDealRentBlockShow, []);

  return (
    <div className={styles['block']}>
      <div className={styles['badge-wrap']}>
        <OwnerBadge isVerified />
        <img src={dealRentIcon} className={styles['title-icon']} />
        <div className={styles['title']}>Через сервис «Сдай/Сними»</div>
      </div>
      <div>
        {advList.map(adv => (
          <div key={adv} className={styles['list-item']}>
            <div className={styles['list-icon']}>
              <IconFunctionalSuccess16 color="icon-main-default" />
            </div>
            {adv}
          </div>
        ))}
      </div>
    </div>
  );
};
