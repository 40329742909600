import { Button, ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './index.css';
import { IDealRentSuccessModalProps } from './typings';

const successIcon = require('./success.svg') as string;

export const DealRentSendSimilarModal = ({ isOpen, offerId, closeModal, sendForm }: IDealRentSuccessModalProps) => {
  const send = React.useCallback(() => {
    const { referrer } = document;
    sendForm({ offerId, referrer });
  }, [offerId, sendForm]);

  return (
    <ModalWindow onClose={closeModal} open={isOpen}>
      <div className={styles['container']}>
        <img src={successIcon} />
        <h1 className={styles['title']}>Отправить анкету на похожие квартиры?</h1>
        <div className={styles['text']}>
          Ваша анкета отправлена. Мы можем автоматически отправить вашу анкету на похожие квартиры
        </div>
        <div className={styles['buttons-wrap']}>
          <div className={styles['button-wrap']}>
            <Button theme="fill_primary" size="L" onClick={send}>
              Отправить
            </Button>
          </div>
          <div className={styles['button-wrap']}>
            <Button theme="fill_secondary" size="L" onClick={closeModal}>
              Не отправлять
            </Button>
          </div>
        </div>
      </div>
    </ModalWindow>
  );
};
