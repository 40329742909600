import { connect } from 'react-redux';

import { Archive, IArchiveProps } from './index';
import { archive, changeActiveFrame, TRemoveModalFrame } from '../../../../../store/popups/remove';

import type { DispatchReduxAction, IState } from '../../../../../store';

type TStoreProps = Pick<IArchiveProps, 'agent' | 'isByHomeowner' | 'offerId' | 'status' | 'dealType'>;
type TDispatchProps = Pick<IArchiveProps, 'changeActiveFrame' | 'onSubmit'>;

export function mapStateToProps(state: IState): TStoreProps {
  return {
    agent: state.offerData.agent,
    isByHomeowner: state.offerData.offer.isByHomeowner,
    offerId: state.pageData.offerId,
    status: state.remove.status,
    dealType: state.pageData.dealType,
  };
}

export function mapDispatchToProps(dispatch: DispatchReduxAction): TDispatchProps {
  return {
    changeActiveFrame: (newActiveFrame: TRemoveModalFrame) => dispatch(changeActiveFrame(newActiveFrame)),
    onSubmit: () => dispatch(archive()),
  };
}

export const ArchiveContainer = connect<TStoreProps, TDispatchProps, {}>(mapStateToProps, mapDispatchToProps)(Archive);
