import { UIHeading1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './priceInfo.css';

interface IPriceInfo {
  price: string;
  priceHistory: React.ReactElement | null;
  priceInOtherCurrencies: React.ReactElement | null;
  coworkingAdditionalInfo: React.ReactElement | null;
}

export const PriceInfo = ({ price, priceHistory, priceInOtherCurrencies, coworkingAdditionalInfo }: IPriceInfo) => {
  return (
    <div className={styles['price']}>
      <div data-testid="price-amount" className={styles['amount']}>
        <UIHeading1>{price}</UIHeading1>
        {coworkingAdditionalInfo && (
          <div className={styles['coworking-additional-info']}>{coworkingAdditionalInfo}</div>
        )}
      </div>
      <div data-testid="price-history-button" className={styles['history']}>
        {priceHistory}
      </div>
      <div className={styles['currencies']}>{priceInOtherCurrencies}</div>
    </div>
  );
};
