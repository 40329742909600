import { Avatar, DoubleAvatar } from '@cian/ui-kit';
import * as React from 'react';

import { ISubscriptionAuthorAvatarProps } from './types';

export const SubscriptionAuthorAvatar = (props: ISubscriptionAuthorAvatarProps) => {
  const {
    agencyUrl,
    agentUrl,
    agencyAvatarUrl,
    agentAvatarUrl,
    isBothAvatarExists,
    handleAgentClick,
    handleSubAgentClick,
  } = props;

  return (
    <>
      {isBothAvatarExists && (
        <DoubleAvatar
          highlight="decorative-theme-white"
          primary={{
            src: agentAvatarUrl,
            href: agentUrl,
            type: 'user',
            onClick: handleSubAgentClick,
          }}
          secondary={{
            src: agencyAvatarUrl,
            href: agencyUrl,
            type: 'agency',
            onClick: handleAgentClick,
          }}
          size={92}
        />
      )}

      {!isBothAvatarExists && (
        <Avatar
          highlight="decorative-theme-white"
          src={agencyAvatarUrl || agentAvatarUrl}
          href={agencyAvatarUrl ? agencyUrl : agentUrl}
          type="agency"
          onClick={handleAgentClick}
          size={92}
        />
      )}
    </>
  );
};
