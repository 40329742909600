import * as React from 'react';

import * as styles from './styles.css';

export interface IOtherProps {
  value: string;
  onChange(value: string): void;
}

export function Other({ value, onChange }: IOtherProps) {
  return (
    <textarea className={styles['textarea']} rows={3} value={value} onChange={e => onChange(e.target.value)}></textarea>
  );
}
