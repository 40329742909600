import cx from 'clsx';
import * as React from 'react';

import { WarningInfoLink } from './WarningInfoLink';
import * as styles from './index.css';
import { FDealType } from '../../utils/deal_type';

export enum WARNING_STATUS {
  NEW = 1,
  RECIDIVIST,
  ERRORS,
  AKK_CHECKED,
}

export interface IWarningsStaticProps {
  dealType: FDealType;
  warningId: number | undefined;
  setComplaintPopupOpen(value: boolean): void;
}

export interface IMessage {
  title: string;
  info: string | React.ReactNode;
}

export const WarningsStatic = ({ warningId, dealType, setComplaintPopupOpen }: IWarningsStaticProps) => {
  const message = warningId ? getMessage(warningId, setComplaintPopupOpen) : undefined;

  return (
    /**
     * Предупреждение про предоплату выводим только для посуточной аренды
     */
    (message && warningId === WARNING_STATUS.NEW && dealType === FDealType.RentDaily) ||
      (message && warningId !== WARNING_STATUS.NEW) ? (
      <div className={cx(styles['container'], getWarningBackground(warningId))}>
        <div className={styles['messages_container']}>
          <div className={getWarningTitleStyle(warningId)}>{message.title}</div>
          <div className={styles['warning_info']}>
            {message.info} {warningId === WARNING_STATUS.ERRORS && <WarningInfoLink />}
          </div>
        </div>
      </div>
    ) : null
  );
};

function getWarningTitleStyle(id: WARNING_STATUS | undefined): string {
  switch (id) {
    case WARNING_STATUS.AKK_CHECKED:
      return styles['warning_title'];
    default:
      return styles['warning_title_with_icon'];
  }
}

function getWarningBackground(id: WARNING_STATUS | undefined): string {
  switch (id) {
    case WARNING_STATUS.RECIDIVIST:
      return styles['red'];
    case WARNING_STATUS.AKK_CHECKED:
      return styles['green'];
    default:
      return styles['grey'];
  }
}

function getMessage(id: WARNING_STATUS, setComplaintPopupOpen: (value: boolean) => void): IMessage {
  switch (id) {
    case WARNING_STATUS.NEW:
      return {
        info: 'Будьте осторожны при перечислении предоплаты. Предварительно рекомендуем запрашивать документы на объект и подтверждение заключения сделки.',
        title: 'Внимание! Объявление размещено новым пользователем',
      };
    case WARNING_STATUS.ERRORS:
      return {
        info: 'Объект может отличаться от предложенного в объявлении. Уточняйте параметры объекта до просмотра. Оставьте жалобу, если обнаружите ошибку.',
        title: 'В объявлениях этого агента встречаются ошибки.',
      };
    case WARNING_STATUS.RECIDIVIST:
      return {
        info: (
          <span>
            Возможно, это объявление вымышленное. Служба качества ЦИАН не рекомендует звонить по данному объявлению.
            Если вы позвонили, но объекта нет,{' '}
            <span className={styles['warning_info_link']} onClick={() => setComplaintPopupOpen(true)}>
              оставьте жалобу
            </span>
            .{' '}
            <a className={styles['warning_info_link']} href="/help/quality/37645/" target="_blank">
              Подробнее
            </a>
          </span>
        ),
        title: 'Этот агент замечен в размещении недостоверных предложений.',
      };
    case WARNING_STATUS.AKK_CHECKED:
      return {
        info: '',
        title: 'Объявление прошло дополнительную проверку',
      };
  }
}
