import * as React from 'react';

import * as styles from './styles.css';

export const Description = (props: React.PropsWithChildren<{}>) => {
  return (
    <div id="description" className={styles['description']}>
      {props.children}
    </div>
  );
};
