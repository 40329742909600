import * as React from 'react';

interface IPriceHistoryDownIcon {
  className?: string;
}

export function PriceHistoryDownIcon(props: IPriceHistoryDownIcon) {
  const className = props.className || '';

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 9 12">
      <path
        fill="#2E9E00"
        fillRule="evenodd"
        d="M5.121 11.65l3.78-6.358a.698.698 0 0 0-.258-.965.73.73 0 0 0-.362-.096H.719A.712.712 0 0 0 0 4.937c0 .125.034.247.098.355l3.78 6.358a.727.727 0 0 0 1.243 0zM2.343 4.231h4.314V.705A.712.712 0 0 0 5.937 0H3.063a.712.712 0 0 0-.719.705v3.526z"
      />
    </svg>
  );
}
