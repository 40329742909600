import { connect } from 'react-redux';

import { IRemoveProps, Remove } from './index';
import { remove } from '../../../../../store/popups/remove';

import type { DispatchReduxAction, IState } from '../../../../../store';

type TStoreProps = Pick<IRemoveProps, 'offerId' | 'status' | 'dealType' | 'isAgent'>;
type TDispatchProps = Pick<IRemoveProps, 'onSubmit'>;

export function mapStateToProps(state: IState): TStoreProps {
  return {
    offerId: state.pageData.offerId,
    status: state.remove.status,
    dealType: state.pageData.dealType,
    isAgent: state.offerData.user?.isAgent,
  };
}

export function mapDispatchToProps(dispatch: DispatchReduxAction): TDispatchProps {
  return {
    onSubmit: () => dispatch(remove()),
  };
}

export const RemoveContainer = connect<TStoreProps, TDispatchProps, {}>(mapStateToProps, mapDispatchToProps)(Remove);
