/* eslint-disable react-hooks/rules-of-hooks */
import * as React from 'react';
import { useSelector } from 'react-redux';

import { IDeveloperStats, IDeveloperLogoProps } from '../../../../../components/AuthorAsideNew';
import { selectIsAgencyBrandingAvailable } from '../../../../../selectors';
import { getIsFromBuilder } from '../../../../../selectors/newObject';
import { selectIsNewbuildingPremiumRate } from '../../../../../selectors/newbuildingPremiumRate';
import { getNewbuildingsListQueryStringUrl } from '../../../../../utils/formatters';
import {
  selectCompanyFoundationTime,
  selectDeveloper,
} from '../../../../AuthorAsideBrandContainer/selectors/selectors';

export interface IDeveloperPropsResponse {
  developerProps: IDeveloperLogoProps;
  developerStats: IDeveloperStats[];
}

export const useDeveloperProps = (): IDeveloperPropsResponse | null => {
  const developer = useSelector(selectDeveloper);
  const isPremiumNewbuilding = useSelector(selectIsNewbuildingPremiumRate);
  const isAgencyBrandingAvailable = useSelector(selectIsAgencyBrandingAvailable);
  const isFromBuilder = useSelector(getIsFromBuilder);

  const companyFoundationTime = useSelector(selectCompanyFoundationTime);

  if (!developer || !isFromBuilder || isPremiumNewbuilding || isAgencyBrandingAvailable) {
    return null;
  }

  const developerProps = {
    logoUrl: developer.logoUrl,
    developerName: developer.name,
    url: developer.url,
  } as IDeveloperLogoProps;

  const developerStats: IDeveloperStats[] = [];

  if (companyFoundationTime) {
    developerStats.push({
      testId: 'year-foundation-stat',
      title: 'Год основания',
      value: `${companyFoundationTime}`,
    });
  }

  if (developer.stats && developer.stats.done) {
    developerStats.push({
      testId: 'houses-done-stat',
      title: 'Сдано',
      value: `${developer.stats.done.text ?? 'Нет сданных ЖК'}`,
      link: getNewbuildingsListQueryStringUrl(developer.stats.done.qs as string),
    });
  }

  if (developer.stats && developer.stats.progress) {
    developerStats.push({
      testId: 'houses-in-progress-stat',
      title: 'Строится',
      value: `${developer.stats.progress.text ?? 'Нет строящихся ЖК'}`,
      link: getNewbuildingsListQueryStringUrl(developer.stats.progress.qs as string),
    });
  }

  if (!developerStats.length) {
    return null;
  }

  return React.useMemo(() => {
    return {
      developerProps,
      developerStats,
    };
  }, [developerProps, developerStats]);
};
