/* eslint-disable */

export interface IUserGASchema {
  /** Тип пользователя **/
  accountType?: EAccountType;
  /** Баланс пользователя **/
  balance?: number;
  /** Email md5 **/
  emailMd5?: string;
  /** Тариф пользователя **/
  packages?: EPackages[];
  /** Тип пользователя для google аналитики **/
  type?: string;
}
export enum EAccountType {
  /** Агенство **/
  'Agency' = 'agency',
  /** Управляющая компания **/
  'ManagementCompany' = 'managementCompany',
  /** Отдел аренды **/
  'RentDepartment' = 'rentDepartment',
  /** Специалист **/
  'Specialist' = 'specialist',
}
export enum EPackages {
  /** Базовый **/
  'Base' = 'base',
  /** Базовый частичный **/
  'BaseAsPart' = 'baseAsPart',
  /** Базовый (устаревший) **/
  'BaseOld' = 'baseOld',
  /** Застройщик **/
  'Builder' = 'builder',
  /** Спящий **/
  'Conscienceless' = 'conscienceless',
  /** Виртуальный пользователь ЕМЛС **/
  'EmlsVirtualUser' = 'emlsVirtualUser',
  /** Лидер **/
  'Lider' = 'lider',
  /** Виртуальный пользователь MLSN **/
  'MlsnVirtualUser' = 'mlsnVirtualUser',
  /** Виртуальный пользователь N1 **/
  'N1VirtualUser' = 'n1VirtualUser',
  /** Некоммерческий **/
  'NoCommerce' = 'noCommerce',
  /** Не указано **/
  'None' = 'none',
  /** Партнер универсальный - Аренда **/
  'PartnerUniversalRent' = 'partnerUniversalRent',
  /** Партнер универсальный - Продажа **/
  'PartnerUniversalSale' = 'partnerUniversalSale',
  /** Партнер **/
  'Pertner' = 'pertner',
  /** Партнер (только премиум) **/
  'PertnerPremiumOnly' = 'pertnerPremiumOnly',
  /** Профи **/
  'Profi' = 'profi',
  /** Профи частичный **/
  'ProfiAsPart' = 'profiAsPart',
  /** Региональный **/
  'Region' = 'region',
  /** Стартовый (устаревший) **/
  'StarterOld' = 'starterOld',
}
