import { ca } from '@cian/analytics';

import { EPanoramaStatus } from '../../components/Panorama';

export function trackOpen() {
  ca('eventSite', {
    action: 'open_panorama',
    category: 'card',
    label: '',
    name: 'oldevent',
    useLastProducts: true,
  });
}

export function trackError(status: EPanoramaStatus.Unsupported | EPanoramaStatus.NotFound | EPanoramaStatus.Failed) {
  let errorAction = 'fail_other_panorama';
  switch (status) {
    case EPanoramaStatus.Unsupported:
      errorAction = 'fail_browser_panorama';
      break;

    case EPanoramaStatus.NotFound:
      errorAction = 'fail_empty_panorama';
      break;
  }

  ca('eventSite', {
    action: errorAction,
    category: 'card',
    label: '',
    name: 'oldevent',
    useLastProducts: true,
  });
}
