import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from '../BookingInfo.css';
import { IBookingInfoLine } from '../types';

export function Lines({ lines }: { lines: IBookingInfoLine[] | undefined }) {
  if (!lines || !lines.length) {
    return null;
  }

  return (
    <>
      {lines.map(({ text, value, link }) => (
        <div className={styles['line']} key={text || value}>
          <span className={styles['line-label']}>
            <UIText2>{text}</UIText2>
          </span>
          {link ? (
            <a href={link} rel="noopener noreferrer nofollow" className={styles['line-value-link']}>
              <UIText2>{value}</UIText2>
            </a>
          ) : (
            <span className={styles['line-value']}>
              <UIText2>{value}</UIText2>
            </span>
          )}
        </div>
      ))}
    </>
  );
}
