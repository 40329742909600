import { ca } from '@cian/analytics';

export const trackShowButton = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'WebIM',
    action: 'Show',
    label: 'ChatIcon',
  });
};

export const trackClickChatOpen = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'WebIM',
    action: 'Click',
    label: 'StartChat',
  });
};

export const trackClickChatClose = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'WebIM',
    action: 'Click',
    label: 'EndChat',
  });
};

export const trackTelegramClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'WebIM',
    action: 'Click',
    label: 'Telegram',
  });
};

export const trackWhatsappClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'WebIM',
    action: 'Click',
    label: 'Whatsapp',
  });
};
