import { TContactType } from '@cian/contact-card-component';
import { useEventCallback } from '@cian/react-utils';
import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ContactButtonContainer } from 'shared/containers/BottomAuthorBlockNew/ContactButtonContainer/ContactButtonContainer';
import { SuperAgentLevelViewContainer } from 'shared/containers/SuperAgentLevelViewContainer';

import { AgentLifeTimeContainer } from './AgentLifeTimeContainer';
import { AgentMoreOffersContainer } from './AgentMoreOffersContainer';
import { AgentProofsTitleContainer } from './AgentProofsTitleContainer';
import { AgentReviewsTitleContainer } from './AgentReviewsTitleContainer';
import { AgentInfo } from '../../../components/BottomAuthorBlockNew';
import {
  offerSelector,
  selectAgentName,
  selectIsAgencyBrandingAvailable,
  selectIsByHomeowner,
  selectOfferAgent,
} from '../../../selectors';
import { getIsFromBuilder } from '../../../selectors/newObject';
import { selectAgentAvatarUrl, selectAgentUrl, selectIsSubAgent } from '../../../selectors/offerData/agent';
import { getAuthorLabelV2 } from '../../../utils/agent';
import { showCountrysideBuilderBadge } from '../../../utils/suburban';
import { CallBackButtonContainer } from '../../CallBackButton';
import { PhonesOpenedInfoContainer } from '../PhonesOpenedInfoContainer';

export const AgentInfoContainer: React.FC = () => {
  const isByHomeowner = useSelector(selectIsByHomeowner);
  const isFromBuilder = useSelector(getIsFromBuilder);
  const isShowCountrysideBuilderBadge = useSelector(showCountrysideBuilderBadge);
  const isBrandingAvailable = useSelector(selectIsAgencyBrandingAvailable);

  const offer = useSelector(offerSelector);
  const agent = useSelector(selectOfferAgent, shallowEqual);

  const agentName = useSelector(selectAgentName);
  const agentLink = useSelector(selectAgentUrl);
  const avatar = useSelector(selectAgentAvatarUrl);

  const isSubAgent = useSelector(selectIsSubAgent);
  const avatarType: TContactType = isSubAgent ? 'agent' : 'agency';

  const [isPhonesOpened, setPhonesOpened] = React.useState(false);

  const handlePhonesOpen = useEventCallback(() => {
    setPhonesOpened(true);
  });

  const title = agent && getAuthorLabelV2(offer, agent, isShowCountrysideBuilderBadge);

  if (!agent || isByHomeowner || isBrandingAvailable || isFromBuilder) {
    return null;
  }

  return (
    <AgentInfo
      avatarType={avatarType}
      avatar={avatar}
      title={title}
      agentLink={agentLink}
      agentName={agentName}
      agentReviews={<AgentReviewsTitleContainer />}
      agentProofs={<AgentProofsTitleContainer />}
      contactButton={
        <ContactButtonContainer
          isPhonesOpened={isPhonesOpened}
          handlePhonesOpen={handlePhonesOpen}
          electronicTradingTestId="electronic-trading-button-bottom"
        />
      }
      phonesShown={isPhonesOpened}
      callButton={<CallBackButtonContainer size="XS" placeType="agent_block" />}
      phonesOpenedInfo={<PhonesOpenedInfoContainer />}
      lifeTime={<AgentLifeTimeContainer />}
      linkToMoreOffers={<AgentMoreOffersContainer />}
      superAgentElement={<SuperAgentLevelViewContainer />}
    />
  );
};
