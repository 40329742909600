import { createSelector } from '@reduxjs/toolkit';

import { EBestPlaceReportStatus } from 'shared/types/bestPlaceAnalytics';

import { selectStatus } from './selectStatus';

export const spinnerStatuses = [
  EBestPlaceReportStatus.PaymentUrlLoading,
  EBestPlaceReportStatus.PaymentCreated,
  EBestPlaceReportStatus.PaymentLoading,
];

export const selectStatusBarHasSpinner = createSelector(selectStatus, status => {
  return status !== null ? spinnerStatuses.includes(status) : false;
});
