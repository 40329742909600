import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingRating } from 'shared/components/NewbuildingRating';
import { selectAsideCompactMode } from 'shared/selectors/aside';
import { getNewbuildingLink, getNewbuildingName, getNewbuildingRating } from 'shared/selectors/newObject';

export const NewbuildingRatingContainer: React.FC = () => {
  const isCompactModeEnabled = useSelector(selectAsideCompactMode);
  const newbuildingName = useSelector(getNewbuildingName);
  const newbuildingUrl = useSelector(getNewbuildingLink);
  const newbuildingRating = useSelector(getNewbuildingRating);

  if (!isCompactModeEnabled) {
    return null;
  }

  return (
    <NewbuildingRating
      reviewsRate={Number(newbuildingRating)}
      newbuildingName={newbuildingName}
      newbuildingUrl={newbuildingUrl}
    />
  );
};
