import { TButtonSize, TButtonTheme } from '@cian/ui-kit/button/types';
import * as React from 'react';

import { useCallbackButton } from './useCallbackButton';
import { CallbackButton, CallbackButtonCollapsed } from '../../components/CallbackButton';
import { TCallbackOpener } from '../../types/callbackPopup';

export interface ICallBackButtonContainerProps {
  size?: TButtonSize;
  collapsed?: boolean;
  placeType: TCallbackOpener;
  buttonTheme?: TButtonTheme;
  callbackButtonText?: string;
}

export function CallBackButtonContainer({
  size = 'M',
  collapsed,
  placeType,
  buttonTheme = 'fill_secondary',
  callbackButtonText = 'Заказ звонка',
}: ICallBackButtonContainerProps) {
  const { handleCallbackButtonClick, loading, isCallbackAvailable } = useCallbackButton(placeType);

  if (!isCallbackAvailable) {
    return null;
  }

  const ButtonComponent = collapsed ? CallbackButtonCollapsed : CallbackButton;

  return (
    <ButtonComponent
      size={size}
      onClick={handleCallbackButtonClick}
      placeType={placeType}
      loading={loading}
      buttonTheme={buttonTheme}
      callbackButtonText={callbackButtonText}
    />
  );
}
