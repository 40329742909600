import { IState } from '../../store';

export interface IAnalyticUser {
  type: string;
  email?: string;
  cianUserId?: number;
  userId?: number;
}

export const getAnalyticsUser = (state: IState): IAnalyticUser => {
  const user = state.offerData.user;

  if (!user) {
    return {
      type: 'not_authorized',
    };
  }

  return {
    type: user.ga?.accountType || 'not_authorized',
    email: user.email || undefined,
    cianUserId: user.cianUserId || undefined,
    userId: user.realtyUserId || undefined,
  };
};
