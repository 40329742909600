import { FormField, InputAdornment, MaskedInput } from '@cian/ui-kit';
import { useField } from 'formik';
import * as React from 'react';

import { useFieldHasErrors } from './utils';
import * as styles from '../index.css';

export const Phone = () => {
  const invalid = useFieldHasErrors('phone');
  const [{ value, name }, , { setValue }] = useField('phone');

  const handleChange = React.useCallback(
    (value: string) => {
      setValue(value);
    },
    [setValue],
  );

  return (
    <div className={styles['field']}>
      <FormField label="Телефон для связи">
        <MaskedInput
          value={value}
          onChange={handleChange}
          invalid={invalid}
          name={name}
          leftAdornment={<InputAdornment>+7</InputAdornment>}
          mask="(000) 000 00-00"
          placeholder="Введите текст"
        />
      </FormField>
    </div>
  );
};
