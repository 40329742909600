import { Input } from '@cian/ui-kit';
import * as React from 'react';

export interface IOtherSiteProps {
  value: string;
  onChange(value: string): void;
}

export function OtherSite({ value, onChange }: IOtherSiteProps) {
  return <Input value={value} onChange={(_, value) => onChange(value)} />;
}
