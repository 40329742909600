import { Button, Tooltip } from '@cian/ui-kit';
import { IconCommunicationCall16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { TCallBackButtonComponent } from '../../types';

export const CallbackButtonCollapsed: TCallBackButtonComponent = ({
  size,
  onClick,
  loading,
  buttonTheme,
  callbackButtonText,
}) => {
  return (
    <Tooltip title={callbackButtonText}>
      <Button
        onClick={onClick}
        beforeIcon={<IconCommunicationCall16 color="current_color" />}
        theme={buttonTheme}
        size={size}
        loading={loading}
        data-testid="collapsed-callback-button"
      />
    </Tooltip>
  );
};
