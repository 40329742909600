import * as React from 'react';

import * as styles from './index.css';
import { trackClickOnItem } from './tracking';

export interface IBreadcrumbsItem {
  title: string;
  url?: string;
}

export interface IOfferBreadcrumbsProps {
  breadcrumbs: IBreadcrumbsItem[] | null;
}

export class OfferBreadcrumbs extends React.Component<IOfferBreadcrumbsProps, {}> {
  public render() {
    if (!this.props.breadcrumbs) {
      return null;
    }

    return (
      <div itemType="http://schema.org/BreadcrumbList" itemScope className={styles['breadcrumbs']}>
        {this.props.breadcrumbs.map((breadcrumb, index) => {
          if (breadcrumb.url) {
            return (
              <span
                key={breadcrumb.url}
                itemScope
                itemProp="itemListElement"
                itemType="http://schema.org/ListItem"
                className={styles['link']}
              >
                <a
                  key={`breadcrumb_${index}`}
                  href={breadcrumb.url}
                  title={breadcrumb.title}
                  itemProp="item"
                  className={styles['link']}
                  onClick={() => this.handleClickOnItem(breadcrumb.url)}
                >
                  <span itemProp="name">{breadcrumb.title}</span>
                  <meta itemProp="position" content={`${index + 1}`} />
                </a>
              </span>
            );
          } else {
            return (
              <span
                key={breadcrumb.url}
                itemScope
                itemProp="itemListElement"
                itemType="http://schema.org/ListItem"
                className={styles['link']}
              >
                <span
                  key={`breadcrumb_${index}`}
                  title={breadcrumb.title}
                  itemProp="item"
                  className={styles['link']}
                  onClick={() => this.handleClickOnItem()}
                >
                  <span itemProp="name">{breadcrumb.title}</span>
                  <meta itemProp="position" content={`${index + 1}`} />
                </span>
              </span>
            );
          }
        })}
      </div>
    );
  }

  private handleClickOnItem = (url?: string) => {
    trackClickOnItem(url || '');
  };
}
