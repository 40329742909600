import * as React from 'react';
import { useSelector } from 'react-redux';

import { ParentNew } from '../../components/ParentNew';
import { trackClick, trackKPLinkClick } from '../../components/parent/tracking';
import { selectOffer, selectParent } from '../../selectors';
import { businessShoppingCenterSelector as selectBusinessShoppingCenterSelector } from '../../selectors/offerData/offer/businessShoppingCenterSelector';
import { selectAgent } from '../../selectors/selectAgent';
import { buildingTypePrepositional, ParentType } from '../../utils/offer_helpers';
import { getCustomUrl } from '../../utils/tracking/getCustomUrl';

export function ParentNewContainer() {
  const agent = useSelector(selectAgent);
  const businessShoppingCenter = useSelector(selectBusinessShoppingCenterSelector);
  const offer = useSelector(selectOffer);
  const parent = useSelector(selectParent);

  const handleClick = React.useCallback(() => {
    if (parent && parent.type === ParentType.JK) {
      trackClick(
        getCustomUrl(offer, agent, {
          buildingType: 'JK',
        }),
      );
    } else if (parent && parent.type === ParentType.KP) {
      trackKPLinkClick(
        getCustomUrl(offer, agent, {
          buildingType: 'KP',
        }),
      );
    }
  }, [parent, offer, agent]);

  const formatParentName = React.useMemo(() => {
    let prefix = '';

    if (!parent) {
      return undefined;
    }

    if (parent.type === ParentType.JK) {
      prefix = 'ЖК';
    } else if (parent.type === ParentType.KP) {
      prefix = 'посёлке';
    } else if (parent.type === ParentType.Coworking) {
      prefix = 'коворкинге';
    } else {
      if (businessShoppingCenter?.buildingType && buildingTypePrepositional[businessShoppingCenter.buildingType]) {
        prefix = buildingTypePrepositional[businessShoppingCenter.buildingType];
      } else if (offer.building?.type) {
        prefix = buildingTypePrepositional[offer.building.type];
      }
    }

    return `${prefix} «${parent.name}»`;
  }, [parent, offer, businessShoppingCenter]);

  if (!parent || !formatParentName) {
    return null;
  }

  return <ParentNew title={formatParentName} onClick={handleClick} url={parent.url} />;
}
