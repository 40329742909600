/* istanbul ignore next */
import { ca } from '@cian/analytics';

export function trackClickCardBuilder(offerId: number, builderId: number) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card',
    action: 'click_sopr',
    label: 'to_card_builder',
    page: {
      extra: {
        is_premium: true,
      },
    },
    products: [
      {
        id: offerId,
        offerType: 'offer',
        extra: {
          to_object: builderId,
        },
      },
    ],
  });
}

export function trackClickBuilderFact(offerId: number, label: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card',
    action: 'click_listingjk',
    label,
    page: {
      extra: {
        is_premium: true,
      },
    },
    products: [
      {
        id: offerId,
        offerType: 'offer',
      },
    ],
  });
}
