import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

export enum NavigationTab {
  Photos = '#photos',
  Description = '#description',
  Map = '#mapsSection',
  Author = '#author',
  ServicePlaceOverMap = '#servicePlaceOverMap',
  ServicePlaceUnderMap = '#servicePlaceUnderMap',
  ServicePlaceOverEvaluation = '#servicePlaceOverEvaluation',
  Valuation = '#valuation',
  SimilarOffers = '#similarOffers',
}

export interface IOfferCardNavigationStoreProps {
  offer?: IOfferSchema;
  isBottomAgentBlockAvailable?: boolean;
  isActive?: boolean;
  activeAnchor?: string;
  isSimilarOffersAvailable?: boolean;
}

export interface IOfferCardNavigationDispatchProps {
  toggleNavigation?(isActive: boolean): void;

  changeNavigationAnchor?(activeAnchor: string): void;
}

export interface IProps {
  isFixed?: boolean;
  showRightContent?: boolean;
}

export type IOfferCardNavigationProps = IOfferCardNavigationStoreProps & IOfferCardNavigationDispatchProps & IProps;
