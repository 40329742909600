import * as React from 'react';

export const IconVk = (props: React.HTMLAttributes<SVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <rect fill="currentColor" width="32" height="32" rx="16" ry="16"></rect>
    {/* tslint:disable-next-line:max-line-length */}
    <path
      fill="#ffffff"
      d="M17.26 9.46c1.72 0 3.19.84 3.19 2.55a2.59 2.59 0 0 1-1.58 2.61c.09.08.7.26.9.36a2.49 2.49 0 0 1 .74.54 2.6 2.6 0 0 1 .71 2.13C21.23 19.88 19.07 21 17 21h-4.8a.2.2 0 0 1-.2-.2V9.66a.2.2 0 0 1 .2-.2h5zm-2.39 3V14c1.35 0 2.62.18 2.62-1.29s-1.44-1.13-2.62-1.13v.92zm0 6.39c1.48 0 3.31.24 3.34-1.44S16.33 16 14.87 16v2.87z"
    ></path>
  </svg>
);
