import { UIText2 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as style from './style.css';

export interface INavigationLink {
  href: string;
  text: string;
}

export interface INavigationLinksProps {
  activeHref: string;
  links: INavigationLink[];
  navigate: (event: React.MouseEvent<HTMLAnchorElement>, href: string) => void;
}

export const NavigationLinks: React.FC<React.PropsWithChildren<INavigationLinksProps>> = ({
  activeHref,
  links,
  navigate,
}) => {
  return (
    <ul className={style['links']}>
      {links.map(({ href, text }) => (
        <li key={text} className={cx(style['link-item'], activeHref === href && style['active'])}>
          <a href={href} onClick={event => navigate(event, href)}>
            <UIText2
              color={activeHref === href ? 'text-main-default' : 'text-primary-default'}
              fontWeight={activeHref === href ? '700' : '400'}
            >
              {text}
            </UIText2>
          </a>
        </li>
      ))}
    </ul>
  );
};
