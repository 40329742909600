import { numberToPrettyString } from '@cian/utils';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { PriceRequestForm } from 'shared/components/PriceRequestForm';
import { useChats } from 'shared/hooks/useChats';
import { isFromBuilderSelector, selectUser } from 'shared/selectors';
import { offerChatSelector } from 'shared/selectors/offerChat';
import { selectAgentLists } from 'shared/selectors/offerData/selectAgentLists';
import { EStatusOffer } from 'shared/types/offerData';

import { DISCOUNT_PRICE } from './constants';
import { trackPriceRequestClick } from './tracking';
import { selectOffer } from '../AuthorAsideBrandContainer/selectors/selectors';

export const PriceRequestContainer: React.FC = () => {
  const [value, setValue] = React.useState<number>(0);
  const user = useSelector(selectUser);
  const offer = useSelector(selectOffer);
  const isFromBuilder = useSelector(isFromBuilderSelector);
  const { handleOpenChat } = useChats();

  const price =
    offer.priceTotalRur ||
    offer.priceTotalPerMonthRur ||
    offer.pricePerUnitAreaRur ||
    offer.bargainTerms.prices.rur ||
    0;

  const isOfferDaily = offer.category.includes('daily');
  const isOfferPublished = offer.status === EStatusOffer.Published;

  const agentLists = useSelector(selectAgentLists);

  const isAuctionAgentLists = React.useMemo(
    () => agentLists.includes('tradeRealty') || agentLists.includes('electronTradeSite'),
    [agentLists],
  );

  const discountPrice = React.useMemo(
    () => numberToPrettyString(Math.floor(Math.floor(price * DISCOUNT_PRICE) / 100) * 100),
    [price],
  );

  const { isChatsEnabled } = useSelector(offerChatSelector);

  const handleSend = React.useCallback(() => {
    if (value) {
      const messageText = `Здравствуйте! Вы не против небольшого торга? Предлагаю цену ${numberToPrettyString(
        value,
      )}? Вы готовы сделать скидку?`;

      trackPriceRequestClick(offer, value);

      handleOpenChat({ defaultMessage: messageText, shouldSendDefaultMessage: true });
    }
  }, [handleOpenChat, offer, value]);

  const handleChange = React.useCallback((value: number) => {
    setValue(value);
  }, []);

  if (
    isFromBuilder ||
    isAuctionAgentLists ||
    isOfferDaily ||
    !isChatsEnabled ||
    user?.cianUserId === offer.cianUserId ||
    !isOfferPublished
  ) {
    return null;
  }

  return <PriceRequestForm discountPrice={discountPrice} onClick={handleSend} onChange={handleChange} />;
};
