import { useSelector } from 'react-redux';

import { selectShowDailyrentBookingCalendarInput } from 'shared/selectors/dailyrentBookingCalendar';
import { useIsDailyrentBookingCalendarEnabled } from 'shared/utils/featureToggles/useIsDailyrentBookingCalendarEnabled';

export const useShowDailyrentBookingCalendar = () => {
  const showDailyrentBookingCalendarInput = useSelector(selectShowDailyrentBookingCalendarInput);
  const isDailyrentBookingCalendarEnabled = useIsDailyrentBookingCalendarEnabled();

  return isDailyrentBookingCalendarEnabled && showDailyrentBookingCalendarInput;
};
