import { ca } from '@cian/analytics';

import { IOfferSchema, IAgentSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IIdenticalOffersView } from '../../../types/identicalOffersView';
import { getCustomUrl } from '../../../utils/tracking/getCustomUrl';

interface IParams {
  identicalOfferIndex: number;
  identicalOffers: IIdenticalOffersView | null;
  offer: IOfferSchema;
  agent: IAgentSchema | null;
}

export function trackIdenticalOfferClick(params: IParams) {
  const { offer, agent, identicalOffers, identicalOfferIndex } = params;
  const identicalOffer = identicalOffers?.items[identicalOfferIndex];

  ca('eventSite', {
    name: 'oldevent',
    category: 'card',
    action: 'to_card_identical_offers',
    label: getCustomUrl(offer, agent),
    products: [
      {
        id: identicalOffer?.id,
        offerType: 'offer',
        position: identicalOfferIndex + 1,
      },
    ],
  });
}
