import { ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles/styles.css';
import { Bubbles } from '../../../icons';
import { trackFeedbackSucceed } from '../../tracking';

interface IPopupFeedbackSentProps {
  isOpen: boolean;
  onClose(): void;
}

let isFirstSend = true;

export function PopupFeedbackSent({ isOpen, onClose }: IPopupFeedbackSentProps) {
  if (isOpen && isFirstSend) {
    trackFeedbackSucceed();
    isFirstSend = false;
  }

  return (
    <ModalWindow
      open={isOpen}
      onClose={onClose}
      content={
        <div className={styles['body']}>
          <div className={styles['image']}>
            <Bubbles />
          </div>
          <div className={styles['bold']}>Спасибо за отзыв</div>
        </div>
      }
    />
  );
}
