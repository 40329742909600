import * as React from 'react';
import { useSelector } from 'react-redux';

import { ReviewStats } from 'shared/components/ReviewStats';
import { selectReviewStats } from 'shared/selectors/newbuildingPremiumRate';

export const RatingContainer = () => {
  const reviewStats = useSelector(selectReviewStats);

  if (!reviewStats || reviewStats.reviewCount === 0) {
    return null;
  }

  return <ReviewStats reviewCountText={reviewStats.reviewCountText} totalRate={reviewStats.totalRate} />;
};
