import { connect } from 'react-redux';

import { IPriceDropdownProps, PriceDropdown } from './index';
import { IState } from '../../store/index';

type StoreProps = Pick<IPriceDropdownProps, 'offer'>;
type OwnProps = Pick<IPriceDropdownProps, 'className'>;

function mapStateToProps(state: IState): StoreProps {
  return {
    offer: state.offerData.offer,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PriceDropdownContainer = connect<IPriceDropdownProps, any, OwnProps>(mapStateToProps)(PriceDropdown);
