import { ContactLabels } from '@cian/contact-card-component';
import { IconProductDocumentsVerified16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

const AgencyLabelsComponent: React.FC = () => {
  return (
    <ContactLabels
      labels={[
        {
          icon: <IconProductDocumentsVerified16 color="icon-main-default" />,
          title: 'Документы проверены',
        },
      ]}
    />
  );
};

export const AgencyLabels = React.memo(AgencyLabelsComponent);
