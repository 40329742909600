import * as React from 'react';

export const BottomArrowIcon = (
  <svg width="10" height="10" viewBox="0 0 48 48" fill="#2B87DB">
    <path
      data-fill-rule="fill"
      d="M40.19 11.4C39.12 12.48 33.73 17.87 24.03 27.56C14.33 17.87 8.94 12.48 7.86 11.4C6.11 9.58 3.21 9.53 1.39 11.29C-0.42 13.04 -0.47 15.94 1.28 17.75C1.32 17.79 1.36 17.83 1.39 17.87C3.33 19.81 18.86 35.33 20.8 37.27C22.58 39.05 25.48 39.05 27.26 37.27C29.2 35.33 44.72 19.81 46.66 17.87C48.44 16.08 48.44 13.19 46.66 11.4C44.87 9.62 41.98 9.62 40.19 11.4Z"
    />
  </svg>
);
