import * as React from 'react';

import { useDeveloperProps } from './hooks';
import { DeveloperLayout, DeveloperLogo } from '../../../../components/AuthorAsideNew';

export const DeveloperInfo: React.FC = () => {
  const props = useDeveloperProps();

  if (!props) {
    return null;
  }

  return <DeveloperLayout logo={<DeveloperLogo {...props.developerProps} />} stats={props.developerStats} />;
};
