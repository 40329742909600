import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingBuildersFactItem, NewbuildingBuildersFacts } from '../../components/NewbuildingBuildersFacts';
import { selectBuilderFacts, selectIsNewbuildingPremiumRate } from '../../selectors/newbuildingPremiumRate';

export function NewbuildingBuildersFactsContainer() {
  const isEnabled = useSelector(selectIsNewbuildingPremiumRate);
  const facts = useSelector(selectBuilderFacts);

  if (!facts || !isEnabled) {
    return null;
  }

  return (
    <NewbuildingBuildersFacts>
      {facts.map(item => (
        <NewbuildingBuildersFactItem
          key={item.title}
          imageUrl={item.imageUrl}
          title={item.title}
          description={item.description}
        />
      ))}
    </NewbuildingBuildersFacts>
  );
}
