import { Label, Tooltip, UIHeading4, UIText2 } from '@cian/ui-kit';
import { IconProductCianLogo16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './UniqueForCianBadge.css';

export const UniqueForCianBadge: React.FC = () => (
  <Tooltip
    placement="bottom"
    theme="white"
    title={
      <div className={styles['content']}>
        <UIHeading4>Только на Циан</UIHeading4>
        <UIText2>
          За последние сутки мы не нашли этот объект
          <br />в открытом доступе на других агрегаторах
        </UIText2>
      </div>
    }
  >
    <Label
      background="accent-main-secondary"
      color="accent-main-primary"
      icon={<IconProductCianLogo16 color="icon-main-default" />}
    >
      Только на Циан
    </Label>
  </Tooltip>
);

UniqueForCianBadge.displayName = 'UniqueForCianBadge';
