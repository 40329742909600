let imapPromise: Promise<IMap.IMap>;

export function loadImapApi(panoramaUrl: string): Promise<IMap.IMap> {
  if (!imapPromise) {
    imapPromise = new Promise((resolve, reject) => {
      if (window.IPANO) {
        resolve(window.IPANO);

        return;
      }

      window.imapOnload = function () {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        resolve(window.IPANO!);

        delete window.imapOnload;
      };

      const e = window.document.createElement('script');
      e.src = `${panoramaUrl}?callback=imapOnload&epsg=4326`;
      e.async = true;
      e.defer = true;
      e.onerror = () => {
        reject(new Error('Unable to load Panorama API'));
      };

      window.document.body.appendChild(e);
    });
  }

  return imapPromise.then(imap => {
    if (window.IPANO) {
      window.IPANO.Config.mode = 'egip';
      window.IPANO.Pano.getSvgHouseNumber = getSvgHouseNumber;
    }

    return imap;
  });
}

export function getSvgHouseNumber({
  scale,
  fillColor,
  width,
  height,
  content,
  textColor,
}: IMap.IGetSvgHouseNumberParameters): string {
  return `<svg style="transform: scale(${scale})">
    <g>
      <rect style="fill:${fillColor};" x="0" y="0" rx="5" width="${width}" height="${height}" />
      <text text-anchor="middle" style="font-family: Lato, Arial, sans-serif; font-size: 26px; font-weight: 600; line-height: 32px; fill: ${textColor};" y="32" x="${
        width / 2
      }">
        ${content}
      </text>
    </g>
  </svg>`;
}
