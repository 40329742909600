import { connect } from 'react-redux';

import { IOfferUnpublished, OfferUnpublished } from './index';
import { IState } from '../../store';

function mapStateToProps(state: IState): IOfferUnpublished {
  return {
    status: state.offerData.offer.status,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OfferUnpublishedContainer = connect<IOfferUnpublished, any, any>(mapStateToProps)(OfferUnpublished);
