import { UIHeading4, UIText2, UIText3 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './tgb_suburban.css';
import { Marquee } from '../../../Marquee';
import { TAdFoxTemplatesParams } from '../OwnTemplates';

export const TgbSuburbanTemplate: React.FC<React.PropsWithChildren<TAdFoxTemplatesParams>> = ({
  image,
  logo,
  titleProject,
  price,
  square,
  url,
  material,
  footnote,
  advertiser,
}) => {
  return (
    <a href={url} target="_blank" rel="nofollow noopener noreferrer" className={styles['link']}>
      <div className={styles['container']}>
        <div className={styles['images']}>
          <img src={image} alt={titleProject} className={styles['background']} />
          <img src={logo} alt={footnote} className={styles['logo']} />
        </div>
        <div className={styles['content']}>
          <UIText3 color="gray60_100">{titleProject}</UIText3>
          <div className={styles['price']}>
            <UIHeading4>{price}</UIHeading4>
          </div>
          <UIText2>{square}</UIText2>
          <UIText2>{material}</UIText2>
          <div className={styles['footer']}>
            <UIText3 color="gray60_100">{footnote}</UIText3>
            <div className={styles['advertiser']}>
              <Marquee text={advertiser} scrollOffset={30} />
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};
