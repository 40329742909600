/* eslint-disable react/jsx-no-target-blank */
import { IConfig } from '@cian/config/shared';
import { Button } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import { IOffenceSchema, TOfferStatus } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IconWarning } from './icons/icon_warning';
import * as styles from './index.css';
import { Link } from '../../common/components/link';
import { TStatusFetch } from '../../store/popups/reclaim';
import { ReclaimModalContainer } from '../popups/reclaim/container';

export interface IOfferRefusedProps {
  config: IConfig;
}
export interface IOfferRefusedStoreProps {
  newEdit: string | null;
  offerUserId: number | null;
  userId: number | null;
  publishedUserId: number | null;
  status: TOfferStatus | null;
  offences: IOffenceSchema[] | null;
  isImported: boolean | null;
  isOpenModalReclaim: boolean;
  statusFetchModalReclaim: TStatusFetch;
  userConfirmedFix: boolean;
}

export interface IOfferRefusedDispatchProps {
  openReclaimModal(): void;
}

export interface IOfferRefusedState {
  isExpanded: boolean;
}

export type IProps = IOfferRefusedProps & IOfferRefusedStoreProps & IOfferRefusedDispatchProps;

export class OfferRefused extends React.Component<IProps, IOfferRefusedState> {
  private container: HTMLDivElement | null;

  public state = {
    isExpanded: true,
  };

  public componentDidMount() {
    if (this.container && this.props.isImported) {
      // Ставим высоту для начального значения анимации CSS Transition
      this.container.style.height = `${this.container.scrollHeight}px`;
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (
      prevProps.isImported &&
      prevProps.isOpenModalReclaim &&
      !this.props.isOpenModalReclaim &&
      prevProps.statusFetchModalReclaim === 'ok' &&
      this.container
    ) {
      this.setState({ isExpanded: false });
    }
  }

  public render() {
    const { newEdit, status, offerUserId, userId, publishedUserId, offences, isImported, userConfirmedFix, config } =
      this.props;

    if (!newEdit || !status || !offerUserId || !userId || !publishedUserId) {
      return null;
    }

    const isCurrentUserOwnerOffer = publishedUserId === userId || offerUserId === userId;

    const isShowFixedApproveButton = config.get<boolean>('xml.showFixedApproveButton') || false;

    if (isCurrentUserOwnerOffer && status === 'refused') {
      return (
        <div
          ref={
            // istanbul ignore next: покрытие собирается некорректно из-за того, что ref замокан
            container => (this.container = container)
          }
          className={cx([styles['container'], !this.state.isExpanded && styles['container--hide']])}
        >
          <div className={styles['content']}>
            <div className={styles['title']}>
              <IconWarning />
              Объявление отклонено модератором
            </div>
            <div className={styles['description']}>
              {offences &&
                offences.map((offence, index) => (
                  <div key={`offence_${index}`}>
                    {offence.reason}
                    {offence.comment && `: ${offence.comment}`}
                  </div>
                ))}
            </div>
            {isImported ? (
              userConfirmedFix ? (
                <span className={styles['user_confirmed_fix']}>
                  Мы проверим исправления при следующей обработке фида
                </span>
              ) : (
                <div className={styles['fix_wrapper']}>
                  {isShowFixedApproveButton ? (
                    <Button size="XS" onClick={this.props.openReclaimModal}>
                      Уже исправлено
                    </Button>
                  ) : null}
                  <Link href="/help/quality/37660/" className={styles['link']} target="_blank">
                    Как исправить объявление?
                  </Link>
                </div>
              )
            ) : (
              <a href={newEdit} className={styles['link_fix']} target="_blank">
                Исправить
              </a>
            )}
            <ReclaimModalContainer />
          </div>
        </div>
      );
    }

    return null;
  }
}
