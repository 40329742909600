import { IState } from '../store';

/**
 * Возвращает состояние ML-ранжирования.
 *
 * @param {IState} state слепок хранилища
 * @returns {boolean}
 */
export const isMlSearchEnabled = (state: IState): boolean => {
  const {
    offerData: {
      offer: { geo },
    },
    isMlSearchForAllEnabled,
    mlSearchRegions,
  } = state;

  const offerLocation =
    geo && geo.address && geo.address.length > 0 ? geo.address.find(addr => addr.type === 'location') : null;

  return isMlSearchForAllEnabled || (!!offerLocation && (mlSearchRegions || []).includes(offerLocation.id));
};
