export function getUnits(count: number, value: number): number[] {
  const units: number[] = [];

  const floatPart = value % 1;
  const intPart = Math.floor(value);

  for (let i = 0; i < count; i++) {
    let unitValue = i < intPart ? 1 : 0;

    if (intPart === i) {
      unitValue = floatPart;
    }

    units.push(unitValue);
  }

  return units;
}
