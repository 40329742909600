import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

export function shouldShowTitle(offer: IOfferSchema): boolean {
  const { title, valueAddedServices, newbuilding } = offer;

  const hasTitle = typeof title === 'string' && title.trim().length > 0;
  const hasTargetVAS = valueAddedServices && (valueAddedServices.isTop3 || valueAddedServices.isPremium);

  return hasTitle && (hasTargetVAS || (newbuilding !== undefined && newbuilding.isPremium));
}
