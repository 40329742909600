import { scrollToAnchor } from '@cian/newbuilding-utils';
import * as React from 'react';

import { trackTransportAccessibilityEntryClick } from './tracking';

const MAP_AVAILABLE_EVENT = 'map-services:available:v1';
const MAP_OPEN_ACCESSIBILITY_EVENT = 'map-services:open-transport-accessibility:v1';

export const useMapEvents = () => {
  const [isMapAvaliable, setIsMapAvaliable] = React.useState(false);
  const [isMapCalled, setIsMapCalled] = React.useState(false);

  const toggleMapState = React.useCallback(() => {
    setIsMapAvaliable(true);
  }, []);

  React.useEffect(() => {
    document.addEventListener(MAP_AVAILABLE_EVENT, toggleMapState);

    return () => {
      document.removeEventListener(MAP_AVAILABLE_EVENT, toggleMapState);
    };
  }, [toggleMapState]);

  React.useEffect(() => {
    if (!isMapAvaliable || !isMapCalled) {
      return;
    }

    document.dispatchEvent(new CustomEvent(MAP_OPEN_ACCESSIBILITY_EVENT));

    setIsMapCalled(false);
  }, [isMapAvaliable, isMapCalled]);

  return React.useCallback((event: React.MouseEvent) => {
    event.preventDefault();

    setIsMapCalled(true);

    scrollToAnchor({
      anchor: '#mapsSection',
      offset: 0,
      hasRelocation: false,
      hasAnimation: true,
    })(event);

    trackTransportAccessibilityEntryClick();
  }, []);
};
