import { ca } from '@cian/analytics';

export const trackOnlineBookingBookButtonClick = (cianId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'DailyrentButton',
    action: 'Click',
    label: 'ToCheckout',
    page: {
      pageType: 'Card',
    },
    products: [{ id: cianId }],
  });
};
