import { connect } from 'react-redux';

import { startBuyAnalytics } from '../../actions/buyAnalyticsModal';
import { BuyAnalyticsButton } from '../../components/BuyAnalyticsBlocks';
import { IBuyAnalyticsButtonProps } from '../../components/BuyAnalyticsBlocks/components/BuyAnalyticsButton/types';
import { IBuyAnalyticsBlockProps } from '../../components/BuyAnalyticsBlocks/types';
import { selectBuyAnalyticsReportPrice } from '../../selectors/buyAnalyticsModal';
import { IState } from '../../store';

/* istanbul ignore next */
export const BuyAnalyticsButtonContainer = connect<IBuyAnalyticsButtonProps, IBuyAnalyticsBlockProps, {}, IState>(
  state => {
    return {
      reportPrice: selectBuyAnalyticsReportPrice(state),
    };
  },
  {
    onBuyClick: startBuyAnalytics,
  },
)(BuyAnalyticsButton);
