import React from 'react';

import {
  getDailyrentQueryParams,
  updateDailyrentUrlParameters,
} from 'shared/containers/BookingCalendarInputContainer/helpers';
import { MAX_GUESTS_COUNT, MIN_GUESTS_COUNT } from 'shared/containers/GuestsInputContainer/constants';
import { extractNumber } from 'shared/utils/extractNumber';

/**
 * При первом рендере страницы устанавливает в URL нормализованное значение количества гостей.
 * Защищает от неадекватных значений – оставляет только допустимый диапазон.
 */
export const useSetNormalizedGuestsCountIntoUrl = () => {
  React.useEffect(() => {
    const { guestsCount } = getDailyrentQueryParams(window.location.href);

    const normalizedGuestsCount = guestsCount
      ? Math.max(MIN_GUESTS_COUNT, Math.min(MAX_GUESTS_COUNT, extractNumber(guestsCount)))
      : MIN_GUESTS_COUNT;

    const newUrl = updateDailyrentUrlParameters({
      currentUrl: window.location.href,
      guestsCount: normalizedGuestsCount,
    });

    history.replaceState(undefined, '', newUrl);
  }, []);
};
