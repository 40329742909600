import { getLogger } from '@cian/logger/browser';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackError, trackOpen } from './tracking';
import { EPanoramaStatus, IPanoramaProps, Panorama } from '../../components/Panorama';
import { IState } from '../../store';

export type TPanoramaContainerProps = Omit<
  IPanoramaProps,
  'logger' | 'initialCoordinates' | 'onOpen' | 'onStatusChange'
>;

export const PanoramaContainer: React.FC<React.PropsWithChildren<TPanoramaContainerProps>> = ({ ...sharedProps }) => {
  const logger = getLogger();
  const initialCoords = useSelector<IState, [number, number]>(state => [
    state.offerData.offer.geo.coordinates.lng,
    state.offerData.offer.geo.coordinates.lat,
  ]);

  const handleOpen = React.useCallback(() => {
    trackOpen();
  }, []);

  const handleStatusChange = React.useCallback((status: EPanoramaStatus) => {
    switch (status) {
      case EPanoramaStatus.NotFound:
      case EPanoramaStatus.Unsupported:
      case EPanoramaStatus.Failed:
        trackError(status);
        break;
    }
  }, []);

  return (
    <Panorama
      {...sharedProps}
      logger={logger}
      initialCoordinates={initialCoords}
      onOpen={handleOpen}
      onStatusChange={handleStatusChange}
    />
  );
};
