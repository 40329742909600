import { useFormikContext } from 'formik';
import * as React from 'react';

import { Label } from './Label';
import { useFieldHasErrors, fitArray } from './utils';
import { CountComponent } from '../../CountComponent';
import { IFormValues } from '../DealRentModal';
import * as styles from '../index.css';

export const Children = () => {
  const { values, setFieldValue } = useFormikContext<IFormValues>();
  const invalid = useFieldHasErrors('childrenCount');
  const onChange = React.useCallback(
    (value: number) => {
      setFieldValue('childrenCount', value);
      setFieldValue('childrenInfo', fitArray(values.childrenInfo || [], value));
    },
    [setFieldValue, values],
  );

  return (
    <div className={styles['field']}>
      <Label invalid={invalid}>Детей</Label>
      <CountComponent min={0} max={6} value={values['childrenCount'] || 0} onChange={onChange} />
    </div>
  );
};
