import { Button } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './AsideBuyAnalyticsBlock.css';
import { IAsideBuyAnalyticsBlockProps } from './types';
import { IBuyAnalyticsBlockProps } from '../../types';
import { ChartIcon } from '../ChartIcon';

export const AsideBuyAnalyticsBlock: React.FC<IAsideBuyAnalyticsBlockProps & IBuyAnalyticsBlockProps> = ({
  reportPrice,
  onBuyClick,
}) => (
  <div className={styles['container']}>
    <div className={styles['text-wrapper']}>
      <div className={styles['icon-wrapper']}>
        <ChartIcon size={40} />
      </div>
      <p className={styles['title']}>Доступен аналитический отчёт по зданию за {reportPrice}</p>
    </div>
    <Button theme="fill_secondary" size="XS" onClick={onBuyClick}>
      Купить
    </Button>
  </div>
);

AsideBuyAnalyticsBlock.displayName = 'AsideBuyAnalyticsBlock';
