import { Label } from '@cian/ui-kit';
import { TColors } from '@cian/ui-kit-design-tokens/colors';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectIsBlackFridayExclusive } from 'shared/selectors/offerData/offer/blackFriday';

const gradientBackground = 'linear-gradient(90deg, var(--solid-black) 0%, var(--brand_lapis) 100%)';

export const PriceLabelsContainer = () => {
  const isBlackFridayExclusive = useSelector(selectIsBlackFridayExclusive);

  if (isBlackFridayExclusive) {
    return <Label background={gradientBackground as TColors}>Эксклюзив</Label>;
  }

  return null;
};

PriceLabelsContainer.displayName = 'PriceLabelsContainer';
