import { ca } from '@cian/analytics';

export const trackEvent = (category: string, label: string, action: string) => {
  ca('eventSite', {
    name: 'oldevent',
    category,
    action,
    label,
  });
};
