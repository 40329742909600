import { useState, useEffect } from 'react';

export const useIsMounted = (isVisible: boolean) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (isVisible && !isMounted) {
      setIsMounted(true);
    }
  }, [isMounted, isVisible]);

  return isMounted;
};
