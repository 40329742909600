import * as React from 'react';
import { useSelector } from 'react-redux';

import { OfferTitleNew } from '../../components/OfferTitleNew';
import { selectOfferTitle } from '../../selectors/selectOfferTitle';

interface IOfferTitleNewContainerProps {
  link: React.ReactNode;
}

export function OfferTitleNewContainer({ link }: IOfferTitleNewContainerProps) {
  const title = useSelector(selectOfferTitle);

  return <OfferTitleNew title={title} link={link} />;
}
