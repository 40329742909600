import * as React from 'react';

export interface IHotkey {
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLElement>) => void;
  preventDefault?: boolean;
  stopPropagation?: boolean;
  disabled?: boolean;
  keyCode: string;
}

export const useHotkeys = (hotkeys: IHotkey[]) => {
  const invokeNamedCallback = React.useCallback(
    (callbackName: 'onKeyUp' | 'onKeyDown', event: React.KeyboardEvent<HTMLElement>) => {
      hotkeys.forEach(hotkey => {
        if (event.code === hotkey.keyCode && !hotkey.disabled) {
          if (hotkey.preventDefault) {
            event.preventDefault();
          }

          if (hotkey.stopPropagation) {
            event.stopPropagation();
          }

          hotkey[callbackName]?.(event);
        }
      });
    },
    [hotkeys],
  );

  const onKeyUp = React.useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => invokeNamedCallback('onKeyUp', event),
    [invokeNamedCallback],
  );
  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => invokeNamedCallback('onKeyDown', event),
    [invokeNamedCallback],
  );

  return { onKeyUp, onKeyDown };
};
