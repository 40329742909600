// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IGetEstimationAndTrendWebRequest,
  TGetEstimationAndTrendWebModel,
  IMappers,
  TGetEstimationAndTrendWebResponse,
  IGetEstimationAndTrendWebResponse,
  IGetEstimationAndTrendWebResponseError,
} from './types';

export const defaultConfig: TGetEstimationAndTrendWebModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'GET',
  microserviceName: 'price-estimator',
  pathApi: '/v1/get-estimation-and-trend-web/',
} as TGetEstimationAndTrendWebModel;

export function createGetEstimationAndTrendWebModel(
  parameters: IGetEstimationAndTrendWebRequest,
): TGetEstimationAndTrendWebModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetEstimationAndTrendWebOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetEstimationAndTrendWebRequest;
}

export async function fetchGetEstimationAndTrendWeb<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetEstimationAndTrendWebOptions<TResponse200, TResponse400>): Promise<
  TGetEstimationAndTrendWebResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createGetEstimationAndTrendWebModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetEstimationAndTrendWebResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IGetEstimationAndTrendWebResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetEstimationAndTrendWebResponse;
}
