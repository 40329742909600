import { FavoritesAssignWidgetContainer } from '@cian/favorites-assign-widget';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  trackCreateFolder,
  trackFolderNameClick,
  trackNotificationChangeFolder,
  trackNotificationSaveToFolder,
  trackSaveToFolder,
} from './tracking';
import { FavoritesFolders } from '../../components/FavoritesFolders';
import { offerSelector, selectUser } from '../../selectors';
import { getFavoritesFolderEnabled } from '../../selectors/featureToggle';
import { changeFavoriteAction, changeOfferFoldersCount } from '../../store/offer';

export function FavoritesFoldersContainer() {
  const dispatch = useDispatch();
  const isFeatureEnabled = useSelector(getFavoritesFolderEnabled);
  const user = useSelector(selectUser);
  const offer = useSelector(offerSelector);
  const isAuthenticated = Boolean(user && user.isAuthenticated);

  if (!isFeatureEnabled) {
    return null;
  }

  const handleCreate = () => {
    trackCreateFolder();
  };

  const handleSave = (offerId: number, { foldersCount }: { foldersCount: number }) => {
    dispatch(changeFavoriteAction(true));
    dispatch(changeOfferFoldersCount(foldersCount));
    trackSaveToFolder();
  };

  const handleRemoveFromFolders = () => {
    dispatch(changeOfferFoldersCount(0));
  };

  const handleLogin = () => {
    if (window.waitForLoginPopup) {
      window.waitForLoginPopup('favorites');
    }
  };

  const handleClickFolderName = (offerId: number, folderId: number) => {
    trackFolderNameClick(offerId, folderId);
  };

  const handleSaveToFolder = (offerId: number) => {
    trackNotificationSaveToFolder(offerId);
  };

  const handleChangeFolder = (offerId: number) => {
    trackNotificationChangeFolder(offerId);
  };

  return (
    <FavoritesFolders>
      <FavoritesAssignWidgetContainer
        disableNotification={true}
        isAuthenticated={isAuthenticated}
        dealType={offer.dealType}
        offerType={offer.offerType}
        onCreateFolder={handleCreate}
        onSaveOffer={handleSave}
        onRemovedFromFolders={handleRemoveFromFolders}
        onWaitForLogin={handleLogin}
        onClickFolderName={handleClickFolderName}
        onNotificationSaveToFolder={handleSaveToFolder}
        onNotificationChangeFolder={handleChangeFolder}
      />
    </FavoritesFolders>
  );
}
