/* eslint-disable max-lines, react/jsx-no-target-blank */
import { InformationTooltip } from '@cian/ui-kit';
import cx from 'clsx';
import { isNil } from 'ramda';
import * as React from 'react';

import { IValueAddedServicesSchema, TOfferStatus } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as styles from './index.css';
import {
  trackPrintButtonClicked,
  trackRemoveButtonClicked,
  trackUpgradeToPremiumClick,
  trackUpgradeToPromotionClick,
  trackUpgradeToTop3Click,
} from './tracking';
import { Link } from '../../common/components/link';

export interface IHeaderAdminPanelProps {
  editPhoto: string | null;
  recoveryLink: string | null;
  docxUrl: string;
  pdfUrl: string;
  userId: number | null;
  userOfferId: number | null;
  isAgent: boolean | null;
  isAuthenticated: boolean | null;
  isImported: boolean | null;
  status: TOfferStatus | null;
  valueAddedServices: IValueAddedServicesSchema | null;
  /** Флаг активности ML-ранжирования */
  isMlSearchEnabled: boolean;
  isDealRent: boolean;
}

export interface IHeaderAdminPanelDispatchProps {
  openPrintModal(): void;
  openRemoveModal(): void;
}

export type IProps = IHeaderAdminPanelProps & IHeaderAdminPanelDispatchProps;

export interface IHeaderAdminPanelState {
  isOpenExport: boolean;
}

export class HeaderAdminPanel extends React.Component<IProps, IHeaderAdminPanelState> {
  public state = {
    isOpenExport: false,
  };

  public render() {
    const isCurrentUserOwnerOffer = this.props.userId === this.props.userOfferId && this.props.isAuthenticated;

    if (
      !this.props.status ||
      !this.props.isAuthenticated ||
      !this.props.userId ||
      !this.props.userOfferId ||
      !(isCurrentUserOwnerOffer && this.props.status === 'published')
    ) {
      return null;
    }

    return (
      <div className={styles['container']}>
        <div className={styles['column']}>
          {
            /**
             * Выводим ссылку, если:
             *  - есть ссылка для редактирования и объявление не импортированно из XML
             *  - есть ссылка для редактирования и объявление импортированно из XML и пользователь сабагент
             */
            this.props.editPhoto &&
              !isNil(this.props.isImported) &&
              (!this.props.isImported || (this.props.isImported && !this.props.isAgent)) && (
                <Link href={this.props.editPhoto} className={styles['link']}>
                  Добавить или редактировать фото
                </Link>
              )
          }
          <button
            onClick={() => {
              trackPrintButtonClicked();
              this.props.openPrintModal();
            }}
            className={styles['button_print']}
          >
            Печать
          </button>
          {!isNil(this.props.isImported) && !this.props.isImported && !this.props.isDealRent && (
            <button onClick={this.remove} className={styles['button_remove']}>
              Удалить
            </button>
          )}
          <InformationTooltip
            arrow
            placement="right"
            content={
              <div className={styles['popup_export']}>
                <Link href={this.props.pdfUrl} download onClick={this.toggleOpenExport}>
                  Скачать в PDF
                </Link>
                <br />
                <Link href={this.props.docxUrl} download onClick={this.toggleOpenExport}>
                  Скачать в .docx
                </Link>
              </div>
            }
          >
            <button className={styles['button_export']} onClick={this.toggleOpenExport}>
              Экспорт
            </button>
          </InformationTooltip>
        </div>
        {this.props.recoveryLink && this.props.valueAddedServices && !this.props.valueAddedServices.isTop3 && (
          <div className={cx(styles['column'], styles['column--content_end'])}>
            <div className={styles['vas']}>{this.getRecoveryBlock()}</div>
          </div>
        )}
      </div>
    );
  }

  private remove = () => {
    trackRemoveButtonClicked();
    this.props.openRemoveModal();
  };

  private toggleOpenExport = () => {
    this.setState({
      isOpenExport: !this.state.isOpenExport,
    });
  };

  private getRecoveryBlock = () => {
    const { isMlSearchEnabled, recoveryLink, valueAddedServices } = this.props;

    if (!valueAddedServices) {
      return null;
    }

    const { isPremium, isTop3, isStandard, isColorized } = valueAddedServices;

    let text: string | undefined;
    let textButton: string | undefined;
    let specialParam = '';
    let handleLinkClick;

    /* istanbul ignore else */
    if (!isPremium && !isTop3) {
      text = 'Получите в 3 раза больше звонков!';
      textButton = 'Купить Премиум';
      specialParam = 'buy=103';
      handleLinkClick = trackUpgradeToPremiumClick;
    } else if ((isPremium || isStandard || isColorized) && !isTop3) {
      /* istanbul ignore next */
      text = 'Получите в 5 раз больше звонков!';
      /* istanbul ignore next */
      textButton = isMlSearchEnabled ? 'Купить Топ' : 'Купить Топ-3';
      specialParam = 'buy=104';
      handleLinkClick = trackUpgradeToTop3Click;
    } else if (!isStandard || !isColorized) {
      // Попасть в эту ветку невозможно.
      // Нужно уточнять у продакта необходимость этих текстов.
      /* istanbul ignore next */
      text = 'Получите больше звонков!';
      /* istanbul ignore next */
      textButton = 'Продвинуть объявление';
      /* istanbul ignore next */
      handleLinkClick = trackUpgradeToPromotionClick;
    }

    /* istanbul ignore next */
    return text && textButton && handleLinkClick ? (
      <span>
        {text}
        <a onClick={handleLinkClick} href={this.getRecoveryUrl(recoveryLink as string, specialParam)} target="_blank">
          {textButton}
        </a>
      </span>
    ) : null;
  };

  private getRecoveryUrl = (recoveryLink: string, specialParam: string) => {
    const url = recoveryLink.split('?');
    const specialQueryParam = specialParam ? `&${specialParam}` : '';

    if (url.length > 1) {
      return `${url[0]}?${url[1]}${specialQueryParam}`;
    }

    return `${recoveryLink}${specialParam ? `?${specialParam}` : ''}`;
  };
}
