import * as React from 'react';

import * as styles from './styles.css';

export interface ISendCodeRepeat {
  secondsToRetry: number;
  sendValidationCode(): void;
}

export const SendCodeRepeat = ({ sendValidationCode, secondsToRetry }: ISendCodeRepeat) => {
  const [secondsLeft, changeSecondsLeft] = React.useState(secondsToRetry);
  React.useEffect(() => {
    if (secondsLeft > 0) {
      const interval = setInterval(() => changeSecondsLeft(secondsLeft => secondsLeft - 1), 1000);

      return () => clearInterval(interval);
    }

    return undefined;
  }, [secondsLeft > 0, secondsLeft]);

  const sendCodeAgain = React.useCallback(() => {
    sendValidationCode();
    changeSecondsLeft(secondsToRetry);
  }, [sendValidationCode]);

  return secondsLeft <= 0 ? (
    <div className={styles['send-code-again']} onClick={sendCodeAgain}>
      Отправить еще раз
    </div>
  ) : (
    <div className={styles['seconds-left']}>{`Отправить еще раз можно через ${secondsLeft} с`}</div>
  );
};
