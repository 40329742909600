import { Button } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { useChats } from 'shared/hooks/useChats';
import { useRenderContactButtons } from 'shared/hooks/useRenderContactButtons';

import { offerChatSelector } from '../../selectors/offerChat';

export const AuthorSendMessageContainer = () => {
  const offerChat = useSelector(offerChatSelector);
  const { showChatButton } = useRenderContactButtons();
  const { handleOpenChat } = useChats();

  const renderChat = offerChat && offerChat.isChatsEnabled && showChatButton;

  const handleSendMessageClick = React.useCallback(() => handleOpenChat(), [handleOpenChat]);

  if (!renderChat) {
    return null;
  }

  return (
    <Button size="XS" theme="fill_secondary" onClick={handleSendMessageClick}>
      Написать сообщение
    </Button>
  );
};
