import { IconMapCar16, IconMapWalk16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { ETransportType } from 'shared/store/similar';

interface IIconsTransport {
  type: ETransportType;
}

export const IconsTransport: React.FC<React.PropsWithChildren<IIconsTransport>> = ({ type }) => {
  switch (type) {
    case ETransportType.Walk:
      return <IconMapWalk16 />;
    case ETransportType.Transport:
      return <IconMapCar16 />;
    default:
      return null;
  }
};

IconsTransport.displayName = 'IconsTransport';
