import { UIText2 } from '@cian/ui-kit';
import { IconActionChevronUpSmall16 } from '@cian/ui-kit-design-tokens/icons';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './CollapseButton.css';
import { ICollapseButtonProps } from './types';

export const CollapseButton: React.FC<ICollapseButtonProps> = props => {
  const { isCollapsed, onClick, collapsedText = 'Развернуть', uncollapsedText = 'Свернуть' } = props;

  return (
    <div className={cx(styles['container'], { [styles['container--collapsed']]: isCollapsed })} onClick={onClick}>
      <div data-testid="collapse-text" className={styles['collapse-text']}>
        <UIText2 color="text-main-default">{isCollapsed ? collapsedText : uncollapsedText}</UIText2>
      </div>
      <div className={styles['collapse-arrow']}>
        <IconActionChevronUpSmall16 color="icon-main-default" />
      </div>
    </div>
  );
};

CollapseButton.displayName = 'CollapseButton';
