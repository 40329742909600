import { getCallbackRecommendations } from './callbackRecommendations';
import { getIsFromBuilder, getIsFromSeller } from './newObject';
import { isNewbuildingSelector } from './offerData/offer';
import { IState } from '../store';

export function isCallbackRecommendationsEnabled(state: IState): boolean {
  const isNewbuilding = isNewbuildingSelector(state);
  const isFromBuilder = getIsFromBuilder(state);
  const isFromSeller = getIsFromSeller(state);
  const recommendation = getCallbackRecommendations(state);

  return Boolean(isNewbuilding && (isFromBuilder || isFromSeller) && recommendation && recommendation.length);
}
