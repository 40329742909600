import * as React from 'react';

import * as styles from './IdenticalOffers.css';
import { Header } from './components/Header';
import { IdenticalOfferCard } from './components/IdenticalOfferCard';
import { IIdenticalOfferView } from '../../types/identicalOffersView';

interface IIdenticalOffersProps {
  items: IIdenticalOfferView[];
  title: string;
  showAllUrl: string;
  onClickShowAllButton(): void;
  onClickCard(index: number): void;
}

/**
 * @description Объявления-дубли текущего объявления
 */
export const IdenticalOffers: React.FC<React.PropsWithChildren<IIdenticalOffersProps>> = ({
  items,
  title,
  showAllUrl,
  onClickCard,
  onClickShowAllButton,
}) => {
  return (
    <section className={styles.root}>
      <Header title={title} showAllUrl={showAllUrl} onClickShowAllButton={onClickShowAllButton} />

      <div className={styles.cards}>
        {items.map((offer, index) => (
          <div key={offer.id} className={styles.card}>
            <IdenticalOfferCard {...offer} index={index} onClick={onClickCard} />
          </div>
        ))}
      </div>
    </section>
  );
};
