import { GenericError } from '@cian/peperrors/shared';

import { requestAction, successAction, failureAction, setEstimationPeriod } from './actions';
import {
  fetchGetEstimationAndTrendWeb,
  IGetEstimationAndTrendWebResponse,
  IGetEstimationAndTrendWebResponse200 as IRes200,
  IGetEstimationAndTrendWebResponse400 as IRes400,
} from '../../../repositories/price-estimator/v1/get-estimation-and-trend-web';
import { selectOfferId } from '../../../selectors';
import { IThunkActionCreator } from '../../index';

export const getOfferValuation =
  (): IThunkActionCreator<Promise<IGetEstimationAndTrendWebResponse | Error>> =>
  async (dispatch, getState, context) => {
    const state = getState();
    const cianOfferId = selectOfferId(state);

    dispatch(requestAction());

    try {
      const res = await fetchGetEstimationAndTrendWeb<IRes200, IRes400>({
        httpApi: context.httpApi,
        parameters: { cianOfferId },
      });

      if (res.statusCode !== 200) {
        throw new GenericError({
          type: 'BadRequestError',
          domain: 'getOfferValuation.ts',
          message: res.response.message as string,
          details: { errors: JSON.stringify(res.response.errors) },
        });
      }

      dispatch(successAction(res.response));

      const { estimationPeriods } = res.response;

      if (estimationPeriods.length) {
        dispatch(setEstimationPeriod(estimationPeriods[estimationPeriods.length - 1]));
      }

      return res.response;
    } catch (e) {
      dispatch(failureAction());

      context.logger.error(e);

      return e as Error;
    }
  };
