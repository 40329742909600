import { ca } from '@cian/analytics';

import { IAgentSchema, IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { getCustomUrl } from '../../utils/tracking/getCustomUrl';

export function trackSubsidisedMortgageBannerShow() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'MortgageDiscount',
    action: 'Show',
    label: 'CardBanner',
    useLastProducts: true,
  });
}

export function trackSubsidisedMortgageModalShow(buttonsList: string[]) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'MortgageDiscountDetail',
    action: 'Show',
    label: 'Popup',
    useLastProducts: true,
    page: {
      extra: {
        button_list: buttonsList,
      },
    },
  });
}

export function trackSubsidisedMortgageModalClick(buttonName: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'MortgageDiscountDetail',
    action: 'Click',
    label: 'Popup',
    useLastProducts: true,
    page: {
      extra: {
        button_name: buttonName,
      },
    },
  });
}

export function trackShowPhoneClick(offer: IOfferSchema, agent: IAgentSchema | null) {
  ca('eventEbc', {
    name: 'oldevent',
    category: 'Phones',
    action: 'Open_card_mortgage_discount_banner',
    label: getCustomUrl(offer, agent),
    useLastProducts: true,
  });
}

export function trackLearnMoreClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'MortgageDiscountDetail',
    action: 'Click',
    label: 'Popup',
    page: {
      extra: {
        button_name: 'Узнать подробнее',
      },
    },
    useLastProducts: true,
  });
}
