import { UIHeading5, UIText2 } from '@cian/ui-kit';
import { IconActionArrowUpRight16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './TooltipContent.css';

export const TooltipContent: React.FC = () => {
  return (
    <div className={styles['container']}>
      <UIHeading5>Коворкинг на 38% дешевле</UIHeading5>
      <UIText2>обычного офиса, по результатам</UIText2>
      <UIText2>нашего исследования</UIText2>
      <div>
        <a className={styles['link']} href="https://promo.cian.ru/coworking" rel="noreferrer" target="_blank">
          <UIText2 color="text-main-default">Подробный разбор</UIText2>
          <span className={styles['icon']}>
            <IconActionArrowUpRight16 color="icon-main-default" />
          </span>
        </a>
      </div>
    </div>
  );
};

TooltipContent.displayName = 'TooltipContent';
