import {
  IconProductPanorama36016,
  IconInfrastructureStore16,
  IconActionTable16,
} from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { IOfferSchema, ISpecialProjectItemSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as styles from './index.css';
import { Tab } from './tab';
import { trackSpecialProjectClick, trackTabMapClick, trackTabPanoramaClick, trackTabSimilarClick } from './tracking';
import { Link } from '../../common/components/link';
import { SPECIAL_PROJECTS } from '../../constants/specialProjects';
import { MapTab, TMapTab } from '../../store/map_tabs';
import { TSpecialPromoInfoState } from '../../store/special_promo_info';
import { getOfferType, isCommercial, isSuburban } from '../../utils/offer_type';
import { getSpecialPromoLink } from '../../utils/specialProjects';

export interface IOfferMapTabsProps {
  offer: IOfferSchema;
  activeTab: TMapTab;
  changeTab(tab: TMapTab): void;
  specialProject: ISpecialProjectItemSchema | null;
  specialPromoInfo: TSpecialPromoInfoState;
  isPanoramaAvailable?: boolean;
}

export const OfferMapTabs: React.FC<React.PropsWithChildren<IOfferMapTabsProps>> = ({
  offer,
  changeTab,
  specialProject,
  specialPromoInfo,
  isPanoramaAvailable,
  activeTab,
}) => {
  const offerType = getOfferType(offer);
  const isCommercialCard = isCommercial(offerType);
  const specialProjectData = specialProject && SPECIAL_PROJECTS[specialProject.id];

  const handleClickOnTab = React.useCallback(
    (tab: TMapTab) => {
      changeTab(tab);
    },
    [changeTab],
  );

  return (
    <div className={styles['container']}>
      <ul className={styles['tabs']}>
        <Tab
          icon={IconInfrastructureStore16}
          isActive={activeTab === MapTab.default}
          onClick={() => {
            handleClickOnTab(MapTab.default);
            trackTabMapClick();
          }}
          className={styles['onmap']}
          style={isSuburban(offerType) ? undefined : { order: 2 }}
        >
          Инфраструктура
        </Tab>
        <Tab
          icon={IconActionTable16}
          isActive={activeTab === MapTab.similarOffers}
          onClick={() => {
            handleClickOnTab(MapTab.similarOffers);
            trackTabSimilarClick();
          }}
          className={styles['similar']}
        >
          Похожие рядом
        </Tab>
        {isPanoramaAvailable && (
          <Tab
            icon={IconProductPanorama36016}
            isActive={activeTab === MapTab.panorama}
            onClick={() => {
              handleClickOnTab(MapTab.panorama);
              trackTabPanoramaClick();
            }}
            className={styles['panorama']}
          >
            Панорама
          </Tab>
        )}
      </ul>
      {!isCommercialCard && specialProject && specialProjectData && (
        <div className={styles['special']}>
          <Link
            href={specialProject.link}
            rel="nofollow noopener"
            onClick={() => trackSpecialProjectClick(specialProject.id)}
          >
            <img width="20" src={specialProjectData.icon} alt="" />
            {specialProjectData.text}
          </Link>
        </div>
      )}
      {!isCommercialCard && !specialProjectData && specialPromoInfo && (
        <div className={styles['special-promo']}>
          <Link href={getSpecialPromoLink(specialPromoInfo)} rel="nofollow noopener">
            <img src={specialPromoInfo.logoSmallUrl} />
            {specialPromoInfo.textShowOffers}
          </Link>
        </div>
      )}
    </div>
  );
};
