import * as React from 'react';

import { IAgentSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as styles from './index.css';
import { trackGetReview } from './tracking';
import { Layout } from '../layout';

export interface IRequestReviewProps {
  agent: IAgentSchema | null;

  onSubmit(): void;
}

export class RequestReview extends React.PureComponent<IRequestReviewProps> {
  private input: HTMLInputElement | null;
  private transitionTimeout: number;

  public state = {
    isErrorMessageVisible: false,
    value: '',
    reviewChecked: false,
    isShowTransition: false,
  };

  public componentDidMount() {
    trackGetReview('Show');
  }

  public componentWillUnmount() {
    this.props.onSubmit();
  }

  public render() {
    return (
      <Layout className={styles['container']} title="Запросите отзыв о вашей работе">
        <div className={styles['content']}>
          {/* tslint:disable-next-line:max-line-length */}
          Отправьте клиенту, с которым сотрудничали по этому объекту, ссылку на свой профиль и просьбу оставить отзыв о
          вашей работе. Агенты с отзывами вызывают больше доверия и привлекают больше клиентов.
        </div>
        <div className={styles['title_link']}>Ссылка на ваш профиль</div>
        <div className={styles['link-container']}>
          {this.state.isShowTransition && <div className={styles['popup_link']}>Ссылка скопирована</div>}
          <input
            ref={input => (this.input = input)}
            value={this.getAgentOffersLink()}
            className={styles['input_link']}
            disabled
          />
          {typeof document !== 'undefined' && (
            <div className={styles['copy_link']} onClick={this.handleCopyLink}>
              Скопировать
            </div>
          )}
        </div>
      </Layout>
    );
  }

  private getAgentOffersLink = () => {
    const { agent } = this.props;

    if (!agent || agent.accountType !== 'specialist' || agent.status !== 'published') {
      return '';
    }

    return agent.offersLink || `${location.protocol}//${location.host}/agents/${agent.id}`;
  };

  private handleCopyLink = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    trackGetReview('Copy');

    if (this.input) {
      this.input.disabled = false;
      this.input.select();

      try {
        document.execCommand('copy');
        this.input.disabled = true;

        this.setState({ isShowTransition: true });

        if (typeof this.transitionTimeout !== 'undefined') {
          window.clearInterval(this.transitionTimeout);
        }

        this.transitionTimeout = window.setTimeout(() => this.setState({ isShowTransition: false }), 2000);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  };
}
