import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';

import { SubscriptionAuthorBrand } from 'shared/components/subscriptionBrand';
import { SuperAgentLevelViewContainer } from 'shared/containers/SuperAgentLevelViewContainer';

import { trackSubscriptionAuthorBrandInViewport } from './tracking';
import { SubscriptionAuthorActionsContainer } from '../SubscriptionAuthorActions';
import { SubscriptionAuthorAvatarContainer } from '../SubscriptionAuthorAvatar';
import { SubscriptionAuthorDignityContainer } from '../SubscriptionAuthorDignity';
import { SubscriptionAuthorNameContainer } from '../SubscriptionAuthorName';
import { SubscriptionAuthorTitleContainer } from '../SubscriptionAuthorTitle';

export const SubscriptionAuthorBrandContainer = () => {
  return (
    <ActionAfterViewObserver callback={trackSubscriptionAuthorBrandInViewport} triggerOnce>
      <SubscriptionAuthorBrand
        avatar={<SubscriptionAuthorAvatarContainer />}
        title={<SubscriptionAuthorTitleContainer />}
        name={<SubscriptionAuthorNameContainer />}
        dignity={<SubscriptionAuthorDignityContainer />}
        actions={<SubscriptionAuthorActionsContainer />}
        superAgentElement={<SuperAgentLevelViewContainer />}
      />
    </ActionAfterViewObserver>
  );
};
