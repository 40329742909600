import * as React from 'react';

import { Toolbar } from '../../components/Toolbar';
import { CommentsButtonContainer } from '../CommentsButtonContainer';
import { ComparisonControlContainer } from '../ComparisonControlContainer';
import { ComplaintButtonNewContainer } from '../ComplaintButtonContainer';
import { ExportButtonNewContainer } from '../ExportButtonContainer';
import { HideButtonNewContainer } from '../HideButtonContainer';
import { PrintButtonNewContainer } from '../PrintButtonContainer';
import { SharingNewContainer } from '../SharingContainer';

export function ToolbarContainer() {
  return (
    <Toolbar
      controls={
        <>
          <ComparisonControlContainer isNewButton={true} />
          <SharingNewContainer />
          <CommentsButtonContainer />
          <ExportButtonNewContainer />
          <PrintButtonNewContainer />
          <HideButtonNewContainer />
          <ComplaintButtonNewContainer />
        </>
      }
    />
  );
}
