import { UIHeading1 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';

export interface IOfferSummaryInfoGroupProps {
  header: string;
  rightGroup?: boolean;
  actionButton?: React.ReactNode;
  children: React.ReactNode;
}

export const OfferSummaryInfoGroup: React.FC<React.PropsWithChildren<IOfferSummaryInfoGroupProps>> = ({
  header,
  rightGroup,
  actionButton,
  children,
}) => {
  return (
    <div data-testid="OfferSummaryInfoGroup" className={cx(styles['group'], rightGroup && styles['right'])}>
      <div className={styles['header']}>
        <UIHeading1 as="h2" color="text-primary-default">
          {header}
        </UIHeading1>

        {actionButton}
      </div>

      <div>{children}</div>
    </div>
  );
};
