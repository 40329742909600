import * as React from 'react';
import { useSelector } from 'react-redux';

import { SimilarOffersFactory } from './SimilarOffersFactory';
import { trackTabClick } from './tracking';
import { ContentTabs, IContentTab } from '../../../components/ContentTabsRedesigned';
import { SimilarOffersSection } from '../../../components/SimilarOffersSection';
import { useSimilarOffersGroups } from '../../../hooks/useSimilarOffersGroups';
import { abGroupSelector, selectIsSimilarOffersForAgencyBrandingAvailable } from '../../../selectors';
import { EModelTypes } from '../../../store/similar';
import { SimilarOffersTitleContainer } from '../SimilarOffersTitle';

interface ISimilarOffersGroupsContainerProps {
  userBasedVisible?: boolean;
}

export const SimilarOffersGroupsContainer: React.FC<ISimilarOffersGroupsContainerProps> = ({
  userBasedVisible = true,
}: ISimilarOffersGroupsContainerProps) => {
  const isBrandingAvailable = useSelector(selectIsSimilarOffersForAgencyBrandingAvailable);
  const abGroup = useSelector(abGroupSelector);
  const { offerData, similarOffers, withoutCompetitors, offersLoaded } = useSimilarOffersGroups(userBasedVisible);

  const SimilarOffersWrapper = React.useMemo(
    () =>
      SimilarOffersFactory({
        offerData,
        withoutCompetitors,
        similarOffers,
        abGroup,
      }),
    [offerData, withoutCompetitors, similarOffers, abGroup],
  );

  // Минимально должно отображаться 2 табы. Но их может и не быть, а здесь они потому,
  // что используется useMemo
  const tabs = React.useMemo(
    () =>
      [
        !withoutCompetitors &&
          similarOffers.itemBased.list.length && {
            title: 'Похожие объявления',
            value: EModelTypes.ItemBased,
            component: (
              <SimilarOffersWrapper
                disableExternalContainerStyles
                key={EModelTypes.ItemBased}
                modelType={EModelTypes.ItemBased}
              />
            ),
          },
        userBasedVisible &&
          similarOffers.userBased.list.length && {
            title: withoutCompetitors ? 'От этого же продавца' : 'Могут подойти',
            value: EModelTypes.UserBased,
            component: (
              <SimilarOffersWrapper
                disableExternalContainerStyles
                key={EModelTypes.UserBased}
                modelType={EModelTypes.UserBased}
              />
            ),
          },
        !withoutCompetitors &&
          similarOffers.newItemBased.list.length && {
            title: 'Новые',
            value: EModelTypes.NewItemBased,
            component: (
              <SimilarOffersWrapper
                disableExternalContainerStyles
                key={EModelTypes.NewItemBased}
                modelType={EModelTypes.NewItemBased}
              />
            ),
          },
        similarOffers.historyBased.list.length && {
          title: 'Вы смотрели',
          value: EModelTypes.HistoryBased,
          component: (
            <SimilarOffersWrapper
              disableExternalContainerStyles
              key={EModelTypes.HistoryBased}
              modelType={EModelTypes.HistoryBased}
            />
          ),
        },
        offerData.similarNewObjects?.length && {
          title: 'Новостройки в этом районе',
          value: EModelTypes.NewobjectItemBased,
          component: (
            <SimilarOffersWrapper
              disableExternalContainerStyles
              key={EModelTypes.NewobjectItemBased}
              modelType={EModelTypes.NewobjectItemBased}
            />
          ),
        },
      ].filter(Boolean) as IContentTab[],
    [
      // здесь, чтобы избежать лишних ререндеров идем до цифры
      similarOffers.itemBased.list.length,
      similarOffers.userBased.list.length,
      similarOffers.newItemBased.list.length,
      similarOffers.historyBased.list.length,
      offerData.similarNewObjects?.length,
      userBasedVisible,
      withoutCompetitors,
      SimilarOffersWrapper,
    ],
  );

  // не отображаем блок, если данные не загружены
  if (!offersLoaded) {
    return null;
  }

  // отображаем 1 блок, если подключена услуга "Убери конкурента" и включен эксперимент CD-131678
  if (withoutCompetitors && !userBasedVisible) {
    return <SimilarOffersWrapper modelType={EModelTypes.HistoryBased} title="Недавно вы смотрели" />;
  }

  // отображаем 2 блока, если подключена услуга "Убери конкурента" и есть брендинг
  if (withoutCompetitors && isBrandingAvailable) {
    return (
      <>
        <SimilarOffersWrapper modelType={EModelTypes.UserBased} title={<SimilarOffersTitleContainer />} />
        <SimilarOffersWrapper modelType={EModelTypes.HistoryBased} title="Недавно вы смотрели" />
      </>
    );
  }

  // если данных нет, то не отображаем ничего
  if (!tabs.length) {
    return null;
  }

  // если таба одна, то нет смысла делать ее табой
  if (tabs.length === 1) {
    return (
      <SimilarOffersSection>
        <SimilarOffersWrapper title={tabs[0].title} modelType={tabs[0].value as EModelTypes} />
      </SimilarOffersSection>
    );
  }

  // отображаем табы
  return (
    <SimilarOffersSection>
      <ContentTabs tabs={tabs} onTabChange={value => trackTabClick(value as EModelTypes)} />
    </SimilarOffersSection>
  );
};
