import cx from 'clsx';
import * as React from 'react';

import * as styles from './styles/index.css';

export interface ITextProps extends React.HTMLProps<HTMLDivElement> {
  textSize?: 'base' | 'small' | 'huge' | 'h1' | 'h2' | 'h3';
  color?: 'black' | 'orange' | 'blue' | 'gray' | 'white';
  bold?: boolean;
  children?: React.ReactNode;
}

export function Text(props: ITextProps) {
  const { textSize = 'base', children, bold, color, ...rest } = props;

  return (
    <span {...rest} className={cx(styles[textSize], bold && styles['bold'], color && styles[color])}>
      {children}
    </span>
  );
}
