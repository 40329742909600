/* eslint-disable */
import { IUserAvailableServicesSchema } from './UserAvailableServicesSchema';
import { IMasterAgentSchema } from './MasterAgentSchema';
import { IAgentMetricSchema } from './AgentMetricSchema';
import { IAgentModerationInfoSchema } from './AgentModerationInfoSchema';
import { IAgentPhoneSchema } from './AgentPhoneSchema';
import { IAgentSkillSchema } from './AgentSkillSchema';

export interface IAgentSchema {
  /** Аватар заданный агентом в настройках ЛК **/
  absoluteAvatarUrl?: string;
  /** Мини аватар заданный агентом в настройках ЛК **/
  absoluteMiniAvatarUrl?: string;
  /** Тип аккаунта **/
  accountType?: EAccountType;
  /** Признаки объявлений (ЭТП, Залоговая, Аукцион) **/
  agentLists?: string[];
  /** Доступные сервисы для пользователя **/
  availableServices?: IUserAvailableServicesSchema;
  /** Банк, к которому привязан агент **/
  bank?: string;
  /** Онлайн показ **/
  canShowOnline?: boolean;
  /** ID пользователя на ЦИАНе **/
  cianUserId?: number;
  /** Место работы, если тип агента специалист **/
  companyName?: string;
  /** Время регистрации на циан **/
  creationDate?: string;
  /** "обо мне" профиля агента **/
  description?: string;
  /** Опыт работы **/
  experience?: string;
  /** Имя **/
  firstName?: string;
  /** Идентификатор **/
  id?: number;
  /** Партнёр ЦИАН **/
  isCianPartner?: boolean;
  /** Является застройщиком **/
  isDeveloper?: boolean;
  /** Профиль скрыт **/
  isHidden?: boolean;
  /** Агент разрешил подавать заявки на просмотры объекта **/
  isMessagingEnabled?: boolean;
  /** Пользователь прошел верификацию по паспорту **/
  isPassportVerified?: boolean;
  /** Агент является частным маклером **/
  isPrivateBroker?: boolean;
  /** Профессионал **/
  isPro?: boolean;
  /** Флаг пессимизации, рецидивист **/
  isRecidivist?: boolean;
  /** Пользователь самозанятый(проверен в ФНС) **/
  isSelfEmployed?: boolean;
  /** Является сабагентом **/
  isSubAgent?: boolean;
  /** Флаг пессимизации, в объявлениях пользователя встречаются ошибки **/
  isUserOffersHasErrors?: boolean;
  /** Фамилия **/
  lastName?: string;
  /** Мастер агент **/
  masterAgent?: IMasterAgentSchema;
  /** Метрики агента **/
  metrics?: IAgentMetricSchema[];
  /** Информация от модерации **/
  moderationInfo?: IAgentModerationInfoSchema;
  /** Имя специалиста или название агентства **/
  name?: string;
  /** Количество офферов агента **/
  offersCount?: number;
  /** Ссылка на все объявления пользователя **/
  offersLink?: string;
  /** Cian ID мастер-агента **/
  parentCianId?: number;
  /** ID мастер-агента **/
  parentId?: number;
  /** Список телефонов **/
  phones?: IAgentPhoneSchema[];
  /** ID пользователя на Realty **/
  realtyUserId?: number;
  /** Опция удалить конкурента **/
  removeCompetitor?: boolean;
  /** Список специализаций **/
  skills?: IAgentSkillSchema[];
  /** Статус публикации **/
  status?: EStatus;
  /** Тип пользователя для google аналитики **/
  userType?: string;
}
export enum EAccountType {
  /** Агенство **/
  'Agency' = 'agency',
  /** Управляющая компания **/
  'ManagementCompany' = 'managementCompany',
  /** Отдел аренды **/
  'RentDepartment' = 'rentDepartment',
  /** Специалист **/
  'Specialist' = 'specialist',
}
export enum EStatus {
  /** Скрыто **/
  'Hidden' = 'hidden',
  /** Опубликовано **/
  'Published' = 'published',
}
