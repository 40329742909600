import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

interface IPriceQualityBarProps {
  estimationLowerBound: React.ReactChild;
  estimationUpperBound: React.ReactChild;
  offerPrice: React.ReactChild;
  labelPos: number;
}

export const PriceQualityBar: React.FC<React.PropsWithChildren<IPriceQualityBarProps>> = props => {
  const { estimationLowerBound, estimationUpperBound, offerPrice, labelPos } = props;
  const labelRef = React.useRef<HTMLSpanElement>(null);
  const [labelWidth, setLabelWidth] = React.useState<number>(0);

  React.useEffect(() => {
    if (labelRef.current) {
      const { width } = labelRef.current.getBoundingClientRect();
      setLabelWidth(width);
    }
  }, []);

  return (
    <div>
      <div className={styles['titles']}>
        <UIText2 display="block" color="gray60_100">
          Низкая
        </UIText2>
        <UIText2 display="block" color="gray60_100">
          Хорошая цена
        </UIText2>
        <UIText2 display="block" color="gray60_100">
          Высокая
        </UIText2>
      </div>
      <div className={styles['bar']}>
        <div className={styles['fill']}>
          <span
            ref={labelRef}
            className={styles['label']}
            style={{ left: `clamp(${labelWidth / 2}px, ${labelPos * 100}%, calc(100% - ${labelWidth / 2}px))` }}
          >
            {offerPrice}
          </span>
        </div>
      </div>
      <div className={styles['values']}>
        <div className={styles['price_start']}>
          <UIText2>{estimationLowerBound}</UIText2>
        </div>
        <div className={styles['price_end']}>
          <UIText2>{estimationUpperBound}</UIText2>
        </div>
      </div>
    </div>
  );
};

PriceQualityBar.displayName = 'PriceQualityBar';
