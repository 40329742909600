import { ca } from '@cian/analytics';

export declare type TGetReviewAction = 'Show' | 'Copy';

export function trackGetReview(action: TGetReviewAction) {
  ca('event', {
    action,
    category: 'ArchiveGetReviewPopup',
    event: 'oldevent',
  });
}
