/* eslint-disable react/jsx-no-target-blank */
import * as React from 'react';

import * as styles from '../../index.css';

export const TermsForms: React.FC = () => (
  <>
    Отправляя анкету, я даю{' '}
    <a
      className={styles['link']}
      href="http://files.cian.ru/files/arenda-bez-riska/soglasie_na_obrabotku.pdf"
      target="_blank"
    >
      согласие на обработку моих персональных данных
    </a>{' '}
    в соответствии с{' '}
    <a className={styles['link']} href="https://www.cian.ru/help/about/36526/" target="_blank">
      политикой конфиденциальности
    </a>
  </>
);
