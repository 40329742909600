import { useSelector } from 'react-redux';

import { createCheckoutPageUrl } from 'shared/containers/AsideContacts/OnlineBookingBookButtonContainer/helpers';
import { selectOfferId } from 'shared/selectors';
import {
  selectDailyrentBookingCalendarDates,
  selectDailyrentBookingCalendarGuestsCount,
} from 'shared/selectors/dailyrentBookingCalendar';

export const useDailyrentCheckoutPageUrl = () => {
  const realtyId = useSelector(selectOfferId);
  const dates = useSelector(selectDailyrentBookingCalendarDates);
  const guestsCount = useSelector(selectDailyrentBookingCalendarGuestsCount);

  return createCheckoutPageUrl({ realtyId, dates, guestsCount });
};
