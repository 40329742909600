import { ModalWindow, Button } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './index.css';

const successIcon = require('./success.svg') as string;

export interface IDealRentSuccessModalProps {
  isOpen: boolean;
  closeModal(): void;
}

export const DealRentSimilarSuccessModal = ({ isOpen, closeModal }: IDealRentSuccessModalProps) => {
  return (
    <ModalWindow onClose={closeModal} open={isOpen}>
      <div className={styles['container']}>
        <img src={successIcon} />
        <h1 className={styles['title']}>Отлично! Ваша анкета отправлена на&nbsp;похожие объявления.</h1>
        <div className={styles['text']}>
          Когда собственник просмотрит анкету вам придет смс&#8209;уведомление с&nbsp;его номером телефона.
        </div>
        <Button size="L" theme="fill_primary" onClick={closeModal}>
          Хорошо
        </Button>
      </div>
    </ModalWindow>
  );
};
