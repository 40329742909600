import { connect } from 'react-redux';

import { HeaderAdminPanel, IHeaderAdminPanelDispatchProps, IHeaderAdminPanelProps } from './index';
import { isMlSearchEnabled } from '../../selectors/isMlSearchEnabled';
import { toggle as togglePrintModal } from '../../store/popups/print';
import { toggle as toggleRemoveModal } from '../../store/popups/remove';

import type { DispatchReduxAction, IState } from '../../store';

export const mapStateToProps = (state: IState): IHeaderAdminPanelProps => {
  const {
    offerData: {
      offer: {
        exportLinks: { docxUrl, pdfUrl },
        editPhoto,
        isImported,
        recoveryLink,
        status,
        userId,
        valueAddedServices,
        flags,
      },
      user,
    },
  } = state;
  const isDealRent = Boolean(flags && flags.isDealRent);

  return {
    docxUrl,
    editPhoto,
    isAgent: user && user.isAgent,
    isAuthenticated: user && user.isAuthenticated,
    isImported,
    pdfUrl,
    recoveryLink,
    status,
    userId: user && user.realtyUserId,
    userOfferId: userId,
    valueAddedServices,
    isMlSearchEnabled: isMlSearchEnabled(state),
    isDealRent,
  };
};

export const mapDispatchToProps = (dispatch: DispatchReduxAction): IHeaderAdminPanelDispatchProps => ({
  openPrintModal: () => dispatch(togglePrintModal(true)),
  openRemoveModal: () => dispatch(toggleRemoveModal(true)),
});

export const HeaderAdminPanelContainer = connect<IHeaderAdminPanelProps, IHeaderAdminPanelDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderAdminPanel);
