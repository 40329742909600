/* eslint-disable react/jsx-no-target-blank */
import * as React from 'react';

import * as styles from './IdenticalOfferCard.css';
import { IIdenticalOfferView } from '../../../../types/identicalOffersView';
import { Author } from '../Author';
import { Features } from '../Features';
import { Photo } from '../Photo';

interface IIdenticalOfferCardProps extends IIdenticalOfferView {
  index: number;
  onClick(index: number): void;
}

export const IdenticalOfferCard: React.FC<React.PropsWithChildren<IIdenticalOfferCardProps>> = ({
  url,
  media,
  author,
  features,
  price,
  index,
  onClick,
}) => {
  const handleClick = React.useCallback(() => onClick(index), [index]);

  return (
    <a href={url} target="_blank" className={styles.root} onClick={handleClick}>
      <div className={styles.row}>
        <Photo {...media} />
      </div>

      <div className={styles.row}>
        <Author {...author} hasFairPlay={features.hasFairPlay} />
      </div>

      <div className={styles.row}>
        <Features {...features} />
      </div>

      <div className={styles.row}>
        <span className={styles.price}>{price}</span>
      </div>
    </a>
  );
};
