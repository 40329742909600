import { CustomTooltip } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './RemovedFromFolderTooltip.css';

interface IRemovedFromFolderTooltipProps {
  open: boolean;
  anchorRef: React.RefObject<HTMLElement>;
  onChangeFolderClick: () => void;
  onClose: () => void;
}

export const RemovedFromFolderTooltip: React.FunctionComponent<
  React.PropsWithChildren<IRemovedFromFolderTooltipProps>
> = ({ open, anchorRef, onChangeFolderClick, onClose }) => {
  return (
    <CustomTooltip
      content={
        <div className={styles['container']}>
          <div className={styles['message']}>Удалено из&nbsp;избранного и&nbsp;всех подборок</div>
          <button onClick={onChangeFolderClick}>Изменить</button>
        </div>
      }
      anchorRef={anchorRef}
      open={open}
      outside
      arrow
      portal
      theme="black"
      placement="bottom-start"
      maxWidth={400}
      onClose={onClose}
    />
  );
};
