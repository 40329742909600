import { EPackages } from 'shared/repositories/offer-card/entities/user/UserGASchema';

import { IState } from '../../store';
import { isSale, getDealType } from '../../utils/deal_type';
import { isResidential, getOfferType } from '../../utils/offer_type';
import { selectOffer, selectUser } from '../offerData';

/**
 * Баннер ипотечного брокера доступен для показа?
 */
export const selectIsMortgageBrokerBannerAvailable = (state: IState): boolean => {
  const offer = selectOffer(state);
  const dealType = getDealType(offer);
  const offerType = getOfferType(offer);
  const user = selectUser(state);

  /**
   * TODO Лучше смотреть на поле isBuilder в ручке offer-card/v1/get-offer-data, когда оно появится там
   */
  const isUserBuilder = !!user?.ga?.packages?.includes(EPackages.Builder);

  return Boolean(
    state.featureToggle.isMortgageBrokerBannerEnabled &&
      user?.isAuthenticated &&
      user?.isAgent &&
      !isUserBuilder &&
      isSale(dealType) &&
      isResidential(offerType),
  );
};
