import cx from 'clsx';
import * as React from 'react';

/***** STYLES *****/
import * as styles from './Link.css';

export interface ILinkProps extends React.HTMLProps<HTMLAnchorElement> {
  children: React.ReactNode;

  onClick?(event: React.SyntheticEvent): void;

  url: string;
  className?: string;
  target?: string;
  title?: string;
  dataMark?: string;
  rel?: string;
}

/***** COMPONENT *****/
export function Link(props: ILinkProps) {
  const { children, id, onClick, url, className, target = 'self', title = '', dataMark = 'Link', rel } = props;

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      target={`_${target}`}
      title={title}
      id={id}
      data-mark={dataMark}
      onClick={(event: React.SyntheticEvent) => {
        if (onClick) {
          onClick(event);
        }
      }}
      href={url}
      className={cx(styles['element'], className)}
      rel={rel}
    >
      {children}
    </a>
  );
}
