import { UIHeading3 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from '../BookingInfo.css';
import { IBookingInfoLine } from '../types';

export function Headline({ headLine }: { headLine: IBookingInfoLine | undefined }) {
  if (!headLine || !headLine.text) {
    return null;
  }

  return (
    <div className={styles['section-heading']}>
      <UIHeading3>{headLine.text}</UIHeading3>
      <UIHeading3>{headLine.value}</UIHeading3>
    </div>
  );
}
