/* eslint-disable react/jsx-no-target-blank */
import * as React from 'react';

import * as styles from '../../index.css';

export const TermsDealV2: React.FC = () => (
  <>
    Отправляя анкету, я даю согласие на обработку моих персональных данных в соответствии с{' '}
    <a className={styles['link']} href="https://www.cian.ru/help/about/36526/" target="_blank">
      политикой конфиденциальности
    </a>{' '}
    и условиями{' '}
    <a
      id="deal-rent-oferta-link"
      className={styles['link']}
      href={'https://www.cian.ru/legal-documents/pravila_polzovaniya_servisom_sdajsnimi_20_0'}
      target="_blank"
    >
      оферты
    </a>
  </>
);
