import { ca } from '@cian/analytics';

export function trackShowThisIsShareBanner() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'AttentionThisIsShare',
    action: 'Show_sopr',
    label: 'Banner',
  });
}
