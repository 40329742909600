import * as React from 'react';

import * as styles from './CalltrackingBadge.css';

export function CalltrackingBadge() {
  return (
    <div className={styles['calltracking_badge']}>
      <div className={styles['calltracking_badge-text']}>
        СМС и сообщения в мессенджерах доставлены не будут. Звоните
      </div>
      <div className={styles['calltracking_badge-description']}>В объявлении показан временный номер</div>
    </div>
  );
}
