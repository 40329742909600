import { Button } from '@cian/ui-kit';
import * as React from 'react';

import { AsyncActionStatus, TAsyncActionStatus } from 'shared/store/common';
import { TDealType } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as styles from './index.css';
import { trackOfferRemoved, trackOfferMoveBasket } from './tracking';
import { Reasons } from '../../components/Reasons';
import { Layout } from '../layout';

export interface IRemoveProps {
  offerId: number;
  status: TAsyncActionStatus;
  dealType: TDealType;
  isAgent?: boolean | null;

  onSubmit(): void;
}

interface IRemoveState {
  value: string;
  isErrorMessageVisible: boolean;
  infoFromUser: string;
}

const MAX_INFO_FROM_USER_LENGTH = 100;

export class Remove extends React.PureComponent<IRemoveProps, IRemoveState> {
  public state = {
    isErrorMessageVisible: false,
    value: '',
    infoFromUser: '',
  };

  public render() {
    const { status, dealType, isAgent } = this.props;
    const { value, infoFromUser } = this.state;

    return (
      <Layout
        className={styles['container']}
        isLoading={status === AsyncActionStatus.Loading || status === AsyncActionStatus.Completed}
        title="Почему вы удалили объявление?"
      >
        <div className={styles['options']}>
          <Reasons
            dealType={dealType}
            isAgent={isAgent}
            value={value}
            infoFromUser={infoFromUser}
            onChange={this.handleChangeValue}
            handleChangeInfoFromUser={this.handleChangeInfoFromUser}
          />
        </div>
        <footer>
          <Button onClick={this.submit}>Отправить</Button>
          {this.state.isErrorMessageVisible && <span className={styles['error']}>Укажите причину удаления</span>}
          {status === AsyncActionStatus.Failed && <span className={styles['error']}>Ошибка сервера</span>}
        </footer>
      </Layout>
    );
  }

  private handleChangeValue = (value: string) => {
    this.setState({
      isErrorMessageVisible: false,
      value,
    });
  };

  private handleChangeInfoFromUser = (infoFromUser: string) => {
    if (infoFromUser.length > MAX_INFO_FROM_USER_LENGTH) {
      return;
    }

    this.setState({ infoFromUser });
  };

  private submit = () => {
    const { value, infoFromUser } = this.state;

    if (!this.state.value) {
      this.setState({
        isErrorMessageVisible: true,
      });

      return;
    }

    trackOfferRemoved(this.props.offerId, this.state.value);

    if (infoFromUser) {
      trackOfferMoveBasket(value, infoFromUser);
    }

    this.props.onSubmit();
  };
}
