import * as React from 'react';

import { useAuthorPremium } from './hooks/useAuthorPremium';
import { AuthorPremium } from '../../components/AuthorPremium';

export const AuthorPremiumContainer = () => {
  const props = useAuthorPremium();

  return <AuthorPremium {...props} />;
};
