import { Image, UIText3, UIHeading4 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './objectFactoidsItem.css';

interface IObjectFactoidsItemProps {
  icon: string;
  note?: string | null;
  title: string;
  value: string;
}

export const ObjectFactoidsItem = (props: IObjectFactoidsItemProps) => {
  return (
    <div className={styles['item']}>
      <div className={styles['img']}>
        <Image width={40} height={40} display={'block'} src={props.icon} />
      </div>
      <div className={styles['text']}>
        <UIText3 color="gray60_100">{props.title}</UIText3>
        <UIHeading4>{props.value}</UIHeading4>
      </div>
    </div>
  );
};
