import { UIHeading3 } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { CoworkingProfitBanner } from 'shared/components/CoworkingProfitBanner';
import { ECoworkingOfferType } from 'shared/repositories/offer-card/entities/offer/OfferSchema';

import * as styles from './CoworkingAdditionalInfo.css';
import { selectOffer } from '../../selectors';

export const CoworkingAdditionalInfo = () => {
  const offer = useSelector(selectOffer);
  const { coworkingOfferType } = offer;

  const isFlexibleOffice = coworkingOfferType === ECoworkingOfferType.Office;

  return (
    <div className={styles['container']}>
      <UIHeading3>за рабочее место</UIHeading3>
      {isFlexibleOffice && (
        <CoworkingProfitBanner isModernButton containerClassName={styles['coworking-profit-banner']} />
      )}
    </div>
  );
};

CoworkingAdditionalInfo.displayName = 'CoworkingAdditionalInfo';
