import { Checkbox } from '@cian/ui-kit';
import cx from 'clsx';
import { useFormikContext, Field, getIn } from 'formik';
import * as React from 'react';

import { Label } from './Label';
import * as styles from './styles.css';
import { IFormValues } from '../SecondStep';

const useFieldHasErrors = (name: keyof IFormValues) => {
  const { touched, errors } = useFormikContext<IFormValues>();

  return Boolean(getIn(touched, name) && getIn(errors, name));
};

export const IsTermAccepted = () => {
  const invalid = useFieldHasErrors('termAccepted');
  const { values } = useFormikContext<IFormValues>();

  return (
    <div className={cx(styles['field'], styles['span-columns'])}>
      <Label invalid={invalid}>
        <Field
          name="termAccepted"
          as={Checkbox}
          checked={values.termAccepted}
          custom={{
            invalid,
          }}
        />
      </Label>
    </div>
  );
};
