import { ca } from '@cian/analytics';

export const trackTransportAccessibilityEntryShown = () => trackTransportAccessibilityEntryEvent('show_sopr');
export const trackTransportAccessibilityEntryClick = () => trackTransportAccessibilityEntryEvent('click_sopr');

function trackTransportAccessibilityEntryEvent(action: 'show_sopr' | 'click_sopr') {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card',
    action,
    label: 'itd_entry_point',
    useLastProducts: true,
  });
}
