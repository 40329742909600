import { FOfferType, isCommercial, isSuburban } from './offer_type';
import { IPointData } from '../store/map_similar';

export interface ILegendItem {
  color: string;
  title: string;
}

const URBAN = [
  {
    code: 'flat10',
    title: 'Койко-место',
  },
  {
    code: 'flat0',
    title: 'Комната',
  },
  {
    code: 'flat9',
    title: 'Студия',
  },
  {
    code: 'flat1',
    title: '1-комн.',
  },
  {
    code: 'flat2',
    title: '2-комн.',
  },
  {
    code: 'flat3',
    title: '3-комн.',
  },
  {
    code: 'flat4',
    title: '4-комн.',
  },
  {
    code: 'flat5',
    title: '5-комн.',
  },
  {
    code: 'flat6',
    title: '6-комн.',
  },
  {
    code: 'flat7',
    title: 'Своб. план',
  },
  {
    code: 'flat8',
    title: 'Доля',
  },
];

const SUBURBAN = [
  {
    code: 'suburban1',
    title: 'Дом',
  },
  {
    code: 'suburban2',
    title: 'Часть дома',
  },
  {
    code: 'suburban4',
    title: 'Таунхаус',
  },
  {
    code: 'suburban3',
    title: 'Участок',
  },
];

const COMMERCIAL = [
  {
    code: 'commercial1',
    title: 'Офис',
  },
  {
    code: 'commercial2',
    title: 'Торг. площ.',
  },
  {
    code: 'commercial3',
    title: 'Склад',
  },
  {
    code: 'commercial5',
    title: 'ПСН',
  },
  {
    code: 'commercial4',
    title: 'Общепит',
  },
  {
    code: 'commercial6',
    title: 'Гараж',
  },
  {
    code: 'commercial7',
    title: 'Произв.',
  },
  {
    code: 'commercial9',
    title: 'Автосервис',
  },
  {
    code: 'commercial10',
    title: 'Гот. бизнес',
  },
  {
    code: 'commercial11',
    title: 'Здание',
  },
  {
    code: 'commercial12',
    title: 'Быт. услуги',
  },
  {
    code: 'commercialLand',
    title: 'Комм. земля',
  },
];

export function getLegendData(
  points: {
    [index: string]: IPointData;
  },
  currentOfferType: FOfferType,
): ILegendItem[] {
  const propertyTypes: {
    [index: string]: {
      color: string;
    };
  } = {};

  Object.keys(points).forEach(key => {
    points[key].stat.forEach(pointStat => {
      let propertyType = pointStat.property_type;

      if (propertyType) {
        propertyType = propertyType.replace(/(Sale|Rent)$/, '');

        if (propertyType && !propertyTypes[propertyType] && pointStat.color) {
          propertyTypes[propertyType] = {
            color: pointStat.color,
          };
        }
      }
    });
  });

  const propertyTypesArray = Object.keys(propertyTypes);
  let locales;

  if (isSuburban(currentOfferType)) {
    locales = SUBURBAN;
  } else if (isCommercial(currentOfferType)) {
    locales = COMMERCIAL;
  } else {
    locales = URBAN;
  }

  return locales.reduce((acc, item) => {
    if (propertyTypesArray.includes(item.code)) {
      acc.push({
        color: propertyTypes[item.code].color,
        title: item.title,
      });
    }

    return acc;
  }, [] as ILegendItem[]);
}
