import { connect } from 'react-redux';

import { DealRentSuccessModal } from './DealRentSuccessModal';
import { IState } from '../../store';
import { closeSuccess } from '../../store/deal-rent';

export function mapStateToProps(state: IState) {
  return {
    isOpen: state.dealRent.isSuccessModal,
  };
}

export const DealRentSuccessModalContainer = connect(mapStateToProps, { closeModal: closeSuccess })(
  DealRentSuccessModal,
);
