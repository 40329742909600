import { type TTgbNativedescGeneralContext } from '@cian/adfox-templates';

import type { INewbuildingInfoForTgbSchema } from 'shared/repositories/newbuilding-search/entities/get_newbuildings_info_for_tgb/NewbuildingInfoForTgbSchema';
import { IAddressInfo } from 'shared/types/geo/address';
import { getTransportTypeFromRegionId } from 'shared/utils/getTransportTypeFromRegionId';

import type { TTgbNativedescGeneralWideProps } from '../components/TgbNativedescGeneralWide';

type TGetTgbNativedescGeneralWidePropsParams = {
  address: IAddressInfo[];
  context: TTgbNativedescGeneralContext;
  newbuidingInfoForTgb: null | INewbuildingInfoForTgbSchema;
};

export function getTgbNativedescGeneralWideProps({
  address,
  context: {
    url,
    label,
    image,
    title,
    annotation,
    description,
    logoImageUrl,
    builderName,
    metro,
    metroColor,
    metroDistance,
    button,
    location,
  },
  newbuidingInfoForTgb,
}: TGetTgbNativedescGeneralWidePropsParams): TTgbNativedescGeneralWideProps {
  if (newbuidingInfoForTgb) {
    return {
      address: newbuidingInfoForTgb.address,
      annotation,
      builderName: newbuidingInfoForTgb.publisher.name,
      button,
      description,
      image,
      label,
      logoImageUrl: newbuidingInfoForTgb.publisher.logo.url,
      title,
      transports: newbuidingInfoForTgb.transports,
      url,
    };
  }
  const transports: TTgbNativedescGeneralWideProps['transports'] = [];

  if (metro) {
    const iconType = address[0]?.id ? getTransportTypeFromRegionId(address[0].id) : null;

    transports.push({
      iconColor: metroColor,
      iconType,
      id: 1,
      name: metro,
      remoteness: metroDistance,
    });
  }

  return {
    logoImageUrl,
    url,
    transports,
    label,
    button,
    image,
    annotation,
    builderName,
    title,
    address: location,
    description,
  };
}
