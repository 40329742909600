import { RefObject, useEffect } from 'react';

interface IParams {
  mapRef: RefObject<YMaps.Map | undefined>;
}

/**
 * Хук реализует идею https://yandex.ru/dev/maps/jsbox/2.1/hiddendiv/, чтобы карта показывалась при показе скрытого контейнера
 */
export const useMapsFitToViewport = ({ mapRef }: IParams) => {
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.container.fitToViewport();
    }
  }, [mapRef]);
};
