import { ca } from '@cian/analytics';

export function trackAgencyBrandingCardClick(label: string) {
  ca('eventSite', {
    action: 'to_realtorCard_floating_block',
    category: 'card',
    label: `to_realtorCard_${label}`,
    name: 'oldevent',
  });
}
