import { TDealType, TOfferType } from 'shared/types/api-models/offer-card/v1/get-offer-data';

export function scrollToElement(anchor: HTMLElement | null) {
  let endLocation = 0;
  if (anchor) {
    endLocation = getOffsetTop(anchor, 52, 10);
  }

  const startLocation = window.pageYOffset;
  const distance = endLocation - startLocation;
  const documentHeight = Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight,
  );
  let timeLapsed = 0;
  let start: number | null;
  let percentage: number;
  let position: number;
  let animationHandle: number;

  // tslint:disable-next-line:variable-name
  const stopAnimateScroll = (_position: number, _endLocation: number) => {
    const currentLocation = window.pageYOffset;

    if (
      _position === _endLocation ||
      currentLocation === _endLocation ||
      (startLocation < _endLocation && window.innerHeight + currentLocation) >= documentHeight
    ) {
      window.cancelAnimationFrame(animationHandle);

      start = null;

      return true;
    }

    return false;
  };

  const loopAnimateScroll = (timestamp: number) => {
    if (!start) {
      start = timestamp;
    }

    timeLapsed += timestamp - start;
    percentage = timeLapsed / 250;
    percentage = percentage > 1 ? 1 : percentage;
    position = startLocation + distance * (percentage * percentage);

    window.scrollTo(0, Math.floor(position));

    if (!stopAnimateScroll(position, endLocation)) {
      animationHandle = window.requestAnimationFrame(loopAnimateScroll);
      start = timestamp;
    }
  };

  animationHandle = window.requestAnimationFrame(loopAnimateScroll);
}

export function getOffsetTop(anchor: HTMLElement, headerHeight: number, offset: number) {
  let location = 0;

  if (anchor.offsetParent) {
    let nextAnchor = anchor;

    do {
      location += nextAnchor.offsetTop;
      nextAnchor = nextAnchor.offsetParent as HTMLElement;
    } while (nextAnchor);
  }

  return Math.max(location - headerHeight - offset, 0);
}

export type THeaderSection =
  | 'index'
  | 'rent'
  | 'daily-rent'
  | 'commercial'
  | 'sale'
  | 'new-buildings'
  | 'business-centers'
  | 'serp';

// На карточке объекта подсвечиваются только вертикали - коммерческая, аренда, продажа
export function getHeaderSection(dealType: TDealType, offerType: TOfferType): THeaderSection {
  if (offerType === 'commercial') {
    return 'commercial';
  }

  return dealType;
}
