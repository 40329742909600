import * as React from 'react';

import * as styles from './GuestsInputWrapper.css';

interface IGuestsInputWrapperProps {
  children: React.ReactNode;
}

export const GuestsInputWrapper = ({ children }: IGuestsInputWrapperProps) => {
  return <div className={styles['root']}>{children}</div>;
};
