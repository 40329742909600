import { Avatar } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './BrandingTitle.css';

interface IBrandingTitleProps {
  avatarUrl: string;
  companyName: string;
  companyUrl: string;
}

export const BrandingTitle: React.FC<React.PropsWithChildren<IBrandingTitleProps>> = ({
  avatarUrl,
  companyUrl,
  companyName,
}) => {
  return (
    <div className={styles['container']}>
      <div className={styles['logo-block']}>
        <Avatar size={48} src={avatarUrl} type="agency" />
      </div>
      <div className={styles['title-block']}>
        <h2 className={styles['title']}>Другие предложения</h2>
        {companyUrl && <a href={companyUrl}>{companyName}</a>}
      </div>
    </div>
  );
};
