export interface IPhoneWarningState {
  isOpen: boolean;
  target: ETargetType | null;
}

export enum ETargetType {
  Booking = 'booking',
}

export interface IModalDetails {
  content: string;
  title: string;
}
