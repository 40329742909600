import { TAdFoxBannerTemplate } from '@cian/adfox-component';
import { AdFoxTemplates, type TTgbNativedescGeneralContext } from '@cian/adfox-templates';
import { Base64 } from 'js-base64';
import * as React from 'react';

import { useAdfoxContext } from 'shared/context/AdfoxContext';

import { MortgageTemplate } from '../Mortgage/MortgageTemplate';
import { TgbNativedescGeneralContainer } from '../TgbNativedescGeneral';
import { MortgageOfferCardTemplate } from '../mortgage_offer_card/mortgage-offer-card';
import { TgbSuburbanTemplate } from '../tgb_suburban/tgb_suburban';

const OWN_TYPES = ['tgb_suburban', 'offer_card_image_banner_new', 'mortgage_in_rent_new', 'tgb_nativedesc_general'];

export type TAdFoxTemplatesParams = {
  [key: string]: string;
};

export const OwnTemplates: React.FC<React.PropsWithChildren<TAdFoxBannerTemplate>> = props => {
  const { bundleName, bundleParams } = props;

  const { bannerPlace } = useAdfoxContext();

  if (bundleName === 'banner.transfer' && bundleParams?.htmlBase64) {
    try {
      const decodedParams = Base64.decode(bundleParams.htmlBase64);
      const parsedDecodedParams = JSON.parse(decodedParams);

      if (isParsedParamsValid(parsedDecodedParams) && OWN_TYPES.includes(parsedDecodedParams.type)) {
        switch (parsedDecodedParams.type) {
          case 'tgb_suburban':
            return <TgbSuburbanTemplate {...parsedDecodedParams} />;
          case 'offer_card_image_banner_new': {
            const props = { ...parsedDecodedParams, bannerId: '' };

            return <MortgageOfferCardTemplate {...props} />;
          }
          case 'mortgage_in_rent_new':
            return <MortgageTemplate {...parsedDecodedParams} />;
          case 'tgb_nativedesc_general':
            return (
              <TgbNativedescGeneralContainer
                behaviour={bannerPlace === 'bottom' ? 'wide' : 'narrow'}
                context={parsedDecodedParams as TTgbNativedescGeneralContext}
              />
            );
        }
      }
    } catch (e) {
      console.error('[AdFox] Failed to decode/parse bundleParams', bundleParams.bannerId, e);

      return null;
    }
  }

  return <AdFoxTemplates {...props} />;
};

function isParsedParamsValid(params: object): params is TAdFoxTemplatesParams {
  return 'type' in params;
}
