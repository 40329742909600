import { UIHeading5 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import { ReviewsMiniWidgetStars } from './components/ReviewsMiniWidgetStars';
import * as styles from './styles.css';

interface IReviewsMiniWidgetProps {
  className?: string;
  rating?: string;
  reviewsCount?: string;
  onClick?: VoidFunction;
}

function ReviewsMiniWidgetComponent({ className, rating, reviewsCount, onClick }: IReviewsMiniWidgetProps) {
  const ratingStringFormatted = rating?.replace(',', '.');
  const ratingNumber = ratingStringFormatted ? parseFloat(ratingStringFormatted) : 0;
  const needToShowPointer = Boolean(onClick);

  return (
    <div className={cx(styles['container'], needToShowPointer && styles['pointer'], className)} onClick={onClick}>
      {!!rating && (
        <div data-testid="rating" className={styles['rating']}>
          {rating}
        </div>
      )}
      <div className={styles['details']}>
        <ReviewsMiniWidgetStars value={ratingNumber} />
        <UIHeading5>{reviewsCount || 'Нет отзывов'}</UIHeading5>
      </div>
    </div>
  );
}

export const ReviewsMiniWidget = React.memo(ReviewsMiniWidgetComponent);
