import * as React from 'react';
import { useSelector } from 'react-redux';

import { MortgageBannersLayout } from 'shared/components/MortgageBannersLayout';
import { MortgageWidgetPromoDiscount } from 'shared/components/MortgageWidgetPromoDiscount';
import { NewbuildingMortgageWidgetPromoDiscount } from 'shared/components/NewbuildingMortgageWidgetPromoDiscount';
import { selectAsideCompactMode } from 'shared/selectors/aside';
import { IState } from 'shared/store';

import { MortgageWidget } from '../../components/MortgageWidgetRedesign';
import {
  isOfferInGageSelector,
  mortgageSelector,
  offerSelector,
  selectIsAgent,
  selectOfferPriceRub,
} from '../../selectors';
import { getOfferType, isNewbuilding, isOldbuilding, isSuburban } from '../../utils/offer_type';
import { useApplicationContext } from '../ApplicationContext';
import { SubsidisedMortgageBannerContainer } from '../SubsidisedMortgageBannerContainer';
import { useMortgageRate } from '../mortgage/hooks/useMortgageRate';

export const MortgageWidgetContainer: React.FC = () => {
  const { config } = useApplicationContext();
  const offer = useSelector(offerSelector);
  const price = useSelector(selectOfferPriceRub);
  const isInGage = useSelector(isOfferInGageSelector);
  const { mortgageWidgetMarkup } = useSelector(mortgageSelector);
  const rate = useMortgageRate();
  const isCompactModeEnabled = useSelector(selectAsideCompactMode);
  const mortgagePromoDesign = useSelector((state: IState) => state.mortgagePromoDesign);
  const isAgent = useSelector(selectIsAgent);

  const isEnabled = config.get<boolean>('mortgage.isWidgetEnabled') || false;
  const offerType = getOfferType(offer);

  if (!isEnabled || isInGage || isCompactModeEnabled) {
    return null;
  }

  if (isNewbuilding(offerType)) {
    return <NewbuildingMortgageWidgetPromoDiscount mortgagePromoDesign={mortgagePromoDesign} />;
  }

  if (isSuburban(offerType)) {
    return <MortgageWidgetPromoDiscount mortgagePromoDesign={mortgagePromoDesign} />;
  }

  if (isOldbuilding(offerType)) {
    return (
      <MortgageBannersLayout>
        <SubsidisedMortgageBannerContainer />
        {!isAgent && <MortgageWidgetPromoDiscount mortgagePromoDesign={mortgagePromoDesign} />}
      </MortgageBannersLayout>
    );
  }

  return <MortgageWidget mortgageWidgetMarkup={mortgageWidgetMarkup} price={price} rate={rate} />;
};
