import { connect } from 'react-redux';

import { DealRentEntryToShowButton } from './DealRentEntryToShowButton';
import { IState } from '../../store';
import { openEntryToShow } from '../../store/deal-rent';

export function mapStateToProps(state: IState) {
  const offer = state.offerData.offer;
  const isAvailable = Boolean(offer.dealRentVersion && offer.dealType === 'rent') && state.entryToShowEnabled;

  return {
    isAvailable,
  };
}

export const DealRentEntryToShowButtonContainer = connect(mapStateToProps, { open: openEntryToShow })(
  DealRentEntryToShowButton,
);
