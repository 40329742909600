import cx from 'clsx';
import * as React from 'react';

import * as styles from './asideSection.css';

export const AsideSection = (
  props: React.PropsWithChildren<{
    withShadow?: boolean;
    withBorders?: boolean;
    withSpaces?: boolean;
    hideForPrint?: boolean;
    overflowed?: boolean;
    dataName?: string;
  }>,
) => {
  const {
    dataName,
    withShadow = true,
    withBorders = true,
    withSpaces = true,
    hideForPrint = false,
    overflowed = false,
  } = props;

  return (
    <div
      data-name={dataName}
      className={cx(
        styles['section'],
        overflowed && styles['overflowed'],
        withShadow && styles['shadow'],
        withBorders && styles['borders'],
        withSpaces && styles['spaces'],
        hideForPrint && styles['hide-for-print'],
      )}
    >
      {props.children}
    </div>
  );
};
