import { useFormikContext } from 'formik';
import * as React from 'react';

import { Label } from './Label';
import { useFieldHasErrors } from './utils';
import { CountComponent } from '../../CountComponent';
import { IFormValues } from '../DealRentModal';
import * as styles from '../index.css';

export const Pets = () => {
  const { values, setFieldValue } = useFormikContext<IFormValues>();
  const invalid = useFieldHasErrors('petsCount');
  const onChange = React.useCallback((value: number) => setFieldValue('petsCount', value), [setFieldValue]);

  return (
    <div className={styles['field']}>
      <Label invalid={invalid}>Животных</Label>
      <CountComponent min={0} max={9} value={values['petsCount'] || 0} onChange={onChange} />
    </div>
  );
};
