import { ca } from '@cian/analytics';

export function trackLeaveFeedbackClick() {
  ca('event', {
    action: 'Open',
    category: 'CallRate_popup',
    label: '',
    name: 'oldevent',
  });
}

export function trackFeedbackOptionClick(option: 'confirmed' | 'not_confirmed' | 'no_answer') {
  ca('event', {
    action: 'Rate',
    category: 'CallRate_popup',
    label: option,
    name: 'oldevent',
  });
}

export function trackSubmitButtonClick() {
  ca('event', {
    action: 'Review_send',
    category: 'CallRate_popup',
    label: '',
    name: 'oldevent',
  });
}

export function trackFeedbackSucceed() {
  ca('event', {
    action: 'Copmplain_send',
    category: 'CallRate_popup',
    label: '',
    name: 'oldevent',
  });
}

export function trackFeedbackComplainSelect(step: number, id: number) {
  ca('event', {
    action: 'complain',
    category: 'Listing',
    label: `answer${step}/${id}`,
    name: 'oldevent',
  });
}
