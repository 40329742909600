import { connect } from 'react-redux';

import { IOfferValueAddedServicesProps, OfferValueAddedServices } from './index';
import { isMlSearchEnabled } from '../../selectors/isMlSearchEnabled';
import { IState } from '../../store';

export const mapStateToProps = (state: IState): IOfferValueAddedServicesProps => {
  return {
    valueAddedServices: state.offerData.offer.valueAddedServices,
    isMlSearchEnabled: isMlSearchEnabled(state),
  };
};

export const OfferValueAddedServicesContainer =
  connect<IOfferValueAddedServicesProps>(mapStateToProps)(OfferValueAddedServices);
