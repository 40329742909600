/* eslint-disable  no-irregular-whitespace */
import { Tooltip, UIHeading5, UIText2 } from '@cian/ui-kit';
import { IconFunctionalInformationCircle16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './styles.css';

export interface ICianEstimationTooltipProps {
  onOpen?(): void;
  estimationRange: React.ReactChild;
  tooltipText: string;
  infoUrl?: string | null;
}

export const CianEstimationTooltip: React.FC<React.PropsWithChildren<ICianEstimationTooltipProps>> = props => {
  const { estimationRange, infoUrl, onOpen, tooltipText } = props;

  return (
    <Tooltip
      title={
        <div className={styles['container']} data-testid="valuation_estimation_faq">
          <UIHeading5>Об оценке Циан</UIHeading5>
          <div className={styles['range']}>
            <UIText2>Диапазон оценки: {estimationRange}</UIText2>
          </div>
          <div className={styles['description']}>
            <UIText2>{tooltipText}</UIText2>
          </div>
          {infoUrl && infoUrl !== 'none' && (
            <div className={styles['link_wrapper']}>
              <a className={styles['link']} href={infoUrl} target="_blank" rel="noreferrer">
                Подробнее об оценке
              </a>
            </div>
          )}
        </div>
      }
      theme="white"
      placement="right-start"
      onOpen={onOpen}
    >
      <div className={styles['faq_icon']} data-testid="valuation_estimation_faq_icon">
        <IconFunctionalInformationCircle16 display="block" color="icon-main-default" />
      </div>
    </Tooltip>
  );
};

CianEstimationTooltip.displayName = 'CianEstimationTooltip';
