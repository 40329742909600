import { ca } from '@cian/analytics';

export function trackSaveComment() {
  ca('eventSite', {
    action: 'comment_send_main_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}
