import * as React from 'react';

import { IPhotoSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as styles from './index.css';

export interface IPrintPhotoProps {
  photos: IPhotoSchema[] | null;
}

export function PrintPhoto(props: IPrintPhotoProps) {
  const { photos } = props;

  if (!photos) {
    return null;
  }

  const photosWithUrl = photos.filter(({ fullUrl }) => Boolean(fullUrl));

  if (photosWithUrl.length === 0) {
    return null;
  }

  const defaultPhoto = photosWithUrl.find(({ isDefault }) => Boolean(isDefault));

  if (defaultPhoto) {
    return (
      <div className={styles['container']}>
        <img className={styles['photo']} src={defaultPhoto.fullUrl} />
      </div>
    );
  }

  return (
    <div className={styles['container']}>
      <img className={styles['photo']} src={photosWithUrl[0].fullUrl} />
    </div>
  );
}
