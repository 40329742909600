import { connect } from 'react-redux';

import { IOfferStatsPopupDispatchProps, IOfferStatsPopupProps, OfferStatsPopup } from './index';
import { offerStatsPopupClose, submitOfferStats } from '../../store/offer_stats_popup';

import type { DispatchReduxAction, IState } from '../../store';

function mapStateToProps(state: IState): IOfferStatsPopupProps {
  return {
    dataStats: state.offerStatsPopup.dataStats,
    isOpen: state.offerStatsPopup.isOpen,
    messageError: state.offerStatsPopup.messageError,
    offerId: state.offerData.offer.id,
    publicationDate: state.offerData.offer.publicationDate,
    statusFetch: state.offerStatsPopup.statusFetch,
  };
}

function mapDispatchToProps(dispatch: DispatchReduxAction): IOfferStatsPopupDispatchProps {
  return {
    offerStatsPopupClose: () => {
      dispatch(offerStatsPopupClose());
    },
    submitOfferStats: (host: string, id: number) => {
      dispatch(submitOfferStats(host, id));
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OfferStatsPopupContainer = connect<IOfferStatsPopupProps, IOfferStatsPopupDispatchProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(OfferStatsPopup);
