import * as React from 'react';
import { useSelector } from 'react-redux';

import { CalltrackingBadge } from '../../components/CalltrackingBadge';
import { selectIsEnabledCallTracking } from '../../selectors';

export const CalltrackingBadgeContainer: React.FC = () => {
  const isEnabledCallTracking = useSelector(selectIsEnabledCallTracking);

  if (!isEnabledCallTracking) {
    return null;
  }

  return <CalltrackingBadge />;
};
