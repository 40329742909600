import { Checkbox } from '@cian/ui-kit';
import cx from 'clsx';
import { useFormikContext, Field } from 'formik';
import * as React from 'react';

import { IFormValues } from '../DealRentModal';
import * as styles from '../index.css';

export const IsAgent = () => {
  const { values } = useFormikContext<IFormValues>();

  return (
    <div className={cx(styles['field'], styles['span-columns'])}>
      <Field name="isAgent" as={Checkbox} checked={values.isAgent} label="Я агент, подбираю квартиру для клиента" />
    </div>
  );
};
