import { EStarSize, EStarTheme, Star } from '@cian/frontend-reviews-components';
import { UIHeading5, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

interface IReviewStatsProps {
  reviewCountText: string;
  totalRate: number;
}

export const ReviewStats = ({ reviewCountText, totalRate }: IReviewStatsProps) => {
  return (
    <div className={style['review']} data-testid="BuilderReviewStats">
      <span className={style['review-rate']}>
        <UIHeading5 color="current_color">{totalRate}</UIHeading5>
      </span>
      <Star theme={EStarTheme.Fillable} size={EStarSize.M} value={totalRate}></Star>
      <span className={style['review-text']}>
        <UIText2 color="current_color">{reviewCountText}</UIText2>
      </span>
    </div>
  );
};
