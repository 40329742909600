import { Button } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './index.css';
import { IGetProductsParams } from '../../tracking/hide_offer';

export interface IHiddenBlockStoreButtonsProps {
  productParams?: IGetProductsParams;
}

export interface IHiddenBlockDispatchButtonsProps {
  toggleComplainPopup(isOpened: boolean): () => void;
  restoreOffer(): void;
}

type IHiddenBlockButtonsProps = IHiddenBlockStoreButtonsProps & IHiddenBlockDispatchButtonsProps;

export class HiddenBlockButtons extends React.PureComponent<IHiddenBlockButtonsProps, {}> {
  public render() {
    const { toggleComplainPopup, restoreOffer } = this.props;

    return (
      <div className={styles['buttons-outer']}>
        <span className={styles['complaint-outer']}>
          <Button size="XS" onClick={toggleComplainPopup(true)} theme="fill_error_secondary">
            Пожаловаться
          </Button>
        </span>
        <Button size="XS" onClick={restoreOffer} theme="fill_secondary">
          Восстановить
        </Button>
      </div>
    );
  }
}
