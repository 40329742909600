import * as React from 'react';

import * as styles from './index.css';
import { Link } from '../../common/components/link';
import { Text } from '../../common/components/text';
import { HiddenBlockButtonsContainer } from '../hidden_block_buttons/container';

export interface IOfferIsHiddenBlockProps {
  lkUrl: string;
}

export const OfferIsHiddenBlock: React.FunctionComponent<React.PropsWithChildren<IOfferIsHiddenBlockProps>> = props => {
  return (
    <div className={styles['hidden-block-segment']}>
      <div>
        <Text bold color="black">
          Объявление скрыто из результатов поиска
        </Text>
      </div>
      <div>
        <Text textSize="small" color="black">
          Вы можете восстановить объявление в{' '}
          <Link href={props.lkUrl} target="_blank" className={styles['link']}>
            личном кабинете.
          </Link>
        </Text>
      </div>
      <HiddenBlockButtonsContainer />
    </div>
  );
};
