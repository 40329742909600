import { UIHeading3 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './MortgageBannersLayout.css';

interface IMortgageBannersLayoutProps {
  children: React.ReactNode;
}

export const MortgageBannersLayout: React.FC<IMortgageBannersLayoutProps> = ({ children }) => {
  return children ? (
    <div className={styles['container']}>
      <UIHeading3>Выгодная ипотека</UIHeading3>
      {children}
    </div>
  ) : /* istanbul ignore next */ null;
};
