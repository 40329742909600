export enum EBestPlaceReportStatus {
  // Получение URL для оплаты
  PaymentUrlLoading,
  // URL для оплаты получен
  PaymentUrlLoaded,
  // Некорректный тип юзера. который пытается произвести оплату отчета
  UserTypeError,
  // Ошибка в requestUrl
  RequestUrlError,
  // Ошибка валидации email'а
  EmailError,
  // Ошибка от сервисов билинга
  BillingServerError,
  // Любая ошибка, которая не попадает под другие категории
  UnknownServerError,

  // Ошибка некорректного кода транзакции
  WrongTransactionId,

  // Ждем данные об успехе/неудаче оплаты отчета
  PaymentLoading,
  // Оплата неуспешна (canceled)
  PaymentCancelled,
  // Оплата in progress (created)
  PaymentCreated,
  // Оплата успешна (paid)
  ReportPaid,
  // Оплата неуспешна (paid_error)
  ReportPaidError,

  // Пользователь нажал на "Купить", показываем модалку подтверждения
  CheckEmail,

  // Форма успешно отправлена
  FeedbackSent,
}

export interface IBestPlaceAnalyticsState {
  amount: number | null;
  discount: number | null;
  discountPrice: number | null;
  paymentUrl: string | null;
  status: EBestPlaceReportStatus | null;
  statusMessage: string | null;
  userEmail: string | null;
}
