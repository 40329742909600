/* istanbul ignore next */

import { IAgentSchema, IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { getBrand } from './getBrand';
import { getCategory } from './getCategory';
import { getVariants } from './getVariants';

export function getProduct(offer: IOfferSchema, agent: IAgentSchema | null) {
  return {
    brand: getBrand(agent),
    category: getCategory(offer),
    id: offer.cianId,
    price: offer.bargainTerms.prices.rur,
    quantity: 1,
    variant: getVariants(offer),
  };
}
