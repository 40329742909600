import { useEventCallback } from '@cian/react-utils';
import * as React from 'react';

import { useIsElectronicTradingOffer } from 'shared/hooks/useIsElectronicTradingOffer';

import { useDeveloperProps } from './hooks';
import { DeveloperInfo } from '../../../components/BottomAuthorBlockNew';
import { CallBackButtonContainer } from '../../CallBackButton';
import { PhonesContainer } from '../PhonesContainer';
import { PhonesOpenedInfoContainer } from '../PhonesOpenedInfoContainer';

export const DeveloperInfoContainer: React.FC = () => {
  const [isPhonesOpened, setPhonesOpened] = React.useState(false);

  const isElectronicTradingOffer = useIsElectronicTradingOffer();

  const handlePhonesOpen = useEventCallback(() => {
    setPhonesOpened(true);
  });

  const props = useDeveloperProps();

  if (!props) {
    return null;
  }

  return (
    <DeveloperInfo
      contactButton={
        !isElectronicTradingOffer ? (
          <PhonesContainer
            trackingAction="Open_card_agent_block"
            size="XS"
            phonesShown={isPhonesOpened}
            onPhoneClick={handlePhonesOpen}
          />
        ) : null
      }
      phonesShown={isPhonesOpened}
      callButton={!isElectronicTradingOffer ? <CallBackButtonContainer size="XS" placeType="agent_block" /> : null}
      phonesOpenedInfo={<PhonesOpenedInfoContainer />}
      {...props}
    />
  );
};
