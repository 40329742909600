import { useSelector } from 'react-redux';

import { offerSelector } from '../../../../selectors';
import { IState } from '../../../../store';
import { shouldShowTitle } from '../utils/shouldShowTitle';

function objectSummaryTitleSelector(state: IState): string | null {
  const offer = offerSelector(state);

  if (shouldShowTitle(offer)) {
    return offer.title;
  }

  return null;
}

export function useObjectSummaryTitleSelector(): string | null {
  return useSelector<IState, string | null>(objectSummaryTitleSelector);
}
