import { ca } from '@cian/analytics';

export function trackOfferMovedToArchive(offerId: number, label: string) {
  ca('event', {
    action: 'archiving',
    category: 'AD_DeleteReason',
    event: 'oldevent',
    label,
    oid: offerId,
  });
}

export function trackOfferMoveArchive(action: string, label: string) {
  ca('eventSite', {
    name: 'oldevent',
    action,
    label,
    category: 'AD_Archive_reason',
    useLastProducts: true,
  });
}
