import { FavoriteButtonsGroupContainer, showFavoritesFoldersList } from '@cian/favorites-assign-widget';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackFavoriteClick } from './tracking';
import { FavoriteButton } from '../../components/FavoriteButton';
import { RemovedFromFolderTooltip } from '../../components/FavoriteButton/RemovedFromFolderTooltip';
import { FavoritesButtonContext } from '../../context';
import {
  getIsFavorite,
  getOfferFoldersCount,
  getRemovedFromFavorites,
  offerSelector,
  selectIsAgent,
} from '../../selectors';
import { getOfferCustomUrl } from '../../selectors/analytics';
import { getRosreestrInfo } from '../../selectors/offerData/getRosreestrInfo';
import { isCommercialSelector } from '../../selectors/offerData/offer/isCommercialSelector';
import { changeFavoriteAction, setRemovedFromFavorites } from '../../store/offer';
import { IExtra, ISoprProducts } from '../../utils/tracking/types';
import { FavoriteButtonAsideContainer } from '../FavoriteButtonAsideContainer';

interface IFavoriteButtonContainerProps {
  newButton?: boolean;
}

export function FavoriteButtonContainer({ newButton }: IFavoriteButtonContainerProps) {
  const dispatch = useDispatch();
  const { placement } = React.useContext(FavoritesButtonContext);
  const buttonGroupRef = React.useRef(null);
  const checked = useSelector(getIsFavorite);
  const foldersCount = useSelector(getOfferFoldersCount);
  const offer = useSelector(offerSelector);
  const isAgent = useSelector(selectIsAgent);
  const isTooltipOpened = useSelector(getRemovedFromFavorites);
  const isCommercialOffer = useSelector(isCommercialSelector);
  const customUrl = useSelector(getOfferCustomUrl);
  const rosreestrInfo = useSelector(getRosreestrInfo);

  const addAction = React.useMemo(() => {
    switch (placement) {
      case 'gallery':
        return 'add_from_card_fullscreen_gallery';
      case 'navigation':
        return 'add_from_card_navi_block';
      default:
        return 'add_from_card_main_block';
    }
  }, [placement]);

  const removeAction = React.useMemo(() => {
    switch (placement) {
      case 'gallery':
        return 'remove_from_card_fullscreen_gallery';
      case 'navigation':
        return 'remove_from_card_navi_block';
      default:
        return 'remove_from_card_main_block';
    }
  }, [placement]);

  const handleChangeFavorite = React.useCallback(() => {
    const products = {
      extra: rosreestrInfo?.analyticsMeta
        ? ({
            rosreestrData: rosreestrInfo.analyticsMeta,
          } as IExtra)
        : undefined,
    } as ISoprProducts;

    trackFavoriteClick(checked ? removeAction : addAction, customUrl, products);

    dispatch(changeFavoriteAction());
  }, [addAction, removeAction, rosreestrInfo, checked, customUrl, dispatch]);

  const handleChangeFolderClick = React.useCallback(() => {
    dispatch(setRemovedFromFavorites(false));
    dispatch(showFavoritesFoldersList({ offerIds: [offer.cianId] }));
  }, [offer.cianId]);

  const handleCloseTooltip = React.useCallback(() => {
    dispatch(setRemovedFromFavorites(false));
  }, []);

  if (!isAgent || isCommercialOffer || newButton) {
    return (
      <FavoriteButton
        favoriteButton={<FavoriteButtonAsideContainer checked={checked} onButtonClick={handleChangeFavorite} />}
        pushSubscription={null}
      />
    );
  }

  return (
    <>
      <div ref={buttonGroupRef}>
        <FavoriteButtonsGroupContainer
          foldersCount={foldersCount}
          isFavorite={checked}
          offerId={offer.cianId}
          onClickFavorite={handleChangeFavorite}
        />
      </div>
      <RemovedFromFolderTooltip
        open={isTooltipOpened}
        anchorRef={buttonGroupRef}
        onChangeFolderClick={handleChangeFolderClick}
        onClose={handleCloseTooltip}
      />
    </>
  );
}
