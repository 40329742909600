import { Button, ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';
const successIcon = require('./assets/success.svg') as string;

export interface ISuccessStateProps {
  open: boolean;
  onClose(): void;
}

export const Success = ({ open, onClose }: ISuccessStateProps) => {
  return (
    <ModalWindow
      content={
        <div className={styles['container']}>
          <img src={successIcon} />
          <h1 className={styles['title']}>Анкета отправлена</h1>
          <div className={styles['text']}>Ответ собственника придёт вам в чат</div>
          <Button onClick={onClose}>Хорошо</Button>
        </div>
      }
      onClose={onClose}
      open={open}
    />
  );
};
