import * as React from 'react';
import { useSelector } from 'react-redux';

import { SubscriptionAuthorDignity } from '../../../components/subscriptionBrand';
import { selectAgentReviews } from '../../../selectors';
import { getAgentReviewsTitle } from '../../../utils/branding';
import { selectHasVerifiedDocuments } from '../../ApprovalContainer/selectors/selectHasVerifiedDocuments';

export const SubscriptionAuthorDignityContainer = () => {
  const hasVerifiedDocuments = useSelector(selectHasVerifiedDocuments);
  const { entityRate, totalCount: agentReviewsCount } = useSelector(selectAgentReviews);

  const agentReviewTitle = getAgentReviewsTitle(entityRate, agentReviewsCount);

  return <SubscriptionAuthorDignity agentReviewTitle={agentReviewTitle} hasVerifiedDocuments={hasVerifiedDocuments} />;
};
