import { Avatar, UIHeading1, UIText1, UIText2 } from '@cian/ui-kit';
import { IconProductDocumentsVerified16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { HOMEOWNER_PROOF_TEXTS } from 'shared/constants/homeownerProofTexts';
import { THomeownerProof } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as style from './style.css';

export interface IHomeownerLayoutProps {
  userName: string;
  avatar: string | null;
  homeownerProofs: THomeownerProof[] | null;
  contactButton: React.ReactElement | null;
  chatButton: React.ReactElement | null;
  phonesOpenedInfo: React.ReactElement;
  phonesShown: boolean;
  superAgentElement: React.ReactElement;
}

export const HomeownerInfo: React.FC<React.PropsWithChildren<IHomeownerLayoutProps>> = ({
  avatar,
  userName,
  homeownerProofs,
  contactButton,
  chatButton,
  phonesOpenedInfo,
  phonesShown,
  superAgentElement,
}) => {
  return (
    <div className={style['container']}>
      <div className={style['left']}>
        <div className={style['title']}>
          <UIText2 color="text-primary-default">Собственник</UIText2>
          <UIHeading1 color="text-primary-default">{userName}</UIHeading1>
          {superAgentElement && <div className={style['super-agent']}>{superAgentElement}</div>}
        </div>

        <ul className={style['proofs']}>
          {homeownerProofs?.map((proof, index) => (
            <li key={proof + index}>
              <IconProductDocumentsVerified16 color="icon-main-default" />
              <UIText1 color="text-primary-default">{HOMEOWNER_PROOF_TEXTS[proof]}</UIText1>
            </li>
          ))}
        </ul>

        <div className={style['buttons']}>
          {contactButton && <div className={style['button']}>{contactButton}</div>}

          {chatButton && <div className={style['button']}>{chatButton}</div>}
        </div>

        {phonesShown && <div className={style['phones-opened-info']}>{phonesOpenedInfo}</div>}
      </div>

      <div className={style['avatar']}>
        <Avatar size={80} src={avatar} />
      </div>
    </div>
  );
};
