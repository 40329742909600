import { IWorkTimeInfoSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

export const timeFormatter = (time: string) => time.slice(0, 5);

type TWorkTimeInfoSchema = Pick<IWorkTimeInfoSchema, 'callFrom' | 'callTo'>;

export const getWorkTime = (workTimeInfo?: TWorkTimeInfoSchema | null, baseText: string = '') => {
  if (!workTimeInfo || !workTimeInfo.callFrom || !workTimeInfo.callTo) {
    return null;
  }

  const callFrom = timeFormatter(workTimeInfo.callFrom);
  const callTo = timeFormatter(workTimeInfo.callTo);

  return `${baseText} c ${callFrom} до ${callTo}`.trim();
};
