import { IConfig } from '@cian/config/shared';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectIsChatsEnabled } from 'shared/selectors/offerChat';

import { chatAvailableSelector } from '../../selectors/newbuildingConsultant';
import { NewbuildingChatContainer } from '../NewbuildingChat';

interface IChatContainerProps {
  config: IConfig;
}

export function ChatContainer({ config }: IChatContainerProps) {
  const isNewbuildingChat = useSelector(chatAvailableSelector);
  const isChatEnabled = useSelector(selectIsChatsEnabled);

  if (isNewbuildingChat && !isChatEnabled) {
    return <NewbuildingChatContainer config={config} />;
  }

  return null;
}
