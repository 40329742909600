import { Input } from '@cian/ui-kit';
import { InputAdornmentButton } from '@cian/ui-kit/inputAdornment';
import { useDeviceType } from '@cian/ui-kit/responsive';
import { TInputSize } from '@cian/ui-kit/types';
import { IconActionPlus16, IconActionMinus16 } from '@cian/ui-kit-design-tokens/icons';
import cx from 'clsx';
import * as React from 'react';

import { extractNumber } from 'shared/utils/extractNumber';

import * as styles from './NumberInputWithButtons.css';

export interface INumberInputWithButtonsProps {
  value: number;
  size?: TInputSize;
  min?: number;
  max?: number;
  disabled?: boolean;
  onChange(value: number): void;
  valueFormatter?(value: number): string;
}

const DEFAULT_MIN = Number.MIN_SAFE_INTEGER;
const DEFAULT_MAX = Number.MAX_SAFE_INTEGER;

export const NumberInputWithButtons: React.FC<INumberInputWithButtonsProps> = ({
  size,
  value,
  disabled,
  min: passedMin,
  max: passedMax,
  onChange,
  valueFormatter,
}) => {
  const [focused, setFocused] = React.useState(false);
  /** Стейт для поддержания ручного ввода, а не ввода кнопками "+" и "-" */
  const [manualModeValue, setManualModeValue] = React.useState('');

  const min = passedMin !== undefined ? passedMin : DEFAULT_MIN;
  const max = passedMax !== undefined ? passedMax : DEFAULT_MAX;

  const decrement = React.useCallback(() => onChange(Math.max(min, value - 1)), [min, value, onChange]);
  const increment = React.useCallback(() => onChange(Math.min(max, value + 1)), [max, value, onChange]);

  const defaultValueFormatter = React.useCallback((value: number) => value.toString(), []);

  /** Когда чел хочет сам ввести цифру, переводим инпут в режим ручного ввода */
  const handleFocus = React.useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    setManualModeValue(extractNumber(e.target.value).toString());
    setFocused(true);
  }, []);

  /** Обрабатываем пользовательский ввод, используем отдельную стейт-переменную для ручного ввода */
  const handleChange = React.useCallback(
    (_e: React.ChangeEvent<HTMLInputElement>, value: string) => setManualModeValue(value),
    [],
  );

  /** Когда чел ввел свое число и перевел фокус, возвращаем инпут в базовый режим с форматированием (например, 2 -> 2 гостя) */
  const handleBlur = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      onChange(Math.max(min, Math.min(max, extractNumber(e.target.value))));
      setManualModeValue('');
      setFocused(false);
    },
    [onChange],
  );

  /** По умолчанию переводит число в строку. Можно передать свою функцию для форматирования (например, 2 -> 2 гостя) */
  const formatter = valueFormatter || defaultValueFormatter;

  const deviceType = useDeviceType();

  return (
    <div className={cx(styles['container'], deviceType === 'phone' && styles['container-phone'])}>
      <Input
        value={focused ? manualModeValue : formatter(value)}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        min={min}
        max={max}
        size={size}
        disabled={disabled}
        leftAdornment={
          <InputAdornmentButton onClick={decrement} disabled={value <= min}>
            <IconActionMinus16 color={value <= min ? 'icon-secondary-default' : 'icon-main-default'} />
          </InputAdornmentButton>
        }
        rightAdornment={
          <InputAdornmentButton onClick={increment} disabled={value >= max}>
            <IconActionPlus16 color={value >= max ? 'icon-secondary-default' : 'icon-main-default'} />
          </InputAdornmentButton>
        }
      />
    </div>
  );
};
