import { ECalendarValidationError } from '@cian/frontend-dailyrent-calendar';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  calendarDatesChange,
  openBookingCalendar,
  setDates,
  setError,
  toggleCalendarModal,
} from 'shared/actions/dailyrentBookingCalendar';
import {
  DEFAULT_INPUT_TEXT,
  deleteDailyrentQueryParams,
  formatDate,
  getNormalizedInitialDates,
} from 'shared/containers/BookingCalendarInputContainer/helpers';
import { selectOfferId } from 'shared/selectors';
import {
  selectDailyrentBookingCalendarAvailabilityLoading,
  selectDailyrentBookingCalendarCanBeBooked,
  selectDailyrentBookingCalendarDateInputText,
  selectDailyrentBookingCalendarDates,
  selectDailyrentBookingCalendarDaysAvailability,
  selectDailyrentBookingCalendarError,
  selectDailyrentBookingCalendarInitialLoading,
  selectDailyrentBookingCalendarOpen,
} from 'shared/selectors/dailyrentBookingCalendar';
import {
  trackCalendarInputClick,
  trackCalendarInputShow,
  trackCalendarOpen,
  trackCalendarReset,
  trackMinimumDurationHint,
} from 'shared/tracking/dailyrentBookingCalendar';

const minDate = new Date();
const onApply = () => {};

export function useBookingCalendarContainer() {
  const dispatch = useDispatch();
  const offerId = useSelector(selectOfferId);
  const isCalendarOpen = useSelector(selectDailyrentBookingCalendarOpen);
  const canBeBooked = useSelector(selectDailyrentBookingCalendarCanBeBooked);
  const error = useSelector(selectDailyrentBookingCalendarError);

  const daysAvailability = useSelector(selectDailyrentBookingCalendarDaysAvailability);
  const dates = useSelector(selectDailyrentBookingCalendarDates);
  const { normalizedInitialStart, normalizedInitialEnd } = getNormalizedInitialDates(minDate, dates, daysAvailability);

  const initialLoading = useSelector(selectDailyrentBookingCalendarInitialLoading);
  const availabilityLoading = useSelector(selectDailyrentBookingCalendarAvailabilityLoading);
  const loading = initialLoading || availabilityLoading;

  const dateInputText = useSelector(selectDailyrentBookingCalendarDateInputText);
  const inputText = dateInputText || DEFAULT_INPUT_TEXT;
  const isDefaultInputText = inputText === DEFAULT_INPUT_TEXT;

  const onShow = React.useCallback(() => {
    trackCalendarOpen(offerId);
  }, [offerId]);

  const onChange = React.useCallback(
    async (start: Date | undefined, end: Date | undefined) => {
      if (!start || !end) {
        return;
      }

      const formattedFrom = formatDate(start);
      const formattedTo = formatDate(end);

      dispatch(calendarDatesChange({ dates: { start: formattedFrom, end: formattedTo } }));
    },
    [offerId],
  );

  const onReset = React.useCallback(() => {
    trackCalendarReset(offerId);
  }, [offerId]);

  const onError = React.useCallback(
    (errorType: ECalendarValidationError) => {
      if (errorType === ECalendarValidationError.MIN_RANGE) {
        trackMinimumDurationHint(offerId);
      }
    },
    [offerId],
  );

  const onInputClick = React.useCallback(() => {
    if (!isCalendarOpen) {
      dispatch(openBookingCalendar());
      trackCalendarInputClick(offerId);

      return;
    }

    dispatch(setError(''));
    dispatch(toggleCalendarModal(false));
  }, [isCalendarOpen, offerId]);

  const onOutsideClick = React.useCallback(() => {
    dispatch(setError(''));
    dispatch(toggleCalendarModal(false));
  }, []);

  // если при загрузке карточки с бэка пришло, что заданные даты недоступны,
  // убираем их из урлы и зануляем в сторе
  React.useEffect(() => {
    if (!canBeBooked) {
      const newUrl = deleteDailyrentQueryParams(window.location.href, ['checkin', 'checkout']);
      history.replaceState(undefined, '', newUrl);
      dispatch(setDates({ from: undefined, to: undefined }));
    }

    trackCalendarInputShow(offerId);
  }, []);

  return React.useMemo(
    () => ({
      isRedesign: true,
      isOpen: isCalendarOpen,
      isInitialLoading: initialLoading,
      isSelectedDatesInfoLoading: loading,
      globalError: false,
      externalError: error,
      initialDates: {
        from: normalizedInitialStart,
        to: normalizedInitialEnd,
      },
      daysAvailability,
      onChange,
      onApply,
      onError,
      onShow,
      onReset,
      inputText,
      isDefaultInputText,
      onInputClick,
      onOutsideClick,
    }),
    [
      isCalendarOpen,
      initialLoading,
      loading,
      error,
      dates,
      daysAvailability,
      onChange,
      onApply,
      onError,
      onShow,
      onReset,
      inputText,
      isDefaultInputText,
      onInputClick,
      onOutsideClick,
    ],
  );
}
