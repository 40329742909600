import { ECoworkingOfferType } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { pluralize } from './pluralize';

export function getFormattedCoworkingTitle({
  title,
  squareInformation,
  workplaceCount,
  coworkingOfferType,
}: {
  title: string;
  squareInformation: string;
  workplaceCount?: number | null;
  coworkingOfferType: ECoworkingOfferType;
}) {
  switch (coworkingOfferType) {
    case ECoworkingOfferType.Office: {
      const workplaces = workplaceCount ? `на ${workplaceCount} чел.` : '';

      return [title, squareInformation, workplaces].filter(Boolean).join(' ');
    }

    case ECoworkingOfferType.FixedWorkplace: {
      if (workplaceCount) {
        return pluralize(workplaceCount, [
          'закреплённое рабочее место',
          'закреплённых рабочих места',
          'закреплённых рабочих мест',
        ]);
      }

      return title;
    }

    case ECoworkingOfferType.FlexibleWorkplace: {
      if (workplaceCount) {
        return pluralize(workplaceCount, [
          'незакреплённое рабочее место',
          'незакреплённых рабочих места',
          'незакреплённых рабочих мест',
        ]);
      }

      return title;
    }

    default:
      return title;
  }
}
