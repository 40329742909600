import { createSelector } from '@reduxjs/toolkit';

import { selectExternalTourBannerImageUrl } from './selectExternalTourBannerImageUrl';
import { selectTourV2BannerImageUrlV2 } from './selectTourV2BannerImageUrl';
import { selectOfferPhotos } from '../offer';

export const selectTourBannerImageUrl = createSelector(
  selectTourV2BannerImageUrlV2,
  selectExternalTourBannerImageUrl,
  selectOfferPhotos,
  (tourBannerImageUrlV2, externalUrl, offerPhotos) => {
    const mainPhoto = offerPhotos?.find(photo => photo.isDefault)?.fullUrl;

    return mainPhoto || tourBannerImageUrlV2 || externalUrl;
  },
);
