import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

/**
 * Проверяем входит ли стоимость объекта в заданные интервалы из админки adfox,
 * для передачи правильного значения в puid6
 *
 * @param price стоимость объекта
 */
/**

* Значения взяты из админки adfox
*/
const BANNER_TARGET_INTERVALS = [
  '0_990000',
  '1000000_2990000',
  '3000000_4990000',
  '5000000_6990000',
  '7000000_9990000',
  '10000000_14900000',
  '15000000_19900000',
  '20000000_24900000',
  '25000000_29900000',
  '30000000_39900000',
  '40000000_49900000',
  '50000000_max',
];

//фикс проблемы попадания между диапазонами, раньше в таком случае был всегда интервал 50000000_max
export function calculateGap(range1: string, range2: string): number {
  const min = Number(range1.split('_')[1]);
  const max = Number(range2.split('_')[0]);

  return max - min;
}

export function getSpecialPriceTargetPuidForAdFox(price: number): string {
  const interval = BANNER_TARGET_INTERVALS.find((bannerTargetInterval, index, array) => {
    const [min, max] = bannerTargetInterval.split('_');
    const gap = Number(max) ? calculateGap(bannerTargetInterval, array[index + 1]) : 0;

    return isNaN(Number(max)) || (price >= Number(min) - gap && price <= Number(max));
  });

  return interval ? interval : '';
}

const BANNER_RENT_TARGET_INTERVALS = [
  '0_9900',
  '10000_19900',
  '20000_29900',
  '30000_39900',
  '40000_49900',
  '50000_59900',
  '60000_69000',
  '70000_79900',
  '80000_89900',
  '90000_99900',
  '100000_199000',
  '200000_299000',
  '300000_399000',
  '400000_499000',
  '500000_599000',
  '600000_699000',
  '700000_799000',
  '800000_899000',
  '900000_max',
];

export function getSpecialRentPriceTargetPuidForAdFox(price: number): string {
  const interval = BANNER_RENT_TARGET_INTERVALS.find((bannerTargetInterval, index, array) => {
    const [min, max] = bannerTargetInterval.split('_');
    const gap = Number(max) ? calculateGap(bannerTargetInterval, array[index + 1]) : 0;

    return isNaN(Number(max)) || (price >= Number(min) - gap && price <= Number(max));
  });

  return interval ? interval : '';
}

export function getPuidAreaTarget(totalArea: string | null): string | undefined {
  if (totalArea) {
    const area = Number(totalArea);

    if (area >= 10 && area < 20) {
      return '10_20';
    } else if (area >= 20 && area < 40) {
      return '20_40';
    } else if (area >= 40 && area < 65) {
      return '40_65';
    } else if (area >= 65 && area < 80) {
      return '65_80';
    } else if (area >= 80 && area < 100) {
      return '80_100';
    } else if (area >= 100) {
      return '100_max';
    }
  }

  return undefined;
}

export function getRailwayAndHighwaysPuids(offer: IOfferSchema): { [key: string]: string } | undefined {
  if (offer.offerType === 'suburban' && offer.geo.highways) {
    return {
      puid35: offer.geo.highways.map(highway => highway.id).join(':'),
    };
  }

  return undefined;
}
