import * as React from 'react';
import { useSelector } from 'react-redux';

import { ObjectFactoids } from '../../components/ObjectFactoids/ObjectFactoids';
import { ObjectFactoidsItem } from '../../components/ObjectFactoids/ObjectFactoidsItem';
import { selectFactoids } from '../../selectors/offerData/selectFactoids';

export const ObjectFactoidsContainer = () => {
  const factoids = useSelector(selectFactoids);

  return (
    <ObjectFactoids>
      {factoids.map(item => (
        <ObjectFactoidsItem key={item.title} title={item.title} icon={item.icon} value={item.value} note={item.note} />
      ))}
    </ObjectFactoids>
  );
};
