import { Button, ModalWindow } from '@cian/ui-kit';
import cx from 'clsx';
import { FormikProps } from 'formik';
import * as React from 'react';

import { Error, Phone } from 'shared/components/DealRentModal/Fields';
import { ISaveFormRequest } from 'shared/repositories/deal-rent-announcements/v1/save-form';

import * as styles from './styles.css';

export interface IThirdStepStateProps {
  open: boolean;
  initialFormState: ISaveFormRequest;
  error: string | null;
  isLoading: boolean;
  phoneConfirmed: boolean;
  onClickBack(): void;
  onClose(): void;
  changePhoneConfirmed(value: boolean): void;
}

export interface IThirdStepProps extends IThirdStepStateProps, FormikProps<ISaveFormRequest> {}

export const ThirdStep = ({
  open,
  onClose,
  resetForm,
  submitForm,
  handleSubmit,
  isLoading,
  error,
  changePhoneConfirmed,
  values,
  onClickBack,
}: IThirdStepProps) => {
  const onCloseModal = React.useCallback(() => {
    resetForm();
    onClose();
  }, [resetForm, onClose]);

  const [phone, setPhone] = React.useState(values.phone);

  React.useEffect(() => {
    if (!values.phone) {
      return;
    }
    if (values.phone !== phone) {
      changePhoneConfirmed(false);
    } else {
      setPhone(values.phone);
    }
  }, [values.phone, phone, setPhone]);

  const handleClickBack = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      onClickBack();
    },
    [onClickBack],
  );

  return (
    <ModalWindow
      title="Оставьте телефон для связи — его увидит только собственник"
      content={
        <form className={styles['container']} onSubmit={handleSubmit}>
          <div className={cx(styles['sms-container'], styles['span-columns'])}>
            <Phone />
          </div>
        </form>
      }
      footer={
        <>
          <Error error={error} />
          <div className={styles['buttons-wrap']}>
            <Button theme={'fill_secondary'} onClick={handleClickBack} size="XS">
              Назад
            </Button>
            <Button disabled={isLoading} loading={isLoading} onClick={submitForm} size="XS">
              Дальше
            </Button>
          </div>
        </>
      }
      onClose={onCloseModal}
      open={open}
      maxWidth={500}
      maxHeight={372}
    />
  );
};
