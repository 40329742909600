import { ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import { isArrayWithItems } from 'shared/utils';
import { ILegendItem } from 'shared/utils/legend';

import * as styles from './index.css';

export interface ILegendModalProps {
  isOpen: boolean;
  legendData: ILegendItem[];

  close(): void;
}

export class LegendModal extends React.PureComponent<ILegendModalProps, {}> {
  public render() {
    const { legendData } = this.props;

    if (!isArrayWithItems(legendData)) {
      return null;
    }

    return (
      <ModalWindow
        open={this.props.isOpen}
        onClose={this.close}
        content={
          <>
            <ul className={styles['list']}>
              {legendData.map(item => (
                <li
                  key={item.color}
                  className={styles['item']}
                  style={{
                    borderColor: item.color,
                  }}
                >
                  {item.title}
                </li>
              ))}
            </ul>
            <button className={styles['close']} onClick={this.close}>
              Закрыть
            </button>
          </>
        }
      />
    );
  }

  private close = () => {
    this.props.close();
  };
}
