import THUMB_SRC from '../createMortgageFrame/img/thumb.svg';

export function createFinMortgageFrame(mortgageGalleryWidgetMarkup: string) {
  if (
    document.querySelectorAll('script[src="https://cdn.cian.site/finance/offer-fotorama-frame/widget.js"]').length === 0
  ) {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.cian.site/finance/offer-fotorama-frame/widget.js';
    document.head.appendChild(script);
  }

  return {
    id: 'mortgage-frame',
    html: mortgageGalleryWidgetMarkup,
    thumb: THUMB_SRC,
  };
}
