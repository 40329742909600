import { createSelector } from '@reduxjs/toolkit';

import { offerPhonesSelector } from './offerPhonesSelector';
import { getFormattedPhoneNumber } from '../../../utils/formatters';

export const offerPreparedPhonesSelector = createSelector(offerPhonesSelector, phones => {
  return phones.reduce((accumulator, phone) => {
    const formattedPhoneNumber = getFormattedPhoneNumber(phone);

    if (formattedPhoneNumber) {
      accumulator.push(formattedPhoneNumber);
    }

    return accumulator;
  }, Array.of<string>());
});
