import { TContactType } from '@cian/contact-card-component';
import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectHasVerifiedDocuments } from 'shared/containers/ApprovalContainer/selectors/selectHasVerifiedDocuments';
import { SuperAgentLevelViewContainer } from 'shared/containers/SuperAgentLevelViewContainer';
import { IOfferSchema } from 'shared/repositories/offer-card/entities/offer/OfferSchema';
import { CommercialOwner } from 'shared/utils/agent/CommercialOwner';

import { AgentInfoLayout, AgentProofsTitle, AgentReviewsTitle } from '../../../../components/AuthorAsideNew';
import {
  offerSelector,
  selectAgentName,
  selectAgentReviews,
  selectIsAgencyBrandingAvailable,
  selectIsByHomeowner,
  selectOfferAgent,
} from '../../../../selectors';
import { getIsFromBuilder } from '../../../../selectors/newObject';
import { selectAgentUrl, selectIsSubAgent } from '../../../../selectors/offerData/agent';
import { getAuthorLabelV2 } from '../../../../utils/agent';
import { getAgentReviewsTitle } from '../../../../utils/branding';
import { getAgentType } from '../../../../utils/getAgentType';
import { getProofMessage } from '../../../../utils/getProofMessage';
import { showCountrysideBuilderBadge } from '../../../../utils/suburban';

export const AgentInfo: React.FC = () => {
  const isByHomeowner = useSelector(selectIsByHomeowner);
  const isFromBuilder = useSelector(getIsFromBuilder);
  const isShowCountrysideBuilderBadge = useSelector(showCountrysideBuilderBadge);
  const isBrandingAvailable = useSelector(selectIsAgencyBrandingAvailable);
  const offer = useSelector(offerSelector);
  const agent = useSelector(selectOfferAgent, shallowEqual);
  const agentName = useSelector(selectAgentName);
  const agentLink = useSelector(selectAgentUrl);
  const isSubAgent = useSelector(selectIsSubAgent);
  const { entityRate, totalCount } = useSelector(selectAgentReviews);
  const isCommercialHonestWork = useSelector(selectHasVerifiedDocuments);

  const avatarType: TContactType = isSubAgent ? 'agent' : 'agency';
  const title = agent && getAuthorLabelV2(offer, agent, isShowCountrysideBuilderBadge);
  const agentReviewTitle = getAgentReviewsTitle(entityRate, totalCount);
  const avatar = agent && agent.absoluteAvatarUrl;
  const agentType = agent && getAgentType(agent);
  const commercialOwner = agent && CommercialOwner.getInstanceIfIsCommercial(offer as unknown as IOfferSchema, agent);

  const message = (() => {
    if (!agentType) {
      return null;
    }

    if (isCommercialHonestWork) {
      return 'Документы проверены';
    }

    if (commercialOwner?.shield.showRosreestrVerified) {
      return 'Проверено в Росреестре';
    }

    return agent.isHonest && getProofMessage(agentType);
  })();

  if (!agent || isByHomeowner || isBrandingAvailable || isFromBuilder) {
    return null;
  }

  return (
    <AgentInfoLayout
      avatarType={avatarType}
      avatar={avatar}
      title={title}
      agentLink={agentLink}
      agentName={agentName}
      agentReviews={agentReviewTitle ? <AgentReviewsTitle title={agentReviewTitle} link={agentLink} /> : null}
      agentProofs={message ? <AgentProofsTitle title={message} /> : null}
      superAgentElement={<SuperAgentLevelViewContainer />}
    />
  );
};
