import * as React from 'react';

import { IPaymentSummarySchema } from 'shared/repositories/dailyrent/entities/payment_summary/PaymentSummarySchema';

import * as styles from './BookingInfo.css';
import { Sections } from './components';

interface IBookingInfoConstructorProps {
  paymentSummary: IPaymentSummarySchema;
}

export const BookingInfo = ({ paymentSummary }: IBookingInfoConstructorProps) => {
  return (
    <div className={styles['block']}>
      <Sections>{[paymentSummary]}</Sections>
    </div>
  );
};
