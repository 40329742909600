import { IconActionCompareAdd16, IconActionCompareAdded16 } from '@cian/ui-kit-design-tokens/icons';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';

interface IComparisonControlProps {
  added: boolean;
  disabled: boolean;
  onClick(): void;
}

export function ComparisonControl({ added, disabled, onClick }: IComparisonControlProps) {
  return (
    <div>
      <button onClick={onClick} disabled={disabled} className={cx(styles['button'], disabled && styles['disabled'])}>
        {added ? (
          <IconActionCompareAdded16 color="icon-main-default" />
        ) : (
          <IconActionCompareAdd16 color={disabled ? 'gray60_100' : 'icon-main-default'} />
        )}
      </button>
    </div>
  );
}
