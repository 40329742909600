import * as React from 'react';

import * as styles from './styles/styles.css';
import { Builder } from '../../../../icons';
import { AgentType } from '../AgentType';

interface IAgentInfoProps {
  name: string | null;
  avatarUrl: string | null;
  firstName: string | null;
  isFromBuilder: boolean | null;
  isFromBuilderPresenter?: boolean;
  isFromLeadFactory?: boolean | null;
}

export function AgentInfo({
  avatarUrl,
  firstName,
  name,
  isFromBuilder,
  isFromBuilderPresenter,
  isFromLeadFactory,
}: IAgentInfoProps) {
  const title = isFromBuilder ? 'Застройщик' : isFromBuilderPresenter ? 'Представитель застройщика' : '';

  return (
    <div className={styles['agent_info']}>
      {avatarUrl && (
        <div className={styles['avatar']}>
          <img className={styles['avatar-img']} src={avatarUrl} alt="Аватар агента" />
        </div>
      )}

      <div className={styles['titles']}>
        <div className={styles['title']}>{name || firstName}</div>

        {(isFromBuilder || isFromBuilderPresenter) && (
          <AgentType Icon={isFromLeadFactory ? null : <Builder />} title={isFromLeadFactory ? 'Консультант' : title} />
        )}
      </div>
    </div>
  );
}
