import * as React from 'react';

export interface ITourProps {
  frameRef?: React.Ref<HTMLIFrameElement>;
  tourUrl: string;
}

export const TourFrame = React.forwardRef<HTMLIFrameElement, ITourProps>(({ tourUrl }, ref) => {
  return (
    <iframe
      data-name="TourIFrame"
      data-testid="TourIFrame"
      ref={ref}
      frameBorder={0}
      src={tourUrl}
      width="100%"
      height="100%"
      allowFullScreen
      sandbox="allow-scripts allow-same-origin allow-popups allow-downloads"
    />
  );
});

TourFrame.displayName = 'TourFrame';
