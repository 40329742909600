import * as React from 'react';

import { Link } from 'shared/common/components/link';

import * as styles from './styles.css';

export interface IMessageWithLinkProps {
  lkUrl: string;
}

export class MessageWithLink extends React.PureComponent<IMessageWithLinkProps, {}> {
  public render() {
    const { lkUrl } = this.props;

    return (
      <>
        Объявление скрыто из результатов поиска. Вы можете восстановить объявление в{' '}
        <Link href={lkUrl} target="_blank" className={styles['popup-link']}>
          личном кабинете
        </Link>
      </>
    );
  }
}
