import { Outside, Button } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';
import {
  trackClickOnDocxButton as trackDocxButtonClick,
  trackClickOnMainButton as trackMainButtonClick,
  trackClickOnPDFButton as trackPDFButtonClick,
} from '../export_button/tracking';

export interface IExportButtonNewProps {
  pdfUrl: string;
  docxUrl: string;
}

export function ExportButtonNew({ pdfUrl, docxUrl }: IExportButtonNewProps) {
  const [open, setOpen] = React.useState(false);

  const clickButtonHandle = React.useCallback(() => {
    trackMainButtonClick();
    setOpen(!open);
  }, [open]);

  const closePopup = () => {
    setOpen(false);
  };

  const clickDocxButtonHandle = () => {
    closePopup();
    trackDocxButtonClick();
  };

  const clickPdfButtonHandle = () => {
    closePopup();
    trackPDFButtonClick();
  };

  return (
    <Outside onOutside={closePopup}>
      <div className={styles['container']}>
        <Button
          theme="stroke_secondary"
          beforeIcon={<span className={styles['icon']} />}
          onClick={clickButtonHandle}
          title="Экспорт"
          size="XS"
        />
        <div className={cx(styles['popup'], open && styles['popup--open'])}>
          <div className={styles['link-container']}>
            <a download className={styles['link']} href={pdfUrl} onClick={clickPdfButtonHandle}>
              Скачать в PDF
            </a>
            <a download className={styles['link']} href={docxUrl} onClick={clickDocxButtonHandle}>
              Скачать в .docx
            </a>
          </div>
        </div>
      </div>
    </Outside>
  );
}
