import {
  DynamicCalltrackingButton as CalltrackingButton,
  EPageTypes,
  EPlatformTypes,
} from '@cian/frontend-dynamic-calltracking-component';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './DynamicCalltrackingButton.css';

export interface IDynamicCalltrackingButtonProps {
  buttonText: string;
  isOpen: boolean;
  offerId: number;
  onClick?(): void;
  phone: string;
  placeType: string;
  siteBlockId: number | null;
  size?: 'xs' | 'm';
}

export function DynamicCalltrackingButton({
  buttonText,
  isOpen,
  offerId,
  onClick,
  phone,
  placeType,
  siteBlockId,
  size = 'm',
}: IDynamicCalltrackingButtonProps) {
  return (
    <CalltrackingButton
      onClick={onClick}
      className={cx(styles['show-phone'], styles[`show-phone-${size}`])}
      openPhoneClassName={cx(styles['phone'], styles[`phone-size-${size}`])}
      isOpen={isOpen}
      phone={phone}
      announcementId={offerId}
      blockId={siteBlockId}
      placeType={placeType}
      platformType={EPlatformTypes.WebDesktop}
      pageType={EPageTypes.OfferCard}
    >
      {buttonText}
    </CalltrackingButton>
  );
}
