import { IState } from '../../store';
import { isNewbuildingSelector, isFromBuilderSelector } from '../offerData';

export function accountNameSelector(state: IState) {
  return state.newbuildingConsultant.accountName;
}

export function chatHostSelector(state: IState) {
  return state.newbuildingConsultant.host;
}

export function chatAvailableSelector(state: IState): boolean {
  return !!accountNameSelector(state) && isNewbuildingSelector(state) && isFromBuilderSelector(state);
}
