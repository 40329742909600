import * as React from 'react';

import { PhonesContainer } from 'shared/containers/BottomAuthorBlockNew/PhonesContainer';
import { ElectronicTradingContactButtonContainer } from 'shared/containers/ElectronicTradingContactButtonContainer';
import { useIsElectronicTradingOffer } from 'shared/hooks/useIsElectronicTradingOffer';
import { useRenderContactButtons } from 'shared/hooks/useRenderContactButtons';

interface IContactButtonContainerProps {
  isPhonesOpened: boolean;
  electronicTradingTestId?: string;
  handlePhonesOpen: () => void;
}

export const ContactButtonContainer = ({
  isPhonesOpened,
  electronicTradingTestId = 'electronic-trading-button',
  handlePhonesOpen,
}: IContactButtonContainerProps) => {
  const isElectronicTradingOffer = useIsElectronicTradingOffer();
  const { showPhoneButton } = useRenderContactButtons();

  if (!showPhoneButton) {
    return null;
  }

  if (isElectronicTradingOffer) {
    return <ElectronicTradingContactButtonContainer buttonSize="XS" dataTestId={electronicTradingTestId} />;
  }

  return (
    <PhonesContainer
      trackingAction="Open_card_agent_block"
      size="XS"
      phonesShown={isPhonesOpened}
      onPhoneClick={handlePhonesOpen}
    />
  );
};
