import { Regions } from '../constants';

export type TCommuteType = 'МКАД' | 'КАД' | 'центра';

export const getCommuteTypeByRegionId = (regionId: number): TCommuteType => {
  switch (regionId) {
    case Regions.Moscow:
    case Regions.MoscowArea:
      return 'МКАД';
    case Regions.Spb:
    case Regions.SpbArea:
      return 'КАД';
    default:
      return 'центра';
  }
};
