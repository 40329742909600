import * as React from 'react';

import * as styles from './SubscriptionAuthorTitle.css';
import { ISubscriptionAuthorTitleProps } from './types';

export const SubscriptionAuthorTitle = (props: ISubscriptionAuthorTitleProps) => {
  const { title, agency } = props;

  return (
    <div className={styles['title']}>
      {title}
      {agency}
    </div>
  );
};
