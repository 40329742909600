import { ca } from '@cian/analytics';

export function trackPopupLimit() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Compare',
    action: 'show_popup_limit',
    useLastProducts: true,
  });
}
