import * as React from 'react';

import * as styles from './textWithLink.css';
import { trackLeaveFeedbackClick } from '../../tracking';

interface IWarningTextWithLinkProps {
  onClick(): void;
}

export function WarningTextWithLink({ onClick }: IWarningTextWithLinkProps) {
  const handleClick = () => {
    trackLeaveFeedbackClick();

    onClick();
  };

  return (
    <>
      <button type="button" className={styles['button']} onClick={handleClick}>
        Расскажите
      </button>
      , подтвердилась ли информация в&nbsp;объявлении
    </>
  );
}
