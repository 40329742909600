import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectOfferCategory } from '../../../selectors';
import { selectOfferValuationData } from '../../../store/offerValuation';

export const usePricesBlockData = () => {
  const offerValuationData = useSelector(selectOfferValuationData);
  const offerCategory = useSelector(selectOfferCategory);

  return React.useMemo(() => {
    if (!offerValuationData || !offerValuationData.priceInfo) {
      return null;
    }

    const isSuburbanEstimation = ['landSale', 'houseSale', 'cottageSale'].includes(offerCategory);

    const { offerPrice, estimation, estimationRange, infoUrl, priceTag } = offerValuationData.priceInfo;

    return {
      offerPrice,
      estimationPrice: estimation,
      estimationLabel: 'Оценка Циан',
      estimationRange,
      offerLabel: `Цена в объявлении ${
        isSuburbanEstimation && priceTag.priceLabel ? priceTag.priceLabel : ''
      }`.trimEnd(),
      infoUrl,
      priceTagInfo:
        priceTag.priceLabel && !isSuburbanEstimation ? { ...priceTag, priceLabel: priceTag.priceLabel } : null,
    };
  }, [offerCategory, offerValuationData]);
};
