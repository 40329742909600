import * as React from 'react';

export const IconPremium = (props: React.HTMLAttributes<SVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    {/* tslint:disable-next-line:max-line-length */}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.3 5.7l-4.6 4.6v3.2H5.4l-.7-1.1.3-.8-1.4-1.4.6.6-.6 1.7-.6-1.8-1.7-.6L3 9.6l-.9-.9-.8.3-1.1-.7V7h3.1L8 2.3s4.2-2.5 5-1.7c.8.9-1.7 5.1-1.7 5.1zm-.7-2.6c-.3-.3-1.3.2-1.8.6-.4.5-.6 1.1-.3 1.4.3.3.9.2 1.4-.3.5-.4 1-1.4.7-1.7zm-9.3 9.3l1.7.5-.6.6H.2v-2.2l.6-.6.5 1.7z"
    />
  </svg>
);
