import { Button } from '@cian/ui-kit';
import { IconFunctionalWarningSign16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './styles.css';

export interface IComplaintButtonNewProps {
  modal: React.ReactNode;
  onModalOpen(): void;
}

export function ComplaintButtonNew({ modal, onModalOpen }: IComplaintButtonNewProps) {
  return (
    <span data-name="ComplaintButtonNew" className={styles['container']}>
      <Button
        theme="stroke_secondary"
        beforeIcon={<IconFunctionalWarningSign16 color="icon-secondary-default" />}
        onClick={onModalOpen}
        size="XS"
      >
        Пожаловаться
      </Button>
      {modal}
    </span>
  );
}
