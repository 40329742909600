import { ca } from '@cian/analytics';

export function trackClickOnMainButton() {
  ca('event', {
    action: 'export_ads_main_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}

export function trackClickOnPDFButton() {
  ca('event', {
    action: 'download_pdf_main_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}

export function trackClickOnDocxButton() {
  ca('event', {
    action: 'download_docx_main_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}
