import { clsx } from 'clsx';
import * as React from 'react';

import * as styles from './sendMessageButton.css';

interface ISendMessageButtonProps {
  builderButton?: boolean;
}

export const SendMessageButton = (props: React.PropsWithChildren<ISendMessageButtonProps>) => {
  const { builderButton, children } = props;

  return <div className={clsx(styles['button'], builderButton && styles['button--builder'])}>{children}</div>;
};
