import { useEffect, useMemo } from 'react';

import { SimpleBotDetector } from '../utils/simpleBotDetector';

export function useSimpleBotDetector(): SimpleBotDetector {
  const simpleBotDetector = useMemo<SimpleBotDetector>(() => new SimpleBotDetector(), []);

  useEffect(() => {
    simpleBotDetector.init();

    return () => simpleBotDetector.destroy();
  }, [simpleBotDetector]);

  return simpleBotDetector;
}
