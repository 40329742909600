import * as React from 'react';

interface IPriceHistoryUpIcon {
  className?: string;
}

export function PriceHistoryUpIcon(props: IPriceHistoryUpIcon) {
  const className = props.className || '';

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 9 12">
      <path
        fill="#FF5050"
        fillRule="evenodd"
        d="M5.121.35l3.78 6.358c.201.336.085.768-.258.965a.73.73 0 0 1-.362.096H.719A.712.712 0 0 1 0 7.063c0-.125.034-.247.098-.355L3.878.35a.727.727 0 0 1 1.243 0zM2.343 7.769h4.314v3.526c0 .39-.322.705-.72.705H3.063a.712.712 0 0 1-.719-.705V7.769z"
      />
    </svg>
  );
}
