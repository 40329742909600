import { ca } from '@cian/analytics';

export function trackNewbuildingStretchBannerClick({
  offerId,
  builderId,
}: {
  offerId: number;
  builderId: number | null;
}) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card',
    action: 'click_card_builder',
    label: 'premium_header',
    products: [{ id: offerId, offerType: 'offer', extra: { to_object: builderId } }],
    page: {
      extra: {
        is_premium: true,
      },
    },
  });
}
