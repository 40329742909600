import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackClickShowPhone } from 'shared/components/author/tracking';
import { PhonesOpenedInfoContainer } from 'shared/containers/BottomAuthorBlockNew/PhonesOpenedInfoContainer/PhonesOpenedInfoContainer';
import { ElectronicTradingContactButtonContainer } from 'shared/containers/ElectronicTradingContactButtonContainer';
import { useChats } from 'shared/hooks/useChats';
import { getRosreestrInfo } from 'shared/selectors/offerData/getRosreestrInfo';
import { selectKpId } from 'shared/selectors/offerData/kp';
import { selectAgent } from 'shared/selectors/selectAgent';
import { SimpleBotDetector } from 'shared/utils/simpleBotDetector';

import { SubscriptionAuthorActions } from '../../../components/subscriptionBrand';
import { useIsElectronicTradingOffer } from '../../../hooks/useIsElectronicTradingOffer';
import { selectIsOfferOwnedByUser, offerSelector, selectABGroup, selectUser } from '../../../selectors';
import { selectIsChatsEnabled, selectIsOfferChatExists } from '../../../selectors/offerChat';
import { offerPreparedPhonesSelector } from '../../../selectors/offerData/offer/offerPreparedPhonesSelector';

export const SubscriptionAuthorActionsContainer = () => {
  const isOfferChatExists = useSelector(selectIsOfferChatExists);
  const isOfferOwnedByUser = useSelector(selectIsOfferOwnedByUser);
  const phones = useSelector(offerPreparedPhonesSelector);
  const isChatsEnabled = useSelector(selectIsChatsEnabled);
  const offer = useSelector(offerSelector);
  const agent = useSelector(selectAgent);
  const abGroup = useSelector(selectABGroup);
  const user = useSelector(selectUser);
  const kpId = useSelector(selectKpId);
  const rosreestrInfo = useSelector(getRosreestrInfo);
  const { handleOpenChat } = useChats();

  const isElectronicTradingOffer = useIsElectronicTradingOffer();

  const [isPhonesVisible, setIsPhonesVisible] = React.useState(false);
  const [botDetector] = React.useState<SimpleBotDetector>(new SimpleBotDetector());

  const isMessageButtonVisible = isChatsEnabled && (isOfferChatExists || !isOfferOwnedByUser);

  React.useEffect(() => {
    botDetector.init();

    return () => botDetector.destroy();
  }, []);

  const handleShowPhonesClick = React.useCallback(() => {
    trackClickShowPhone({
      offer,
      agent,
      abGroup,
      user,
      kpId,
      isBot: !botDetector.isHuman(),
      rosreestrInfo,
    });

    setIsPhonesVisible(true);
  }, [offer, agent, abGroup, user, kpId, botDetector, rosreestrInfo, setIsPhonesVisible]);

  const handleSendMessageClick = React.useCallback(() => {
    handleOpenChat();
  }, [handleOpenChat]);

  return (
    <SubscriptionAuthorActions
      isPhonesVisible={isPhonesVisible}
      phones={phones}
      isMessageButtonVisible={isMessageButtonVisible}
      messageButtonText="Написать сообщение"
      handleShowPhonesClick={handleShowPhonesClick}
      handleSendMessageClick={handleSendMessageClick}
      phonesOpenedInfo={<PhonesOpenedInfoContainer />}
      isElectronicTradingOffer={isElectronicTradingOffer}
      electronicTradingContactButton={
        <ElectronicTradingContactButtonContainer
          buttonSize="XS"
          dataTestId="electronic-trading-button-subscription-actions"
        />
      }
    />
  );
};
