import { Ticker } from '@cian/adfox-templates';
import { Label, Image, UIHeading3, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './TgbNativedescGeneralNarrow.css';

export type TTgbNativedescGeneralProps = {
  url: string;
  image: string;
  logoUrl: string | undefined;
  title: string;
  annotation: string;
  label: string | undefined;
  description: string | undefined;
};

export const TgbNativedescGeneralNarrow: React.FC<TTgbNativedescGeneralProps> = ({
  title,
  annotation,
  image,
  url,
  label,
  logoUrl,
  description,
}) => {
  return (
    <a href={url} target="_blank" className={styles['container']} rel="noopener noreferrer">
      <div className={styles['content']}>
        <div className={styles['title-container']}>
          <UIHeading3>{title}</UIHeading3>
          {!!description && <UIText1>{description}</UIText1>}
        </div>
        {!!logoUrl && (
          <div className={styles['logo-container']}>
            <Image src={logoUrl} borderRadius="4px" width={60} height={60} objectFit="contain" />
          </div>
        )}
      </div>
      <div className={styles['photo-block']} style={{ backgroundImage: `url(${image})` }}>
        {!!label && (
          <div className={styles['photo-label']}>
            <Label color="text-inverted-default" background="overlay-default">
              {label}
            </Label>
          </div>
        )}
        {!!annotation && (
          <div className={styles['annotation']}>
            <Ticker withBackground text={annotation} />
          </div>
        )}
      </div>
    </a>
  );
};
