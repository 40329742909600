import cx from 'clsx';
import * as React from 'react';

import * as styles from './index.css';

export interface ILinkProps
  extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  hidePointer?: boolean;
}

export class Link extends React.Component<ILinkProps, {}> {
  public render() {
    const { className, children, hidePointer, ...anchorProps } = this.props;

    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a {...anchorProps} className={cx(styles['link'], hidePointer && styles['without-pointer'], className)}>
        {children}
      </a>
    );
  }
}
