import {
  fetchCheckAccessToAdminApi,
  ICheckAccessToAdminApiResponse200 as IRes200,
  ICheckAccessToAdminApiResponse400 as IRes400,
} from 'shared/repositories/price-estimator/v1/check-access-to-admin-api';
import { IThunkActionCreator } from 'shared/store';

import { setIsAdmin } from './actions';

export function checkAccessToAdmin(): IThunkActionCreator<Promise<void>> {
  return async (dispatch, getState, context) => {
    const { httpApi } = context;

    try {
      const res = await fetchCheckAccessToAdminApi<IRes200, IRes400>({ httpApi });

      if (res.statusCode !== 200) {
        throw res.response.message;
      }

      dispatch(setIsAdmin(res.response.hasAccess));
    } catch (_) {
      dispatch(setIsAdmin(false));
    }
  };
}
