import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackMortgageSidebarBannerClick } from './tracking';
import { MortgageSidebarBanner } from '../../components/MortgageSidebarBanner';
import { banksListSelector, selectIsMortgageBlockAvailable } from '../../selectors';
import { useApplicationContext } from '../ApplicationContext';
import { useMortgageRate } from '../mortgage/hooks/useMortgageRate';

export function MortgageSidebarBannerContainer() {
  const { config } = useApplicationContext();
  const mortgageBanks = useSelector(banksListSelector);
  const isMortgageBlockAvailable = useSelector(selectIsMortgageBlockAvailable);
  const rate = useMortgageRate();

  const isBannerEnabled = config.get<boolean>('mortgage.sidebarBannerEnabled');

  if (!isBannerEnabled || !isMortgageBlockAvailable) {
    return null;
  }

  return (
    <MortgageSidebarBanner onClick={trackMortgageSidebarBannerClick} banksLength={mortgageBanks.length} rate={rate} />
  );
}
