import { selectSuperAgentLevelInfo } from './selectAgentLevelInfo';
import { IState } from '../../store';

export const selectIsQualityLevelInfoShow = (state: IState) => {
  const superAgentLevelInfo = selectSuperAgentLevelInfo(state);

  return {
    showSuperAgent: superAgentLevelInfo?.showSuperAgent,
    showSuperHost: superAgentLevelInfo?.showSuperHost,
  };
};
