import { UIText1 } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectAsideCompactMode } from 'shared/selectors/aside';
import { selectFloorTitle } from 'shared/selectors/newbuilding';
import { selectOfferTitle } from 'shared/selectors/selectOfferTitle';

export function OfferTitleSidebarContainer() {
  const title = useSelector(selectOfferTitle);
  const isCompactModeEnabled = useSelector(selectAsideCompactMode);
  const floorTitle = useSelector(selectFloorTitle);

  if (!isCompactModeEnabled) {
    return null;
  }

  return (
    <UIText1 data-testid="offer-title-sidebar">
      {title}
      {floorTitle}
    </UIText1>
  );
}
