import { Image, Label, TooltipDesktop, UIText2 } from '@cian/ui-kit';
import { IconFunctionalSuccess16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './SuperAgentLevelView.css';
import starSvg from './icons/star.svg';

type TSuperAgentLevelViewProps = {
  onOpenTooltip(): void;
  tooltip: string[];
  isSuperAgent: boolean;
};

export const SuperAgentLevelView: React.FC<TSuperAgentLevelViewProps> = ({ tooltip, onOpenTooltip, isSuperAgent }) => {
  const key = React.useId();
  const labelText = isSuperAgent ? 'Суперагент' : 'Суперхозяин';
  const analyticKey = isSuperAgent ? 'superAgent' : 'superHomeowner';

  return (
    <TooltipDesktop
      theme="white"
      onOpen={onOpenTooltip}
      title={
        <ul className={styles['tooltip']}>
          {tooltip.map((item, i) => (
            <li key={`${key}${i}`} className={styles['item']}>
              <IconFunctionalSuccess16 color="icon-positive-default" />
              <UIText2>{item}</UIText2>
            </li>
          ))}
        </ul>
      }
      placement="bottom"
    >
      <div className={styles['wrapper']}>
        <Label
          data-testid={`badge_${analyticKey}`}
          icon={<Image src={starSvg} height={16} width={16} />}
          background="control-positive-secondary-default"
          color="control-positive-primary-default"
        >
          {labelText}
        </Label>
      </div>
    </TooltipDesktop>
  );
};
