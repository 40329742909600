import * as React from 'react';

import * as styles from './VisibilityController.css';
import { useIsMounted } from './hooks';

interface IVisibilityControllerProps {
  isVisible?: boolean;
}

export const VisibilityController: React.FC<React.PropsWithChildren<IVisibilityControllerProps>> = ({
  children,
  isVisible,
}) => {
  const isMounted = useIsMounted(!!isVisible);

  return isMounted ? <div className={isVisible ? '' : styles['hidden']}>{children}</div> : null;
};
