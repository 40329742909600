import * as React from 'react';

// TODO: обновить иконку IconMapMCD416 в ui-kit и использовать ее, а эту удалить
/* istanbul ignore next */
export const IconMapMCD416: React.FC = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_31565_6598)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00003 2H-0.00463867V4H1L1.00223 14L3.00003 14C6.31372 14 8.99997 11.3137 8.99997 7.99999C8.99997 4.68633 6.31372 2 3.00003 2ZM3.00002 12C5.20912 12 6.99997 10.2091 6.99997 7.99999C6.99997 5.79087 5.20912 4 3.00003 4L3.00002 12Z"
        fill="currentColor"
      />
      <path
        d="M13.998 11.9999L14 14.0012L16 13.9988L15.9939 7.99523L13.9939 7.99697L13.9959 9.99992H11.5704L13.4687 2.56426L11.5309 2.06953L9.5 10.0244V11.9999H13.998Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_31565_6598">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
