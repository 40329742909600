import { numberToPrettyString } from '@cian/utils/lib/shared/number_helpers';
import { plural } from '@cian/utils/lib/shared/plural';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectStats } from 'shared/selectors';

export const useTitle = () => {
  const offerStats = useSelector(selectStats);

  return React.useMemo(() => {
    const { total = 0, daily = 0, totalUniqueViewsFormattedString, totalViewsFormattedString } = offerStats || {};
    const totalView = `${numberToPrettyString(total)} ${plural(total, ['просмотр', 'просмотра', 'просмотров'])}`;

    if (totalUniqueViewsFormattedString && totalViewsFormattedString) {
      return `${totalViewsFormattedString}, ${totalUniqueViewsFormattedString}`;
    } else {
      return `${totalView}, ${daily} за сегодня`;
    }
  }, [offerStats]);
};
