import { IConfig } from '@cian/config/shared';

import { useApplicationContext } from '../../containers/ApplicationContext';

const RS_FEATURE_TOGGLE_NAME = 'dailyrent.BookingCalendar.Enabled';

function getIsDailyrentBookingCalendarEnabled(config: IConfig) {
  return !!config.get<boolean>(RS_FEATURE_TOGGLE_NAME);
}

export function useIsDailyrentBookingCalendarEnabled() {
  const { config } = useApplicationContext();

  return getIsDailyrentBookingCalendarEnabled(config);
}
