import { connect } from 'react-redux';

import { IPrintPhotoProps, PrintPhoto } from './index';
import { IState } from '../../store/index';

function mapStateToProps(state: IState): IPrintPhotoProps {
  return {
    photos: state.offerData.offer.photos,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PrintPhotoContainer = connect<IPrintPhotoProps, any, any>(mapStateToProps)(PrintPhoto);
