import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './priceChangingSubscription.css';

interface IPriceChangingSubscriptionProps {
  label: string;
  onClick(): void;
}

export const PriceChangingSubscription = ({ label, onClick }: IPriceChangingSubscriptionProps) => {
  return (
    <button className={styles['button']} onClick={onClick}>
      <UIText2 color="text-main-default">{label}</UIText2>
    </button>
  );
};
