import { connect } from 'react-redux';

import { TopPopup, ITopPopupDispatchProps } from './';
import { DispatchReduxAction } from '../../store';
import { toggleTopPopup } from '../../store/hide_offer';

export const TopPopupContainer = connect(
  undefined,
  (dispatch: DispatchReduxAction): ITopPopupDispatchProps => ({
    toggleTopPopup: (isOpened: boolean) => () => dispatch(toggleTopPopup(isOpened)),
  }),
)(TopPopup);
