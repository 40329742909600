import { TContactType } from '@cian/contact-card-component';
import { Avatar, UIHeading1, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

export interface IAgentLayoutProps {
  avatarType: TContactType;
  title: string;
  agentName: string;
  avatar: string | null;
  agentLink: string | null;
  agentReviews: React.ReactElement | null;
  agentProofs: React.ReactElement | null;
  linkToMoreOffers: React.ReactElement | null;
  contactButton: React.ReactElement | null;
  callButton: React.ReactElement | null;
  lifeTime: React.ReactElement | null;
  phonesOpenedInfo: React.ReactElement;
  phonesShown: boolean;
  superAgentElement?: React.ReactNode;
}

export const AgentInfo: React.FC<React.PropsWithChildren<IAgentLayoutProps>> = ({
  avatarType,
  avatar,
  title,
  agentName,
  agentLink,
  agentReviews,
  agentProofs,
  lifeTime,
  linkToMoreOffers,
  contactButton,
  callButton,
  phonesOpenedInfo,
  phonesShown,
  superAgentElement,
}) => {
  return (
    <div className={style['container']}>
      <div className={style['left']}>
        <div className={style['title']}>
          <UIText2 color="text-primary-default">{title}</UIText2>

          {agentLink ? (
            <a className={style['agent-name']} href={agentLink}>
              <UIHeading1>{agentName}</UIHeading1>
            </a>
          ) : (
            <div className={style['agent-name']}>
              <UIHeading1>{agentName}</UIHeading1>
            </div>
          )}
          {superAgentElement && <div className={style['super-agent']}>{superAgentElement}</div>}
        </div>
        <div className={style['proofs']}>
          {agentReviews && agentReviews}

          {agentProofs && agentProofs}

          {lifeTime && lifeTime}

          {linkToMoreOffers && linkToMoreOffers}
        </div>

        <div className={style['buttons']}>
          {contactButton && <div className={style['button']}>{contactButton}</div>}

          {callButton && <div className={style['button']}>{callButton}</div>}
        </div>

        {phonesShown && <div className={style['phones-opened-info']}>{phonesOpenedInfo}</div>}
      </div>

      <div className={style['avatar']}>
        <Avatar size={80} src={avatar} type={avatarType} />
      </div>
    </div>
  );
};
