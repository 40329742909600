import * as Analytics from '../../../ebc/track_similar';
import { TModelType } from '../../../store/similar';

export const getAnalyticsLabel = (
  modelType: TModelType,
  withoutCompetitors: boolean | null,
): Analytics.EtrackOfferClicksLabels | undefined => {
  if (withoutCompetitors) {
    return undefined;
  }

  switch (modelType) {
    case 'userBased':
      return Analytics.EtrackOfferClicksLabels.userBased;

    case 'itemBased':
      return Analytics.EtrackOfferClicksLabels.itemBased;

    case 'newItemBased':
      return Analytics.EtrackOfferClicksLabels.newItemBased;

    case 'historyBased':
      return Analytics.EtrackOfferClicksLabels.historyBased;

    case 'newobjectItemBased':
      return Analytics.EtrackOfferClicksLabels.newobjectItemBased;
  }
};
