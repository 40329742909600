import { CHAT_FORCE_OPEN_INTERVAL } from '../../../constants/newbuildingChat';

export function isNeedToShow(lastShown: number): boolean {
  return lastShown < new Date().getTime();
}

export function updateShownTimestamp(): number {
  const now = new Date().getTime();

  return now + CHAT_FORCE_OPEN_INTERVAL;
}
