/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import cx from 'clsx';
import * as React from 'react';

import { IAgentSchema, IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as styles from './SimilarOffers.css';
import { SimilarOfferItem } from './components/SimilarOfferItem';
import { getSettings as getSlickSettings } from './slick/getSettings';
import './slick/slickCustomStyles.css';
import * as Analytics from '../../ebc/track_similar';
import { ISimilarOffer, TModelType } from '../../store/similar';
import { isArrayWithItems } from '../../utils';
import { getVariants } from '../../utils/ebc';
import { CustomSlider } from '../Slider';

export interface IDataLayerEvent {
  eventAction: string;
  modelVersion: string;
}

export interface ISimilarOffersProps {
  title?: string | React.ReactNode;
  agent: IAgentSchema | null;
  offer: IOfferSchema;
  similarOffers: ISimilarOffer[];
  withoutCompetitors: boolean | null;
  modelVersion: number | string | null;
  modelType: TModelType;
  user: Analytics.IUserEBC;
  page: Analytics.IPageEBC;
  disableExternalContainerStyles?: boolean;
  slidesToShow?: number;

  requestSimilarOffers?(): void;
}

export class SimilarOffers extends React.Component<ISimilarOffersProps> {
  public componentDidMount() {
    const { requestSimilarOffers } = this.props;
    if (requestSimilarOffers) {
      requestSimilarOffers();
    }
  }

  public render() {
    const { title, similarOffers, disableExternalContainerStyles, slidesToShow } = this.props;
    const slickSettings = getSlickSettings({ totalSlides: similarOffers.length, slidesToShow });
    const firstLabelIndex = similarOffers.findIndex(({ photoLabel }) => photoLabel && photoLabel.name);

    if (!isArrayWithItems<ISimilarOffer>(similarOffers)) {
      return null;
    }

    return (
      <section
        className={cx(styles['container'], {
          [styles['disable-external-container-styles']]: disableExternalContainerStyles,
        })}
      >
        {typeof title === 'string' ? <h2 className={styles['title_block']}>{title}</h2> : title}
        <CustomSlider {...slickSettings} className="similar-offers-slider">
          {similarOffers.map((similarOffer, index) => (
            <SimilarOfferItem
              key={`similarOffer_${index}`}
              offer={similarOffer}
              withPhotoLabel={firstLabelIndex === index}
              onClick={this.handleClickOffer}
              position={index + 1}
            />
          ))}
        </CustomSlider>
      </section>
    );
  }

  public getAnalyticsLabel = (
    modelType: TModelType,
    withoutCompetitors: boolean | null,
  ): Analytics.EtrackOfferClicksLabels | undefined => {
    if (withoutCompetitors) {
      return undefined;
    }

    switch (modelType) {
      case 'userBased':
        return Analytics.EtrackOfferClicksLabels.userBased;

      case 'itemBased':
        return Analytics.EtrackOfferClicksLabels.itemBased;

      case 'newItemBased':
        return Analytics.EtrackOfferClicksLabels.newItemBased;

      case 'historyBased':
        return Analytics.EtrackOfferClicksLabels.historyBased;

      case 'newobjectItemBased':
        return Analytics.EtrackOfferClicksLabels.newobjectItemBased;
    }
  };

  private handleClickOffer = (curOffer: ISimilarOffer, position: number) => {
    const { agent, offer, withoutCompetitors, modelType, modelVersion: propsModelVersion, user, page } = this.props;
    const products: Analytics.TProductPartial[] = [
      {
        id: curOffer.cianId,
        position,
        ownerId: curOffer.publishedUserId || undefined,
        variant: curOffer.gaVariant ? getVariants(curOffer.gaVariant) : [],
      },
    ];
    const modelVersion = String(propsModelVersion || 0);

    Analytics.trackOfferClick({
      action: withoutCompetitors
        ? Analytics.EtrackOfferClicksActions.click_others_offers
        : Analytics.EtrackOfferClicksActions.click_similar_offers,
      label: this.getAnalyticsLabel(modelType, withoutCompetitors),
      products,
      agent,
      offer,
      modelVersion,
      user,
      page,
    });
  };
}
