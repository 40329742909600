import * as React from 'react';

import * as styles from './styles.css';

interface INewbuildingBuilderFactoidProps {
  value: number | string;
  text: string;
}

export function NewbuildingBuilderFactoid({ value, text }: INewbuildingBuilderFactoidProps) {
  const [entity, ...rest] = text.split(' ');
  const description = rest.join(' ');

  return (
    <div className={styles['container']}>
      <div className={styles['number']}>{value}</div>
      <div className={styles['text']}>
        {entity} <br /> {description}
      </div>
    </div>
  );
}
