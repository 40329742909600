import { ca } from '@cian/analytics';

export function trackModalOpen() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card',
    action: 'Click',
    label: 'show_good_price_label',
    useLastProducts: true,
  });
}
