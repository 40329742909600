import { connect } from 'react-redux';

import { IOfferBreadcrumbsProps, OfferBreadcrumbs } from './index';
import { IState } from '../../store/index';

function mapStateToProps(state: IState): IOfferBreadcrumbsProps {
  return {
    breadcrumbs: state.offerData.breadcrumbs,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OfferBreadcrumbsContainer = connect<IOfferBreadcrumbsProps, any, any>(mapStateToProps)(OfferBreadcrumbs);
