/* eslint-disable */
//tslint:disable

import { IWarningMessageHelpInfoSchema } from './WarningMessageHelpInfoSchema';

export interface IWarningMessageSchema {
  /** Цвет фона предупреждающей плашки **/
  background?: EBackground | null;
  /** Данные ссылки на базу знаний **/
  helpInfo?: IWarningMessageHelpInfoSchema | null;
  /** Текст предупреждающей плашки **/
  text?: string | null;
  /** Заголовок предупреждающей плашки **/
  title?: string | null;
}

export enum EBackground {
  /** Серый **/
  Gray = 'gray',
  /** Красный **/
  Red = 'red',
}
