import * as React from 'react';

export interface IIconsMetro {
  color: string;
  type?: string;
}

export const IconsMetro: React.FC<React.PropsWithChildren<IIconsMetro>> = ({ type, color }) => {
  switch (type) {
    case 'spb':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" style={{ top: '1px' }}>
          {/* tslint:disable-next-line:max-line-length */}
          <path
            fill={`#${color}`}
            fillRule="evenodd"
            d="M8 11.667L10.5 3s5 1.556 5 6.89c0 3.777-1.818 5.11-1.818 5.11h-3.41s3.523-1.556 3.637-5.11c.112-3.557-2.616-5.334-2.616-5.334L8.227 14.778H8v-3.11zm0 0v3.11h-.227L4.705 4.557S1.977 6.333 2.09 9.89C2.205 13.443 5.727 15 5.727 15h-3.41S.5 13.667.5 9.89C.5 4.555 5.5 3 5.5 3L8 11.667z"
          ></path>
        </svg>
      );

    default:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" style={{ top: '3px' }}>
          {/* tslint:disable-next-line:max-line-length */}
          <path
            fill={`#${color}`}
            d="M50 81.7l14.6-25.4 6.1 17.2h-4.9v7.6H95v-7.6h-5.6l-21-55.2-18.4 34-18.4-34-21 55.2H5v7.6h29.2v-7.6h-4.9l6.1-17.2L50 81.7"
          />
        </svg>
      );
  }
};

IconsMetro.displayName = 'IconsMetro';
