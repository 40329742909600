import { ca } from '@cian/analytics';

import { IOfferSchema, IAgentSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { getCustomUrl } from '../../../utils/tracking/getCustomUrl';

interface IParams {
  offer: IOfferSchema;
  agent: IAgentSchema | null;
}

export function trackShowAllIdenticalOffers(params: IParams) {
  const { offer, agent } = params;

  ca('eventSite', {
    name: 'oldevent',
    category: 'card',
    action: 'to_identical_offers_listing',
    label: getCustomUrl(offer, agent),
    products: [
      {
        id: offer.id,
        offerType: 'offer',
        position: 1,
      },
    ],
  });
}
