import { isCorrectPhoneObject } from './isCorrectPhoneObject';
import { IPhone } from '../../../store/similar';

export function phoneToString(phone?: IPhone | null) {
  if (phone && isCorrectPhoneObject(phone)) {
    return `${phone.countryCode}${phone.number}`;
  }

  return '';
}
