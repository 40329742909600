import { FormikBag, withFormik } from 'formik';
import * as React from 'react';
import { boolean, object, string } from 'yup';

import { ISaveFormRequest } from '../../../repositories/deal-rent-announcements/v1/save-form';
import { withAnalytics } from '../../../utils/withAnalytics';
import { IFormValues, ISecondStepProps, SecondStep } from '../../components/SecondStep';

export interface ISecondStepContainerProps {
  open: boolean;
  initialFormState: ISaveFormRequest;
  onClose(): void;
  onClickBack(): void;
  onClickNext(values: ISaveFormRequest): void;
}

const SecondStepWithAnalytics = withAnalytics<ISecondStepProps>(
  {
    category: 'SafeRent',
    label: 'renter_form_second',
  },
  SecondStep,
);

const handleSubmit = (
  values: ISaveFormRequest,
  { props: { onClickNext } }: FormikBag<ISecondStepContainerProps, ISaveFormRequest>,
) => {
  onClickNext(values);
};

const mapPropsToValues = ({ initialFormState }: ISecondStepContainerProps): IFormValues => ({
  ...initialFormState,
  termAccepted: false,
});

const SecondStepWithFormik = withFormik<ISecondStepContainerProps, ISaveFormRequest>({
  mapPropsToValues,
  validationSchema: object({
    comment: string(),
    activityScope: string().required(),
    name: string().required(),
    isAgent: boolean().required(),
    termAccepted: boolean()
      .test('termAccepted', 'Необходимо согласиться с политикой конфиденциальности', Boolean)
      .required(),
  }),
  handleSubmit,
  enableReinitialize: true,
})(SecondStepWithAnalytics);

export const SecondStepContainer: React.FC<React.PropsWithChildren<ISecondStepContainerProps>> = props => {
  return <SecondStepWithFormik {...props} />;
};
