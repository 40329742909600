import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IGetEstimationAndTrendWebResponse } from '../../../repositories/price-estimator/v1/get-estimation-and-trend-web';
import { trackPriceHistoryNextClick as onClick } from '../tracking';

export function getValuation(offer: IOfferSchema, offerValuationData?: IGetEstimationAndTrendWebResponse | null) {
  if (!offerValuationData?.priceInfo?.calculatorUrl) {
    return undefined;
  }

  const url = offerValuationData.priceInfo.calculatorUrl;
  const title =
    offer.dealType === 'sale' ? 'Рыночная цена квартиры по оценке Циан' : 'Арендная ставка квартиры по оценке Циан';

  return { url, title, onClick };
}
