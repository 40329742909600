import * as React from 'react';

import { MessageWithLink } from './message_with_link';
import { EHideOfferState, errorMessages } from '../../../store/hide_offer';

interface IGetPopupTextProps {
  hideOfferErrorMessage?: string;
  status: EHideOfferState;
  hidden: boolean;
  lkUrl: string;
}

export function getPopupText({ status, hidden, lkUrl, hideOfferErrorMessage }: IGetPopupTextProps) {
  const defaultErrorText = hidden ? errorMessages.errorWhileRestore : errorMessages.errorWhileHide;

  switch (true) {
    case status === EHideOfferState.SUCCESS && hidden:
      return <MessageWithLink lkUrl={lkUrl} />;
    case status === EHideOfferState.SUCCESS && !hidden:
      return 'Объявление восстановлено';
    case status === EHideOfferState.ERROR:
      return hideOfferErrorMessage || defaultErrorText;
    default:
      return '';
  }
}
