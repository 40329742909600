import { selectIsAgencyBottomBrandingAvailable } from './selectIsAgencyBottomBrandingAvailable';
import { selectIsAgencyBrandingAvailable } from './selectIsAgencyBrandingAvailable';
import { IState } from '../../store';

export function selectIsBottomAgentBlockAvailable(state: IState): boolean {
  const isAgencyBrandingAvailable = selectIsAgencyBrandingAvailable(state);

  return Boolean(
    state.offerData.agent &&
      (!isAgencyBrandingAvailable || (isAgencyBrandingAvailable && selectIsAgencyBottomBrandingAvailable(state))),
  );
}
