import { ca } from '@cian/analytics';

export function trackDeleteWindowShowed() {
  ca('event', {
    action: 'DeleteWindow',
    category: 'сard',
    label: 'ShowWindow',
    name: 'oldevent',
  });
}

export function trackArchiveButtonClicked() {
  ca('event', {
    action: 'DeleteWindow',
    category: 'сard',
    label: 'PutIntoArchive',
    name: 'oldevent',
  });
}

export function trackRemoveButtonClicked() {
  ca('event', {
    action: 'DeleteWindow',
    category: 'сard',
    label: 'Delete',
    name: 'oldevent',
  });
}

export function trackCancelButtonClicked() {
  ca('event', {
    action: 'DeleteWindow',
    category: 'сard',
    label: 'Cancel',
    name: 'oldevent',
  });
}
