/* eslint-disable react/jsx-no-target-blank */
import cx from 'clsx';
import * as React from 'react';

import { TermsDealV2 } from './TermsDealV2';
import { TermsForms } from './TermsForms';
import { EDealRentVersion } from '../../../../repositories/offer-card/entities/offer/OfferSchema';
import * as styles from '../../index.css';

export interface ILabel {
  invalid: boolean;
  freeDealRent: boolean;
  dealVersion: EDealRentVersion;
}

export const Label = ({ invalid, freeDealRent, dealVersion }: ILabel) => (
  <span className={cx(styles['term-description'], invalid && styles['label-invalid'])}>
    {freeDealRent ? (
      dealVersion === EDealRentVersion.V2 ? (
        <TermsDealV2 />
      ) : (
        <TermsForms />
      )
    ) : (
      <>
        Отправляя анкету, я даю согласие на обработку моих персональных данных в соответствии с{' '}
        <a className={styles['link']} href="https://www.cian.ru/help/about/36526/" target="_blank">
          политикой конфиденциальности
        </a>
      </>
    )}
  </span>
);
