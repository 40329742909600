import { UIText1, UIText2 } from '@cian/ui-kit';
import { IconActionChevronRight16 } from '@cian/ui-kit-design-tokens/icons';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './EntryPointRow.css';

interface IEntryPointRowProps {
  link?: string;
  imgSrc: string;
  title: string;
  subtitle?: string;
  dark?: boolean;
  onClick?(): void;
}

export const EntryPointRow: React.FC<IEntryPointRowProps> = ({ onClick, link, imgSrc, title, subtitle, dark }) => {
  const content = React.useMemo(
    () => (
      <>
        <img className={styles['icon']} src={imgSrc} alt="" />
        <span className={styles['texts']}>
          <UIText1>{title}</UIText1>
          {subtitle && <UIText2 color="current_color">{subtitle}</UIText2>}
        </span>
        <span className={styles['arrow']}>
          <IconActionChevronRight16 color="current_color" />
        </span>
      </>
    ),
    [imgSrc, subtitle, title],
  );

  return React.useMemo(
    () =>
      link ? (
        <a
          onClick={onClick}
          className={cx(styles['btn'], dark && styles['btn--dark'])}
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          {content}
        </a>
      ) : (
        <div onClick={onClick} className={cx(styles['btn'], dark && styles['btn--dark'])}>
          {content}
        </div>
      ),
    [link, onClick, content, dark],
  );
};
