import cx from 'clsx';
import * as React from 'react';

import * as styles from '../index.css';

export interface ILabelProps {
  invalid?: boolean;
  children?: React.ReactNode;
}
export const Label = ({ invalid = false, children }: ILabelProps) => (
  <div className={cx(styles['label'], invalid && styles['label-invalid'])}>{children}</div>
);
