import { connect } from 'react-redux';

import { DealRentSimilarSuccessModal } from './DealRentSimilarSuccessModal';
import { IState } from '../../store';
import { closeSimilarSuccess } from '../../store/deal-rent';

export function mapStateToProps(state: IState) {
  return {
    isOpen: state.dealRent.isSimilarSuccessModal,
  };
}

export const DealRentSimilarSuccessModalContainer = connect(mapStateToProps, { closeModal: closeSimilarSuccess })(
  DealRentSimilarSuccessModal,
);
