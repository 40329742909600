import { createSelector } from '@reduxjs/toolkit';

import { selectExternalTour } from './selectExternalTour';
import { selectTourV2 } from './selectTourV2';

export const selectTourLink = createSelector(
  selectTourV2,
  selectExternalTour,
  (tourKey, externalTourKey) => tourKey || externalTourKey,
);
