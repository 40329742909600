import {
  IconFunctionalClock16,
  IconCommunicationPhone16,
  IconCommunicationSupport16,
  IconFunctionalWarningSign16,
} from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './phonesOpenedInfo.css';

interface IPhonesOpenedInfoProps {
  phones?: React.ReactElement;
  schedule: string | null;
  availability: string | null;
  calltracking: string | null;
  warningMessage: string | null;
  feedback: React.ReactElement;
  id?: string;
}
export const PhonesOpenedInfo = ({
  phones,
  schedule,
  availability,
  calltracking,
  feedback,
  warningMessage,
  id = 'asidePhonesOpenedInfo',
}: IPhonesOpenedInfoProps) => {
  return (
    <div className={styles['container']} id={id}>
      {phones && (
        <div data-testid="phones-opened-value" className={styles['phones']}>
          {phones}
        </div>
      )}
      <div>
        {availability && (
          <div className={styles['info']}>
            <div className={styles['icon']}>
              <IconFunctionalClock16 />
            </div>
            <span>{availability}</span>
          </div>
        )}
        {schedule && (
          <div data-testid="phones-opened-scheduled" className={styles['info']}>
            <div className={styles['icon']}>
              <IconFunctionalClock16 />
            </div>
            <span>{schedule}</span>
          </div>
        )}
        {warningMessage && (
          <div className={styles['info']}>
            <div className={styles['icon']}>
              <IconFunctionalWarningSign16 />
            </div>
            <span>{warningMessage}</span>
          </div>
        )}
        {calltracking && (
          <div data-testid="phones-opened-calltracking" className={styles['info']}>
            <div className={styles['icon']}>
              <IconCommunicationPhone16 />
            </div>
            <span>{calltracking}</span>
          </div>
        )}
        {feedback && (
          <div data-testid="phones-opened-feedback" className={styles['info']}>
            <div className={styles['icon']}>
              <IconCommunicationSupport16 />
            </div>
            <span>{feedback}</span>
          </div>
        )}
      </div>
    </div>
  );
};
