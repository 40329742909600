import * as React from 'react';

import { useGradient } from './hooks/useGradient';
import { EStarTheme } from './types/star';

interface IFillableStarProps {
  value: number;
  theme: EStarTheme.Fillable;
}

interface ISimpleStarProps {
  theme: EStarTheme.Filled | EStarTheme.NotFilled;
  color?: string;
}

export type TStarProps = IFillableStarProps | ISimpleStarProps;

export function StarWithGradient(props: TStarProps) {
  const { theme } = props;

  const { gradient, fill } = useGradient({
    value: 'value' in props ? props.value : undefined,
    theme,
    color: 'color' in props ? props.color : undefined,
  });

  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      {gradient}
      <path
        fill={fill}
        d="M6.52555 0.359142C6.33711 -0.119714 5.66289 -0.119714 5.47445 0.359142L4.2427 3.48924C4.16374 3.68988 3.97852 3.82825 3.76462 3.84637L0.518496 4.12143C0.0253754 4.16321 -0.179979 4.77675 0.187729 5.10967L2.70182 7.38587C2.85388 7.52354 2.92002 7.73308 2.87475 7.93369L2.11402 11.3044C2.00211 11.8003 2.54496 12.1829 2.9706 11.9081L5.69459 10.1496C5.88066 10.0295 6.11934 10.0295 6.30541 10.1496L9.0294 11.9081C9.45504 12.1829 9.99789 11.8003 9.88598 11.3044L9.12525 7.93369C9.07998 7.73308 9.14612 7.52354 9.29818 7.38587L11.8123 5.10967C12.18 4.77675 11.9746 4.16321 11.4815 4.12143L8.23538 3.84637C8.02148 3.82825 7.83626 3.68989 7.7573 3.48924L6.52555 0.359142Z"
      />
    </svg>
  );
}
