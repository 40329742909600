import * as React from 'react';
import { useSelector } from 'react-redux';

import { ReviewsMiniWidget } from 'shared/components/ReviewsMiniWidget';
import { reviewsElementId } from 'shared/constants/reviews';
import { selectDailyrentOfferRating } from 'shared/selectors/offerData/selectDailyrentOfferRating';
import { scrollToElement } from 'shared/utils/navigation';

export function ReviewsMiniWidgetContainer() {
  const offerRating = useSelector(selectDailyrentOfferRating);

  const onReviewsClick = React.useCallback(() => {
    const reviewsSnippet = document.getElementById(reviewsElementId);

    if (reviewsSnippet) {
      scrollToElement(reviewsSnippet);
    }
  }, []);

  return (
    <ReviewsMiniWidget rating={offerRating?.rating} reviewsCount={offerRating?.reviewsCount} onClick={onReviewsClick} />
  );
}
