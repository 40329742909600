import * as React from 'react';

import * as styles from './WrapElementsSpacer.css';

type Space = 0 | 2 | 4 | 8 | 12 | 16;

type WrapElementsSpacerProps = {
  children: Array<React.ReactNode> | React.ReactNode;
  verticalSpace: Space;
  horizontalSpace: Space;
};

const addPx = (num: number): string => `${num}px`;

export const WrapElementsSpacer: React.FC<React.PropsWithChildren<WrapElementsSpacerProps>> = props => {
  const { children, verticalSpace, horizontalSpace } = props;

  const realVerticalSpace = verticalSpace / 2;
  const realHorizontalSpace = horizontalSpace / 2;

  const childStyle = { padding: `${addPx(realVerticalSpace)} ${addPx(realHorizontalSpace)}` };
  const containerStyle = { margin: `${addPx(-realVerticalSpace)} ${addPx(-realHorizontalSpace)}` };

  const childrenArrWithWrapper = Array.of<JSX.Element>();
  React.Children.forEach(children, (child, index) => {
    if (child || typeof child === 'number') {
      childrenArrWithWrapper.push(
        <div className={styles['item']} key={index} style={childStyle}>
          {child}
        </div>,
      );
    }
  });

  if (!childrenArrWithWrapper.length) {
    return null;
  }

  return (
    <div className={styles['container']} data-testid="wrap-elements-spacer-container" style={containerStyle}>
      {childrenArrWithWrapper}
    </div>
  );
};
