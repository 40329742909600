import { isNil } from 'ramda';

import { IState } from 'shared/store';

export function getFloorInfo(state: IState): string {
  const floorNumber = state.offerData.offer.floorNumber;
  const buildingInfo = state.offerData.offer.building;

  if (floorNumber && buildingInfo && !isNil(buildingInfo.floorsCount)) {
    return `${floorNumber}/${buildingInfo.floorsCount} эт.`;
  }

  if (floorNumber) {
    return `${floorNumber} эт.`;
  }

  return '';
}
