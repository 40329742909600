import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useApplicationContext } from 'shared/containers/ApplicationContext';
import { offerSelector, selectSpecialProject, selectSpecialPromoInfo, selectMapTabs } from 'shared/selectors';
import { getPanoramaAvailable } from 'shared/selectors/getPanoramaAvailable';
import { IState } from 'shared/store';

import { OfferMapTabs } from './tabs';
import { TMapTab, changeTab } from '../../store/map_tabs';

export const OfferMapTabsContainer: React.FC = () => {
  const dispatch = useDispatch();

  const { activeTab } = useSelector(selectMapTabs);
  const offer = useSelector(offerSelector);
  const specialProject = useSelector(selectSpecialProject);
  const specialPromoInfo = useSelector(selectSpecialPromoInfo);

  const { config } = useApplicationContext();
  const panoramaEnabled = !!config.get<boolean>('panoramaBanner.enabled');
  const panoramaUrl = !!config.get<string>('panoramaBannerUrl');
  const citiesWithPanoramaID = config.get<number[]>('suburban.citiesWithPanoramaID') || [];
  /* istanbul ignore next */
  const isPanoramaBannerEnabled = Boolean(panoramaEnabled && panoramaUrl);

  const isPanoramaAvailable = useSelector<IState, boolean>(state =>
    getPanoramaAvailable(state, citiesWithPanoramaID, isPanoramaBannerEnabled),
  );

  const handleTabChange = React.useCallback(
    (tab: TMapTab) => {
      dispatch(changeTab(tab));
    },
    [dispatch],
  );

  return (
    <OfferMapTabs
      activeTab={activeTab}
      offer={offer}
      specialProject={specialProject}
      specialPromoInfo={specialPromoInfo}
      isPanoramaAvailable={isPanoramaAvailable}
      changeTab={handleTabChange}
    />
  );
};
