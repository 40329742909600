import { LinkButton } from '@cian/ui-kit';
import * as React from 'react';

import { Link } from 'shared/common/components/link';

import * as styles from './index.css';
import { trackPrimaryActionButtonClick, trackSecondaryActionButtonClick } from '../tracking';

export interface IPremiumProps {
  isVisible: boolean;
  /** Ссылка смены размещения */
  recoveryLink: string | null;
  /** True, если тип сделки — Аренда */
  isRent: boolean;
  /** Флаг активности ML-ранжирования */
  isMlSearchEnabled: boolean;
}

export const Premium = (props: IPremiumProps) => {
  const { isVisible, recoveryLink, isRent, isMlSearchEnabled } = props;

  if (!isVisible || !recoveryLink) {
    return null;
  }

  return (
    <article className={styles['container']}>
      <h3 className={styles['title']}>{isRent ? 'Сдайте ' : 'Продайте '} быстрее с премиумом!</h3>
      <div className={styles['description']}>
        {isMlSearchEnabled
          ? `Премиум-объявления выделены в результатах поиска за счёт дополнительных фотографий.
              Такие объявления показываются в 1.5 раза чаще обычных.`
          : `Премиум-размещение позволяет получить в три раза
              больше звонков по сравнению с обычной публикацией объявления.`}
      </div>
      <footer className={styles['actions']}>
        <span className={styles['primary-action']}>
          <LinkButton
            target="_blank"
            href={`${recoveryLink}?buy=103`}
            onClick={() => trackPrimaryActionButtonClick('premium')}
          >
            Купить Премиум
          </LinkButton>
        </span>
        <Link href={recoveryLink} onClick={trackSecondaryActionButtonClick} target="_blank">
          Сменить тип размещения
        </Link>
      </footer>
    </article>
  );
};
