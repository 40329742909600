import * as React from 'react';
import { useSelector } from 'react-redux';

import { AgentReviewsTitle } from 'shared/components/AuthorAsideNew';
import { selectAgentReviews } from 'shared/selectors';
import { selectAgentUrl } from 'shared/selectors/offerData/agent';
import { getAgentReviewsTitle } from 'shared/utils/branding';

export const AgentReviewsTitleContainer: React.FC = () => {
  const agentLink = useSelector(selectAgentUrl);
  const { entityRate, totalCount } = useSelector(selectAgentReviews);
  const agentReviewTitle = getAgentReviewsTitle(entityRate, totalCount);

  if (!agentReviewTitle) {
    return null;
  }

  return (
    <li>
      <AgentReviewsTitle title={agentReviewTitle} link={agentLink} />
    </li>
  );
};
