import { Input } from '@cian/ui-kit';
import cx from 'clsx';
import { Field } from 'formik';
import * as React from 'react';

import { Label } from './Label';
import { useFieldHasErrors } from './utils';
import * as styles from '../index.css';

export const Name = () => {
  const invalid = useFieldHasErrors('name');

  return (
    <div className={cx(styles['field'], styles['span-columns'])}>
      <Label invalid={invalid}>Ваше имя</Label>
      <Field name="name" as={Input} custom={{ invalid, size: 'XS' }} />
    </div>
  );
};
