import * as React from 'react';

import { AdfoxBanner } from 'shared/components/AdfoxBanner';
import { TAdfoxContextData, AdfoxContextProvider } from 'shared/context/AdfoxContext';
import { IPParams } from 'shared/types/adfox';

const POST_VIEW_ANALYTICS_DATA: TAdfoxContextData['postViewAnalyticsData'] = {
  banner: {
    place: 'offersSerp',
    name: 'TgbBottomBanners',
  },
};
const P_PARAMS: IPParams = { p1: 'ddcga', p2: 'fkhj' };
const SIZE: TAdfoxContextData['size'] = { minWidth: '100%', minHeight: '200px' };

export const TgbBottomBannersContainer: React.FC = () => {
  return (
    <AdfoxContextProvider
      bannerPlace="bottom"
      size={SIZE}
      pParams={P_PARAMS}
      postViewAnalyticsData={POST_VIEW_ANALYTICS_DATA}
    >
      <AdfoxBanner />
    </AdfoxContextProvider>
  );
};
