import { ca } from '@cian/analytics';

export function trackCreateFolder() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingFavorites',
    action: 'add_collection',
    label: 'from_card',
    useLastProducts: true,
  });
}

export function trackSaveToFolder() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingFavorites',
    action: 'add_to_collection',
    label: '',
    useLastProducts: true,
  });
}

export function trackFolderNameClick(offerId: number, folderId: number) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingFavorites',
    action: 'click_collection_name',
    label: folderId,
    products: [
      {
        id: offerId,
        offerType: 'offer',
        position: 1,
      },
    ],
  });
}

export function trackNotificationSaveToFolder(offerId: number) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingFavorites',
    action: 'Click',
    label: 'add_to_collection',
    products: [
      {
        id: offerId,
        offerType: 'offer',
        position: 1,
      },
    ],
  });
}

export function trackNotificationChangeFolder(offerId: number) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingFavorites',
    action: 'Click',
    label: 'change_collection',
    products: [
      {
        id: offerId,
        offerType: 'offer',
        position: 1,
      },
    ],
  });
}
