import * as React from 'react';
import { useSelector } from 'react-redux';

import { useApplicationContext } from '../../containers/ApplicationContext';
import { selectIsMortgageBannerEnabled } from '../../selectors/mortgage/selectIsMortgageBannerEnabled';

export const useIsMortgageBannerAvailable = (): boolean => {
  const { config } = useApplicationContext();
  const isMortgageBannerEnabled = useSelector(selectIsMortgageBannerEnabled);

  return React.useMemo(
    () => !!config.get<boolean>('mortgage.galleryBanner') && isMortgageBannerEnabled,
    [config, isMortgageBannerEnabled],
  );
};
