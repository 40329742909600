import * as React from 'react';
import { useSelector } from 'react-redux';

import { offerSelector } from '../../../../selectors';
import { selectMortgageRates } from '../../../../selectors/mortgage/rates';
import { IMortgageRatesConfig } from '../../../../types/mortgage';
import { getOfferType, isNewbuilding, isOldbuilding, isSuburban } from '../../../../utils/offer_type';
import { useApplicationContext } from '../../../ApplicationContext';

const DEFAULT_RATE = 10;

export function useMortgageRate(): number {
  const offer = useSelector(offerSelector);
  const { flat, suburban, appartments } = useSelector(selectMortgageRates);
  const { config } = useApplicationContext();

  return React.useMemo(() => {
    const offerType = getOfferType(offer);
    const isApartments = offer.isApartments;

    const rates = config.get<IMortgageRatesConfig>('mortgage.rates');

    let rate: number | undefined;

    if (isSuburban(offerType) && suburban.used) {
      rate = suburban.used;
    } else if (isApartments && isNewbuilding(offerType) && appartments.new) {
      rate = appartments.new;
    } else if (isApartments && isOldbuilding(offerType) && appartments.used) {
      rate = appartments.used;
    } else if (isNewbuilding(offerType) && flat.new) {
      rate = flat.new;
    } else if (isOldbuilding(offerType) && flat.used) {
      rate = flat.used;
    }

    if (!rate && rates) {
      rate = isNewbuilding(offerType) ? rates.newbuilding : rates.resale;
    }

    return rate ?? DEFAULT_RATE;
  }, [offer, config, flat, suburban]);
}
