import { Checkbox } from '@cian/ui-kit';
import cx from 'clsx';
import { useFormikContext, Field } from 'formik';
import * as React from 'react';

import { EDealRentVersion } from 'shared/repositories/offer-card/entities/offer/OfferSchema';

import { Label } from './Label';
import { IFormValues } from '../../DealRentModal';
import * as styles from '../../index.css';
import { useFieldHasErrors } from '../utils';

export interface IIsTermAccepted {
  freeDealRent: boolean;
  dealVersion: EDealRentVersion;
}

export const IsTermAccepted = ({ freeDealRent, dealVersion }: IIsTermAccepted) => {
  const invalid = useFieldHasErrors('termAccepted');
  const { values } = useFormikContext<IFormValues>();

  return (
    <div className={cx(styles['field'], styles['span-columns'])}>
      <Field
        name="termAccepted"
        as={Checkbox}
        checked={values.termAccepted}
        label={<Label dealVersion={dealVersion} freeDealRent={freeDealRent} invalid={invalid} />}
        invalid={invalid}
      />
    </div>
  );
};
