import { numberToPrettyString } from '@cian/utils/lib/shared/number_helpers';

import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { CurrencySymbol } from '../../utils/formatters';

export const getPriceString = (
  currencySymbol: string,
  minPrice?: number,
  maxPrice?: number,
  minPriceStr?: string,
  maxPriceStr?: string,
): string | undefined => {
  if (minPriceStr && maxPriceStr && minPrice && maxPrice && minPrice > 0 && minPrice < maxPrice) {
    return `от ${minPriceStr} до ${maxPriceStr} ${currencySymbol}`;
  } else if (minPriceStr || maxPriceStr) {
    return `${maxPriceStr || minPriceStr} ${currencySymbol}`;
  }

  return undefined;
};

export interface IRangePrice {
  minPriceUsdPrepared?: number;
  minPriceEurPrepared?: number;
  minPriceUsd?: string;
  minPriceEur?: string;
  maxPriceUsdPrepared?: number;
  maxPriceEurPrepared?: number;
  maxPriceUsd?: string;
  maxPriceEur?: string;
}

export const calcRangePrice = (
  pricePerUnitAreaUsd: number | null,
  pricePerUnitAreaEur: number | null,
  minArea?: number,
  maxArea?: number,
): IRangePrice => {
  let minPriceUsdPrepared;
  let minPriceEurPrepared;
  let minPriceUsd;
  let minPriceEur;
  let maxPriceUsdPrepared;
  let maxPriceEurPrepared;
  let maxPriceUsd;
  let maxPriceEur;

  if (pricePerUnitAreaUsd && pricePerUnitAreaEur) {
    if (minArea) {
      minPriceUsdPrepared = Math.round(minArea * pricePerUnitAreaUsd);
      minPriceEurPrepared = Math.round(minArea * pricePerUnitAreaEur);

      minPriceUsd = numberToPrettyString(minPriceUsdPrepared);
      minPriceEur = numberToPrettyString(minPriceEurPrepared);
    }

    if (maxArea) {
      maxPriceUsdPrepared = Math.round(maxArea * pricePerUnitAreaUsd);
      maxPriceEurPrepared = Math.round(maxArea * pricePerUnitAreaEur);

      maxPriceUsd = numberToPrettyString(maxPriceUsdPrepared);
      maxPriceEur = numberToPrettyString(maxPriceEurPrepared);
    }
  }

  return {
    minPriceUsdPrepared,
    minPriceEurPrepared,
    minPriceUsd,
    minPriceEur,
    maxPriceUsdPrepared,
    maxPriceEurPrepared,
    maxPriceUsd,
    maxPriceEur,
  };
};

export interface IPriceCurrencies {
  usd?: string;
  eur?: string;
}

export const getPriceCurrencies = (offer: IOfferSchema): IPriceCurrencies => {
  const { minArea, maxArea, pricePerUnitAreaUsd, pricePerUnitAreaEur } = offer;

  if ((minArea || maxArea) && (pricePerUnitAreaUsd || pricePerUnitAreaEur)) {
    const minAreaPrepared = (minArea && parseFloat(minArea)) || undefined;
    const maxAreaPrepared = (maxArea && parseFloat(maxArea)) || undefined;

    const {
      minPriceUsdPrepared,
      minPriceEurPrepared,
      minPriceUsd,
      minPriceEur,
      maxPriceUsdPrepared,
      maxPriceEurPrepared,
      maxPriceUsd,
      maxPriceEur,
    }: IRangePrice = calcRangePrice(pricePerUnitAreaUsd, pricePerUnitAreaEur, minAreaPrepared, maxAreaPrepared);

    const priceUsd = getPriceString(
      CurrencySymbol.Usd,
      minPriceUsdPrepared,
      maxPriceUsdPrepared,
      minPriceUsd,
      maxPriceUsd,
    );
    const priceEur = getPriceString(
      CurrencySymbol.Eur,
      minPriceEurPrepared,
      maxPriceEurPrepared,
      minPriceEur,
      maxPriceEur,
    );

    return {
      usd: priceUsd,
      eur: priceEur,
    };
  }

  return {};
};
