import { Button, ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './index.css';

const successIcon = require('./success.svg') as string;

export interface IDealRentSuccessModalProps {
  isOpen: boolean;
  closeModal(): void;
}

export const DealRentSuccessModal = ({ isOpen, closeModal }: IDealRentSuccessModalProps) => {
  return (
    <ModalWindow
      onClose={closeModal}
      open={isOpen}
      content={
        <div className={styles['container']}>
          <img src={successIcon} />
          <h1 className={styles['title']}>Анкета отправлена</h1>
          <div className={styles['text']}>Ответ собственника придёт вам в чат</div>
          <Button size="XS" onClick={closeModal}>
            Хорошо
          </Button>
        </div>
      }
    />
  );
};
