import { UIText1 } from '@cian/ui-kit';
import * as React from 'react';

export interface IAgentLifeTimeProps {
  lifeTime: string;
}

export const AgentLifeTime: React.FC<React.PropsWithChildren<IAgentLifeTimeProps>> = ({ lifeTime }) => {
  return <UIText1>{lifeTime}</UIText1>;
};
