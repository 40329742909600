import * as React from 'react';

import * as styles from './BookingCalendarLayout.css';

interface IBookingCalendarLayoutProps {
  children: React.ReactNode;
}

export const BookingCalendarLayout = React.forwardRef<HTMLDivElement, IBookingCalendarLayoutProps>(
  ({ children }, ref) => {
    return (
      <div className={styles['root']} ref={ref}>
        {children}
      </div>
    );
  },
);

BookingCalendarLayout.displayName = 'BookingCalendarLayout';
