import * as React from 'react';
import { useSelector } from 'react-redux';

import { AddressContainer } from './AddressContainer';
import { HighwaysContainer } from './HighwaysContainer';
import { UndergroundsContainer } from './UndergroundsContainer';
import { Geo } from '../../components/GeoRedesigned/Geo';
import { addressSelector } from '../../selectors';

export const GeoContainer = () => {
  const address = useSelector(addressSelector);
  const addressString = address.map(addr => addr.shortName).join(', ');

  if (!address.length) {
    return null;
  }

  return (
    <Geo
      addressString={addressString}
      address={<AddressContainer />}
      highways={<HighwaysContainer />}
      undergrounds={<UndergroundsContainer />}
    />
  );
};
