import { IconToggleStarOff16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as style from './style.css';

export interface IAgentReviewsTitleProps {
  title: string;
  link: string | null;
}

export const AgentReviewsTitle: React.FC<React.PropsWithChildren<IAgentReviewsTitleProps>> = ({ title, link }) => {
  return link ? (
    <a className={style['agent-reviews-title']} href={`${link}#reviews`}>
      <IconToggleStarOff16 color="icon-warning-default" />
      {title}
    </a>
  ) : (
    <div className={style['agent-reviews-title']}>
      <IconToggleStarOff16 color="icon-warning-default" />
      {title}
    </div>
  );
};
