import * as React from 'react';
import { useSelector } from 'react-redux';

import * as styles from './mortgage-offer-card.css';
import { isOfferInGageSelector, selectTrackingPage, selectTrackingUser } from '../../../../selectors';
import { trackAdFoxMortgage } from '../../../../tracking/ad-fox-mortgage';
import { TAdFoxTemplatesParams } from '../OwnTemplates';

export const MortgageOfferCardTemplate: React.FC<TAdFoxTemplatesParams> = ({ bannerId, image, url }) => {
  const isInGage = useSelector(isOfferInGageSelector);
  const page = useSelector(selectTrackingPage);
  const user = useSelector(selectTrackingUser);

  const onClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();

      trackAdFoxMortgage('click', 'BannerIpoteka', bannerId, user, page);

      window.open(url);
    },
    [bannerId, user, page],
  );

  React.useEffect(() => {
    trackAdFoxMortgage('show', 'BannerIpoteka', bannerId, user, page);
  }, []);

  if (isInGage) {
    return null;
  }

  return (
    <div
      onClick={onClick}
      style={{ backgroundImage: `url(${image})` }}
      className={styles['mortgage-offer-card-template-wrapper']}
    />
  );
};
