import * as React from 'react';
import { useLocalStorage } from 'usehooks-ts';

export const useIsLocalOpen = (key: string) => {
  const [isOpen, setOpen] = React.useState(false);
  const [value, setValue] = useLocalStorage<boolean>(key, false);

  const close = React.useCallback(() => {
    setOpen(false);
    setValue(true);
  }, [setValue]);

  const checkIsOpen = React.useCallback(() => {
    if (!value) {
      setOpen(true);
    }
  }, [value]);

  React.useEffect(() => {
    checkIsOpen();
  }, [checkIsOpen]);

  return { isOpen, close };
};
