import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';

import icon from './assets/icon.svg';
import { EntryPointRow } from '../EntryPointRow';

interface ISubsidisedMortgageBannerProps {
  onClick(): void;
  onShow(): void;
  title: string;
  subtitle: string;
}

export const SubsidisedMortgageBanner: React.FC<ISubsidisedMortgageBannerProps> = ({
  onClick,
  title,
  subtitle,
  onShow,
}) => {
  return (
    <ActionAfterViewObserver callback={onShow} percentVisible={0.8} triggerOnce>
      <EntryPointRow dark onClick={onClick} imgSrc={icon} title={title} subtitle={subtitle} />
    </ActionAfterViewObserver>
  );
};
