import { ca } from '@cian/analytics';

export const trackSafeRentBlockShow = () => {
  ca('event', {
    name: 'oldevent',
    category: 'card',
    action: 'show',
    label: 'cian_assistant',
  });
};

export const trackSafeRentBlockHover = () => {
  ca('event', {
    name: 'oldevent',
    category: 'card',
    action: 'hover_block',
    label: 'cian_assistant',
  });
};

export const trackOfferLinkClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'SafeRent',
    action: 'Click',
    label: 'OfferLink',
  });
};
