import * as React from 'react';

import { StarWithGradient } from './StarWithGradient';
import * as styles from './styles.css';
import { EStarTheme } from './types/star';
import { getUnits } from './utils/getUnits';

export interface IStarsProps {
  value: number;
}

export function ReviewsMiniWidgetStars({ value }: IStarsProps) {
  const units = React.useMemo(() => getUnits(5, value), [value]);

  return (
    <ul className={styles['container']}>
      {units.map((unit, index) => (
        <li key={index} className={styles['element']}>
          <StarWithGradient value={unit} theme={EStarTheme.Fillable} />
        </li>
      ))}
    </ul>
  );
}
