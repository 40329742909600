import { ca } from '@cian/analytics';

export function trackClickOnSharingMainButton() {
  ca('event', {
    action: 'share_open_main_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}

export function trackClickOnSharingButton(label: string) {
  ca('event', {
    action: 'share_send_main_block',
    category: 'card',
    label,
    name: 'oldevent',
  });
}
