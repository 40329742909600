import { Button } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './index.css';
import { trackEntryToShowClick } from '../../store/deal-rent/tracking';

export interface IDealRentEntryToShowButtonProps {
  isAvailable: boolean;
  open(): void;
}

export const DealRentEntryToShowButton = ({ open, isAvailable }: IDealRentEntryToShowButtonProps) => {
  const onClick = React.useCallback(() => {
    trackEntryToShowClick();
    open();
  }, [open]);

  return isAvailable ? (
    <div className={styles['container']}>
      <Button onClick={onClick} size="M">
        Записаться на показ
      </Button>
    </div>
  ) : null;
};
