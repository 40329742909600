/* eslint-disable  no-irregular-whitespace */
import { Tooltip, UIHeading5, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

export interface IPriceLabelTooltipProps {
  onOpen?(): void;
  estimationRange: React.ReactChild;
  priceQualityBar: React.ReactElement;
  priceTag: string;
}

export const PriceLabelTooltip: React.FC<React.PropsWithChildren<IPriceLabelTooltipProps>> = props => {
  const { estimationRange, priceQualityBar, priceTag, onOpen } = props;

  return (
    <Tooltip
      title={
        <div className={styles['container']} data-testid="valuation_good_price">
          <UIHeading5>Почему цена хорошая</UIHeading5>
          <div className={styles['description']}>
            <UIText2>
              Хорошей мы считаем цену, которая попадает в диапазон оценки Циан.
              <br />
              Для этой квартиры диапазон:
              <br />
              {estimationRange}
            </UIText2>
          </div>
          <div className={styles['price_bar']}>{priceQualityBar}</div>
        </div>
      }
      theme="white"
      placement="right-start"
      onOpen={onOpen}
    >
      <span className={styles['label']} data-testid="valuation_good_price_label">
        {priceTag}
      </span>
    </Tooltip>
  );
};

PriceLabelTooltip.displayName = 'PriceLabelTooltip';
