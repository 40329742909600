import * as React from 'react';

import * as styles from './Features.css';

interface IFeaturesProps {
  hasOnlineViewing: boolean;
  hasLayout: boolean;
}

export const Features: React.FC<React.PropsWithChildren<IFeaturesProps>> = ({ hasOnlineViewing, hasLayout }) => {
  if (!hasLayout && !hasOnlineViewing) {
    return null;
  }

  return (
    <div className={styles.root}>
      {hasLayout && <span className={styles.label}>ПЛАНИРОВКА</span>}
      {hasOnlineViewing && <span className={styles.label}>ОНЛАЙН ПОКАЗ</span>}
    </div>
  );
};
