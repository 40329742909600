import { ca } from '@cian/analytics';

export type TLabel = 'floating_block_ready_JK' | 'floating_block_construction_JK' | 'floating_block_JK';

export function trackBuilderLogoClick({ offerId, builderId }: { offerId: number; builderId?: number | null }) {
  ca('eventSite', {
    name: 'oldevent',
    action: 'click_sopr',
    category: 'Сard',
    label: 'to_card_builder',
    products: [{ id: offerId, offerType: 'offer', extra: { to_object: builderId } }],
    page: {
      extra: {
        is_premium: true,
      },
    },
  });
}

export function trackBuilderStatClick({ offerId, label }: { offerId: number; label: TLabel }) {
  ca('eventSite', {
    action: 'click_listingjk',
    category: 'Сard',
    label,
    name: 'oldevent',
    products: [{ id: offerId, offerType: 'offer' }],
    page: {
      extra: {
        is_premium: true,
      },
    },
  });
}
