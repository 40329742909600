import { IState } from '../store';

export function getWebimUserDataSelector(state: IState) {
  const { userData } = state.newbuildingChat;

  if (!userData) {
    return {};
  }

  return userData;
}
