import { ContactAvatar, Subcontact } from '@cian/contact-card-component';
import * as React from 'react';

interface IAgentDescription {
  agentLink: string | null;
  agentName: string;
  agentReviews: React.ReactNode;
  agentAvatar: string | null;
  onAgentNameClick(): void;
  qualityInfo: React.ReactNode;
}

const AgentDescriptionComponent: React.FC<React.PropsWithChildren<IAgentDescription>> = ({
  agentLink,
  agentName,
  agentReviews,
  agentAvatar,
  onAgentNameClick,
  qualityInfo,
}) => {
  return (
    <Subcontact
      title="Риелтор"
      onNameClick={onAgentNameClick}
      href={agentLink}
      name={agentName}
      labels={agentReviews}
      avatar={<ContactAvatar type="agent" src={agentAvatar} />}
      qualityInfo={qualityInfo}
    />
  );
};

export const AgentDescription = React.memo(AgentDescriptionComponent);
