interface IParams {
  isSubAgent: boolean;
  agentName: string;
  agencyName: string;
}

export const getName = (params: IParams): string => {
  const { isSubAgent, agentName, agencyName } = params;

  return isSubAgent ? agentName : agencyName;
};
