import { IHttpApi } from '@cian/http-api';

import {
  fetchGetModerationComplainMicrofrontend,
  IGetModerationComplainMicrofrontendResponse200,
} from '../../repositories/moderation-complain-frontend/v1';

type Parameters = {
  httpApi: IHttpApi;
};
export const getComplaints = async ({ httpApi }: Parameters) => {
  try {
    const { statusCode, response } =
      await fetchGetModerationComplainMicrofrontend<IGetModerationComplainMicrofrontendResponse200>({
        httpApi,
      });

    if (statusCode === 200) {
      return {
        isError: false,
        manifest: response.manifest,
      };
    }
  } catch {
    return {
      isError: true,
      manifest: null,
    };
  }

  return {
    isError: false,
    manifest: null,
  };
};
