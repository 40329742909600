import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingOnlineStatus } from 'shared/components/NewbuildingOnlineStatus';
import { getAgentAvailability } from 'shared/selectors';

export const NewbuildingPushToCallStatusContainer = () => {
  const availability = useSelector(getAgentAvailability);

  if (!availability?.available) {
    return null;
  }

  return <NewbuildingOnlineStatus isOnline={true} onlineStatusText="Свяжитесь с застройщиком, пока он онлайн" />;
};
