import { FormikBag, withFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';

import { ISaveFormRequest } from '../../../repositories/deal-rent-announcements/v1/save-form';
import { getRenterFromState } from '../../../selectors/renterForm/selectors';
import { changePhoneConfirmed, saveForm, sendValidationCode, updateForm } from '../../../store/renterForm';
import { withAnalytics } from '../../../utils/withAnalytics';
import { IThirdStepProps, ThirdStep } from '../../components/ThirdStep';

export interface IThirdStepContainerProps {
  onClose(): void;
  onClickBack(): void;
  onSaveForm(values: ISaveFormRequest): void;
}

const ThirdStepWithAnalytics = withAnalytics<IThirdStepProps>(
  {
    category: 'SafeRent',
    label: 'renter_form_third',
  },
  ThirdStep,
);

export interface IThirdStepWithFormikContainerProps extends IThirdStepContainerProps {
  open: boolean;
  initialFormState: ISaveFormRequest;
  phoneConfirmed: boolean;
  isLoading: boolean;
  error: string | null;
  onClick(values: ISaveFormRequest, phoneConfirmed: boolean): void;
  changePhoneConfirmed(value: boolean): void;
}

const handleSubmit = (
  values: ISaveFormRequest,
  { props: { onClick, phoneConfirmed } }: FormikBag<IThirdStepWithFormikContainerProps, ISaveFormRequest>,
) => {
  onClick(values, phoneConfirmed);
};

const mapPropsToValues = ({ initialFormState }: IThirdStepWithFormikContainerProps): ISaveFormRequest => ({
  ...initialFormState,
});

const ThirdStepWithFormik = withFormik<IThirdStepWithFormikContainerProps, ISaveFormRequest>({
  mapPropsToValues,
  validationSchema: object({
    phone: string()
      .matches(/^\d( ?-?\(?\)?\d){9}$/, { message: 'Некорректный номер телефона', excludeEmptyString: false })
      .required(),
  }),
  handleSubmit,
  enableReinitialize: true,
})(ThirdStepWithAnalytics);

const ThirdStepWithFormikContainer: React.FC<React.PropsWithChildren<IThirdStepWithFormikContainerProps>> = props => {
  return <ThirdStepWithFormik {...props} />;
};

export const ThirdStepContainer = (props: IThirdStepContainerProps) => {
  const { initialFormState, isLoading, isSendSMSLoading, error, phoneConfirmed } = useSelector(getRenterFromState);

  const dispatch = useDispatch();

  const onClick = React.useCallback(
    (values: ISaveFormRequest, phoneConfirmed: boolean) => {
      dispatch(updateForm({ form: values }));

      if (phoneConfirmed) {
        props.onSaveForm({ ...values });
      } else {
        dispatch(sendValidationCode({ phone: `+7${values.phone}` }));
      }
    },
    [updateForm, sendValidationCode, saveForm],
  );

  const onChangePhone = React.useCallback(() => {
    dispatch(changePhoneConfirmed(false));
  }, [dispatch, changePhoneConfirmed]);

  return (
    <ThirdStepWithFormikContainer
      {...props}
      phoneConfirmed={phoneConfirmed}
      open={true}
      initialFormState={initialFormState}
      isLoading={isLoading || isSendSMSLoading}
      error={error}
      onClick={onClick}
      changePhoneConfirmed={onChangePhone}
    />
  );
};
