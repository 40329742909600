import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectAsideCompactMode } from 'shared/selectors/aside';

import { OfferFactItem, OfferFactsInSidebar } from '../../components/OfferFactsInSidebar';
import { selectSidebarData } from '../../selectors';

export const OfferFactsInSidebarContainer: React.FC = () => {
  const factsList = useSelector(selectSidebarData);
  const isCompactModeEnabled = useSelector(selectAsideCompactMode);

  if (!factsList || !factsList.length || isCompactModeEnabled) {
    return null;
  }

  return (
    <OfferFactsInSidebar>
      {factsList.map(fact => (
        <OfferFactItem key={fact.title} {...fact} />
      ))}
    </OfferFactsInSidebar>
  );
};
