import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { transformSimilarNewObjects } from 'shared/mappers/transformSimilarNewObjects';

import { hasWithoutCompetitors } from '../components/similar_offers/utils/hasWithoutCompetitors';
import { similarOffersSelector, offerDataSelector } from '../selectors';
import { EModelTypes, requestSimilarOffers } from '../store/similar';

import type { DispatchReduxAction } from '../store';

export function useSimilarOffersGroups(userBasedVisible = true) {
  const dispatch = useDispatch<DispatchReduxAction>();
  const [offersLoaded, setOfferLoaded] = useState(false);
  const similarOffers = useSelector(similarOffersSelector);
  const offerData = useSelector(offerDataSelector);
  const withoutCompetitors = useMemo<boolean>(() => hasWithoutCompetitors(similarOffers), [similarOffers]);

  useEffect(() => {
    const promises: Promise<void>[] = [];

    const {
      offer: { cianId: offerId, publishedUserId },
    } = offerData;

    promises.push(
      dispatch(
        requestSimilarOffers({
          offerId,
          modelType: EModelTypes.HistoryBased,
          publishedUserId,
        }),
      ).catch(e => e),
    );

    if (userBasedVisible) {
      promises.push(
        dispatch(
          requestSimilarOffers({
            offerId,
            modelType: EModelTypes.UserBased,
            publishedUserId,
          }),
        ).catch(e => e),
      );
    }

    if (!withoutCompetitors) {
      promises.push(
        dispatch(
          requestSimilarOffers({
            offerId,
            modelType: EModelTypes.ItemBased,
            publishedUserId,
          }),
        ).catch(e => e),
      );
      promises.push(
        dispatch(
          requestSimilarOffers({
            offerId,
            modelType: EModelTypes.NewItemBased,
            publishedUserId,
          }),
        ).catch(e => e),
      );
    }

    if (promises.length) {
      Promise.all(promises).then(() => setOfferLoaded(true));
    }
  }, [dispatch, offerData, userBasedVisible, withoutCompetitors]);

  return useMemo(
    () => ({
      offerData,
      similarOffers: {
        ...similarOffers,
        newobjectItemBased: {
          list: transformSimilarNewObjects(offerData.similarNewObjects || []),
          modelVersion: '',
          withoutCompetitors: true,
        },
      },
      withoutCompetitors,
      offersLoaded,
    }),
    [offerData, similarOffers, withoutCompetitors, offersLoaded],
  );
}
