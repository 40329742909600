import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { UIHeading2, ArticleParagraph1 } from '@cian/ui-kit';
import { Button } from '@cian/ui-kit/button';
import { ModalWindow } from '@cian/ui-kit/modal';
import * as React from 'react';

import * as styles from './SubsidisedMortgageSendOfferModal.css';
import image from './assets/image.svg';

interface ISubsidisedMortgageSendOfferModalProps {
  title: string;
  text: string;
  isChatEnabled: boolean;
  onClose(): void;
  onShowPhone(): void;
  onSendOffer(): void;
  onShow(buttonsList: string[]): void;
}

export const SubsidisedMortgageSendOfferModal = ({
  onClose,
  title,
  text,
  isChatEnabled,
  onSendOffer,
  onShowPhone,
  onShow,
}: ISubsidisedMortgageSendOfferModalProps) => {
  const handleShown = React.useCallback(() => {
    onShow([isChatEnabled ? 'send_offer' : 'call']);
  }, [onShow, isChatEnabled]);

  return (
    <ModalWindow
      open
      onClose={onClose}
      portal
      size="XS"
      width={656}
      content={
        <ActionAfterViewObserver callback={handleShown} triggerOnce>
          <div className={styles['container']}>
            <img className={styles['image']} src={image} />
            <UIHeading2>{title}</UIHeading2>
            <ArticleParagraph1>{text}</ArticleParagraph1>
          </div>
        </ActionAfterViewObserver>
      }
      footer={
        isChatEnabled ? (
          <Button theme="fill_primary" size="L" onClick={onSendOffer}>
            Отправить предложение
          </Button>
        ) : (
          <Button theme="fill_primary" size="L" onClick={onShowPhone}>
            Показать телефон
          </Button>
        )
      }
    />
  );
};
