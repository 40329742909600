import { setCookie } from '@cian/utils/lib/browser/cookie';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { getCookie } from 'browser/utils/cookie';

import { UXFB_COOKIE_KEY } from 'shared/constants/uxfb';
import { selectOfferId } from 'shared/selectors';
import { getUxfbVisitedOffersLimit, parseUxfbVisitedOffers } from 'shared/utils/uxfb';

import { useApplicationContext } from '../ApplicationContext';

export const UxfbCookieUpdater = () => {
  const { config } = useApplicationContext();

  const uxfbVisitedOffersLimit = getUxfbVisitedOffersLimit(config);
  const offerId = useSelector(selectOfferId);

  React.useEffect(() => {
    const visitedOffers = parseUxfbVisitedOffers(getCookie(UXFB_COOKIE_KEY));

    if (visitedOffers.length < uxfbVisitedOffersLimit && !visitedOffers.includes(offerId)) {
      setCookie(UXFB_COOKIE_KEY, JSON.stringify([...visitedOffers, offerId]));
    }
  }, [offerId, uxfbVisitedOffersLimit]);

  return null;
};
