import { IState } from '../../store';
import { getCustomUrl } from '../../utils/tracking/getCustomUrl';
import { selectOfferAgent, offerSelector } from '../offerData';

export const getOfferCustomUrl = (state: IState): string => {
  const offer = offerSelector(state);
  const agent = selectOfferAgent(state);

  return getCustomUrl(offer, agent);
};
