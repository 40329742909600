import * as React from 'react';

// TODO: обновить иконку IconMapMCD516 в ui-kit и использовать ее, а эту удалить
/* istanbul ignore next */
export const IconMapMCD516: React.FC = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.00003 2H-0.00463867V4H1L1.00223 14L3.00003 14C6.31372 14 8.99997 11.3137 8.99997 7.99999C8.99997 4.68633 6.31372 2 3.00003 2ZM3.00002 12C5.20912 12 6.99997 10.2091 6.99997 7.99999C6.99997 5.79087 5.20912 4 3.00003 4L3.00002 12Z"
      fill="currentColor"
    />
    <path
      d="M11.9999 8.50001L9.99988 8.50001V2.00316L15 2.00316L15 4.00316L11.9999 4.00316V6.50001C14.0906 6.50001 15.9999 7.92942 15.9999 10.25C15.9999 12.5706 14.0906 14 11.9999 14C10.6766 14 9.29832 13.3329 8.60547 11.9472L10.3943 11.0528C10.7015 11.6671 11.3232 12 11.9999 12C13.2229 12 13.9999 11.2431 13.9999 10.25C13.9999 9.25691 13.2229 8.50005 11.9999 8.50001Z"
      fill="currentColor"
    />
  </svg>
);
