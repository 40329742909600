import { IState } from 'shared/store';

export const selectFloorTitle = (state: IState) => {
  const {
    offerData: { offer },
  } = state;

  if (offer.floorNumber && offer.building?.floorsCount) {
    return `, ${offer.floorNumber}/${offer.building.floorsCount} этаж`;
  }

  return null;
};
