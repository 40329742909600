import * as React from 'react';

import * as styles from './styles.css';
import { pluralize } from '../../utils/pluralize';

const MORTGAGE_LINK =
  'https://www.cian.ru/ipoteka-main/?cian_source=cian&cian_medium=trap&cian_campaign=side_bar&cian_content=link_under_show_phone&cian_term=card';

interface IMortgageSidebarBannerProps {
  banksLength: number;
  rate?: number;
  onClick?(): void;
}

export function MortgageSidebarBanner({ banksLength, rate = 5.72, onClick }: IMortgageSidebarBannerProps) {
  return (
    <a href={MORTGAGE_LINK} target="_blank" rel="noopener noreferrer" onClick={onClick} className={styles['banner']}>
      <div className={styles['logo']} />
      {banksLength > 0 && <div className={styles['paragraph']}>Один запрос в {pluralizeToBanks(banksLength)}</div>}
      <div className={styles['paragraph']}>10 минут на предварительное решение по ипотеке</div>
      <div className={styles['rate']}>Ставки от {rate.toLocaleString('ru-RU')}%</div>
    </a>
  );
}

const pluralToBanksForms: [string, string, string] = ['банк', 'банка', 'банков'];

function pluralizeToBanks(count: number) {
  return pluralize(count, pluralToBanksForms);
}
