import { UIHeading1 } from '@cian/ui-kit';
import clsx from 'clsx';
import * as React from 'react';

import * as styles from './NewbuildingPriceInfo.css';

interface INewbuildingPriceInfoProps {
  price: string;
  priceRelevance: React.ReactElement | null;
  priceHistory: React.ReactElement | null;
  labels?: React.ReactElement | null;
  discount?: React.ReactElement | null;
  isCompactModeEnabled?: boolean;
}

export const NewbuildingPriceInfo = ({
  price,
  priceHistory,
  labels,
  discount,
  isCompactModeEnabled,
  priceRelevance,
}: INewbuildingPriceInfoProps) => {
  return (
    <div className={styles['price']}>
      <div data-testid="price-relevance" className={styles['relevance']}>
        {priceRelevance}
      </div>
      <div data-testid="price-amount" className={styles['amount']}>
        <UIHeading1>{price}</UIHeading1>
      </div>
      <div data-testid="price-history-button" className={styles['history']}>
        {priceHistory}
      </div>
      <div className={styles['labels']}>{labels}</div>
      {discount && (
        <div className={clsx(styles['discount'], { [styles['inline']]: isCompactModeEnabled })}>{discount}</div>
      )}
    </div>
  );
};
