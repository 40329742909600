import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { AgentDescription, AgentReviews } from '../../../components/AgencyBrandingAsideCard';
import { SuperAgentLevelViewContainer } from '../../../containers/SuperAgentLevelViewContainer';
import { selectOfferAgent, selectAgentName, selectAgentReviews } from '../../../selectors';
import { selectAgentUrl } from '../../../selectors/offerData/agent';
import { trackAgencyBrandingCardClick } from '../../../utils/analytics';
import { getAgentReviewsTitle } from '../../../utils/branding';

export const AgencyBrandingAgentCardContainer: React.FC = () => {
  const agent = useSelector(selectOfferAgent, shallowEqual);
  const agentName = useSelector(selectAgentName);
  const { entityRate, totalCount: agentReviewsCount } = useSelector(selectAgentReviews, shallowEqual);
  const agentLink = useSelector(selectAgentUrl);

  const { absoluteAvatarUrl } = agent;
  const agentReviewTitle = getAgentReviewsTitle(entityRate, agentReviewsCount);

  const handleAgentNameClick = React.useCallback(() => {
    trackAgencyBrandingCardClick('sub');
  }, []);

  return (
    <AgentDescription
      agentLink={agentLink}
      agentName={agentName}
      agentReviews={agentReviewTitle && <AgentReviews title={agentReviewTitle} />}
      agentAvatar={absoluteAvatarUrl}
      onAgentNameClick={handleAgentNameClick}
      qualityInfo={<SuperAgentLevelViewContainer />}
    />
  );
};
