import { IOfferRecommendation } from 'shared/types/microfrontends/newbuildingCallbackWidget';

import { isValidPhone, mapPhoneToString } from './mapPhoneToString';
import { IPhone, ISimilarOffer } from '../../store/similar';

export interface ISimilarOfferRecommendation extends ISimilarOffer {
  newbuildingName: string;
  phones: IPhone[];
}

export const mapSimilarOfferToRecommendation = (item: ISimilarOfferRecommendation): IOfferRecommendation => {
  return {
    agentPhone: mapPhoneToString(item.phones[0]),
    formattedPrice: item.formattedPrice,
    newbuildingName: item.newbuildingName,
    photoUrl: item.photoUrl,
    realtyId: item.realtyId,
    title: item.title,
    type: 'offer',
    underground: item.underground,
    url: item.url,
  };
};

export const isSimilarOfferRecommendation = (item: ISimilarOffer): item is ISimilarOfferRecommendation =>
  Boolean(item.newbuildingName && item.phones && item.phones[0] && isValidPhone(item.phones[0]));
