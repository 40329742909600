import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { useIsElectronicTradingOffer } from 'shared/hooks/useIsElectronicTradingOffer';
import { selectIsAuctionBankObject } from 'shared/selectors/selectIsAuctionBankObject';

import { PriceAuction } from './PriceAuction';

export const PriceAuctionContainer: FC = () => {
  const isElectronicTradingOffer = useIsElectronicTradingOffer();
  const isAuctionBankObject = useSelector(selectIsAuctionBankObject);

  return isElectronicTradingOffer || isAuctionBankObject ? <PriceAuction /> : null;
};
