import { Button, ModalWindow, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './NotAvailablePopup.css';

interface INotAvailablePopupProps {
  onClose(): void;
  onWriteMessage(): void;
  onCallAnyway(): void;
  onShow(): void;
  title: string;
  message: string;
}

export const NotAvailablePopup: React.FC<INotAvailablePopupProps> = ({
  onClose,
  onWriteMessage,
  onCallAnyway,
  onShow,
  title,
  message,
}) => {
  React.useEffect(onShow, [onShow]);

  return (
    <ModalWindow
      open={true}
      onClose={onClose}
      title={title}
      width={637}
      content={<UIText1>{message}</UIText1>}
      footer={
        <div className={styles['buttons']}>
          <Button onClick={onWriteMessage}>Написать сообщение</Button>
          <Button theme="fill_white_primary" onClick={onCallAnyway}>
            Всё равно позвонить
          </Button>
        </div>
      }
    />
  );
};
