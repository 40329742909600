import * as React from 'react';
import { useSelector } from 'react-redux';

import { IPanoramaBannerProps, PanoramaBanner } from '../../components/PanoramaBanner';
import { IState } from '../../store';
import { useApplicationContext } from '../ApplicationContext';

export type TPanoramaBannerContainerProps = Omit<IPanoramaBannerProps, 'panoramaUrl'>;

export const PanoramaBannerContainer: React.FC<React.PropsWithChildren<TPanoramaBannerContainerProps>> = ({
  ...sharedProps
}) => {
  const subdomain = useSelector<IState, string>(state => state.pageData.subdomain);

  const { config } = useApplicationContext();
  const panoramaEnabled = config.get<boolean>('panoramaBanner.enabled') || false;
  const panoramaUrl = config.get<string>('panoramaBannerUrl') || null;

  if (subdomain !== 'nn' || !panoramaEnabled || !panoramaUrl) {
    return null;
  }

  return <PanoramaBanner {...sharedProps} panoramaUrl={panoramaUrl} />;
};
