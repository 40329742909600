import { IConfig } from '@cian/config/shared';
import { ConsultantWidgetDesktop, prepareUserData } from '@cian/newbuilding-chat-component';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocalStorage } from 'usehooks-ts';

import { trackShowButton, trackClickChatOpen, trackClickChatClose } from './analytics';
import { isNeedToShow, updateShownTimestamp } from './utils/periodicActions';
import { prepareMessangers } from './utils/prepareMessangers';
import { CHAT_OPEN_DELAY, CHAT_FORCE_OPEN_KEY } from '../../constants/newbuildingChat';
import { getWebimUserDataSelector } from '../../selectors/getWebimUserData';
import { accountNameSelector, chatAvailableSelector, chatHostSelector } from '../../selectors/newbuildingConsultant';

interface INewbuildingChatContainerProps {
  config: IConfig;
}

export function NewbuildingChatContainer({ config }: INewbuildingChatContainerProps) {
  const [isOpen, toggleOpen] = React.useState(false);
  const [needForceOpen, setNeedForceOpen] = React.useState(false);
  const [lastOpen, setLastOpen] = useLocalStorage<number>(CHAT_FORCE_OPEN_KEY, new Date().getTime());

  const onOpenedChange = React.useCallback(
    (isOpen: boolean) => {
      toggleOpen(isOpen);

      if (isOpen) {
        setNeedForceOpen(false);
        trackClickChatOpen();
      } else {
        trackClickChatClose();
      }
    },
    [needForceOpen],
  );

  const needForceOpenRS = config.get<boolean>('newbuildingChat.needForceOpen');

  const accountName = useSelector(accountNameSelector);
  const host = useSelector(chatHostSelector);
  const chatAvailable = useSelector(chatAvailableSelector);
  const userData = useSelector(getWebimUserDataSelector);

  const webimProps = React.useMemo(
    () => ({
      accountName: accountName as string,
      userData: prepareUserData(userData),
      host,
    }),
    [],
  );
  const messangersLinks = React.useMemo(() => prepareMessangers(config), []);

  const onReady = React.useCallback(() => {
    if (chatAvailable && needForceOpen) {
      setTimeout(() => {
        toggleOpen(true);
        setNeedForceOpen(false);
        setLastOpen(updateShownTimestamp());
      }, CHAT_OPEN_DELAY);
    }
  }, [chatAvailable, needForceOpen]);

  React.useEffect(() => {
    if (needForceOpenRS && isNeedToShow(lastOpen)) {
      setNeedForceOpen(true);
    }
  }, []);

  React.useEffect(() => {
    if (chatAvailable) {
      trackShowButton();
    }
  }, [chatAvailable]);

  if (!chatAvailable) {
    return null;
  }

  return (
    <ConsultantWidgetDesktop
      webimProps={webimProps}
      chatOpened={isOpen}
      messengersLinks={messangersLinks}
      toggleOpen={onOpenedChange}
      onReady={onReady}
    />
  );
}
