import cx from 'clsx';
import * as React from 'react';

import * as styles from './styles/styles.css';

interface IAgentTypeProps {
  Icon: React.ReactNode;
  title: string;
}

export function AgentType({ Icon, title }: IAgentTypeProps) {
  return (
    <div className={cx(styles['type_block'], !Icon && styles['type_block--without_icon'])}>
      {Icon}
      <div className={styles['type']}>{title}</div>
    </div>
  );
}
