import { connect } from 'react-redux';

import { InfoBlock } from './InfoBlock';
import { IState } from '../../../store';

export const mapStateToProps = (state: IState) => ({
  agent: state.offerData.agent,
  offerData: state.offerData,
});

export const InfoBlockContainer = connect(mapStateToProps)(InfoBlock);
