import { connect } from 'react-redux';

import { TDealType, TOfferType } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { Comment, ICommentDispatch, ICommentProps } from './index';
import { changeEdditing, clearError, closeComments, setCommentText, submitComment } from '../../../store/comments';

import type { DispatchReduxAction, IState } from '../../../store';

function mapStateToProps(state: IState): ICommentProps {
  return {
    commentText: state.commentsData.commentText,
    commentTextFetched:
      (state.offerData.notes && state.offerData.notes.offer && state.offerData.notes.offer.text) || '',
    dealType: state.offerData.offer.dealType,
    editing: state.commentsData.editing,
    isCommentsVisible: state.printModal.isCommentsVisible,
    messageError: state.commentsData.messageError,
    offerId: state.offerData.offer.cianId,
    offerType: state.offerData.offer.offerType,
    statusFetch: state.commentsData.statusFetch,
  };
}

function mapDispatchToProps(dispatch: DispatchReduxAction): ICommentDispatch {
  return {
    changeEdditing: (editing: boolean) => {
      dispatch(changeEdditing(editing));
    },
    clearError: () => {
      dispatch(clearError());
    },
    closeComments: () => {
      dispatch(closeComments());
    },
    setCommentText: (commentText: string) => {
      dispatch(setCommentText(commentText));
    },
    submitComment: (
      host: string,
      text: string,
      action: 'save' | 'delete',
      oid: number,
      dealType: TDealType,
      offerType: TOfferType,
    ) => {
      dispatch(submitComment(host, text, action, oid, dealType, offerType));
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CommentContainer = connect<ICommentProps, ICommentDispatch, any>(
  mapStateToProps,
  mapDispatchToProps,
)(Comment);
