import * as React from 'react';

import * as styles from './SubscriptionAuthorName.css';
import { ISubscriptionAuthorNameProps } from './types';

export const SubscriptionAuthorName = (props: ISubscriptionAuthorNameProps) => {
  const { name, nameUrl, handleLinkClick } = props;

  return (
    <div className={styles['name_wrapper']}>
      {!nameUrl && <div className={styles['name']}>{name}</div>}
      {!!nameUrl && (
        <a
          href={nameUrl}
          className={styles['name']}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleLinkClick}
        >
          {name}
        </a>
      )}
    </div>
  );
};
