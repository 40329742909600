import * as React from 'react';

export const IconTop3 = (props: React.HTMLAttributes<SVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <g fill="none" fillRule="evenodd">
      <circle cx="7" cy="7" r="7" fill="currentColor" />
      <path fill="#FFF" d="M11 8.1L7 2 3 8.1h2.5v3h3v-3z" />
    </g>
  </svg>
);
