import { ca } from '@cian/analytics';

import { IUserTrackingData, ITrackingPageData } from '../types';

type TEventCategory = 'LinkIpoteka' | 'BannerIpoteka' | 'VijetIpoteka';
type TEventAction = 'show' | 'click';

export const trackAdFoxMortgage = (
  action: TEventAction,
  category: TEventCategory,
  label: string,
  user: IUserTrackingData,
  page: ITrackingPageData,
) => {
  ca('eventSite', {
    name: 'ipotekaevent',
    action,
    category,
    label,
    user,
    page,
  });
};
