import { Button } from '@cian/ui-kit';
import { IButtonProps } from '@cian/ui-kit/button/types';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { useChats } from 'shared/hooks/useChats';
import { selectDailyrentBookingCalendarDefaultMessage } from 'shared/selectors/dailyrentBookingCalendar';

import { SendMessageButton } from '../../components/SendMessageButton/SendMessageButton';
import { PopupOwnOfferContainer } from '../../components/popups/own_offer/container';
import { getAgentAvailability, isNewbuildingFromDeveloperSelector, selectOffer, selectUser } from '../../selectors';
import { getAgent } from '../../selectors/newObject/getAgent';
import { selectIsChatsEnabled } from '../../selectors/offerChat';
import { trackSendMessageClick } from '../ChatPopupContainer/tracking';

interface ISendMessageButtonContainerProps {
  size?: IButtonProps['size'];
  title?: string;
}

export const SendMessageButtonContainer = ({ size = 'M', title = 'Написать' }: ISendMessageButtonContainerProps) => {
  const offer = useSelector(selectOffer);
  const { handleOpenChat } = useChats();
  const agentAvailability = useSelector(getAgentAvailability);
  const agent = useSelector(getAgent);
  const user = useSelector(selectUser);
  const isChatsEnabled = useSelector(selectIsChatsEnabled);
  const isAgentNotAvailable = agentAvailability && !agentAvailability.available;
  const [isOwnOfferPopupOpen, setOwnOfferPopupOpen] = React.useState(false);
  const dailyrentDefaultMessage = useSelector(selectDailyrentBookingCalendarDefaultMessage);
  const isNewbuildingFromDeveloper = useSelector(isNewbuildingFromDeveloperSelector);

  const type = isAgentNotAvailable ? 'open_message' : undefined;

  const onClick = () => {
    trackSendMessageClick({ offer, agent });

    if (user && user.realtyUserId === offer.userId) {
      setOwnOfferPopupOpen(true);

      return;
    }

    handleOpenChat({
      type,
      shouldSendDefaultMessage: !!dailyrentDefaultMessage,
      defaultMessage: dailyrentDefaultMessage,
    });
  };

  const ownOfferPopupClose = () => setOwnOfferPopupOpen(false);

  if (!(isChatsEnabled && !offer.dealRentVersion)) {
    return null;
  }

  return (
    <>
      <PopupOwnOfferContainer isOpen={isOwnOfferPopupOpen} onClose={ownOfferPopupClose} />

      <SendMessageButton builderButton={isNewbuildingFromDeveloper}>
        <Button theme="fill_secondary" size={size} onClick={onClick} fullWidth>
          {title}
        </Button>
      </SendMessageButton>
    </>
  );
};
