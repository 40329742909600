import * as Highcharts from 'highcharts';
import * as React from 'react';

export interface IChartProps {
  data: [number, number][];
}

export class Chart extends React.Component<IChartProps, {}> {
  private chart: HTMLDivElement | null;

  public componentDidMount() {
    if (this.chart) {
      // https://github.com/highcharts/highcharts/issues/14802
      window.Highcharts = Highcharts;

      Highcharts.setOptions({
        lang: {
          months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
          shortMonths: [
            'января',
            'февраля',
            'марта',
            'апреля',
            'мая',
            'июня',
            'июля',
            'августа',
            'сентября',
            'октября',
            'ноября',
            'декабря',
          ],
        },
      });

      Highcharts.chart(this.chart, {
        chart: {
          height: 266,
          type: 'spline',
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: true,
            },
            pointInterval: 10,
          },
        },
        series: [
          {
            type: 'spline',
            color: '#47749C',
            data: this.props.data,
            name: 'Количество просмотров',
          },
        ],
        title: {
          text: '',
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '{point.x:%e %b}: {point.y}',
        },
        xAxis: {
          dateTimeLabelFormats: {
            day: '%e.%m',
          },
          gridLineWidth: 1,
          tickInterval: 24 * 3600 * 1000,
          tickmarkPlacement: 'on',
          type: 'datetime',
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Количество',
          },
        },
      });
    }
  }

  public render() {
    return <div ref={chart => (this.chart = chart)}></div>;
  }
}
