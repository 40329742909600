import * as React from 'react';

import * as styles from './pageFooter.css';

export const OfferCardPageLayoutFooter = (props: React.PropsWithChildren<{}>) => {
  return (
    <div className={styles['footer']}>
      <div className={styles['container']}>{props.children}</div>
    </div>
  );
};
