import { UIHeading1, UIText1, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

export interface IDeveloperStats {
  title: string;
  value: string;
  link?: string;
}

export interface IDeveloperLayoutProps {
  stats: IDeveloperStats[];
  name: string | null;
  logoUrl: string | null;
  developerUrl: string | null;
  contactButton?: React.ReactElement | null;
  callButton?: React.ReactElement | null;
  phonesOpenedInfo?: React.ReactElement;
  phonesShown?: boolean;
}

export const DeveloperInfo: React.FC<React.PropsWithChildren<IDeveloperLayoutProps>> = ({
  name,
  stats,
  logoUrl,
  developerUrl,
  contactButton,
  callButton,
  phonesOpenedInfo,
  phonesShown,
}) => {
  return (
    <div className={style['container']}>
      <div className={style['left']}>
        <div className={style['title']}>
          <UIText2 color="text-primary-default">Застройщик</UIText2>
          {name && <UIHeading1 color="text-primary-default">{name}</UIHeading1>}
        </div>

        <div className={style['stats']}>
          {stats.map(({ title, value, link }, index) => {
            return link ? (
              <div key={title + index} className={style['item']}>
                <UIText1 color="text-primary-default">{title}</UIText1>
                <a href={link} target="_blank" rel="noreferrer">
                  <UIText1 color="text-main-default">{value}</UIText1>
                </a>
              </div>
            ) : (
              <div key={title + index} className={style['item']}>
                <UIText1 color="text-primary-default">{title}</UIText1>
                <UIText1 color="text-primary-default">{value}</UIText1>
              </div>
            );
          })}
        </div>

        <div className={style['buttons']}>
          {contactButton && <div className={style['button']}>{contactButton}</div>}

          {callButton && <div className={style['button']}>{callButton}</div>}

          {developerUrl && (
            <div className={style['button']}>
              <a href={developerUrl} target="_blank" rel="noreferrer">
                <UIText2 color="text-main-default" fontWeight="bold">
                  Подробнее о застройщике
                </UIText2>
              </a>
            </div>
          )}
        </div>

        {phonesShown && <div className={style['phones-opened-info']}>{phonesOpenedInfo}</div>}
      </div>

      <div
        className={style['logo']}
        style={logoUrl ? { backgroundImage: `url(${logoUrl})` } : { backgroundColor: `#f1f2f4` }}
      />
    </div>
  );
};
