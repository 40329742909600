import * as React from 'react';
import { useSelector } from 'react-redux';

import { BookingInfo } from 'shared/components/BookingInfo';
import { selectDailyrentBookingPaymentSummary } from 'shared/selectors/dailyrentBookingCalendar';

export const BookingSummaryContainer = () => {
  const paymentSummary = useSelector(selectDailyrentBookingPaymentSummary);

  if (!paymentSummary) {
    return null;
  }

  return <BookingInfo paymentSummary={paymentSummary} />;
};
