export function calcPayment(price: number, initialPayment: number, rate: number, months: number) {
  const i = rate / 12 / 100;
  const pow = Math.pow(i + 1, months);
  const K = (i * pow) / (pow - 1);

  let payment = Math.round((price - initialPayment) * K);

  if (payment < 0) {
    payment = 0;
  }

  return payment;
}
