import * as React from 'react';
import { useSelector } from 'react-redux';

import { useFormattedPublisherType } from 'shared/hooks/selectors/useAgentSelector';
import { getMasterOrSelfLink } from 'shared/selectors/agentSelectors';

import { getTitle } from './utils';
import { SubscriptionAuthorTitle } from '../../../components/subscriptionBrand';
import { selectAgencyName } from '../../../selectors';
import { selectIsSubAgent } from '../../../selectors/offerData/agent';
import { trackGoToAgentCard } from '../SubscriptionAuthorBrand/tracking';

export const SubscriptionAuthorTitleContainer = () => {
  const isSubAgent = useSelector(selectIsSubAgent);
  const agencyName = useSelector(selectAgencyName);
  const agencyUrl = useSelector(getMasterOrSelfLink);

  const title = useFormattedPublisherType(getTitle(isSubAgent));

  /* istanbul ignore next */
  const agency = isSubAgent && (
    <>
      {'・'}
      {!agencyUrl && agencyName}
      {!!agencyUrl && (
        <a
          href={agencyUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={trackGoToAgentCard('to_realtorCard_name')}
        >
          {agencyName}
        </a>
      )}
    </>
  );

  return <SubscriptionAuthorTitle title={title} agency={agency} />;
};
