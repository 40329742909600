import { createSelector } from '@reduxjs/toolkit';
import { pathOr } from 'ramda';

import { selectIsNewbuildingPremiumRate } from 'shared/selectors/newbuildingPremiumRate';

import { getNewbuildingName } from './newObject';
import {
  ISimilarOfferRecommendation,
  isSimilarOfferRecommendation,
  mapSimilarOfferToRecommendation,
} from '../mappers/callbackRecommendations';
import { IState } from '../store';
import { ISimilarOffer } from '../store/similar';

// селектор для извлечения списка похожих предложений из состояния
const selectSimilarOffers = (state: IState) =>
  pathOr<ISimilarOffer[]>([], ['similarOffers', 'itemBased', 'list'], state);

// селектор для фильтрации предложений от застройщика
const selectRecommendationsFromBuilder = createSelector([selectSimilarOffers], similarOffers =>
  similarOffers.filter(
    (item: ISimilarOffer): item is ISimilarOfferRecommendation =>
      isSimilarOfferRecommendation(item) && Boolean(item.isFromBuilder),
  ),
);

// селектор для группировки предложений по имени новостройки
const selectRecommendationsByNewbuilding = createSelector(
  [selectRecommendationsFromBuilder, getNewbuildingName],
  (recommendationsFromBuilder, originalName) => {
    const recommendationsMap = recommendationsFromBuilder.reduce((map, item) => {
      if (!map.has(item.newbuildingName)) {
        map.set(item.newbuildingName, item);
      }

      return map;
    }, new Map<string, ISimilarOfferRecommendation>());

    recommendationsMap.delete(originalName);

    return Array.from(recommendationsMap.values());
  },
);

// Финальный селектор для получения рекомендаций
export const getCallbackRecommendations = createSelector(
  [selectRecommendationsByNewbuilding, selectIsNewbuildingPremiumRate],
  (recommendationsByNewbuilding, isNewbuildingPremiumRate) => {
    if (isNewbuildingPremiumRate) {
      return [];
    }

    return recommendationsByNewbuilding.slice(0, 3).map(mapSimilarOfferToRecommendation);
  },
);
