import { createSelector } from '@reduxjs/toolkit';

import { selectTourLink } from './selectTourLink';

export const selectTourLinkWithoutFullscreen = createSelector(selectTourLink, tourLink => {
  if (!tourLink) {
    return '';
  }

  return `${tourLink}${tourLink.includes('?') ? '&' : '?'}fullscreen=no`;
});
