import { UIText2 } from '@cian/ui-kit';
import { IconFunctionalBullet16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as style from './style.css';

interface INewbuildingOnlineStatusProps {
  isOnline: boolean;
  onlineStatusText: string;
}

export const NewbuildingOnlineStatus: React.FC<INewbuildingOnlineStatusProps> = ({ isOnline, onlineStatusText }) => {
  return (
    <div className={style['container']} data-testid="NewbuildingOnlineStatus">
      {isOnline && (
        <div className={style['icon']}>
          <IconFunctionalBullet16 color="current_color" />
        </div>
      )}
      <UIText2 display="inline">{onlineStatusText}</UIText2>
    </div>
  );
};
