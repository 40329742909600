import * as React from 'react';
import { useSelector } from 'react-redux';

import { PhonesContainer } from 'shared/containers/AsideContacts/PhonesContainer';
import { SendMessageButtonContainer } from 'shared/containers/AsideContacts/SendMessageButton';
import { BookButtonContainer } from 'shared/containers/BookButtonContainer';
import { selectShouldRenderContacts } from 'shared/selectors/contacts/selectShouldRenderContacts';
import { selectDailyrentActionType } from 'shared/selectors/dailyrentActionType';
import { EActionType } from 'shared/types/dailyrentActionButtonsType';
import { useIsDailyrentOnlineBookingEnabled } from 'shared/utils/featureToggles/useIsDailyrentOnlineBookingEnabled';

import { OnlineBookingBookButtonContainer } from '../OnlineBookingBookButtonContainer';
import { OnlineBookingCheckDatesButtonContainer } from '../OnlineBookingCheckDatesButtonContainer';

interface IWrapperProps {
  children: React.ReactNode;
}

const Wrapper: React.FC<IWrapperProps> = ({ children }) => {
  return <div data-testid="DailyrentActionButtonsContainer">{children}</div>;
};

export const DailyrentActionButtonsContainer = () => {
  const isDailyrentOnlineBookingEnabled = useIsDailyrentOnlineBookingEnabled();

  const shouldRenderContacts = useSelector(selectShouldRenderContacts);
  const actionType = useSelector(selectDailyrentActionType);

  switch (true) {
    case isDailyrentOnlineBookingEnabled && actionType === EActionType.OtaBooking: {
      return (
        <Wrapper>
          <OnlineBookingBookButtonContainer />
          <SendMessageButtonContainer />
        </Wrapper>
      );
    }
    case isDailyrentOnlineBookingEnabled && actionType === EActionType.OtaCheckDates: {
      return (
        <Wrapper>
          <OnlineBookingCheckDatesButtonContainer />
          <SendMessageButtonContainer />
        </Wrapper>
      );
    }
    case actionType === EActionType.PartnerBooking:
    case actionType === EActionType.PartnerCheckDates: {
      return <BookButtonContainer />;
    }
    default: {
      return (
        <Wrapper>
          {shouldRenderContacts && <PhonesContainer />}
          <SendMessageButtonContainer />
        </Wrapper>
      );
    }
  }
};
