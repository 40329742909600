import { Button, ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import { OnboardingContent } from './OnboardingContent';
import * as styles from './styles.css';

export interface IOnboardingProps {
  open: boolean;
  onClose(): void;
  onClick(): void;
}

export const Onboarding: React.FC<React.PropsWithChildren<IOnboardingProps>> = ({ open, onClose, onClick }) => {
  return (
    <ModalWindow
      title="Заполните анкету для собственника"
      content={<OnboardingContent />}
      footer={
        <div className={styles['buttons-wrap']}>
          <Button onClick={onClick} size="XS">
            Заполнить
          </Button>
        </div>
      }
      onClose={onClose}
      open={open}
    />
  );
};
