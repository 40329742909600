import { UIHeading2, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

interface IPriceItemProps {
  price: React.ReactChild;
  priceTooltip?: React.ReactChild | null;
  caption: React.ReactChild;
  captionTooltip?: React.ReactChild | null;
  testId?: string;
}

export const PriceItem: React.FC<React.PropsWithChildren<IPriceItemProps>> = props => {
  const { price, priceTooltip, caption, captionTooltip, testId } = props;

  return (
    <div data-testid={testId}>
      <div className={styles['price']}>
        <UIHeading2>{price}</UIHeading2>
        {priceTooltip && <div className={styles['price_tooltip']}>{priceTooltip}</div>}
      </div>
      <div className={styles['caption']}>
        <UIText1>{caption}</UIText1>
        {captionTooltip && <div className={styles['caption_tooltip']}>{captionTooltip}</div>}
      </div>
    </div>
  );
};

PriceItem.displayName = 'PriceItem';
