import {
  IconMapMetroSpb16,
  IconMapMetroEkb16,
  IconMapMetroKzn16,
  IconMapMetroNovosib16,
  IconMapMetroSam16,
  IconMapMetroNN16,
  IconMapMetroMsk16,
  IIconProps,
} from '@cian/ui-kit-design-tokens/icons';
import React from 'react';

import { TLineType } from 'shared/types/geo/underground';

import { IconMapMCD116, IconMapMCD216, IconMapMCD316, IconMapMCD416, IconMapMCD516 } from '../icons';

const METRO_ICONS_BY_REGION: Partial<Record<number, React.ComponentType<IIconProps>>> = {
  // Мск и область
  '-1': IconMapMetroMsk16,
  1: IconMapMetroMsk16,
  4593: IconMapMetroMsk16,
  // СПб и область
  '-2': IconMapMetroSpb16,
  2: IconMapMetroSpb16,
  4588: IconMapMetroSpb16,
  // Екб и Свердловская область
  4612: IconMapMetroEkb16,
  4743: IconMapMetroEkb16,
  // Казать и Татарстан
  4618: IconMapMetroKzn16,
  4777: IconMapMetroKzn16,
  // Новосиб и Новосиб область
  4598: IconMapMetroNovosib16,
  4897: IconMapMetroNovosib16,
  // Самара и Самарская область
  4608: IconMapMetroSam16,
  4966: IconMapMetroSam16,
  // Нижний Новгород и Нижегородская область
  4596: IconMapMetroNN16,
  4885: IconMapMetroNN16,
};

const MCD_ICONS_BY_LINE_TYPE: Partial<Record<string, React.ComponentType<IIconProps>>> = {
  mcd1: IconMapMCD116,
  mcd2: IconMapMCD216,
  mcd3: IconMapMCD316,
  mcd4: IconMapMCD416,
  mcd5: IconMapMCD516,
};

interface IParams {
  lineType?: TLineType;
  regionId: number;
}

export const getUndergroundIcon = ({ lineType, regionId }: IParams) => {
  const mcdIcon = lineType ? MCD_ICONS_BY_LINE_TYPE[lineType] : undefined;
  const metroIcon = METRO_ICONS_BY_REGION[regionId];

  return mcdIcon || metroIcon || IconMapMetroMsk16;
};
