import { ca } from '@cian/analytics';

import { IAgentSchema, IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { getCustomUrl } from '../../utils/tracking/getCustomUrl';

export function trackShowPhoneClick(placeType: string, offer: IOfferSchema, agent: IAgentSchema | null) {
  ca('eventEbc', {
    action: `Open_${placeType}`,
    category: 'Phones',
    label: getCustomUrl(offer, agent),
    name: 'oldevent',
    useLastProducts: true,
  });
}
