import { IDailyrentBookingCalendarState } from 'shared/store/dailyrentBookingCalendar/types';

interface ICreateCheckoutPageUrlParams extends Pick<IDailyrentBookingCalendarState, 'dates' | 'guestsCount'> {
  realtyId: number;
}

export const createCheckoutPageUrl = ({
  realtyId,
  dates,
  guestsCount,
}: ICreateCheckoutPageUrlParams): string | undefined => {
  if (!dates.from || !dates.to) {
    return undefined;
  }
  const url = new URL('https://www.cian.ru/dailyrent-checkout/');
  url.searchParams.append('offerId', realtyId.toString());
  url.searchParams.append('checkin', dates.from);
  url.searchParams.append('checkout', dates.to);
  url.searchParams.append('guestsCount', guestsCount.toString());

  return url.toString();
};
