import * as React from 'react';
import { useSelector } from 'react-redux';

import { SuperAgentLevelViewContainer } from 'shared/containers/SuperAgentLevelViewContainer';

import { HomeownerLayout } from '../../../../components/AuthorAsideNew';
import { offerSelector, selectIsByHomeowner, selectOfferAgent } from '../../../../selectors';
import { createAgentIdName } from '../../../../utils/createAgentIdName';
import { getAgentAvatar } from '../../../../utils/getAgentAvatar';

export const HomeownerInfo: React.FC = () => {
  const { homeownerProofs } = useSelector(offerSelector);
  const agent = useSelector(selectOfferAgent);
  const isByHomeowner = useSelector(selectIsByHomeowner);

  const avatar = agent ? getAgentAvatar(agent) : '';
  const userName = agent ? createAgentIdName(agent) : '';

  if (!agent || !isByHomeowner) {
    return null;
  }

  return (
    <HomeownerLayout
      avatar={avatar}
      userName={userName}
      homeownerProofs={homeownerProofs}
      superAgentElement={<SuperAgentLevelViewContainer />}
    />
  );
};
