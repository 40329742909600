import { IconProductTrust12 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './styles.css';

export function NewbuildingBuilderLabel() {
  return (
    <div className={styles['container']}>
      <div className={styles['icon']}>
        <IconProductTrust12 color="icon-main-default" />
      </div>
      <div className={styles['text']}>Надежный застройщик</div>
    </div>
  );
}
