/* eslint-disable react/jsx-no-target-blank */
import { LinkButton } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './Header.css';

interface IHeaderProps {
  title: string;
  showAllUrl: string;
  onClickShowAllButton(): void;
}

export const Header: React.FC<React.PropsWithChildren<IHeaderProps>> = ({
  title,
  showAllUrl,
  onClickShowAllButton,
}) => {
  return (
    <div className={styles.root}>
      <span className={styles.title}>{title}</span>

      <LinkButton theme="fill_secondary" size="XS" href={showAllUrl} target="_blank" onClick={onClickShowAllButton}>
        Посмотреть все объявления
      </LinkButton>
    </div>
  );
};
