import { UIHeading1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './similarOffersSection.css';

export const SimilarOffersSection = (props: React.PropsWithChildren<{}>) => {
  return (
    <div>
      <UIHeading1>Похожие объявления</UIHeading1>
      <div className={styles['content']}>{props.children}</div>
    </div>
  );
};
