import { connect } from 'react-redux';

import {
  ISubscriptionPriceChangesPopupDispatchProps,
  ISubscriptionPriceChangesPopupProps,
  SubscriptionPriceChangesPopup,
} from './index';
import {
  submitSubscriptionPriceChanges,
  submitUnsubscriptionPriceChanges,
  subscriptionPriceChangesPopupClose,
} from '../../store/subscription_price_changes_popup';

import type { DispatchReduxAction, IState } from '../../store';

function mapStateToProps(state: IState): ISubscriptionPriceChangesPopupProps {
  const { error, isOpen, statusFetch, isEmailConfirmed } = state.subscriptionPriceChangesPopup;

  return {
    error,
    isEmailConfirmed,
    isOpen,
    offerId: state.offerData.offer.cianId,
    pushSubscribed: state.pushSubscribed,
    statusFetch,
    subscription: state.offerData.subscription,
    user: state.offerData.user,
  };
}

function mapDispatchToProps(dispatch: DispatchReduxAction): ISubscriptionPriceChangesPopupDispatchProps {
  return {
    submitSubscriptionPriceChanges: (
      email: string,
      offerId: number,
      isSubscribeNews: boolean,
      isPushSubscribe: boolean,
    ) => {
      dispatch(submitSubscriptionPriceChanges(email, offerId, isSubscribeNews, isPushSubscribe));
    },
    submitUnsubscriptionPriceChanges: (offerId: number) => {
      dispatch(submitUnsubscriptionPriceChanges(offerId));
    },
    subscriptionPriceChangesPopupClose: () => {
      dispatch(subscriptionPriceChangesPopupClose());
    },
  };
}

export const SubscriptionPriceChangesPopupContainer = connect<
  ISubscriptionPriceChangesPopupProps,
  ISubscriptionPriceChangesPopupDispatchProps
>(
  mapStateToProps,
  mapDispatchToProps,
)(SubscriptionPriceChangesPopup);
