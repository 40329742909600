import { connect } from 'react-redux';

import { IPremiumProps, Premium } from './index';
import { isMlSearchEnabled } from '../../../../selectors/isMlSearchEnabled';
import { IState } from '../../../../store/index';
import { getDealType, isRent } from '../../../../utils/deal_type';

export const mapStateToProps = (state: IState): IPremiumProps => {
  const {
    offerData: { offer },
  } = state;

  const dealType = getDealType(offer);

  return {
    isVisible: Boolean(state.offerStatsPopup.dataStats && state.offerStatsPopup.dataStats.premium_is_available),
    recoveryLink: offer.recoveryLink,
    isMlSearchEnabled: isMlSearchEnabled(state),
    isRent: isRent(dealType),
  };
};

export const PremiumContainer = connect<IPremiumProps>(mapStateToProps)(Premium);
