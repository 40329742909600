import { ca } from '@cian/analytics';

import { IUserTrackingData, ITrackingPageData } from '../../../../types';

export const trackMortgageInRentShow = (url: string, user: IUserTrackingData, page: ITrackingPageData) => {
  ca('event', {
    name: 'ipotekaevent',
    action: 'show',
    category: 'LinkIpoteka',
    label: url,
    user,
    page,
  });
};

export const trackMortgageInRentClick = (url: string, user: IUserTrackingData, page: ITrackingPageData) => {
  ca('event', {
    name: 'ipotekaevent',
    action: 'click',
    category: 'LinkIpoteka',
    label: url,
    user,
    page,
  });
};
