import { LinkButton, UIHeading1, UIText2 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { IMortgageRatesState } from 'shared/store/mortgage';
import { IBank } from 'shared/types/banksList';

import THUMB_SRC from './img/thumb.svg';
import * as styles from './styles.css';
import { trackMortgageButtonClick } from './tracking';
import { BanksList } from '../../../../packages/mortgage-assets';
import { IFotoramaFrame } from '../types';

interface IProps {
  banksList: IBank[];
  rates: IMortgageRatesState;
  isSuburban?: boolean;
  villageMortgageAllowed?: boolean;
  villageMortgageRate?: number;
}

const defaultRates = {
  suburban: {
    new: 9.6,
    used: 10.5,
  },
  flat: {
    new: 5.72,
    used: 7.4,
  },
};

const formatRate = (rate: number) => rate.toLocaleString('ru-RU');

const getMortgageText = ({ flat, suburban }: IMortgageRatesState, banksList: IBank[], isSuburban?: boolean) => {
  if (isSuburban) {
    return `Покупка домов, таунхаусов. Новостройки от ${formatRate(
      suburban.new || defaultRates.suburban.new,
    )}%. Вторичка от ${formatRate(suburban.used || defaultRates.suburban.used)}%`;
  }

  return `Реальные ипотечные ставки от ${banksList.length} банков - ${formatRate(
    flat.new || defaultRates.flat.new,
  )}% на новостройки, ${formatRate(flat.used || defaultRates.flat.used)}% на вторичку`;
};

export const MortgageFrame: React.FC<React.PropsWithChildren<IProps>> = ({
  banksList,
  isSuburban,
  rates,
  villageMortgageAllowed,
  villageMortgageRate,
}) => {
  if (villageMortgageAllowed) {
    return (
      <div className={cx(styles['frame'], styles['village'])}>
        <div className={styles['content']}>
          <div className={styles['logo']} />
          <div className={styles['header-wrapper']}>
            <UIHeading1 color="text-inverted-default">Cельская ипотека {villageMortgageRate}%</UIHeading1>
          </div>
          <div className={styles['description-wrapper']}>
            <UIText2 color="text-inverted-default">
              В этом населённом пункте действует программа сельской ипотеки. Подайте заявку через Циан.Ипотеку.
            </UIText2>
          </div>
          <LinkButton
            theme="fill_white_primary"
            size="M"
            href="https://cian.ru/ipoteka-main?banner=from_village_gallery_slide"
          >
            Перейти в Циан.Ипотеку
          </LinkButton>
        </div>
      </div>
    );
  }

  return (
    <div className={styles['frame']}>
      <div className={styles['content']}>
        <div className={styles['logo']} />
        <div className={styles['banks']}>
          <BanksList banksList={banksList} />
        </div>
        <div className={styles['header-wrapper']}>
          <UIHeading1 color="text-primary-default">{getMortgageText(rates, banksList, isSuburban)}</UIHeading1>
        </div>
        <LinkButton
          size="M"
          href="https://cian.ru/ipoteka-main?banner=from_photo_desktop_to_anketa"
          onClick={trackMortgageButtonClick}
        >
          Перейти в сервис
        </LinkButton>
      </div>
    </div>
  );
};

export function createMortgageFrame(
  banksList: IBank[],
  rates: IMortgageRatesState,
  isSuburban?: boolean,
  villageMortgageAllowed?: boolean,
  villageMortgageRate?: number,
): IFotoramaFrame | null {
  const markup = renderToStaticMarkup(
    <MortgageFrame
      banksList={banksList}
      isSuburban={isSuburban}
      villageMortgageAllowed={villageMortgageAllowed}
      rates={rates}
      villageMortgageRate={villageMortgageRate}
    />,
  );

  const tempContainer = document.createElement('div');
  tempContainer.innerHTML = markup;
  const frameElement = tempContainer.firstElementChild as Element;

  const goToFormButtonElement = frameElement.querySelector('a') as HTMLAnchorElement;

  goToFormButtonElement.addEventListener('click', trackMortgageButtonClick, true);

  return {
    id: 'mortgage-frame',
    html: frameElement,
    thumb: THUMB_SRC,
  };
}
