/* eslint-disable */

export interface IOfferRosreestrCheckSchema {
  /** Статус проверки **/
  status?: EStatus;
}
export enum EStatus {
  /** Проверка на стороне протухла **/
  'Expired' = 'expired',
  /** Проверка прошла неуспешно **/
  'Fail' = 'fail',
  /** Проверка на стороне росреестра **/
  'InProgress' = 'inProgress',
  /** Проверка прошла успешно **/
  'Success' = 'success',
}
