import { IconToggleHeartOff24, IconToggleHeartOn24 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './FavoriteButtonAside.css';

export interface IFavoriteButtonAside {
  checked: boolean;
  disabled?: boolean;
  onClick(): void;
}

export const FavoriteButtonAside = ({ checked, onClick, disabled }: IFavoriteButtonAside) => {
  if (disabled) {
    return (
      <button className={styles['btn-disabled']}>
        <IconToggleHeartOn24 />
      </button>
    );
  }

  return (
    <button className={styles['btn']} onClick={onClick}>
      {checked ? (
        <IconToggleHeartOn24 color="icon-negative-default" />
      ) : (
        <IconToggleHeartOff24 color="icon-negative-default" />
      )}
    </button>
  );
};
