import * as React from 'react';
import { FC } from 'react';

import { useOfferSelector } from '../../hooks/selectors/useOfferSelector';
import { GoodPriceBadge } from '../GoodPriceBadge';
import { UniqueForCianBadge } from '../UniqueForCianBadge';
import { WrapElementsSpacer } from '../WrapElementsSpacer';

export const CommercialPublishersBadges: FC = () => {
  const { hasFairPrice, isUniqueForCian } = useOfferSelector();

  return (
    <WrapElementsSpacer horizontalSpace={8} verticalSpace={8}>
      {!!isUniqueForCian && <UniqueForCianBadge />}
      {
        /**
         * @todo Удалить строку с экспериментом
         * @description Данный функционал появился в задаче CD-128336, будет удалён в задаче CD-128344
         * Если эксперимент будет удачным, то оставить строку, но удалить комментарий,
         * если эксперимент будет не удачным, то удалить полностью строку
         */
        !!hasFairPrice && <GoodPriceBadge />
      }
    </WrapElementsSpacer>
  );
};
