import { mergePaths } from '@cian/http-api/shared';
import * as React from 'react';

import { IOfferSchema, IUserSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as styles from './index.css';
import { Link } from '../../common/components/link';
import { IPageData } from '../../store';
import { isArrayWithItems } from '../../utils';

export interface IAdminButtonsProps {
  offer: IOfferSchema;
  pageData: IPageData;
  serviceBaseUrl: string;
  user: IUserSchema | null;
}

interface IAdminButtonData {
  title: string;
  url: string;
}

export class AdminButtons extends React.PureComponent<IAdminButtonsProps, {}> {
  public render() {
    const { offer, pageData, serviceBaseUrl, user } = this.props;

    if (!user || !user.permissions) {
      return null;
    }

    const adminButtons: Array<IAdminButtonData> = [];

    if (offer.cianUserId) {
      if (user.permissions.canModerateUsers) {
        adminButtons.push({
          title: 'Редактировать',
          url: `/customers/edit/${offer.cianUserId}`,
        });
      }

      if (user.permissions.canViewUsers) {
        adminButtons.push(
          {
            title: 'Телефоны',
            url: `/customers/phones/?AccountId=${offer.cianUserId}`,
          },
          {
            title: 'Клиент',
            url: `/customers/view/${offer.cianUserId}`,
          },
        );
      }

      if (user.permissions.canViewAnnouncements) {
        adminButtons.push({
          title: 'Проверить',
          url: `/moderate-announcements/index?Compact=true&AnnFilter.OfferType=${pageData.dealType}&AnnFilter.Author=${offer.cianUserId}&AnnFilter.selectType=All`,
        });

        if (offer.cianId) {
          adminButtons.push({
            title: 'Объявление',
            url: `/moderate-announcements/index?Compact=true&AnnFilter.CianObjectId=${offer.cianId}`,
          });
        }

        if (offer.id) {
          adminButtons.push({
            title: 'Лог объявления',
            url: `/announcementslogs?ActionFilter.ObjectId=${offer.id}`,
          });
        }
      }
    }

    if (!isArrayWithItems<IAdminButtonData>(adminButtons)) {
      return null;
    }

    return (
      <section className={styles['container']}>
        <h5 className={styles['title']}>Админ:</h5>
        <ul className={styles['list']}>
          {adminButtons.map(data => (
            <li key={data.title} className={styles['item']}>
              <Link href={mergePaths(serviceBaseUrl, data.url)} target="_blank" rel="nofollow noopener">
                {data.title}
              </Link>
            </li>
          ))}
        </ul>
      </section>
    );
  }
}
