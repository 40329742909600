import { Spinner, ModalWindow } from '@cian/ui-kit';
import { plural } from '@cian/utils';
import * as React from 'react';

import { Chart } from './chart';
import * as styles from './index.css';
import { PremiumContainer } from './promotion/premium/container';
import { Top3Container } from './promotion/top3/container';
import { trackOfferStatsPopupShow } from './tracking';
import { IResponseSuccess } from '../../api/stats/get_offer_stats';
import { EStatusFetch } from '../../store/offer_stats_popup';

export interface IOfferStatsPopupProps {
  dataStats: IResponseSuccess | null;
  isOpen: boolean;
  offerId: number;
  messageError: string;
  publicationDate: number;
  statusFetch: EStatusFetch;
}

export interface IOfferStatsPopupDispatchProps {
  offerStatsPopupClose(): void;
  submitOfferStats(host: string, id: number): void;
}

export type IProps = IOfferStatsPopupProps & IOfferStatsPopupDispatchProps;

export class OfferStatsPopup extends React.PureComponent<IProps, {}> {
  public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      this.props.submitOfferStats(window.location.host, this.props.offerId);

      document.body.style.overflow = 'hidden';
    }

    if (!nextProps.isOpen) {
      document.body.style.overflow = '';
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      trackOfferStatsPopupShow();
    }
  }

  public render() {
    return (
      <ModalWindow
        title="Статистика просмотров за последние 10 дней"
        open={this.props.isOpen}
        onClose={this.props.offerStatsPopupClose}
        content={
          <>
            {this.props.statusFetch === EStatusFetch.isLoad && this.renderPreloader()}
            {this.props.statusFetch === EStatusFetch.error && this.renderError()}
            {this.props.statusFetch === EStatusFetch.ok && this.renderChart()}
            <PremiumContainer />
            <Top3Container />
          </>
        }
      />
    );
  }

  private renderPreloader = () => (
    <div className={styles['preloader']}>
      <Spinner size={24} />
    </div>
  );

  private renderError = () => <div className={styles['error']}>{this.props.messageError}</div>;

  private renderChart = () => {
    if (!this.props.dataStats) {
      return this.renderError();
    }

    const { dataStats } = this.props;

    const totalViewText = plural(dataStats.total_views_count, ['просмотр', 'просмотра', 'просмотров']);
    const tenDaysViewtext = plural(dataStats['10_days_views_count'], ['просмотр', 'просмотра', 'просмотров']);

    return (
      <div>
        <div className={styles['information']}>
          <div>
            {this.props.dataStats.total_views_count} {totalViewText} с даты создания объявления{' '}
            {this.props.publicationDate && this.publishedDateFormatting(this.props.publicationDate)}
          </div>
          <div>
            {this.props.dataStats['10_days_views_count']} {tenDaysViewtext} за последние 10 дней
          </div>
        </div>
        <div className={styles['chart']}>
          <Chart data={this.props.dataStats.data.map<[number, number]>(item => [item[0] * 1000, item[1]])} />
        </div>
      </div>
    );
  };

  private publishedDateFormatting = (dateTimestamp: number): string => {
    const publishedDate = new Date(dateTimestamp * 1000);

    return `${`0${publishedDate.getDate()}`.slice(-2)}.${`0${publishedDate.getMonth() + 1}`.slice(
      -2,
    )}.${publishedDate.getFullYear()}`;
  };
}
