import * as React from 'react';

export const TopArrowIcon = (
  <svg width="10" height="10" viewBox="0 0 48 48" fill="#2B87DB">
    <path
      data-fill-rule="fill"
      d="M40.18 37.09C39.11 36.01 33.71 30.61 24 20.9C14.29 30.61 8.89 36.01 7.81 37.09C6.03 38.88 3.13 38.88 1.34 37.09C-0.45 35.3 -0.45 32.4 1.34 30.61C3.28 28.67 18.82 13.13 20.77 11.19C22.55 9.4 25.45 9.4 27.24 11.19C29.18 13.13 44.72 28.67 46.66 30.61C48.45 32.4 48.45 35.3 46.66 37.09C46.66 37.09 46.66 37.09 46.66 37.09C44.87 38.88 41.97 38.88 40.18 37.09Z"
    />
  </svg>
);
