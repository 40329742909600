import { ca } from '@cian/analytics';

export const trackGuestsCountChange = (offerId: number, label: 'Minus' | 'Plus') => {
  return ca('eventSite', {
    name: 'oldevent',
    category: 'DailyrentGuests',
    action: 'Click',
    label,
    page: {
      pageType: 'Card',
    },
    products: [{ id: offerId }],
  });
};
