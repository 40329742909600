/* eslint-disable react/display-name, @typescript-eslint/member-ordering */
import * as React from 'react';

import { INavigationProps, INavigationLink, NavigationLinks } from '../../../components/NavigationNew';
import { MAP_CONTAINER_ID } from '../../../constants/map';
import { scrollToElement } from '../../../utils/navigation';
import { getOfferTitle, getSquareInformation } from '../../../utils/offer_helpers';
import { SharingButtonContainer } from '../parts';
import {
  trackGoToAuthorClick,
  trackGoToDescriptionClick,
  trackGoToMapClick,
  trackGoToPhotosClick,
  trackGoToServicePlaceOverEvaluationClick,
  trackGoToServicePlaceOverMapClick,
  trackGoToServicePlaceUnderMapClick,
  trackGoToSimilarOffersClick,
  trackGoToValuationClick,
} from '../tracking';
import { IOfferCardNavigationProps, NavigationTab } from '../types';

interface IOfferCardNavigationState {
  isInTransition: boolean;
}

/**
 * Обертка для элемента навигации, которая передает линки, функцию обработки клика по навигации,
 * обработку изменения состояния
 * @param {React.SFC<INavigation>} Navigation
 */
export const offerCardNavigation = (Navigation: React.FC<React.PropsWithChildren<INavigationProps>>) => {
  return class extends React.Component<IOfferCardNavigationProps, {}> {
    private transitionTimeout: number;
    private descriptionElement: HTMLElement | null;
    private mapElement: HTMLElement | null;
    private authorElement: HTMLElement | null;
    private photosElement: HTMLElement | null;
    private servicePlaceOverMapElement: HTMLElement | null;
    private servicePlaceUnderMapElement: HTMLElement | null;
    private servicePlaceOverEvaluationElement: HTMLElement | null;
    private valuation: HTMLElement | null;
    private similarOffers: HTMLElement | null;

    public state: IOfferCardNavigationState = {
      isInTransition: false,
    };

    public componentDidMount() {
      this.descriptionElement = document.querySelector('#description') as HTMLElement | null;
      this.mapElement = document.getElementById(MAP_CONTAINER_ID) as HTMLElement | null;
      this.authorElement = document.querySelector('#author') as HTMLElement | null;
      this.photosElement = document.querySelector('#photos') as HTMLElement | null;
      this.servicePlaceOverMapElement = document.querySelector('#servicePlaceOverMap') as HTMLElement | null;
      this.servicePlaceOverEvaluationElement = document.querySelector(
        '#servicePlaceOverEvaluation',
      ) as HTMLElement | null;
      this.valuation = document.querySelector('#valuation');
      this.servicePlaceUnderMapElement = document.querySelector('#servicePlaceUnderMap') as HTMLElement | null;
      this.similarOffers = document.querySelector('#similarOffers') as HTMLElement | null;
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IOfferCardNavigationProps) {
      if (this.props.isFixed && this.props.isActive !== nextProps.isActive) {
        this.setState(
          {
            isInTransition: true,
          },
          () => {
            if (typeof this.transitionTimeout !== 'undefined') {
              window.clearTimeout(this.transitionTimeout);
            }

            this.transitionTimeout = window.setTimeout(
              () =>
                this.setState({
                  isInTransition: false,
                }),
              250,
            );
          },
        );
      }
    }

    public render() {
      const { isInTransition } = this.state;
      const { activeAnchor, isFixed, isActive, showRightContent } = this.props;

      return (
        <Navigation
          description={this.getDescription()}
          active={Boolean(isActive)}
          fixed={Boolean(isFixed)}
          transition={isInTransition}
          showRightContent={Boolean(showRightContent)}
          navigateLinks={
            <NavigationLinks activeHref={activeAnchor as string} links={this.getLinks()} navigate={this.onNavClick} />
          }
          sharingButton={<SharingButtonContainer />}
        />
      );
    }

    private getDescription(): string {
      const { offer } = this.props;

      if (offer) {
        const offerTitle = getOfferTitle(offer);
        const squareInformation = getSquareInformation(offer);

        if (offerTitle && squareInformation) {
          return `${offerTitle}, ${squareInformation}`;
        }

        if (offerTitle) {
          return offerTitle;
        }

        return '';
      }

      return '';
    }

    private getLinks = (): INavigationLink[] => {
      const { offer, isBottomAgentBlockAvailable, isFixed, isSimilarOffersAvailable } = this.props;

      const photoText = isFixed ? `Фотографии (${(offer && offer.photos && offer.photos.length) || 0})` : 'Фотографии';
      const authorText = isFixed ? 'Контактное лицо' : 'Контакты';

      const links: INavigationLink[] = [];
      links.push({ href: NavigationTab.Photos, text: photoText });
      links.push({ href: NavigationTab.Description, text: 'Описание' });
      if (this.servicePlaceOverMapElement) {
        links.push({ href: NavigationTab.ServicePlaceOverMap, text: 'Ипотека' });
      }
      links.push({ href: NavigationTab.Map, text: 'Расположение' });
      if (this.servicePlaceUnderMapElement) {
        links.push({ href: NavigationTab.ServicePlaceUnderMap, text: 'Ипотека' });
      }
      if (this.servicePlaceOverEvaluationElement) {
        links.push({ href: NavigationTab.ServicePlaceOverEvaluation, text: 'Ипотека' });
      }
      if (this.valuation) {
        links.push({ href: NavigationTab.Valuation, text: 'Оценка' });
      }

      if (isBottomAgentBlockAvailable) {
        links.push({ href: NavigationTab.Author, text: authorText });
      }

      if (isSimilarOffersAvailable) {
        links.push({ href: NavigationTab.SimilarOffers, text: 'Похожие объявления' });
      }

      return links;
    };
    private onNavClick = (event: React.MouseEvent<HTMLAnchorElement>, href: string) => {
      switch (href) {
        case NavigationTab.Photos:
          this.handleNavigate(event, this.photosElement);
          trackGoToPhotosClick();
          break;
        case NavigationTab.Description:
          this.handleNavigate(event, this.descriptionElement);
          trackGoToDescriptionClick();
          break;
        case NavigationTab.ServicePlaceOverMap:
          this.handleNavigate(event, this.servicePlaceOverMapElement);
          trackGoToServicePlaceOverMapClick();
          break;
        case NavigationTab.ServicePlaceUnderMap:
          this.handleNavigate(event, this.servicePlaceUnderMapElement);
          trackGoToServicePlaceUnderMapClick();
          break;
        case NavigationTab.ServicePlaceOverEvaluation:
          this.handleNavigate(event, this.servicePlaceOverEvaluationElement);
          trackGoToServicePlaceOverEvaluationClick();
          break;
        case NavigationTab.Map:
          this.handleNavigate(event, this.mapElement);
          trackGoToMapClick();
          break;
        case NavigationTab.Valuation:
          this.handleNavigate(event, this.valuation);
          trackGoToValuationClick();
          break;
        case NavigationTab.SimilarOffers:
          this.handleNavigate(event, this.similarOffers);
          trackGoToSimilarOffersClick();
          break;
        default:
          this.handleNavigate(event, this.authorElement);
          trackGoToAuthorClick();
      }
    };
    private handleNavigate = (event: React.MouseEvent<HTMLAnchorElement>, element: HTMLElement | null) => {
      scrollToElement(element);
    };
  };
};
