import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import { Close } from './parts/Close';
import * as styles from './styles.css';

interface IFlatViewOrderTooltipProps {
  description: string;
  onClose(): void;
}

export function FlatViewOrderTooltip({ description, onClose }: IFlatViewOrderTooltipProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['wrapper']}>
        <div className={styles['tooltip']}>
          <div className={styles['calendar']} />
          <UIText2 fontWeight="bold">{description}</UIText2>
          <Close onClick={onClose} />
        </div>
      </div>
    </div>
  );
}
