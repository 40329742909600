import { numberToPrettyString } from '@cian/utils';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { useSelector } from 'react-redux';

import { NewbuildingBuilderBackground } from './components/NewbuildingBuilderBackground';
import { NewbuildingBuilderFactoid } from './components/NewbuildingBuilderFactoid';
import { NewbuildingBuilderLogo } from './components/NewbuildingBuilderLogo';
import { NewbuildingBuilderStretchBanner } from './components/NewbuildingBuilderStretchBanner';
import { trackNewbuildingStretchBannerClick } from './tracking';
import { selectOfferId } from '../../selectors';
import { selectBuilderStats } from '../../selectors/newbuildingPremiumRate';
import { ICompanyWithFeatures } from '../../types/newbuildingCompany';

interface INewbuildingBuilderStretchBannerContainerProps {
  builderInfo: ICompanyWithFeatures;
}

export function NewbuildingBuilderStretchBannerContainer({
  builderInfo,
}: INewbuildingBuilderStretchBannerContainerProps) {
  const stretchBannerDomElement = React.useRef<HTMLElement | null>(null);

  const stats = useSelector(selectBuilderStats);
  const offerId = useSelector(selectOfferId);

  React.useEffect(() => {
    stretchBannerDomElement.current = document.getElementById('adfox-stretch-banner');

    if (stretchBannerDomElement.current) {
      const root = createRoot(stretchBannerDomElement.current);
      root.render(
        <NewbuildingBuilderStretchBanner
          onClick={() => trackNewbuildingStretchBannerClick({ offerId, builderId: builderInfo.id })}
          logo={<NewbuildingBuilderLogo imageUrl={builderInfo.logoUrl || ''} name={builderInfo.name} />}
          builderUrl={builderInfo.url}
          isReliable={builderInfo.isReliable}
          name={builderInfo.name}
          background={
            builderInfo.headerImageUrl && <NewbuildingBuilderBackground imageUrl={builderInfo.headerImageUrl} />
          }
          workYears={
            builderInfo.workYears && (
              <NewbuildingBuilderFactoid value={builderInfo.workYears.value} text={builderInfo.workYears.text} />
            )
          }
          newbuildingWithOffers={
            stats && (
              <NewbuildingBuilderFactoid
                value={stats.newbuildingWithOffers.value}
                text={stats.newbuildingWithOffers.text}
              />
            )
          }
          offersCount={
            builderInfo.offersCount && (
              <NewbuildingBuilderFactoid
                value={numberToPrettyString(builderInfo.offersCount)}
                text="квартир в продаже"
              />
            )
          }
        />,
      );
    }
  }, []);

  return null;
}
