import { ca } from '@cian/analytics';

export function trackTabMapClick() {
  ca('event', {
    action: 'click_tab_on_map',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}

export function trackTabPanoramaClick() {
  ca('event', {
    action: 'click_tab_panorama',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}

export function trackTabSimilarClick() {
  ca('event', {
    action: 'click_tab_similar_on_map',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}

export function trackSpecialProjectClick(project: string) {
  ca('event', {
    action: `${project}_jk_on_map`,
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}
