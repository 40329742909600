import cx from 'clsx';
import * as React from 'react';

import * as styles from './undergroundItem.css';

interface IUndergroundItemProps {
  icon: React.ReactElement;
  underConstruction: boolean;
  undergroundName: string;
  undergroundUrl: string;
  constructionInfo: string | null;
  travelTime: string | null;
  travelType?: React.ReactElement;
}

export const UndergroundItem = ({
  icon,
  underConstruction,
  undergroundName,
  undergroundUrl,
  constructionInfo,
  travelTime,
  travelType,
}: IUndergroundItemProps) => {
  return (
    <li className={styles['underground']} key={`underground_${undergroundName}`}>
      <div className={styles['icon']}>{icon}</div>
      <a
        className={cx(styles['underground_link'], underConstruction && styles['under_construction'])}
        href={undergroundUrl}
        target="_blank"
        rel="noreferrer"
      >
        {undergroundName}
      </a>
      {constructionInfo && <span className={styles['underground_time']}>{constructionInfo}</span>}
      {travelTime && travelType && (
        <span className={styles['underground_time']}>
          <div className={styles['icon']}>{travelType}</div>
          {travelTime}
        </span>
      )}
    </li>
  );
};
