import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closePhoneWarning, openPersonalArea } from '../../actions/phoneWarning';
import { PhoneWarningModal } from '../../components/PhoneWarningModal';
import { PHONE_WARNING_MODAL_DETAILS } from '../../constants/phoneWarning';
import { selectIsPhoneWarningOpen, selectPhoneWarningTarget } from '../../selectors/phoneWarning';

export const PhoneWarningModalContainer = () => {
  const isOpen = useSelector(selectIsPhoneWarningOpen);
  const target = useSelector(selectPhoneWarningTarget);
  const dispatch = useDispatch();

  const handleClose = React.useCallback(() => {
    dispatch(closePhoneWarning());
  }, [dispatch]);

  const handleConfirm = React.useCallback(() => {
    dispatch(openPersonalArea());
  }, [dispatch]);

  const details = React.useMemo(() => target && PHONE_WARNING_MODAL_DETAILS[target], [target]);

  if (!details) {
    return null;
  }

  return (
    <PhoneWarningModal
      open={isOpen}
      title={details.title}
      content={details.content}
      onClose={handleClose}
      onConfirm={handleConfirm}
    />
  );
};
