import { connect } from 'react-redux';

import { EntryToShowSuccessModal } from './EntryToShowSuccessModal';
import { IState } from '../../store';
import { closeEntryToShowSuccessModal } from '../../store/deal-rent';

export function mapStateToProps(state: IState) {
  return {
    isOpen: state.dealRent.isEntryToShowSuccessModal,
  };
}

export const EntryToShowSuccessModalContainer = connect(mapStateToProps, { closeModal: closeEntryToShowSuccessModal })(
  EntryToShowSuccessModal,
);
