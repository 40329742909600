import { ca } from '@cian/analytics';

interface ITrackAddOfferToComparison {
  label: string;
}

export const trackAddOfferToComparison = ({ label }: ITrackAddOfferToComparison) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Compare',
    action: 'Add',
    useLastProducts: true,
    label,
  });
};

interface ITrackDeleteOfferFromComparison {
  label: string;
}

export const trackDeleteOfferFromComparison = ({ label }: ITrackDeleteOfferFromComparison) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Compare',
    action: 'Remove',
    useLastProducts: true,
    label,
  });
};
