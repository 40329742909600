import { UIText2 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';

export interface INewbuidlingRatingProps {
  reviewsRate: number;
  newbuildingName: string;
  newbuildingUrl: string;
}

export function NewbuildingRating(props: INewbuidlingRatingProps) {
  const { reviewsRate, newbuildingUrl, newbuildingName } = props;
  const hasMark = reviewsRate !== 0;

  return (
    <div className={styles['review-rating']}>
      <div data-testid="newbuilding-rating-mark" className={styles['mark-container']}>
        {hasMark && <div className={cx(styles['star'], styles['star-has-value'])}></div>}
        {!hasMark && <div className={cx(styles['star'], styles['star-not-has-value'])}></div>}
        <span className={styles['mark']}>{reviewsRate}</span>
      </div>

      <a
        data-testid="newbuilding-rating-link"
        className={styles['link']}
        href={newbuildingUrl}
        target="_blank"
        rel="noreferrer"
      >
        <UIText2 color="text-main-default">ЖК «{newbuildingName}»</UIText2>
      </a>
    </div>
  );
}
