import * as React from 'react';

import { HomeownerInfo, DeveloperInfo, AgentInfo } from './parts';

export const AuthorAsideContainer: React.FC = () => {
  return (
    <>
      <HomeownerInfo />
      <AgentInfo />
      <DeveloperInfo />
    </>
  );
};
