import loadable from '@loadable/component';

export const SubsidisedMortgageSendOfferModalContainer = loadable(() => {
  /* istanbul ignore else */
  if (process.env.IS_BROWSER_BUILD) {
    return import('./SubsidisedMortgageSendOfferModalContainer');
  }

  /* istanbul ignore next */
  return Promise.resolve({
    default: /* istanbul ignore next */ () => null,
  });
});
