import { connect } from 'react-redux';

import { startBuyAnalytics } from '../../actions/buyAnalyticsModal';
import { AsideBuyAnalyticsBlock } from '../../components/BuyAnalyticsBlocks';
import { IAsideBuyAnalyticsBlockProps } from '../../components/BuyAnalyticsBlocks/components/AsideBuyAnalyticsBlock/types';
import { IBuyAnalyticsBlockProps } from '../../components/BuyAnalyticsBlocks/types';
import { selectBuyAnalyticsReportPrice } from '../../selectors/buyAnalyticsModal';
import { IState } from '../../store';

/* istanbul ignore next */
export const AsideBuyAnalyticsBlockContainer = connect<
  IAsideBuyAnalyticsBlockProps,
  IBuyAnalyticsBlockProps,
  {},
  IState
>(
  state => {
    return { reportPrice: selectBuyAnalyticsReportPrice(state) };
  },
  {
    onBuyClick: startBuyAnalytics,
  },
)(AsideBuyAnalyticsBlock);
