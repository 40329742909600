import { Image } from '@cian/ui-kit';
import * as React from 'react';

interface INewbuildingBuilderLogoProps {
  imageUrl: string;
  name: string;
}

export function NewbuildingBuilderLogo({ imageUrl, name }: INewbuildingBuilderLogoProps) {
  return <Image src={imageUrl} width={64} height={64} alt={name} objectFit="contain" borderRadius="4px" />;
}
