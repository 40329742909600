import * as React from 'react';

import styles from './TourSlide.css';

export interface ITourComponentProps {
  tourBannerImageUrl: string;
  isExternal?: boolean;
  onClick: () => void;
}

export const TourSlide: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles['tour-slide']} id="tourSlide">
      {children}
    </div>
  );
};
