import { Button } from '@cian/ui-kit';
import * as React from 'react';

import { RemoveModalFrame, TRemoveModalFrame } from 'shared/store/popups/remove';

import * as styles from './index.css';
import * as Analytics from './tracking';
import { Layout } from '../layout';

export interface IInitialProps {
  changeActiveFrame(newActiveFrame: TRemoveModalFrame): void;
  close(): void;
}

export class Initial extends React.PureComponent<IInitialProps, {}> {
  public componentDidMount() {
    Analytics.trackDeleteWindowShowed();
  }

  public render() {
    return (
      <Layout className={styles['container']} title="Удалить объявление?">
        <p className={styles['subtitle']}>
          Объявление будет удалено, все данные по нему будут утеряны. Вы можете перенести его в архив. Плата за хранение
          в архиве не взимается.
        </p>
        <blockquote className={styles['description']}>
          Напоминаем, что неактуальные объявления должны быть сняты с публикации (удалены или перенесены в архив).
          Аккаунты с неактуальными объявлениями подлежат пессимизации, штрафам и блокировке.
        </blockquote>
        <footer className={styles['footer']}>
          <span className={styles['archive']}>
            <Button size="XS" onClick={this.archive}>
              Перенести в архив
            </Button>
          </span>
          <Button size="XS" theme="fill_error_secondary" onClick={this.remove}>
            Удалить
          </Button>
          <span className={styles['cancel']}>
            <Button size="XS" onClick={this.cancel} theme="fill_secondary">
              Отменить
            </Button>
          </span>
        </footer>
      </Layout>
    );
  }

  private archive = () => {
    Analytics.trackArchiveButtonClicked();
    this.props.changeActiveFrame(RemoveModalFrame.Archive);
  };

  private remove = () => {
    Analytics.trackRemoveButtonClicked();
    this.props.changeActiveFrame(RemoveModalFrame.Remove);
  };

  private cancel = () => {
    Analytics.trackCancelButtonClicked();
    this.props.close();
  };
}
