import * as React from 'react';
import { useSelector } from 'react-redux';

import { OfferStatsContainer } from './OfferStats';
import { OfferMetaData } from '../../components/OfferMetaData/OfferMetaData';
import { OfferValueAddedServicesContainer } from '../../components/offer_value_added_services/container';
import { selectIsInHiddenBase } from '../../selectors/offerData/selectIsInHiddenBase';
import { selectOfferUpdated } from '../../selectors/offerData/selectOfferUpdated';

export const OfferMetaDataContainer = () => {
  const isInHiddenBase = useSelector(selectIsInHiddenBase);
  const offerUpdated = useSelector(selectOfferUpdated);

  return (
    <OfferMetaData
      updated={offerUpdated && `Обновлено: ${offerUpdated}`}
      stats={<OfferStatsContainer />}
      values={<OfferValueAddedServicesContainer />}
      isInHiddenBase={isInHiddenBase}
    />
  );
};
