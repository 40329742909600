import { IconMediaPhoto16, IconMediaVideo16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { NoImage } from 'shared/components/NoImage';

import * as styles from './Photo.css';

interface IPhotoProps {
  photoUrl: string | null;
  photoCount: number;
  videoCount: number;
}

export const Photo: React.FC<React.PropsWithChildren<IPhotoProps>> = ({ photoUrl, photoCount, videoCount }) => {
  return (
    <div className={styles.root}>
      {photoUrl && <img src={photoUrl} className={styles.image} />}

      {!photoUrl && <NoImage />}

      <div className={styles.labels}>
        {!!videoCount && renderLabel(<IconMediaVideo16 color="icon-inverted-default" />, videoCount)}
        {!!photoCount && renderLabel(<IconMediaPhoto16 color="icon-inverted-default" />, photoCount)}
      </div>
    </div>
  );
};

function renderLabel(icon: React.ReactNode, count: number) {
  return (
    <span className={styles.label}>
      {icon}
      <span className={styles['label-count']}>{count}</span>
    </span>
  );
}
