import { UIHeading4 } from '@cian/ui-kit';
import React from 'react';

import styles from './seoBlock.css';

interface ISeoBlockProps {
  blockIndex: number;
  title: string;
}

export const SeoBlock = ({ title, children }: React.PropsWithChildren<ISeoBlockProps>) => {
  return (
    <div className={styles['seo-block']}>
      <UIHeading4>{title}</UIHeading4>
      <div className={styles['links']}>{children}</div>
    </div>
  );
};
