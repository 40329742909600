import { EPageTypes, EPlatformTypes } from '@cian/frontend-dynamic-calltracking-component';
import { useDynamicPhone } from '@cian/frontend-newbuilding-call-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { DynamicPhoneLinkWrapper } from 'shared/components/DynamicPhoneLinkWrapper';
import { PhoneLinkQRCode } from 'shared/components/PhoneLinkQRCode';
import { PhonesPreloader } from 'shared/components/PhonesPreloader/PhonesPreloader';
import { useApplicationContext } from 'shared/containers/ApplicationContext';
import { getPhones } from 'shared/selectors';
import { getDynamicCalltrackingSiteBlockId } from 'shared/selectors/getDynamicCalltrackingSiteBlockId';
import { getOfferId } from 'shared/selectors/newObject';
import { formatNumber, getPhoneLink } from 'shared/utils/phone';

export const NewbuildingDynamicPhone = ({ placeType }: { placeType: string }) => {
  const { httpApi, logger } = useApplicationContext();
  const phones = useSelector(getPhones);
  const siteBlockId = useSelector(getDynamicCalltrackingSiteBlockId);
  const offerId = useSelector(getOfferId);

  const formattedPhones = React.useMemo(
    () =>
      phones.map(phone => ({
        number: formatNumber(phone),
        link: getPhoneLink(phone),
      })),
    [phones],
  );

  const notFormattedPhone = React.useMemo(() => `${phones[0].countryCode}${phones[0].number}`, [phones]);

  const { dynamicPhone, responseEnded, linkPhone } = useDynamicPhone({
    formattedPhone: formattedPhones[0].number,
    notFormattedPhone,
    siteBlockId,
    httpApi,
    logger,
    placeType,
    pageType: EPageTypes.OfferCard,
    platformType: EPlatformTypes.WebDesktop,
    announcementId: offerId,
  });

  if (!responseEnded) {
    return <PhonesPreloader />;
  }

  return (
    <DynamicPhoneLinkWrapper>
      <a href={linkPhone}>{dynamicPhone}</a>
      <PhoneLinkQRCode phoneLink={linkPhone} />
    </DynamicPhoneLinkWrapper>
  );
};
