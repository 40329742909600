import * as React from 'react';
import { useSelector } from 'react-redux';

import { HighwayItem } from '../../components/GeoRedesigned/HighwayItem';
import { HighwayList } from '../../components/GeoRedesigned/HighwayList';
import { highwaysSelector } from '../../selectors/offerData/geo/highwaysSelector';
import { regionIdSelector } from '../../selectors/offerData/geo/regionIdSelector';
import { getCommuteTypeByRegionId } from '../../utils/getCommuteTypeByRegionId';

export const HighwaysContainer = () => {
  const regionId = useSelector(regionIdSelector);
  const highways = useSelector(highwaysSelector);

  if (!highways) {
    return null;
  }

  return (
    <HighwayList>
      {highways.map(highway => {
        return (
          <HighwayItem
            key={`highway_${highway.name}`}
            name={`${highway.name} шоссе`}
            url={highway.url}
            distance={
              highway.distance ? `${Math.round(highway.distance)} км от ${getCommuteTypeByRegionId(regionId)}` : null
            }
          />
        );
      })}
    </HighwayList>
  );
};
