import { SUBURBAN } from '../constants/suburban';
import { IState } from '../store';

export function getPanoramaAvailable(state: IState, citiesWithPanoramaId: number[], isPanoramaBannerEnabled: boolean) {
  let isPanoramaAvailable = state.pageData.subdomain !== 'nn' || !isPanoramaBannerEnabled;

  const isSuburban = SUBURBAN.includes(state.offerData.offer.category);

  if (isSuburban) {
    const { address } = state.offerData.offer.geo;
    const city = address.find(({ locationTypeId }) => locationTypeId === 1);
    if ((city && !citiesWithPanoramaId?.includes(city.id)) || !city) {
      isPanoramaAvailable = false;
    }
  }

  return isPanoramaAvailable;
}
