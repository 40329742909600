import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { MoreOffers } from 'shared/components/BottomAuthorBlockNew';
import { selectOfferAgent } from 'shared/selectors';
import { isNewbuildingFromDeveloperSelector } from 'shared/selectors/offerData/offer/isNewbuildingFromDeveloperSelector';

export const AgentMoreOffersContainer: React.FC = () => {
  const agent = useSelector(selectOfferAgent, shallowEqual);
  const isNewbuildingFromDeveloper = useSelector(isNewbuildingFromDeveloperSelector);

  if (isNewbuildingFromDeveloper || !agent?.offersCount) {
    return null;
  }

  return <MoreOffers offersLink={agent.offersLink} offersCount={agent.offersCount} />;
};
