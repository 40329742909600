import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setGuestsCount } from 'shared/actions/dailyrentBookingCalendar';
import { GuestsInputWrapper } from 'shared/components/GuestsInputWrapper/GuestsInputWrapper';
import { updateDailyrentUrlParameters } from 'shared/containers/BookingCalendarInputContainer/helpers';
import { MAX_GUESTS_COUNT, MIN_GUESTS_COUNT } from 'shared/containers/GuestsInputContainer/constants';
import { useSetNormalizedGuestsCountIntoUrl } from 'shared/containers/GuestsInputContainer/helpers';
import { trackGuestsCountChange } from 'shared/containers/GuestsInputContainer/tracking';
import { selectOfferId } from 'shared/selectors';
import { selectDailyrentBookingCalendarGuestsCount } from 'shared/selectors/dailyrentBookingCalendar';
import { pluralize } from 'shared/utils/pluralize';

import { NumberInputWithButtons } from '../../../packages/NumberInputWithButtons';

export const GuestsInputContainer = () => {
  const dispatch = useDispatch();
  const offerId = useSelector(selectOfferId);
  const value = useSelector(selectDailyrentBookingCalendarGuestsCount);
  /** Параметр для аналитики. Нужно отправлять только первое событие изменения кол-ва гостей из серии */
  const [seriesLabel, setSeriesLabel] = React.useState<'Minus' | 'Plus' | null>(null);

  useSetNormalizedGuestsCountIntoUrl();

  const formatValue = React.useCallback(
    (value: number | undefined) => pluralize(value || MIN_GUESTS_COUNT, ['гость', 'гостя', 'гостей']),
    [],
  );

  const handleChange = React.useCallback(
    (newValue: number | undefined) => {
      const nextValue = newValue || MIN_GUESTS_COUNT;

      dispatch(setGuestsCount(nextValue));

      const newUrl = updateDailyrentUrlParameters({
        currentUrl: window.location.href,
        guestsCount: nextValue,
      });

      history.replaceState(undefined, '', newUrl);

      const newSeriesLabel = nextValue > value ? 'Plus' : 'Minus';
      if (seriesLabel !== newSeriesLabel) {
        setSeriesLabel(newSeriesLabel);
        trackGuestsCountChange(offerId, newSeriesLabel);
      }
    },
    [seriesLabel, value],
  );

  return (
    <GuestsInputWrapper>
      <NumberInputWithButtons
        value={value}
        valueFormatter={formatValue}
        onChange={handleChange}
        min={MIN_GUESTS_COUNT}
        max={MAX_GUESTS_COUNT}
        size="M"
      />
    </GuestsInputWrapper>
  );
};
