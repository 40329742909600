// держать в соответствии с EActionType из src/node/repositories/offer-card/entities/extended_data/ExtendedDataSchema.ts

export enum EActionType {
  /** Возможность связаться напрямую **/
  'Contact' = 'contact',
  /** Бронирование **/
  'OtaBooking' = 'otaBooking',
  /** Проверка дат **/
  'OtaCheckDates' = 'otaCheckDates',
  /** Забронировать партнёрский объект **/
  'PartnerBooking' = 'partnerBooking',
  /** Проверка дат у партнёрского объекта **/
  'PartnerCheckDates' = 'partnerCheckDates',
}
