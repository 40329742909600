import { Button } from '@cian/ui-kit';
import { useFormikContext } from 'formik';
import * as React from 'react';

import { IFormValues } from '../../DealRentModal';

export interface ISendCodeButton {
  sendValidationCode(): void;
}

export const SendCodeButton = ({ sendValidationCode }: ISendCodeButton) => {
  const { errors } = useFormikContext<IFormValues>();

  return (
    <div>
      <Button size="XS" type="button" disabled={Boolean(errors.phone)} onClick={sendValidationCode}>
        Подтвердить телефон
      </Button>
    </div>
  );
};
