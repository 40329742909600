import { ca } from '@cian/analytics';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectDailyrentActionType } from 'shared/selectors/dailyrentActionType';
import { selectTrackingUrl } from 'shared/selectors/offerData/selectTrackingUrl';
import { EActionType } from 'shared/types/dailyrentActionButtonsType';

import { BookButton } from '../../components/BookButton';
import { selectOffer } from '../../selectors';

const mapButtonTitleToActionType = (actionType: EActionType) => {
  if (actionType === EActionType.PartnerBooking) {
    return 'Забронировать';
  }

  return 'Проверить даты';
};

export const BookButtonContainer = () => {
  const offer = useSelector(selectOffer);
  const trackingUrl = useSelector(selectTrackingUrl);
  const actionType = useSelector(selectDailyrentActionType);

  const { id, offerType, externalOfferUrl } = offer;
  const buttonTitle = mapButtonTitleToActionType(actionType || EActionType.PartnerCheckDates);

  const trackLinkClick = () => {
    ca('eventEbc', {
      name: 'oldevent',
      category: 'Phones',
      action: 'Open_card_dailyrent',
      label: trackingUrl,
      page: {
        pageType: 'Card',
        siteType: 'desktop',
      },
      products: [{ id, offerType }],
    });
  };

  return <BookButton onClick={trackLinkClick} url={externalOfferUrl} title={buttonTitle} />;
};
