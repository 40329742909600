import * as React from 'react';

import * as styles from './styles.css';

interface IToolbarProps {
  controls: React.ReactNode;
}

export function Toolbar({ controls }: IToolbarProps) {
  return <div className={styles['container']}>{controls}</div>;
}
