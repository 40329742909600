import cx from 'clsx';
import * as React from 'react';

import * as styles from './Underground.css';
import { ISimilarOfferUnderground } from '../../../../store/similar';
import { IconsMetro } from '../../icons/IconsMetro';
import { IconsTransport } from '../../icons/IconsTransport';

interface IUndergroundProps {
  underground: ISimilarOfferUnderground;
}

export const Underground: React.FC<IUndergroundProps> = ({
  underground: { time, transportType, color, iconType, name },
}) => {
  return (
    <div className={styles['container']}>
      <div style={{ color }} className={cx(styles['name'], (!time || !transportType) && styles['full_width'])}>
        <IconsMetro type={iconType || undefined} />
        <span className={styles['ellipsis']}>{name}</span>
      </div>
      {time && transportType && (
        <div className={styles['transport']}>
          <IconsTransport type={transportType} />
          <span>{time}</span>
        </div>
      )}
    </div>
  );
};
