import * as React from 'react';

import * as style from './style.css';

export const DynamicPhoneLinkWrapper = ({ children }: React.PropsWithChildren) => {
  return (
    <div className={style['wrapper']} data-testid="DynamicPhoneLink">
      {children}
    </div>
  );
};
