import { useFormikContext, FieldArray } from 'formik';
import * as R from 'ramda';
import * as React from 'react';

import { ChildrenInfoItem } from './ChildrenInfoItem';
import { IFormValues } from '../../DealRentModal';
import * as styles from '../../index.css';

const options = [
  'До года',
  '1 год',
  '2 года',
  '3 года',
  '4 года',
  '5 лет',
  '6 лет',
  '7 лет',
  '8 лет',
  '9 лет',
  '10 лет',
  '11 лет',
  '12 лет',
  '13 лет',
  '14 лет',
  '15 лет',
  '16 лет',
  '17 лет',
  '18 лет',
];

export const ChildrenInfo = () => {
  const { values } = useFormikContext<IFormValues>();

  const content = React.useCallback(
    () => (
      <div className={styles['child-types-container']}>
        {R.defaultTo<string[], string[]>([], values.childrenInfo).map((value: string, index: number) => (
          <ChildrenInfoItem key={index} index={index} options={options} />
        ))}
      </div>
    ),
    [values.childrenInfo],
  );

  return <FieldArray name="childrenInfo" render={content} />;
};
