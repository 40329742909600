import { createAgentRate } from '../agent';

export const getAgentReviewsTitle = (entityRate: number | null, agentReviewsCount: number): string | null => {
  if (!entityRate) {
    return null;
  }

  const agentRate = createAgentRate(entityRate);

  return agentReviewsCount ? `${agentRate}・${agentReviewsCount}` : null;
};
