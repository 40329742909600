import * as React from 'react';
import { useSelector } from 'react-redux';

import { Disclaimer } from '../../components/Disclaimer/Disclaimer';
import {
  isShowAuctionDisclaimer,
  isShowBankDisclaimer,
  isShowDemolishedProgramDisclaimer,
  isShowElectronicTradeDisclaimer,
  isShowEncumbranceDisclaimer,
  isShowLayoutApprovedDisclaimer,
  isShowLayoutNotApprovedDisclaimer,
  selectIsApartment,
} from '../../selectors';
import { useApplicationContext } from '../ApplicationContext';

export const DisclaimerContainer: React.FC = () => {
  const { config } = useApplicationContext();
  const isSearchFlatShareEnabled = config.get('frontend_search_flat_share_enabled.Enabled');

  const showAuctionDisclaimer = useSelector(isShowAuctionDisclaimer);
  const showBankDisclaimer = useSelector(isShowBankDisclaimer);
  const showDemolishedProgramDisclaimer = useSelector(isShowDemolishedProgramDisclaimer);
  const showElectronicTradeDisclaimer = useSelector(isShowElectronicTradeDisclaimer);
  const showEncumbranceDisclaimer = useSelector(isShowEncumbranceDisclaimer);
  const showLayoutApprovedDisclaimer = useSelector(isShowLayoutApprovedDisclaimer);
  const showLayoutNotApprovedDisclaimer = useSelector(isShowLayoutNotApprovedDisclaimer);
  const isApartments = useSelector(selectIsApartment);

  return (
    <>
      {showAuctionDisclaimer && (
        <Disclaimer
          testId="auction"
          header="Аукцион"
          title="Объект продаётся на сайте электронных торгов. В объявлении указана стартовая цена"
        />
      )}

      {isSearchFlatShareEnabled && showLayoutApprovedDisclaimer && (
        <Disclaimer
          testId="layout-approved"
          header={`Это доля в ${isApartments ? 'апартаментах' : 'квартире'}`}
          title="Уточните у автора объявления, как оформлено право собственности и перепланировка, а также какой ремонт — можно ли заезжать и жить"
          sendAnalytics
        />
      )}

      {isSearchFlatShareEnabled && showLayoutNotApprovedDisclaimer && (
        <Disclaimer
          testId="layout-not-approved"
          header={`Это доля в ${isApartments ? 'апартаментах' : 'квартире'} с несогласованной перепланировкой`}
          title="Уточните у автора объявления, как оформлено право собственности и какой ремонт — можно ли заезжать и жить"
          sendAnalytics
        />
      )}

      {showBankDisclaimer && (
        <Disclaimer
          testId="bank"
          header="Залоговая недвижимость"
          title="Продаётся банком, которому объект перешёл из-за невыплаченного кредита или ипотеки"
        />
      )}

      {showDemolishedProgramDisclaimer && (
        <Disclaimer
          testId="demolished"
          header="Дом под снос"
          title="По результатам голосования жильцов дом включен в программу реновации и будет снесен"
        />
      )}

      {showElectronicTradeDisclaimer && (
        <Disclaimer
          testId="electronic-trade"
          header="Аукцион"
          title="Объект продаётся на сайте электронных торгов. В объявлении указана стартовая цена"
        />
      )}

      {showEncumbranceDisclaimer && (
        <Disclaimer
          testId="encumbrance"
          header="От банка, с обременением"
          title="Объект с обременением, подробности уточняйте по телефону"
        />
      )}
    </>
  );
};
