import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingCallbackWidgetContext } from 'shared/containers/NewbuildingCallbackWidgetProvider';
import { getCallbackRecommendations } from 'shared/selectors/callbackRecommendations';
import { getAgentPhone, getUserPhone } from 'shared/selectors/callbackRightNow';
import { isCallbackRecommendationsEnabled } from 'shared/selectors/isCallbackRecommendationsEnabled';
import { getNewbuildingName, selectTimezone } from 'shared/selectors/newObject';

import { phoneToString } from './utils';
import { getIsCallbackAvailable, getNewbuildingId, getOfferUserId } from '../../selectors';
import { getAnalyticsUser, getOfferCustomUrl } from '../../selectors/analytics';
import { TCallbackOpener } from '../../types/callbackPopup';

export const useCallbackButton = (placeType: TCallbackOpener) => {
  const agentPhone = useSelector(getAgentPhone);
  const timezoneOffset = useSelector(selectTimezone);
  const user = useSelector(getAnalyticsUser);
  const userPhone = useSelector(getUserPhone);
  const customUrl = useSelector(getOfferCustomUrl);
  const recommendations = useSelector(getCallbackRecommendations);
  const isRecommendationsEnabled = useSelector(isCallbackRecommendationsEnabled);
  const offerUserId = useSelector(getOfferUserId);
  const newbuildingName = useSelector(getNewbuildingName);
  const newbuildingId = useSelector(getNewbuildingId);
  const isCallbackAvailable = useSelector(getIsCallbackAvailable);

  const [loading, setLoading] = React.useState(false);

  const openWidget = React.useContext(NewbuildingCallbackWidgetContext);

  const openWidgetCallback = React.useCallback(async () => {
    if (!agentPhone || !offerUserId) {
      return;
    }

    setLoading(true);

    await openWidget({
      agentPhone: phoneToString(agentPhone),
      timezoneOffset,
      userPhoneNumber: userPhone ? userPhone.substring(1) : undefined,
      builderRealtyUserId: offerUserId,
      recommendations: isRecommendationsEnabled ? recommendations : undefined,
      newbuildingName,
      newbuildingId,
      callbackButtonText: 'Заказать',
      trackingParams: {
        category: 'Card',
        opener: placeType,
        gaLabel: customUrl,
        user,
      },
    });

    setLoading(false);
  }, [
    agentPhone,
    userPhone,
    offerUserId,
    placeType,
    customUrl,
    timezoneOffset,
    isRecommendationsEnabled,
    recommendations,
    newbuildingName,
    newbuildingId,
    user,
    openWidget,
  ]);

  return { handleCallbackButtonClick: openWidgetCallback, loading, isCallbackAvailable };
};
