import { UIHeading1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './objectSummaryTitle.css';

interface IObjectSummaryTitleProps {
  title: string;
}
export const ObjectSummaryTitle = ({ title }: IObjectSummaryTitleProps) => {
  return (
    <div className={styles['title']}>
      <UIHeading1 as="h2">{title}</UIHeading1>
    </div>
  );
};
