import * as React from 'react';

import { IAgentSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';
import { IOfferData } from 'shared/types/offerData';

import { AgentInfo } from '../PopupWithForm/FeedbackPopup/AgentInfo';

interface IInfoBlockProps {
  agent: IAgentSchema | null;
  offerData: IOfferData;
}

export function InfoBlock({ agent, offerData }: IInfoBlockProps) {
  if (agent) {
    const { absoluteAvatarUrl, name, firstName } = agent;
    const {
      offer: { isFromBuilder, newbuilding },
    } = offerData;
    const isFromBuilderPresenter = newbuilding && newbuilding.isFromDeveloper;
    const isFromLeadFactory = newbuilding && newbuilding.isFromLeadFactory;

    return (
      <AgentInfo
        avatarUrl={absoluteAvatarUrl}
        name={name}
        firstName={firstName}
        isFromBuilder={isFromBuilder}
        isFromBuilderPresenter={isFromBuilderPresenter}
        isFromLeadFactory={isFromLeadFactory}
      />
    );
  }

  return null;
}
