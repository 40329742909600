import { Button } from '@cian/ui-kit';
import { useFormikContext } from 'formik';
import * as React from 'react';

import { trackSafeRentFormSend } from 'shared/store/deal-rent/tracking';

import { IFormValues } from '../DealRentModal';
import * as styles from '../index.css';

export interface IDealRentModalProps {
  isLoading: boolean;
}

export const Submit = ({ isLoading }: IDealRentModalProps) => {
  const { submitForm } = useFormikContext<IFormValues>();
  const submit = React.useCallback(() => {
    trackSafeRentFormSend();
    submitForm();
  }, [submitForm, trackSafeRentFormSend]);

  return (
    <div className={styles['submit']}>
      <Button size="XS" type="submit" disabled={isLoading} loading={isLoading} onClick={submit}>
        Отправить анкету
      </Button>
    </div>
  );
};
