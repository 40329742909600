import {
  IconMapHighway16,
  IconMapMetroEkb16,
  IconMapMetroKzn16,
  IconMapMetroMsk16,
  IconMapMetroNN16,
  IconMapMetroNovosib16,
  IconMapMetroSam16,
  IconMapMetroSpb16,
  IconMapTrain16,
  IIconProps,
} from '@cian/ui-kit-design-tokens/icons';
import React, { CSSProperties, FC } from 'react';

import { TTransportTypes } from 'shared/types/TransportTypes';

import * as styles from './TransportIcon.css';

type TTransportIconProps = {
  iconType: TTransportTypes;
  iconColor: string | null;
};

const transportIconMap: Record<TTransportTypes, FC<IIconProps>> = {
  ekb: IconMapMetroEkb16,
  highway: IconMapHighway16,
  kzn: IconMapMetroKzn16,
  mow: IconMapMetroMsk16,
  nn: IconMapMetroNN16,
  nsk: IconMapMetroNovosib16,
  railway: IconMapTrain16,
  smr: IconMapMetroSam16,
  spb: IconMapMetroSpb16,
};

export const TransportIcon: FC<TTransportIconProps> = props => {
  const { iconType, iconColor } = props;

  const TransportIcon = transportIconMap[iconType];

  const style: CSSProperties = {};

  if (iconColor) {
    style.color = iconColor;
  }

  return (
    <div data-testid="TransportIcon" className={styles['container']} style={style}>
      <TransportIcon color="current_color" data-testid={`icon-${iconType}`} />
    </div>
  );
};
