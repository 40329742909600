import { IconFunctionalShow16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './offerStats.css';

interface IOfferStatsProps {
  onClick(): void;
}

export const OfferStats = ({ onClick, children }: React.PropsWithChildren<IOfferStatsProps>) => {
  return (
    <button className={styles['button']} onClick={onClick}>
      <div className={styles['icon']}>
        <IconFunctionalShow16 color="current_color" />
      </div>
      {children}
    </button>
  );
};
