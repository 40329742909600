import * as React from 'react';

import { IOfferSchema, IPhotoSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as styles from './index.css';

export interface IPrintInformationProps {
  isPrintModeEnabled: boolean;
  offer: IOfferSchema;
}

export function PrintInformation(props: IPrintInformationProps) {
  const { isPrintModeEnabled, offer } = props;

  if (!isPrintModeEnabled) {
    return null;
  }

  const { photos } = offer;
  let rows: IPhotoSchema[][] = [];

  if (photos) {
    rows = photos.reduce((acc, currentValue, index) => {
      if (index === photos.length - 1) {
        acc.push([currentValue]);

        return acc;
      }

      if (index % 2 === 1) {
        acc.push([photos[index - 1], currentValue]);
      }

      return acc;
    }, rows);
  }

  return (
    <div className={styles['container']}>
      {rows.length && (
        <table>
          <tbody>
            {rows.map((row, index) => {
              if (row[1]) {
                return (
                  <tr key={index}>
                    <td className={styles['cell']}>
                      <img className={styles['photo']} key={row[0].id} src={row[0].fullUrl} />
                    </td>
                    <td className={styles['cell']}>
                      <img className={styles['photo']} key={row[1].id} src={row[1].fullUrl} />
                    </td>
                  </tr>
                );
              }

              return (
                <tr key={index}>
                  <td colSpan={2} className={styles['cell']}>
                    <img className={styles['photo']} key={row[0].id} src={row[0].fullUrl} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}
