import { TDealType } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { mapIdenticalOffers } from './mapIdenticalOffers';
import { IState } from '../../../store';
import { IIdenticalOffersView } from '../../../types/identicalOffersView';

const IDENTICAL_TITLES: { [key in TDealType]: string } = {
  sale: 'Это объявление от других продавцов',
  rent: 'Это объявление от других арендаторов',
};

export function selectIdenticalOffers(state: IState): IIdenticalOffersView | null {
  const {
    offerData: { offer, identicalOffers },
  } = state;

  if (!identicalOffers || !identicalOffers.items.length) {
    return null;
  }

  // Показывем максимум 4 дубля
  const slicedItems = identicalOffers.items.slice(0, 4);
  const mappedItems = mapIdenticalOffers(slicedItems);
  const title = IDENTICAL_TITLES[offer.dealType];

  return {
    title,
    url: identicalOffers.url,
    items: mappedItems,
  };
}
