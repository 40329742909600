import * as React from 'react';

import * as styles from './InfoLayout.css';

interface IInfoLayoutProps {
  id?: string;
}

export const InfoLayout: React.FC<React.PropsWithChildren<IInfoLayoutProps>> = ({ children, id }) => {
  return (
    <div className={styles['info-layout']} id={id}>
      {children}
    </div>
  );
};
