import { IOfferSchema as DEPRECATED__IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { CommercialOwnerShield, ICommercialOwnerShield } from './CommercialOwnerShield';
import { EAccountType, IAgentSchema } from '../../repositories/offer-card/entities/agent/AgentSchema';
import { IOfferSchema } from '../../repositories/offer-card/entities/offer/OfferSchema';
import { getIsCommercialByOffer } from '../getIsCommercialByOffer';

export interface ICommercialOwner {
  readonly shield: ICommercialOwnerShield;
}

export class CommercialOwner implements ICommercialOwner {
  public static getInstanceIfIsCommercial(offer: IOfferSchema, agent: IAgentSchema): CommercialOwner | null {
    return getIsCommercialByOffer(offer as unknown as DEPRECATED__IOfferSchema)
      ? new CommercialOwner(offer, agent)
      : null;
  }

  public readonly shield: ICommercialOwnerShield;

  private readonly agent: IAgentSchema;

  private constructor(offer: IOfferSchema, agent: IAgentSchema) {
    this.agent = agent;

    this.shield = CommercialOwnerShield.getInstance(offer, this.agent);
  }

  public get isLandlord(): boolean {
    const { accountType } = this.agent;

    return !!accountType && [EAccountType.ManagementCompany, EAccountType.RentDepartment].includes(accountType);
  }
}
