import { type TTgbNativedescGeneralContext } from '@cian/adfox-templates';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { useApplicationContext } from 'shared/containers/ApplicationContext';
import type { INewbuildingInfoForTgbSchema } from 'shared/repositories/newbuilding-search/entities/get_newbuildings_info_for_tgb/NewbuildingInfoForTgbSchema';
import { addressSelector } from 'shared/selectors';
import { fetchGetNewbuildingsForSerpService } from 'shared/services/fetchGetNewbuildingsForSerpService';

import { TgbNativedescGeneralNarrow } from './components/TgbNativedescGeneralNarrow';
import { TgbNativedescGeneralWide } from './components/TgbNativedescGeneralWide';
import { getTgbNativedescGeneralNarrowProps } from './utils/getTgbNativedescGeneralNarrowProps';
import { getTgbNativedescGeneralWideProps } from './utils/getTgbNativedescGeneralWideProps';

type TTgbNativedescGeneralContainerProps = {
  behaviour: 'wide' | 'narrow';
  context: TTgbNativedescGeneralContext;
};

export const TgbNativedescGeneralContainer: React.FC<TTgbNativedescGeneralContainerProps> = ({
  context,
  behaviour,
}) => {
  const { newBuildingId, newBuildingIdIsUsed } = context;

  const applicationContext = useApplicationContext();

  const address = useSelector(addressSelector);

  /**
   * @description Нам не нужно хранить в сторе данные по баннеру,
   * так как страница сама по себе не перерисовывается с/без баннера + баннер нельзя закрыть и открыть
   */
  const [newbuidingInfoForTgb, setNewbuidingInfoForTgb] = React.useState<null | INewbuildingInfoForTgbSchema>(null);
  const isFetching = React.useRef(false);

  /**
   * @description Только для значения newBuildingIdIsUsed === '1' делаем запрос на бекенд для обогащения данных
   */
  const isEnrichmentFromBackendEnabled = newBuildingIdIsUsed === '1';

  React.useEffect(() => {
    if (!isFetching.current && isEnrichmentFromBackendEnabled && newBuildingId && !newbuidingInfoForTgb) {
      isFetching.current = true;

      fetchGetNewbuildingsForSerpService(applicationContext, { newbuildingIds: [parseInt(newBuildingId, 10)] })
        .then(data => {
          setNewbuidingInfoForTgb(data.newbuildings[0]);
        })
        .finally(() => {
          isFetching.current = false;
        });
    }
    /**
     * @description Тут смотрим на изменение newBuildingId, изменение остальных данных не должно триггерить запрос
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBuildingId]);

  switch (behaviour) {
    case 'narrow':
      return <TgbNativedescGeneralNarrow {...getTgbNativedescGeneralNarrowProps({ context, newbuidingInfoForTgb })} />;
    case 'wide': {
      return (
        <TgbNativedescGeneralWide {...getTgbNativedescGeneralWideProps({ address, context, newbuidingInfoForTgb })} />
      );
    }
  }
};
