import { ISpecialPromoInfo } from '../../store/special_promo_info';

export const getSpecialPromoLink = (specialPromoInfo: ISpecialPromoInfo) => {
  const { builderId, jkMapInfo, region } = specialPromoInfo;

  const baseQuery = 'currency=2&deal_type=2&engine_version=2&object_type=2&with_newobject=1&newbuildings_search=true';

  const search = new URLSearchParams(baseQuery);
  search.set('center', `${jkMapInfo.lat},${jkMapInfo.lng}`);
  search.set('zoom', String(jkMapInfo.zoom));
  search.set('builder', String(builderId));
  search.set('region', String(region));
  search.sort();

  return `/map/?${search.toString()}`;
};
