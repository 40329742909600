import * as React from 'react';

import { OfferTitleNewContainer } from 'shared/containers/OfferTitleNewContainer';
import { ParentNewContainer } from 'shared/containers/ParentNewContainer';
import { ReviewsMiniWidgetContainer } from 'shared/containers/ReviewsMiniWidgetContainer';
import { useDailyRentReviews } from 'shared/hooks/useReviewsMiniWidget';

import * as styles from './styles.css';

export function MainNewTitle() {
  const { isReviewsEnabled } = useDailyRentReviews();

  return (
    <div className={styles['container']}>
      <OfferTitleNewContainer link={<ParentNewContainer />} />
      {isReviewsEnabled && (
        <div className={styles['rating']}>
          <ReviewsMiniWidgetContainer />
        </div>
      )}
    </div>
  );
}
