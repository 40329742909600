import { FavoriteButtonWrapperContainer } from '@cian/favorites-assign-widget';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { FavoriteButtonAside } from 'shared/components/FavoriteButtonAside';

import { offerSelector, selectIsAuthenticated } from '../../selectors';

export interface IFavoriteButtonAsideContainer {
  checked: boolean;
  onButtonClick(): void;
  newButton?: boolean;
}

export const FavoriteButtonAsideContainer = ({ checked, onButtonClick }: IFavoriteButtonAsideContainer) => {
  const offer = useSelector(offerSelector);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  if (offer.status !== 'published') {
    return <FavoriteButtonAside checked={checked} onClick={onButtonClick} disabled />;
  }

  if (!isAuthenticated) {
    return <FavoriteButtonAside checked={checked} onClick={onButtonClick} />;
  }

  return (
    <FavoriteButtonWrapperContainer isAuthenticated={isAuthenticated} offerId={offer.id} placement="bottom-end">
      <FavoriteButtonAside checked={checked} onClick={onButtonClick} />
    </FavoriteButtonWrapperContainer>
  );
};
