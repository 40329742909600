import { ArticleParagraph1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './NewbuildingBlackFridayPromo.css';
import icon from './assets/blackFridayIcon.svg';

interface INewbuildingBlackFridayPromoProps {
  text: string;
}

export const NewbuildingBlackFridayPromo: React.FC<INewbuildingBlackFridayPromoProps> = ({ text }) => {
  return (
    <div data-testid="NewbuildingBlackFridayPromo" className={styles['container']}>
      <div className={styles['promo']}>
        <img className={styles['icon']} src={icon} alt="Иконка звезды Чёрной пятницы" />
        <ArticleParagraph1>{text}</ArticleParagraph1>
      </div>
    </div>
  );
};
