import { Button, ModalWindow } from '@cian/ui-kit';
import { FormikProps } from 'formik';
import * as React from 'react';

import { ActivityScope, Comment, IsAgent, Name } from 'shared/components/DealRentModal/Fields';
import { ISaveFormRequest } from 'shared/repositories/deal-rent-announcements/v1/save-form';

import * as styles from './styles.css';
import { IsTermAccepted } from '../IsTermAccepted';

export interface IFormValues extends ISaveFormRequest {
  termAccepted?: false;
}

export interface ISecondStepStateProps {
  open: boolean;
  initialFormState: ISaveFormRequest;
  onClickBack(): void;
  onClose(): void;
}

export interface ISecondStepProps extends ISecondStepStateProps, FormikProps<IFormValues> {}

export const SecondStep = ({ open, onClose, resetForm, handleSubmit, submitForm, onClickBack }: ISecondStepProps) => {
  const onCloseModal = React.useCallback(() => {
    resetForm();
    onClose();
  }, [resetForm, onClose]);

  return (
    <ModalWindow
      title="Расскажите о себе"
      content={
        <form className={styles['container']} onSubmit={handleSubmit}>
          <Name />
          <ActivityScope />
          <Comment />
          <IsAgent />
          <IsTermAccepted />
        </form>
      }
      footer={
        <div className={styles['buttons-wrap']}>
          <Button theme={'fill_secondary'} onClick={onClickBack} size="XS">
            Назад
          </Button>
          <Button onClick={submitForm} size="XS">
            Отправить
          </Button>
        </div>
      }
      onClose={onCloseModal}
      open={open}
    />
  );
};
