import { ca } from '@cian/analytics';

export function trackPrint() {
  ca('event', {
    action: 'print_send_main_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}
