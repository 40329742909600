import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './offerMetaData.css';

interface IOfferMetaDataProps {
  updated: string;
  stats: React.ReactElement;

  values: React.ReactElement;
  isInHiddenBase: boolean;
}

export const OfferMetaData = ({
  updated,
  stats,
  values,
  isInHiddenBase,
}: React.PropsWithChildren<IOfferMetaDataProps>) => {
  return (
    <div className={styles['container']}>
      <div className={styles['item']}>
        <div data-testid="metadata-updated-date" className={styles['updated']}>
          <UIText2 color="gray40_100">{updated}</UIText2>
        </div>
        <div className={styles['stats']}>{stats}</div>
      </div>
      <div className={styles['item']}>
        <div className={styles['values']}>{values}</div>
        {isInHiddenBase && <div className={styles['in_hidden_base']}>Видят только агенты</div>}
      </div>
    </div>
  );
};
