import { Input } from '@cian/ui-kit';
import { Field } from 'formik';
import * as React from 'react';

import { Label } from './Label';
import { useFieldHasErrors } from './utils';
import * as styles from '../index.css';

export const PetsInfo = () => {
  const invalid = useFieldHasErrors('petsInfo');

  return (
    <div className={styles['field']}>
      <Label invalid={invalid}>Какие животные</Label>
      <Field name="petsInfo" as={Input} placeholder="Кошка, собака и т.д." custom={{ invalid }} />
    </div>
  );
};
