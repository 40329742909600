import { ca } from '@cian/analytics';

export function trackClick(label: string) {
  ca('event', {
    action: 'footer_chpu',
    category: 'card',
    label,
    name: 'oldevent',
  });
}
