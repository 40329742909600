import { ca } from '@cian/analytics';

export function trackPriceHistoryPopupShow(label: string) {
  ca('eventSite', {
    action: 'price_history_popup_show',
    category: 'card',
    label,
    name: 'oldevent',
  });
}

export function trackPriceHistoryScroll(label: string) {
  ca('eventSite', {
    action: 'price_history_popup_scroll',
    category: 'card',
    label,
    name: 'oldevent',
  });
}

export function trackPriceHistoryScrolled(label: string) {
  ca('eventSite', {
    action: 'price_history_popup_scroll_bottom',
    category: 'card',
    label,
    name: 'oldevent',
  });
}

export function trackPriceHistoryNextClick() {
  ca('eventSite', {
    action: 'bti_click',
    category: 'card',
    label: 'estimate_from_price_history_popup',
    name: 'oldevent',
  });
}
