import { ca } from '@cian/analytics';

export function trackClickOnAddressItem() {
  ca('event', {
    action: 'click_address_main_block',
    category: 'card',
    label: 'address',
    name: 'oldevent',
  });
}

export function trackClickOnMapAnchor() {
  ca('event', {
    action: 'slide_to_map_main_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}
