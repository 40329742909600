import * as React from 'react';
import { useSelector } from 'react-redux';

import { SharingNew } from '../../components/SharingNew';
import { getPageData, getShortShareTitleSelector } from '../../selectors';
import { getOffer } from '../../selectors/newObject';

export function SharingNewContainer() {
  const offer = useSelector(getOffer);
  const title = useSelector(getShortShareTitleSelector);
  const pageData = useSelector(getPageData);

  return <SharingNew offer={offer} pageData={pageData} title={title} />;
}
