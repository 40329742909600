import { connect } from 'react-redux';

import { HiddenBlockButtons, IHiddenBlockStoreButtonsProps, IHiddenBlockDispatchButtonsProps } from './';
import { toggleComplainPopup, restoreOffer } from '../../store/hide_offer';
import {
  ERestoreEventSource,
  selectProductsParams,
  trackHiddenObjects,
  EHideOfferAnalyticsCategory,
} from '../../tracking/hide_offer';

import type { DispatchReduxAction, IState } from '../../store';

export const HiddenBlockButtonsContainer = connect(
  (state: IState): IHiddenBlockStoreButtonsProps => ({
    productParams: selectProductsParams(state),
  }),
  (dispatch: DispatchReduxAction): IHiddenBlockDispatchButtonsProps => ({
    toggleComplainPopup: (isOpened: boolean) => () => dispatch(toggleComplainPopup(isOpened)),
    restoreOffer: () => dispatch(restoreOffer(ERestoreEventSource.BUTTON)),
  }),
  (store: IHiddenBlockStoreButtonsProps, actions: IHiddenBlockDispatchButtonsProps) => ({
    ...store,
    ...actions,
    toggleComplainPopup: (isOpened: boolean) => () => {
      if (isOpened) {
        trackHiddenObjects({
          action: 'Click_complain',
          category: EHideOfferAnalyticsCategory.HIDE,
          productParams: store.productParams,
        });
      }
      actions.toggleComplainPopup(isOpened)();
    },
  }),
)(HiddenBlockButtons);
