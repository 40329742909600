import * as React from 'react';
import { useSelector } from 'react-redux';

import { useApplicationContext } from 'shared/containers/ApplicationContext';
import { isRentDailySelector } from 'shared/selectors/isRentDaily';

export function useDailyRentReviews() {
  const { config } = useApplicationContext();
  const isDailyRent = useSelector(isRentDailySelector);
  const isDailyRentReviewsEnabled = !!config.get<boolean>('Dailyrent.CianReviewsFrontend.Enabled');

  return React.useMemo(
    () => ({
      isReviewsEnabled: Boolean(isDailyRent && isDailyRentReviewsEnabled),
    }),
    [isDailyRent, isDailyRentReviewsEnabled],
  );
}
