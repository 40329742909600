import * as React from 'react';

// TODO: обновить иконку IconMapMCD116 в ui-kit и использовать ее, а эту удалить
/* istanbul ignore next */
export const IconMapMCD116: React.FC = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_31286_6443)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00003 2H-0.00463867V4H1L1.00223 14L3.00003 14C6.31372 14 8.99997 11.3137 8.99997 7.99999C8.99997 4.68633 6.31372 2 3.00003 2ZM3.00002 12C5.20912 12 6.99997 10.2091 6.99997 7.99999C6.99997 5.79087 5.20912 4 3.00003 4L3.00002 12Z"
        fill="currentColor"
      />
      <path
        d="M12.0039 12H10.0039V14H16.0039V12H14.0039L14.0039 2.00001L12.0042 2.00001L9.3584 4.23627L10.6495 5.76375L12.0039 4.61893V12Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_31286_6443">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
