import { ca } from '@cian/analytics';

export function trackMortgageButtonClick() {
  ca('eventSite', {
    name: 'oldevent',
    action: 'ipoteka_click',
    category: 'card',
    label: 'FOTORAMA_userClickGoToAnketa',
  });
}
