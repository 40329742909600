import { ca } from '@cian/analytics';

import { EComparisonNotificationCategory } from '../../../types/comparisonNotification';

interface ITrackClickPopup {
  category: EComparisonNotificationCategory;
}

export function trackClickPopup({ category }: ITrackClickPopup) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Compare',
    action: `click_popup_${category}`,
    label: 'to_compare',
    useLastProducts: true,
  });
}
