import * as React from 'react';
import { useSelector } from 'react-redux';

import { AddressItem } from '../../components/GeoRedesigned/AddressItem';
import { MapAnchor } from '../../components/GeoRedesigned/MapAnchor';
import { trackClickOnAddressItem, trackClickOnMapAnchor } from '../../components/geo/tracking';
import { MAP_CONTAINER_ID } from '../../constants/map';
import { addressSelector, selectIsOfferPublished } from '../../selectors';
import { scrollToElement } from '../../utils/navigation';

export const AddressContainer = () => {
  const address = useSelector(addressSelector);
  const isPublished = useSelector(selectIsOfferPublished);

  const handleClickOnMapAnchor = () => {
    const map = document.getElementById(MAP_CONTAINER_ID);

    trackClickOnMapAnchor();

    if (map) {
      scrollToElement(map);
    }
  };

  if (!address.length) {
    return null;
  }

  return (
    <div>
      {address.map((addr, index) => {
        const addressItem = (
          <AddressItem
            key={`address_${index}`}
            url={addr.url}
            onClick={trackClickOnAddressItem}
            text={addr.shortName}
          />
        );

        return [index > 0 && ', ', addressItem];
      })}
      {isPublished && <MapAnchor text="На карте" onClick={handleClickOnMapAnchor} />}
    </div>
  );
};
