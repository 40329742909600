import loadable from '@loadable/component';

import {
  IAgentAvailabilitySchema,
  IAgentSchema,
  IKPSchema,
  IOfferSchema,
  IUserSchema,
} from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IOfferChatsState } from '../../reducers/chat';
import { IRosreestrInfo } from '../../types/offerData';
import { IParent } from '../../utils/offer_helpers';

export interface IOfferContactsAsideStateProps {
  abGroup: number;
  agent: IAgentSchema | null;
  isPhonesVisible: boolean;
  offer: IOfferSchema;
  offerChat: IOfferChatsState;
  user: IUserSchema | null;
  agentAvailability: IAgentAvailabilitySchema | null;
  parent: IParent | undefined;
  bookedOfferId: number;
  isCommercial: boolean | null;
  kp: IKPSchema | null;
  siteBlockId: number | null;
  isAuthenticated: boolean;
  newbuildingName: string;
  dynamicCalltrackingPlaceType: string;
  isDealRent: boolean;
  isNewBuilding: boolean;
  entryToShowEnabled: boolean;
  isPik: boolean;
  shouldRenderContactButtons: boolean;
  shouldRenderCallbackButton: boolean;
  showFlatViewNotification?: boolean;
  isDealRentFormEnabled: boolean;
  isCalltrackingEnabled: boolean;
  callButtonText: string;
  /**
   * @todo Удалить блок с экспериментом
   * @description Данный функционал появился в задаче CD-129223, будет удалён в задаче CD-129224
   * Удалить, если эксперимент будет не удачным
   */
  disablePhoneButton: boolean;
  rosreestrInfo: IRosreestrInfo | null;
}

export interface IOfferContactsAsideDispatchProps {
  setPhoneCollapse(): void;
  openDealRentForm(): void;
  openNotAvailablePopup(): void;
  openChat(type?: 'open_phone' | 'open_message'): void;
  requestAuthenticationForChat(type?: 'open_phone' | 'open_message'): void;
}

export const NewbuildingContactsContainerLoadable = loadable(
  () => import('../../containers/NewbuildingContactsContainer'),
);
