import * as React from 'react';

import * as styles from './styles.css';

export interface IParentNewProps {
  title: string;
  url?: string;
  onClick(): void;
}

const nbsp = '\u00a0';

export function ParentNew({ title, url, onClick }: IParentNewProps) {
  return (
    <div className={styles['container']}>
      в{nbsp}
      {url ? (
        <a className={styles['link']} href={url} target="_blank" rel="noopener noreferrer" onClick={onClick}>
          {title}
        </a>
      ) : (
        <strong>{title}</strong>
      )}
    </div>
  );
}
