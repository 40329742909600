import { selectOffer } from './offerData';
import { selectAgentLists } from './offerData/selectAgentLists';
import { IState } from '../store';

export const selectIsAuctionBankObject = (state: IState) => {
  const { auctionBanks } = state;
  const { publishedUserId } = selectOffer(state);
  const agentLists = selectAgentLists(state);
  const isAuctionAgentLists = agentLists.includes('tradeRealty') || agentLists.includes('electronTradeSite');
  const isAuctionBanksLists = publishedUserId && auctionBanks.includes(publishedUserId);

  return isAuctionAgentLists || isAuctionBanksLists;
};
