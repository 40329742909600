import { Button } from '@cian/ui-kit';
import { IconFilePrint16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { trackClick } from './tracking';
import { toggle } from '../../store/popups/print';

export function PrintButtonNewContainer() {
  const dispatch = useDispatch();

  const clickButtonHandle = () => {
    dispatch(toggle(true));
    trackClick();
  };

  return (
    <Button
      theme="stroke_secondary"
      beforeIcon={<IconFilePrint16 color="icon-secondary-default" />}
      onClick={clickButtonHandle}
      size="XS"
      title="Печать"
    />
  );
}
