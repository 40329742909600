import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { WarningTextWithLink } from 'shared/FeedbackExperiment/components/PopupWithForm/TextWithLinkRedesigned/WarningTextWithLink';
import { selectorShowWarningMessage } from 'shared/selectors/contacts/selectorShowWarningMessage';

import { TextWithLink } from './TextWithLink';
import { openFeedbackPopup } from '../../../actions/feedback';

export const TextWithLinkContainer = () => {
  const dispatch = useDispatch();
  const showWarningMessage = useSelector(selectorShowWarningMessage);

  const onClick = React.useCallback(() => {
    dispatch(openFeedbackPopup());
  }, []);

  if (showWarningMessage) {
    return <WarningTextWithLink onClick={onClick} />;
  }

  return <TextWithLink onClick={onClick} />;
};
