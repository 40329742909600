import { ca } from '@cian/analytics';

export function trackOfferStatsPopupShow() {
  ca('event', {
    action: 'stat_popup_open',
    category: 'card',
    label: 'open',
    name: 'oldevent',
  });
}
