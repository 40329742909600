import { connect } from 'react-redux';

import { IPrintModalProps, PrintModal } from './index';
import { changePrintOptions, IChangePrintOptions, toggle } from '../../../store/popups/print';

import type { DispatchReduxAction, IState } from '../../../store';

type TStoreProps = Pick<IPrintModalProps, 'isOpen' | 'isCommentsVisible' | 'isPhonesVisible' | 'isPrintModeEnabled'>;
type TDispatchProps = Pick<IPrintModalProps, 'toggle' | 'changePrintOptions'>;

function mapStateToProps(state: IState): TStoreProps {
  return {
    isCommentsVisible: state.printModal.isCommentsVisible,
    isOpen: state.printModal.isOpen,
    isPhonesVisible: state.printModal.isPhonesVisible,
    isPrintModeEnabled: state.printModal.isPrintModeEnabled,
  };
}

function mapDispatchToProps(dispatch: DispatchReduxAction): TDispatchProps {
  return {
    changePrintOptions: (options: IChangePrintOptions) => dispatch(changePrintOptions(options)),
    toggle: (isOpen?: boolean) => dispatch(toggle(isOpen)),
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PrintModalContainer = connect<TStoreProps, TDispatchProps, any>(
  mapStateToProps,
  mapDispatchToProps,
)(PrintModal);
