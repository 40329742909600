import { ca } from '@cian/analytics';

export function trackShareClick() {
  ca('event', {
    action: 'share_open_navi_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}
