import cx from 'clsx';
import * as React from 'react';

import { MinusIcon } from './MinusIcon';
import { PlusIcon } from './PlusIcon';
import * as styles from './index.css';

export interface ICountComponent {
  min?: number;
  max?: number;
  value: number;
  onChange(value: number): void;
}

export const CountComponent = ({ value, min, max, onChange }: ICountComponent) => {
  const [increase, decrease] = React.useMemo(
    () => [() => onChange(value + 1), () => onChange(value - 1)],
    [value, onChange],
  );

  return (
    <div className={styles['container']}>
      <div
        onClick={decrease}
        className={cx(
          styles['action'],
          styles['action-left'],
          min !== undefined && value <= min && styles['action-disabled'],
        )}
      >
        {MinusIcon}
      </div>
      <div className={styles['value']}>{value === 0 ? 'нет' : value}</div>
      <div
        onClick={increase}
        className={cx(
          styles['action'],
          styles['action-right'],
          max !== undefined && value >= max && styles['action-disabled'],
        )}
      >
        {PlusIcon}
      </div>
    </div>
  );
};
