import { Button, ModalWindow } from '@cian/ui-kit';
import { FormikProps } from 'formik';
import * as React from 'react';

import {
  Children,
  ChildrenInfo,
  Persons,
  PersonsDescription,
  Pets,
  PetsInfo,
} from 'shared/components/DealRentModal/Fields';
import { ISaveFormRequest } from 'shared/repositories/deal-rent-announcements/v1/save-form';

import * as styles from './styles.css';

export interface IFirstStepStateProps {
  open: boolean;
  initialFormState: ISaveFormRequest;
  onClose(): void;
}

export interface IFirstStepProps extends IFirstStepStateProps, FormikProps<ISaveFormRequest> {}

export const FirstStep = ({ open, onClose, resetForm, values, handleSubmit, submitForm }: IFirstStepProps) => {
  const onCloseModal = React.useCallback(() => {
    resetForm();
    onClose();
  }, [resetForm, onClose]);

  return (
    <ModalWindow
      title="Заполните анкету для собственника"
      content={
        <form className={styles['container']} onSubmit={handleSubmit}>
          <Persons />
          {(values.personsCount || 1) > 1 ? <PersonsDescription /> : <div />}
          <Children />
          <ChildrenInfo />
          <Pets />
          {(values.petsCount || 0) > 0 ? <PetsInfo /> : <div />}
        </form>
      }
      footer={
        <div className={styles['buttons-wrap']}>
          <Button type="submit" onClick={submitForm} size="XS">
            Дальше
          </Button>
        </div>
      }
      onClose={onCloseModal}
      open={open}
    />
  );
};
