import { ca } from '@cian/analytics';

import { EModelTypes } from '../../store/similar';

const TRACK_BLOCK_LABELS = {
  [EModelTypes.UserBased]: 'SimilarObjectsMayLike',
  [EModelTypes.ItemBased]: 'SimilarObjectsSimilar',
  [EModelTypes.HistoryBased]: 'SimilarObjectsViewed',
  [EModelTypes.NewItemBased]: 'SimilarObjectsNew',
  [EModelTypes.NewobjectItemBased]: 'NewbuildingsInThisDistrict',
};

export function trackEntityTeaserShow({
  offerId,
  position,
  modelType,
}: {
  offerId: number;
  position: number;
  modelType: string;
}) {
  ca('teaser', {
    product: {
      id: offerId,
      name: 'show_card',
      extra: {
        offerType: 'offer',
      },
      position,
      screen: 'Card',
      block: TRACK_BLOCK_LABELS[modelType as EModelTypes],
    },
  });
}
