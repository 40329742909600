import { ca } from '@cian/analytics';

import { IAgentSchema, IOfferSchema, IUserSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IRosreestrInfo } from '../../types/offerData';
import { getIsCommercialByOffer } from '../../utils/getIsCommercialByOffer';
import { getCustomUrl } from '../../utils/tracking/getCustomUrl';
import { getPage } from '../../utils/tracking/getPage';
import { getPartner } from '../../utils/tracking/getPartner';
import { getUser } from '../../utils/tracking/getUser';
import { getProducts } from '../../utils/tracking/get_products';

interface ITrackShowPhoneClick {
  trackingAction?: string;
  offer: IOfferSchema;
  agent: IAgentSchema | null;
  user: IUserSchema | null;
  kpId: number | null;
  bot?: boolean;
  isAgentNotAvailable?: boolean | null;
  siteBlockId?: number | null;
  rosreestrInfo?: IRosreestrInfo | null;
}

export function trackShowPhoneClick({
  trackingAction,
  offer,
  agent,
  user,
  kpId,
  rosreestrInfo,
  bot,
  isAgentNotAvailable,
  siteBlockId,
}: ITrackShowPhoneClick) {
  const products = getProducts({ agent, offer, kpId, siteBlockId, rosreestrInfo });

  const isNeedToEnrichEvent = getIsCommercialByOffer(offer);

  ca('eventEbc', {
    action: trackingAction || `Open_card_floating_block${bot ? '_bot' : ''}${isAgentNotAvailable ? '_inactive' : ''}`,
    category: 'Phones',
    label: getCustomUrl(offer, agent),
    name: 'oldevent',
    ...(isNeedToEnrichEvent && { event: 'oldevent' }),
    page: {
      ...getPage(agent, offer, siteBlockId),
      ...(isNeedToEnrichEvent && { offerType: 'offer' }),
    },
    partner: getPartner(user, offer),
    products: [
      {
        ...products,
        variant: products.variant ? products.variant.split('/') : [],
      },
    ],
    user: getUser(user, 0),
  });
}
