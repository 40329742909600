import { UIHeading5, UIText3 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as style from './style.css';

export interface INewbuildingPremiumBuilderStats {
  testId: string;
  title: string;
  value: string;
  isAvailableForCompactMode: boolean;
  link?: string;
  onClick?(): void;
}

export interface INewbuildingPremiumBuilderLayoutProps {
  logo: React.ReactElement;
  label?: React.ReactElement | null;
  rating?: React.ReactElement | null;
  stats: INewbuildingPremiumBuilderStats[];
  isCompact?: boolean;
}

export const NewbuildingPremiumBuilderLayout: React.FC<
  React.PropsWithChildren<INewbuildingPremiumBuilderLayoutProps>
> = ({ logo, label, stats, isCompact, rating }) => {
  return (
    <div
      className={cx(style['container'], (label || rating) && style['with--label'], isCompact && style['with--compact'])}
      data-testid="NewbuildingPremiumBuilder"
    >
      {!isCompact && <div className={style['background']} />}

      <div className={style['logo']}>{logo}</div>

      {rating && <div className={style['rating']}>{rating}</div>}
      {label && <div className={style['label']}>{label}</div>}

      <div data-testid="sidebar-builder-info" className={cx(style['wrapper'], isCompact && style['wrapper--compact'])}>
        {stats
          .filter(stat => !isCompact || stat.isAvailableForCompactMode)
          .map(({ testId, title, value, link, onClick }) => {
            return link ? (
              <a
                key={value}
                data-testid={testId}
                className={cx(
                  style['title'],
                  stats.length === 1 && style['full-width'],
                  isCompact && style['title--compact'],
                )}
                href={link}
                target="blank"
                rel="noreferrer"
                onClick={onClick}
              >
                <UIText3 color="gray60_100">{title}</UIText3>
                <UIHeading5 color="text-primary-default">{value}</UIHeading5>
              </a>
            ) : (
              <div
                key={value}
                data-testid={testId}
                className={cx(
                  style['title'],
                  stats.length === 1 && style['full-width'],
                  isCompact && style['title--compact'],
                )}
              >
                <UIText3 color="gray60_100">{title}</UIText3>
                <UIHeading5 color="text-primary-default">{value}</UIHeading5>
              </div>
            );
          })}
      </div>
    </div>
  );
};
