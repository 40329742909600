import * as React from 'react';
import { useSelector } from 'react-redux';

import { getMasterOrSelfLink } from 'shared/selectors/agentSelectors';

import { SubscriptionAuthorAvatar } from '../../../components/subscriptionBrand';
import { selectAgencyAvatarUrl, selectAgentAvatarUrl, selectAgentUrl } from '../../../selectors/offerData/agent';
import { trackGoToAgentCard } from '../SubscriptionAuthorBrand/tracking';

export const SubscriptionAuthorAvatarContainer = () => {
  const agencyUrl = useSelector(getMasterOrSelfLink);
  const agentUrl = useSelector(selectAgentUrl);
  const agencyAvatarUrl = useSelector(selectAgencyAvatarUrl);
  const agentAvatarUrl = useSelector(selectAgentAvatarUrl);

  const isBothAvatarExists = !!agencyAvatarUrl && !!agentAvatarUrl;

  return (
    <SubscriptionAuthorAvatar
      agencyUrl={agencyUrl}
      agentUrl={agentUrl}
      agencyAvatarUrl={agencyAvatarUrl}
      agentAvatarUrl={agentAvatarUrl}
      isBothAvatarExists={isBothAvatarExists}
      handleAgentClick={trackGoToAgentCard('to_realtorCard_ava')}
      handleSubAgentClick={trackGoToAgentCard('to_realtorCard_sub')}
    />
  );
};
