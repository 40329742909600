import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackIdenticalOfferClick } from './tracking/trackIdenticalOfferClick';
import { trackShowAllIdenticalOffers } from './tracking/trackShowAllIdenticalOffers';
import { selectIdenticalOffers } from './utils/selectIdenticalOffers';
import { IdenticalOffers } from '../../components/IdenticalOffers';
import { IState } from '../../store';

export const IdenticalOffersContainer: React.FC = () => {
  const identicalOffers = useSelector(selectIdenticalOffers);
  const offer = useSelector((state: IState) => state.offerData.offer);
  const agent = useSelector((state: IState) => state.offerData.agent);

  const handleClickShowAllButton = React.useCallback(() => {
    trackShowAllIdenticalOffers({ offer, agent });
  }, [offer, agent]);

  const handleClickCard = React.useCallback(
    (identicalOfferIndex: number) => {
      trackIdenticalOfferClick({ identicalOfferIndex, identicalOffers, offer, agent });
    },
    [offer, agent],
  );

  if (!identicalOffers) {
    return null;
  }

  const { url, title, items } = identicalOffers;

  return (
    <IdenticalOffers
      items={items}
      title={title}
      showAllUrl={url}
      onClickCard={handleClickCard}
      onClickShowAllButton={handleClickShowAllButton}
    />
  );
};
