import { Button, Checkbox } from '@cian/ui-kit';
import * as React from 'react';

import { IconComments } from 'shared/common/components/icon_comments';
import { AsyncActionStatus, TAsyncActionStatus } from 'shared/store/common';
import { RemoveModalFrame, TRemoveModalFrame } from 'shared/store/popups/remove';
import { IAgentSchema, TDealType } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as styles from './index.css';
import { trackOfferMovedToArchive, trackOfferMoveArchive } from './tracking';
import { Reasons } from '../../components/Reasons';
import { Layout } from '../layout';

export interface IArchiveProps {
  agent: IAgentSchema | null;
  offerId: number;
  isByHomeowner: boolean | null;
  status: TAsyncActionStatus;
  dealType: TDealType;

  changeActiveFrame(newActiveFrame: TRemoveModalFrame): void;
  onSubmit(): void;
}

interface IArchiveState {
  value: string;
  isErrorMessageVisible: boolean;
  reviewChecked: boolean;
  infoFromUser: string;
}

const MAX_INFO_FROM_USER_LENGTH = 100;

export class Archive extends React.PureComponent<IArchiveProps, IArchiveState> {
  public state = {
    isErrorMessageVisible: false,
    value: '',
    reviewChecked: false,
    infoFromUser: '',
  };

  public render() {
    const { status, dealType } = this.props;
    const { reviewChecked, value, infoFromUser } = this.state;

    return (
      <Layout
        className={styles['container']}
        isLoading={status === AsyncActionStatus.Loading || status === AsyncActionStatus.Completed}
        title="Почему вы перенесли объявление в архив?"
      >
        <div className={styles['options']}>
          <Reasons
            value={value}
            infoFromUser={infoFromUser}
            dealType={dealType}
            isAgent={this.isSpecialist()}
            onChange={this.handleChangeValue}
            handleChangeInfoFromUser={this.handleChangeInfoFromUser}
          />
        </div>
        {this.isShowReview(value) && (
          <div className={styles['review-container']}>
            <div>
              <Checkbox
                label="Запросить отзыв о моей работе у клиента"
                onChange={this.handleReviewChecked}
                checked={reviewChecked}
              />
              <div className={styles['description']}>
                Агенты с отзывами вызывают больше доверия и привлекают больше клиентов
              </div>
            </div>
            <div className={styles['icon-group-block']}>
              <IconComments />
            </div>
          </div>
        )}
        <footer>
          <Button onClick={this.submit}>Отправить</Button>
          {this.state.isErrorMessageVisible && (
            <span className={styles['error']}>Укажите причину переноса в архив</span>
          )}
          {status === AsyncActionStatus.Failed && <span className={styles['error']}>Ошибка сервера</span>}
        </footer>
      </Layout>
    );
  }

  private handleChangeValue = (value: string) => {
    this.setState(prevState => ({
      isErrorMessageVisible: false,
      value,
      reviewChecked: this.isShowReview(value) ? prevState.reviewChecked : false,
    }));
  };

  private handleChangeInfoFromUser = (infoFromUser: string) => {
    if (infoFromUser.length > MAX_INFO_FROM_USER_LENGTH) {
      return;
    }

    this.setState({ infoFromUser });
  };

  private handleReviewChecked = (_: React.ChangeEvent<HTMLInputElement>, reviewChecked: boolean) => {
    this.setState({
      reviewChecked,
    });
  };

  private isSpecialist = () => {
    return this.props.agent && this.props.agent.accountType === 'specialist';
  };

  private isPublished = () => {
    return this.props.agent && this.props.agent.status === 'published';
  };

  private isShowReview = (value: string) => {
    return (
      !this.props.isByHomeowner &&
      this.isSpecialist() &&
      this.isPublished() &&
      ['Closed', 'Other'].indexOf(value) !== -1
    );
  };

  private submit = () => {
    const { value, reviewChecked, infoFromUser } = this.state;

    if (!value) {
      this.setState({
        isErrorMessageVisible: true,
      });

      return;
    }

    trackOfferMovedToArchive(this.props.offerId, this.state.value);
    if (infoFromUser) {
      trackOfferMoveArchive(value, infoFromUser);
    }

    if (this.isShowReview(value) && reviewChecked) {
      this.props.changeActiveFrame(RemoveModalFrame.RequestReview);

      return;
    }

    this.props.onSubmit();
  };
}
