import { Button, ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './index.css';

export interface IPopupOwnOfferProps {
  isOpen: boolean;
  onClose(): void;
}

const PopupOwnOfferComponent = (props: IPopupOwnOfferProps) => {
  const { isOpen, onClose } = props;

  return (
    <ModalWindow
      title="Вы не можете написать сообщение самому себе"
      open={isOpen}
      onClose={onClose}
      content={
        <div className={styles['actions']}>
          <Button onClick={onClose}>Закрыть</Button>
        </div>
      }
    />
  );
};

export const PopupOwnOfferContainer = (props: IPopupOwnOfferProps) => {
  return <PopupOwnOfferComponent isOpen={props.isOpen} onClose={props.onClose} />;
};
