import * as React from 'react';

import { TLineType } from 'shared/types/geo/underground';

import * as styles from './UndergroundIcon.css';
import { getUndergroundIcon } from './utils';

export interface IUndergroundIconProps {
  color: string;
  regionId: number;
  lineType?: TLineType;
}

export const UndergroundIcon: React.FunctionComponent<React.PropsWithChildren<IUndergroundIconProps>> = ({
  color,
  regionId,
  lineType,
}) => {
  const Icon = React.useMemo(() => getUndergroundIcon({ lineType, regionId }), [lineType, regionId]);

  return (
    <span className={styles['icon']} style={{ color }}>
      <Icon color="current_color" />
    </span>
  );
};
