import * as React from 'react';
import { useSelector } from 'react-redux';

import { ExportButtonNew } from '../../components/ExportButtonNew';
import { getExportLinks } from '../../selectors';

export function ExportButtonNewContainer() {
  const { docxUrl, pdfUrl } = useSelector(getExportLinks);

  return <ExportButtonNew docxUrl={docxUrl} pdfUrl={pdfUrl} />;
}
