import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NotAvailablePopup } from 'shared/components/NotAvailablePopup';
import { useChats } from 'shared/hooks/useChats';
import { getAgentAvailability, offerDataSelector } from 'shared/selectors';
import { selectNotAvailablePopupOpen } from 'shared/selectors/selectNotAvailablePopupOpen';
import { setNotAvailablePopupOpenValue } from 'shared/store/notAvailablePopupOpen';

import { trackCallAnyway, trackPopupShown } from './tracking';
import { trackSendMessageClick } from '../ChatPopupContainer/tracking';

export const NotAvailablePopupContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { offer, agent } = useSelector(offerDataSelector);
  const agentAvailability = useSelector(getAgentAvailability);
  const isNotAvailablePopupOpened = useSelector(selectNotAvailablePopupOpen);
  const { handleOpenChat } = useChats();

  const onWriteMessage = React.useCallback(() => {
    trackSendMessageClick({ offer, agent, fromWorkHoursPopup: true });
    dispatch(setNotAvailablePopupOpenValue(false));

    handleOpenChat({ type: 'open_message' });
  }, [offer, agent, dispatch, handleOpenChat]);

  const onCallAnyway = React.useCallback(() => {
    trackCallAnyway(offer, agent);
    dispatch(setNotAvailablePopupOpenValue(false));
  }, [offer, agent, dispatch]);

  const onClose = React.useCallback(() => {
    dispatch(setNotAvailablePopupOpenValue(false));
  }, [dispatch]);

  const onShow = React.useCallback(() => trackPopupShown(offer, agent), [offer, agent]);

  if (!isNotAvailablePopupOpened || !agentAvailability || agentAvailability.available) {
    return null;
  }

  const { title, message } = agentAvailability;

  return (
    <NotAvailablePopup
      onClose={onClose}
      onCallAnyway={onCallAnyway}
      onWriteMessage={onWriteMessage}
      onShow={onShow}
      title={title || ''}
      message={message || ''}
    />
  );
};
