import { connect } from 'react-redux';

import { IOfferCardAsideDispatchProps, IOfferCardAsideStoreProps, OfferCardAside } from './index';
import { setHeight, setCompactMode } from '../../store/navigation';

import type { DispatchReduxAction, IState } from '../../store';

function mapStateToProps(state: IState): IOfferCardAsideStoreProps {
  return {
    height: state.aside.height,
    isNavigationActive: state.aside.isNavigationActive,
    availableCompactMode: state.offerData.offer.newbuilding?.isTariffPremium,
  };
}

function mapDispatchToProps(dispatch: DispatchReduxAction): IOfferCardAsideDispatchProps {
  return {
    setHeight: (height: number) => dispatch(setHeight(height)),
    setCompactMode: (isEnable: boolean) => dispatch(setCompactMode(isEnable)),
  };
}

export const OfferCardAsideContainer = connect<IOfferCardAsideStoreProps, IOfferCardAsideDispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps,
)(OfferCardAside);
