import {
  IOfferSchema,
  IAddressInfoSchema,
  IUndergroundInfoSchema,
} from 'shared/types/api-models/offer-card/v1/get-offer-data';

const getUndergroundName = (underground: IUndergroundInfoSchema | undefined): string | void => {
  const name = underground && underground.name;

  return name && `м. ${name}`;
};

export const getGeoLabel = (offer: IOfferSchema) => {
  const { geo } = offer;

  if (!geo) {
    return '';
  }

  let address: IAddressInfoSchema[] = [...(geo.address || [])];
  const street = address.find(({ type }) => type === 'street');

  if (street) {
    const house = address.find(({ type }) => type === 'house');
    const city = address.find(({ locationTypeId }) => locationTypeId === 1);
    const head = [street, house, city].filter(Boolean) as IAddressInfoSchema[];

    address = [...head, ...address.filter(item => !head.includes(item))];
  }

  const underground = (geo.undergrounds || [])[0];

  return [
    address
      .map(({ shortName }) => shortName)
      .filter(Boolean)
      .join(', '),
    getUndergroundName(underground),
  ]
    .filter(Boolean)
    .join(' ');
};
