import { Button } from '@cian/ui-kit';
import * as React from 'react';

import { TCallBackButtonComponent } from '../../types';

export const CallbackButton: TCallBackButtonComponent = ({
  size,
  onClick,
  placeType,
  loading,
  buttonTheme,
  callbackButtonText,
}) => {
  return (
    <Button
      data-testid={`callback-button-${placeType}`}
      onClick={onClick}
      theme={buttonTheme}
      size={size}
      fullWidth
      loading={loading}
    >
      {callbackButtonText}
    </Button>
  );
};
