import { IState } from '../../../store';
import { selectOfferAgent } from '../selectOfferAgent';

export const selectAgentUrl = (state: IState): string | null => {
  const agent = selectOfferAgent(state);

  if (agent) {
    if (agent.accountType === 'specialist') {
      return `/agents/${agent.id}`;
    } else if (agent.offersLink) {
      return agent.offersLink;
    }
  }

  return null;
};
