import { Button, ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import { EntryField } from './EntryField';
import * as styles from './index.css';

export interface IDealRentEntryToShowModalProps {
  isOpen: boolean;
  isLoading: boolean;
  selectEntryToShow(date: string): void;
  close(): void;
}

export const DealRentEntryToShowModal = ({
  isLoading,
  selectEntryToShow,
  close,
  isOpen,
}: IDealRentEntryToShowModalProps) => {
  const [entry, setEntry] = React.useState('');

  const onSubmit = React.useCallback(() => {
    selectEntryToShow(entry);
  }, [selectEntryToShow, entry]);

  return (
    <ModalWindow
      open={isOpen}
      onClose={close}
      title="Запись на показ"
      content={
        <div className={styles['container']}>
          <div className={styles['info']}>Выберите удобное время для записи</div>
          <EntryField entry={entry} setEntry={setEntry} />
          <div className={styles['button']}>
            <Button loading={isLoading} size="XS" onClick={onSubmit}>
              Записаться
            </Button>
          </div>
        </div>
      }
    />
  );
};
