import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IPhoneSchema } from '../repositories/offer-card/entities/offer/PhoneSchema';
import { IValidPhone } from '../types/offerData';

export function formatNumber(phone: IValidPhone): string;
export function formatNumber(phone: IPhoneSchema): string | null;

export function formatNumber(phone: IValidPhone | IPhoneSchema): string | null {
  if (!phone.countryCode || !phone.number) {
    return null;
  }

  return `${phone.countryCode} ${phone.number.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2-$3-$4')}`;
}

export function getPhoneLink(phone: IValidPhone): string;
export function getPhoneLink(phone: IPhoneSchema): string | null;

export function getPhoneLink(phone: IValidPhone | IPhoneSchema): string | null {
  if (!phone.countryCode || !phone.number) {
    return null;
  }

  return `tel:${phone.countryCode}${phone.number}`;
}

export function parsePhone(phone?: string | null): IValidPhone | null {
  const notFormattedPhone = phone && phone.replace(/\D/g, '');

  if (!notFormattedPhone) {
    return null;
  }

  return {
    countryCode: `+${notFormattedPhone[0]}`,
    number: notFormattedPhone.slice(1),
  };
}

export const concatPhones = (offer: IOfferSchema | null) => {
  return offer && offer.phones && offer.phones.length > 0
    ? offer.phones.map(p => `${p.countryCode}${p.number}`)
    : undefined;
};
