import * as React from 'react';

import * as styles from './SubscriptionAuthorDignity.css';
import { ISubscriptionAuthorDignityProps } from './types';
import { AgencyLabels, AgentReviews } from '../../../components/AgencyBrandingAsideCard';

export const SubscriptionAuthorDignity = (props: ISubscriptionAuthorDignityProps) => {
  const { agentReviewTitle, hasVerifiedDocuments } = props;

  return (
    <div className={styles['dignity']}>
      {agentReviewTitle && (
        <div className={styles['dignity_item']}>
          <AgentReviews title={agentReviewTitle} />
        </div>
      )}

      {hasVerifiedDocuments && (
        <div className={styles['dignity_item']}>
          <AgencyLabels />
        </div>
      )}
    </div>
  );
};
