import * as React from 'react';

import * as styles from './styles.css';

interface IComparisonControlTooltipProp {
  text: string | null;
}

export function ComparisonControlTooltip({ text }: IComparisonControlTooltipProp) {
  return <div className={styles['container']}>{text}</div>;
}
