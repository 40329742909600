import * as React from 'react';
import { useSelector } from 'react-redux';

import { FeaturesItem, FeaturesLayout } from 'shared/components/FeaturesNew';
import { selectAmenities } from 'shared/selectors';

export const FeaturesNewV2: React.FC = () => {
  const amenitiesGroup = useSelector(selectAmenities);

  if (!amenitiesGroup) {
    return null;
  }

  return (
    <>
      {amenitiesGroup.map(({ title, amenities }, index) => (
        <FeaturesLayout key={title + index} title={title}>
          {amenities.map(({ value, theme }) => (
            <FeaturesItem key={value} name={value} theme={theme} />
          ))}
        </FeaturesLayout>
      ))}
    </>
  );
};
