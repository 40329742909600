import * as React from 'react';

import { useApplicationContext } from '../ApplicationContext';
import { WarningsFlexibleContainer } from '../WarningsFlexibleContainer';
import { WarningsStaticContainer } from '../WarningsStaticContainer';

export const WarningsContainer = () => {
  const { config } = useApplicationContext();
  const isFlexibleSanctions = config && config.get<boolean>('Moderation.CustomSanction.NewModerationInfo.Enabled');

  return isFlexibleSanctions ? <WarningsFlexibleContainer /> : <WarningsStaticContainer />;
};
