import { UIText3 } from '@cian/ui-kit';
import { IconProductTrust16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as style from './style.css';

export const NewbuildingPremiumBuilderLabel: React.FC = () => {
  return (
    <div data-testid="premium-builder-label" className={style['label']}>
      <IconProductTrust16 color="icon-main-default" />
      <UIText3 color="text-primary-default">Надежный застройщик</UIText3>
    </div>
  );
};
