import { IconMapMetroMsk16, IconMapMetroSpb16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

export interface IIconsMetro {
  type?: string;
}

export const IconsMetro: React.FC<React.PropsWithChildren<IIconsMetro>> = ({ type }) => {
  switch (type) {
    case 'spb':
      return <IconMapMetroSpb16 color="current_color" />;

    default:
      return <IconMapMetroMsk16 color="current_color" />;
  }
};

IconsMetro.displayName = 'IconsMetro';
