import { UIHeading5 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import { IBank } from 'shared/types/banksList';

import * as styles from './styles.css';

interface IBanksList {
  banksList: IBank[];
}

export const BanksList: React.FC<React.PropsWithChildren<IBanksList>> = ({ banksList }) => {
  return (
    <div className={styles['banks']}>
      {banksList.map(({ name, icon }, index) => (
        <div key={index} className={styles['bank']}>
          <span className={cx(styles['icon'])} style={{ backgroundImage: `url(${icon})` }} />
          <UIHeading5 as="span">{name}</UIHeading5>
        </div>
      ))}
    </div>
  );
};
