import { Button, Input, ModalWindow } from '@cian/ui-kit';
import { Field, FormikProps } from 'formik';
import * as React from 'react';

import { Error } from 'shared/components/DealRentModal/Fields';
import { Label } from 'shared/components/DealRentModal/Fields/Label';
import { useFieldHasErrors } from 'shared/components/DealRentModal/Fields/utils';
import { ISaveFormRequest } from 'shared/repositories/deal-rent-announcements/v1/save-form';

import { SendCodeRepeat } from './SendCodeRepeat';
import * as styles from './styles.css';

export interface IFourthStepStateProps {
  open: boolean;
  initialFormState: ISaveFormRequest;
  error: string | null;
  isLoading: boolean;
  secondsToRetry: number;
  onClose(): void;
  onClickBack(): void;
  sendValidationCode(): void;
}

export interface IFourthStepProps extends IFourthStepStateProps, FormikProps<ISaveFormRequest> {}

export const FourthStep = ({
  open,
  error,
  onClose,
  resetForm,
  handleSubmit,
  isLoading,
  secondsToRetry,
  values,
  errors,
  submitForm,
  onClickBack,
  sendValidationCode,
}: IFourthStepProps) => {
  const onCloseModal = React.useCallback(() => {
    resetForm();
    onClose();
  }, [resetForm, onClose]);

  const invalid = useFieldHasErrors('smsCode');

  const handleClickBack = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      onClickBack();
    },
    [onClickBack],
  );

  return (
    <ModalWindow
      title="Подтвердите номер телефона"
      header={`Введите код — отправили его на +7${values.phone}`}
      content={
        <form className={styles['container']} onSubmit={handleSubmit}>
          <div className={styles['field']}>
            <Label invalid={invalid}>Код из СМС</Label>
            <div className={styles['sms-code']}>
              <Field name="smsCode" as={Input} custom={{ invalid }} />
              <SendCodeRepeat sendValidationCode={sendValidationCode} secondsToRetry={secondsToRetry} />
            </div>
          </div>
        </form>
      }
      footer={
        <>
          <Error error={error} />
          <div className={styles['buttons-wrap']}>
            <Button theme={'fill_secondary'} onClick={handleClickBack} size="XS">
              Назад
            </Button>
            <Button disabled={isLoading || Boolean(errors.phone)} loading={isLoading} onClick={submitForm} size="XS">
              Дальше
            </Button>
          </div>
        </>
      }
      onClose={onCloseModal}
      open={open}
    />
  );
};
