import { connect } from 'react-redux';

import { IPrintInformationProps, PrintInformation } from './index';
import { IState } from '../../store/index';

function mapStateToProps(state: IState): IPrintInformationProps {
  return {
    isPrintModeEnabled: state.printModal.isPrintModeEnabled,
    offer: state.offerData.offer,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PrintInformationContainer = connect<IPrintInformationProps, any, any>(mapStateToProps)(PrintInformation);
