import { ca } from '@cian/analytics';

import { IAgentSchema, IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { getProduct } from '../../utils/tracking/getProduct';

export function trackPopupShown(offer: IOfferSchema, agent: IAgentSchema | null) {
  const products = getProduct(offer, agent);

  ca('eventSite', {
    action: 'show_sopr',
    category: 'work_hours',
    label: 'popup',
    name: 'oldevent',
    products: [{ ...products, variant: products.variant ? products.variant.split('/') : [] }],
  });
}

export function trackCallAnyway(offer: IOfferSchema, agent: IAgentSchema | null) {
  const products = getProduct(offer, agent);

  ca('eventSite', {
    action: 'click_callbutton',
    category: 'work_hours',
    label: 'popup',
    name: 'oldevent',
    products: [{ ...products, variant: products.variant ? products.variant.split('/') : [] }],
  });
}
