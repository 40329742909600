import cx from 'clsx';
import * as React from 'react';

import * as styles from './FavoriteButton.css';

export interface IFavoriteButtonProps {
  favoriteButton: React.ReactNode;
  pushSubscription: React.ReactNode;
}

export class FavoriteButton extends React.PureComponent<IFavoriteButtonProps> {
  public render() {
    const { favoriteButton, pushSubscription } = this.props;
    const classNames = cx(styles['container']);

    return (
      <span className={classNames}>
        {favoriteButton}
        {pushSubscription}
      </span>
    );
  }
}
