import { connect } from 'react-redux';

import { RequestReview, IRequestReviewProps } from './index';
import { archive } from '../../../../../store/popups/remove';

import type { DispatchReduxAction, IState } from '../../../../../store';

type TStoreProps = Pick<IRequestReviewProps, 'agent'>;
type TDispatchProps = Pick<IRequestReviewProps, 'onSubmit'>;

function mapStateToProps(state: IState): TStoreProps {
  return {
    agent: state.offerData.agent,
  };
}

function mapDispatchToProps(dispatch: DispatchReduxAction): TDispatchProps {
  return {
    onSubmit: () => dispatch(archive()),
  };
}

export const RequestReviewContainer = connect<TStoreProps, TDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(RequestReview);
