import { useBooleanState } from '@cian/react-utils';
import { Button, ModalWindow, Spinner } from '@cian/ui-kit';
import loadable from '@loadable/component';
import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useApplicationContext } from 'shared/containers/ApplicationContext';
import { useAgentSelector } from 'shared/hooks/selectors/useAgentSelector';
import { useOfferPreparedPhonesSelector } from 'shared/hooks/selectors/useOfferPreparedPhonesSelector';
import { useOfferSelector } from 'shared/hooks/selectors/useOfferSelector';
import { selectAgentName } from 'shared/selectors';
import { extractUndefinedValue } from 'shared/utils/extractUndefinedValue';
import { getAgentAvatar } from 'shared/utils/getAgentAvatar';

import styles from './ElectronicTradingContactButtonContainer.css';
import { trackJoinTrade, trackOpenTradingModal } from './internal/tracking';

import type { TButtonSize } from '@cian/ui-kit/button/types';

const ElectronicTradingWidgetLoadable = loadable.lib(() => import('@cian/frontend-electronic-trading-widget'), {
  ssr: false,
});

const MODAL_WIDTHS = {
  narrow: 498,
  wide: 680,
} as const;

type ElectronicTradingContactButtonContainerProps = {
  /**
   * @default 'M'
   */
  buttonSize?: TButtonSize;
  /**
   * @default false
   */
  buttonFullWidth?: boolean;
  dataTestId?: string;
};

export const ElectronicTradingContactButtonContainer: FC<ElectronicTradingContactButtonContainerProps> = props => {
  const { buttonSize = 'M', buttonFullWidth = false, dataTestId } = props;

  const { config } = useApplicationContext();

  const agent = useAgentSelector();
  const offer = useOfferSelector();

  const { tradingLink } = useOfferSelector();

  const phones = useOfferPreparedPhonesSelector();
  const publisherName = useSelector(selectAgentName);

  const modalState = useBooleanState();

  const onOpenTradingModal = useCallback(() => {
    trackOpenTradingModal(offer, agent);
    modalState.setTrue();
  }, [offer, agent, modalState]);

  if (!tradingLink) {
    return null;
  }

  const legalLink = config.getStrict<string>('electronicTrading.legalDocsUrl');

  return (
    <>
      <ModalWindow
        open={modalState.state}
        onClose={modalState.setFalse}
        portal
        size="M"
        fixed
        maxWidth={phones.length ? MODAL_WIDTHS.wide : MODAL_WIDTHS.narrow}
        title="Переходим к электронным торгам"
        content={
          <ElectronicTradingWidgetLoadable
            fallback={
              <div className={styles['modal-content-fallback']}>
                <Spinner size={40} />
              </div>
            }
          >
            {Lib => (
              <Lib.ElectronicTradingWidget
                legalLink={legalLink}
                trackLegalLinkClick={/* istanbul ignore next */ (): void => {}}
                joinTradeButtonLink={tradingLink}
                trackJoinTrade={() => trackJoinTrade(offer)}
                publisherPhoneNumbers={phones}
                publisherAvatarSrc={agent && getAgentAvatar(agent)}
                publisherTypeLabel={extractUndefinedValue(agent?.formattedPublisherType)}
                publisherName={publisherName}
                trackPublisherPhoneNumberClick={/* istanbul ignore next */ (): void => {}}
                deviceType="desktop"
              />
            )}
          </ElectronicTradingWidgetLoadable>
        }
      />
      <Button fullWidth={buttonFullWidth} size={buttonSize} onClick={onOpenTradingModal} data-testid={dataTestId}>
        Принять участие в торгах
      </Button>
    </>
  );
};
