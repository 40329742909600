import { ca } from '@cian/analytics';

import { IAgentSchema, IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

export interface IUserEBC {
  userId?: number;
  isAuthorized: boolean;
  abGroup: number;
}

export interface IPageEBC {
  breadCrumbs: string[];
  offerType: 'offer';
  offerID: number;
  pageType: 'Card';
  siteType: 'desktop';
}

export type TProductPartial = Pick<EBC.IProduct, 'id' | 'position' | 'variant' | 'ownerId'>;

export enum EtrackOffersShowActions {
  show_similar_offers = 'show_similar_offers',
  show_others_offers = 'show_others_offers',
  show_similar_newobjects = 'show_similar_newobjects',
}
export enum EtrackOfferClicksActions {
  click_similar_offers = 'click_similar_offers',
  click_others_offers = 'click_others_offers',
  click_similar_newobjects = 'click_similar_newobjects',
  click_similar_on_map = 'click_similar_on_map',
}
export enum EtrackOfferClicksLabels {
  itemBased = 'offer-based',
  userBased = 'user-based',
  newItemBased = 'new-item-based',
  historyBased = 'history-based',
  newobjectItemBased = 'newobject-item-based',
}

export interface ITrackOffersShowParams {
  action: EtrackOffersShowActions;
  products: TProductPartial[];
  modelVersion: number | string | null;
  page: IPageEBC;
  user: IUserEBC;
  label?: EtrackOfferClicksLabels;
}

export interface ITrackOfferClicksParams {
  action: EtrackOfferClicksActions;
  products: TProductPartial[];
  offer?: IOfferSchema;
  agent?: IAgentSchema | null;
  modelVersion: number | string | null;
  page: IPageEBC;
  user: IUserEBC;
  label?: EtrackOfferClicksLabels;
}

export function trackOfferClick(params: ITrackOfferClicksParams) {
  const { action, label = '', modelVersion, products, user, page } = params;

  ca('eventEbc', {
    action,
    category: 'similar_objects',
    modelVersion,
    label,
    name: 'oldevent',
    user,
    page,
    products: products.map(p => {
      return { ...p, offerType: 'offer', published: true };
    }),
  });
}

export function trackOffersShow(params: ITrackOffersShowParams) {
  const { action, label = '', modelVersion, products, user, page } = params;

  ca('eventEbc', {
    action,
    category: 'similar_objects',
    modelVersion,
    label,
    name: 'oldevent',
    user,
    page,
    products: products.map(p => {
      return { ...p, offerType: 'offer', published: true };
    }),
  });
}

interface ITrackBannerShowParams {
  page: IPageEBC;
  user: IUserEBC;
  products: TProductPartial[];
}

export function trackBannerShow(params: ITrackBannerShowParams) {
  const { page, user, products } = params;

  ca('eventEbc', {
    action: 'show_similar_banner',
    category: 'similar_objects',
    name: 'oldevent',
    page,
    user,
    products,
  });
}

interface ITrackBannerClickParams {
  page: IPageEBC;
  user: IUserEBC;
  products: TProductPartial[];
}

export function trackBannerClick(params: ITrackBannerClickParams) {
  const { page, user, products } = params;

  ca('eventEbc', {
    action: 'click_similar_banner',
    category: 'similar_objects',
    name: 'oldevent',
    page,
    user,
    products,
  });
}
