import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackSubscribePopupOpen } from './tracking';
import { PriceChangingSubscription } from '../../components/PriceChangingSubscription/PriceChangingSubscription';
import { SubscriptionPriceChangesPopupContainer } from '../../components/subscription_price_changes_popup/container';
import { selectIsUserSubscribedToPriceChange } from '../../selectors/price/selectIsUserSubscribedToPriceChange';
import { selectIsUserSubscriptionToPriceChangeEnabled } from '../../selectors/price/selectIsUserSubscriptionToPriceChangeEnabled';
import { subscriptionPriceChangesPopupOpen } from '../../store/subscription_price_changes_popup';

export const PriceChangesContainer = () => {
  const dispatch = useDispatch();
  const isSubscribed = useSelector(selectIsUserSubscribedToPriceChange);
  const isEnabled = useSelector(selectIsUserSubscriptionToPriceChangeEnabled);

  const handleSubscriptionClick = React.useCallback(() => {
    trackSubscribePopupOpen();
    dispatch(subscriptionPriceChangesPopupOpen());
  }, []);

  if (!isEnabled) {
    return null;
  }

  return (
    <>
      <PriceChangingSubscription
        label={isSubscribed ? 'Отменить подписку на цену' : 'Следить за изменением цены'}
        onClick={handleSubscriptionClick}
      />
      <SubscriptionPriceChangesPopupContainer />
    </>
  );
};
