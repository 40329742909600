import { ca } from '@cian/analytics';

export function trackPrimaryActionButtonClick(label: string) {
  ca('event', {
    action: 'stat_popup_open',
    category: 'card',
    label,
    name: 'oldevent',
  });
}

export function trackSecondaryActionButtonClick() {
  ca('event', {
    action: 'stat_popup_open',
    category: 'card',
    label: 'changeVAS',
    name: 'oldevent',
  });
}
