import { Button } from '@cian/ui-kit';
import * as React from 'react';

import { IBuyAnalyticsButtonProps } from './types';
import { IBuyAnalyticsBlockProps } from '../../types';
import { ChartFlatIcon } from '../ChartFlatIcon';

export const BuyAnalyticsButton: React.FC<IBuyAnalyticsButtonProps & IBuyAnalyticsBlockProps> = ({
  reportPrice,
  onBuyClick,
}) => (
  <Button
    theme="fill_primary"
    size="XS"
    color="control-inverted-primary-default"
    onClick={onBuyClick}
    beforeIcon={<ChartFlatIcon />}
  >
    Купить отчёт по зданию за {reportPrice}
  </Button>
);

BuyAnalyticsButton.displayName = 'BuyAnalyticsButton';
