import * as React from 'react';

export function RealtorHonest16() {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 2c-1.875.8-4 1.2-4 1.2l.424 4.217a10 10 0 0 0 6.8 8.491L8 16l.276-.092a10 10 0 0 0 6.8-8.491L15.5 3.2s-2.125-.4-4-1.2C9.625 1.2 8 0 8 0S6.375 1.2 4.5 2Zm3.452 9.365 3.826-4.737-1.556-1.256-2.635 3.262L5.586 7.19 4.415 8.811l3.537 2.554Z"
        fill="var(--accent-positive-primary)"
      ></path>
    </svg>
  );
}
