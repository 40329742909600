import * as React from 'react';

import { PhonesOpenedContext, TPhonesOpenedContext } from './context';

export const PhonesOpenedProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isPhonesOpened, setPhonesOpened] = React.useState(false);

  const context = React.useMemo<TPhonesOpenedContext>(
    () => ({
      isPhonesOpened,
      setPhonesOpened,
    }),
    [isPhonesOpened],
  );

  return <PhonesOpenedContext.Provider value={context}>{children}</PhonesOpenedContext.Provider>;
};
