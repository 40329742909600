import { IMedia } from 'shared/types/api-models/offer-card/v1/identicalOffers';

import { IMediaView } from '../../../types/identicalOffersView';

export function formatMedia({ photoUrl, ...media }: IMedia): IMediaView {
  return {
    ...media,
    photoUrl: photoUrl || null,
  };
}
