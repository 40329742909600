interface IParams {
  isSubAgent: boolean;
  agentUrl: string | null;
  agencyUrl: string | null;
}

export const getNameUrl = (params: IParams): string | null => {
  const { isSubAgent, agentUrl, agencyUrl } = params;

  return isSubAgent ? agentUrl : agencyUrl;
};
