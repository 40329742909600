import { Avatar, Overline, UIHeading3, UIText3 } from '@cian/ui-kit';
import { IconProductDocumentsVerified16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { THomeownerProof } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as style from './style.css';
import { HOMEOWNER_PROOF_TEXTS } from '../../../constants/homeownerProofTexts';

export interface IHomeownerLayoutProps {
  avatar: string;
  userName: string;
  homeownerProofs: THomeownerProof[] | null;
  superAgentElement: React.ReactNode;
}

export const HomeownerLayout: React.FC<React.PropsWithChildren<IHomeownerLayoutProps>> = ({
  avatar,
  userName,
  homeownerProofs,
  superAgentElement,
}) => {
  return (
    <div className={style['wrapper']}>
      <Avatar size={72} src={avatar} type="user" />

      <div className={style['title']}>
        <Overline color="gray60_100">Собственник</Overline>
        <UIHeading3 color="text-primary-default">{userName}</UIHeading3>
        {superAgentElement && <div className={style['badge']}>{superAgentElement}</div>}
        {homeownerProofs && (
          <ul className={style['proofs']}>
            {homeownerProofs?.map(proof => (
              <li key={proof}>
                <IconProductDocumentsVerified16 color="icon-main-default" />
                <UIText3>{HOMEOWNER_PROOF_TEXTS[proof]}</UIText3>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
