import { Image, UIHeading4, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './newbuildingBuildersFactItem.css';

interface INewbuildingBuildersFactItemProps {
  imageUrl: string;
  title: string;
  description: string;
}

export function NewbuildingBuildersFactItem({ imageUrl, title, description }: INewbuildingBuildersFactItemProps) {
  return (
    <div className={styles['item']}>
      <div className={styles['image']}>
        <Image width="100%" src={imageUrl} />
      </div>
      <div className={styles['content']}>
        <UIHeading4>{title}</UIHeading4>
        <UIText2 color="gray60_100">{description}</UIText2>
      </div>
    </div>
  );
}
