import * as React from 'react';

import * as styles from './SubscriptionAuthorBrand.css';
import { ISubscriptionAuthorBrandProps } from './types';

export const SubscriptionAuthorBrand = (props: ISubscriptionAuthorBrandProps) => {
  const { avatar, title, name, dignity, actions, superAgentElement } = props;

  return (
    <div id="author" className={styles['container']}>
      <div className={styles['content']}>
        {avatar}
        {title}
        {name}
        {dignity}
        {superAgentElement}
        {actions}
      </div>
    </div>
  );
};
