import * as React from 'react';
import { useSelector } from 'react-redux';

import { IAuthorPremiumProps } from 'shared/components/AuthorPremium';
import { IAuthorFactProps } from 'shared/components/AuthorPremium/components/AuthorFact';
import { IState } from 'shared/store';
import { ICompanyWithFeatures } from 'shared/types/newbuildingCompany';
import { getNewbuildingsListQueryStringUrl } from 'shared/utils/formatters';
import { pluralize } from 'shared/utils/pluralize';

import * as Analytics from '../../../components/AuthorPremium/tracking';

const appendFactsToProps = (offerId: number, company: ICompanyWithFeatures | null): IAuthorFactProps[] => {
  const facts: IAuthorFactProps[] = [];

  const yearFoundation = company?.yearFoundation;
  const housesBuiltInTime = company?.housesBuiltInTime;

  if (yearFoundation) {
    facts.push({
      testId: 'year-foundation-stat',
      value: yearFoundation,
      text: 'год<br/>основания',
    });
  }

  const stats = company?.stats;

  if (stats && stats.housesDone && stats.housesDone.housesCount) {
    facts.push({
      testId: 'houses-done-stat',
      value: stats.housesDone.housesCount,
      text: `${pluralize(stats.housesDone.housesCount, ['дом сдан', 'дома сдано', 'домов сдано'], true)}<br/>в ${
        stats.housesDone.newbuildingCount
      } ЖК`,
      link: getNewbuildingsListQueryStringUrl(stats.housesDone.qs),
      onClick: Analytics.trackClickBuilderFact.bind(null, offerId, 'builder_block_ready_JK'),
    });
  }

  if (stats && stats.housesInProgress && stats.housesInProgress.housesCount) {
    facts.push({
      testId: 'houses-in-progress-stat',
      value: stats.housesInProgress.housesCount,
      text: `${pluralize(
        stats.housesInProgress.housesCount,
        ['дом строится', 'дома строится', 'домов строится'],
        true,
      )}<br/>в ${stats.housesInProgress.newbuildingCount} ЖК`,
      link: getNewbuildingsListQueryStringUrl(stats.housesInProgress.qs),
      onClick: Analytics.trackClickBuilderFact.bind(null, offerId, 'builder_block_construction_JK'),
    });
  }

  if (housesBuiltInTime) {
    facts.push({
      testId: 'built-in-time-stat',
      value: housesBuiltInTime,
      text: 'домов сдано<br/>вовремя',
    });
  }

  return facts;
};

export const useAuthorPremium = (): IAuthorPremiumProps => {
  const offerId = useSelector((state: IState) => state.pageData && state.pageData.offerId);
  const company = useSelector((state: IState) => state.offerData.company) as ICompanyWithFeatures;

  const facts = React.useMemo<IAuthorFactProps[]>(() => {
    return appendFactsToProps(offerId, company);
  }, [offerId, company]);

  return React.useMemo<IAuthorPremiumProps>(() => {
    let description = company?.description;

    if (description && description.length > 255) {
      description = description.substring(0, 255).concat('...');
    }

    const props: IAuthorPremiumProps = {
      offerId,
      builderId: company?.id ?? 0,
      description,
      isReliable: company?.isReliable ?? false,
      logoUrl: company?.logoUrl,
      title: company && `Застройщик «${company.name}»`,
      authorUrl: company?.url,
      facts,
    };

    return props;
  }, [offerId, company, facts]);
};
