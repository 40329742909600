import * as React from 'react';

// TODO: обновить иконку IconMapMCD216 в ui-kit и использовать ее, а эту удалить
/* istanbul ignore next */
export const IconMapMCD216: React.FC = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_31286_6444)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00003 2H-0.00463867V4H1L1.00223 14L3.00003 14C6.31372 14 8.99997 11.3137 8.99997 7.99999C8.99997 4.68633 6.31372 2 3.00003 2ZM3.00002 12C5.20912 12 6.99997 10.2091 6.99997 7.99999C6.99997 5.79087 5.20912 4 3.00003 4L3.00002 12Z"
        fill="currentColor"
      />
      <path
        d="M11.212 12H15.5V14H9.00391V12.1059C9.5 10.5 10.2158 9.41982 11.3929 8.3899L13.0284 6.95887C13.5794 6.47667 13.8909 5.51252 13.5103 4.83602C13.0348 3.99056 11.8275 3.96267 11.3134 4.78526L10.8479 5.53001L9.15193 4.47002L9.61739 3.72527C10.9363 1.61497 14.0334 1.68652 15.2535 3.85549C16.0938 5.34935 15.6028 7.36377 14.3454 8.46402L12.7099 9.89505C12.0502 10.4723 11.5 11 11.212 12Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_31286_6444">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
