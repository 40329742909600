import * as React from 'react';

import * as styles from './styles.css';

interface IOfferTitleNewProps {
  title: string;
  link: React.ReactNode;
}

export function OfferTitleNew({ title, link }: IOfferTitleNewProps) {
  return (
    <div className={styles['container']}>
      <h1 className={styles['title']}>{title}</h1>
      {link}
    </div>
  );
}
