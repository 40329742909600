import { IBreadcrumbItemSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IPageEBC } from '../../ebc/track_similar';

/**
 * Возвращает объект page требуемый для SOPR_EBEC
 */
export function getPageEBC(id: number, breadCrumbs: IBreadcrumbItemSchema[] | null): IPageEBC {
  return {
    breadCrumbs: breadCrumbs ? breadCrumbs.map(b => b.title) : [],
    offerType: 'offer',
    offerID: id,
    pageType: 'Card',
    siteType: 'desktop',
  };
}
