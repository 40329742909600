import * as React from 'react';

type IBuilderProps = React.SVGProps<SVGSVGElement>;

export function Builder(props: IBuilderProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 14">
      <g fill="#2E9E00" fillRule="evenodd">
        <path d="M7 13.945c-1.071 0-2.023-.436-2.38-1.2-.12-.218 0-.436.119-.545.238-.11.476 0 .595.11.357.763 1.428.763 1.666.763s1.31-.11 1.666-.764c.12-.218.357-.218.595-.109.238.11.238.327.12.545-.358.764-1.31 1.2-2.381 1.2z" />
        <path d="M11.998 10.455c-.119 2.4-2.26 4.363-4.879 4.363-2.618 0-4.88-1.963-4.88-4.363H1.05c.12 3.054 2.738 5.454 6.07 5.454 3.332 0 6.07-2.4 6.07-5.454h-1.19z" />
        <path d="M9.856 10.673c0-.328-.357-.655-.714-.655s-.714.327-.714.655c0 .327.357.654.714.654s.714-.218.714-.654zM4.977 11.436c.357 0 .714-.327.714-.654s-.357-.655-.714-.655-.714.218-.714.655c0 .436.357.654.714.654zM9.975 2.49V5h-1.19V.636h-3.57V5h-1.19V2.49c-2.38 1.092-2.856 4.692-2.856 4.692H.455v2.182h13.09V7.182h-.714s-.476-3.6-2.856-4.691z" />
      </g>
    </svg>
  );
}
