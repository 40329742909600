import * as React from 'react';

import man from './assets/man.png';
import phone from './assets/phone.png';
import sand from './assets/sand.png';
import sova from './assets/sova.png';
import * as styles from './styles.css';

export type TDealRentOfferModalContentProps = {};

const ELEMENTS = [
  { icon: sand, text: 'Ответы на стандартные вопросы сэкономят время' },
  { icon: man, text: 'Сможете отправить анкету хозяевам любых квартир' },
  { icon: sova, text: 'Отправляйте анкету и днём, и ночью — когда удобно' },
  { icon: phone, text: 'Созванивайтесь только с теми, кто вам подходит' },
];

export const OnboardingContent: React.FC<React.PropsWithChildren<TDealRentOfferModalContentProps>> = () => (
  <div className={styles['container']}>
    <div className={styles['items']}>
      {ELEMENTS.map(({ icon, text }, i) => (
        <div className={styles['item']} key={`item_${i}`}>
          <img className={styles['icon']} src={icon} />
          <div className={styles['content']}>{text}</div>
        </div>
      ))}
    </div>
  </div>
);
