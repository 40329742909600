import { Select } from '@cian/ui-kit';
import { Field, useFormikContext } from 'formik';
import * as React from 'react';

import { Label } from './Label';
import { useFieldHasErrors } from './utils';
import { IFormValues } from '../DealRentModal';
import * as styles from '../index.css';

const options = ['Семья', 'Пара', 'Несколько семей'].map(value => ({ value, label: value }));

export const PersonsDescription = () => {
  const { setFieldValue } = useFormikContext<IFormValues>();
  const invalid = useFieldHasErrors('personsInfo');
  const onChange = React.useCallback((value: string) => setFieldValue('personsInfo', value), [setFieldValue]);

  return (
    <div className={styles['field']}>
      <Label invalid={invalid}>Состав проживающих</Label>
      <Field name="personsInfo">
        {() => <Select options={options} onChange={onChange} title="Выберите состав проживающих" invalid={invalid} />}
      </Field>
    </div>
  );
};
