import { ca } from '@cian/analytics';

export const trackAboutFlatAndHomeShow = () => {
  ca('eventSite', {
    name: 'oldevent',
    action: 'show_sopr',
    label: 'about_flat_and_home',
    category: 'Card',
    useLastProducts: true,
  });
};
