import { TDealType } from '@cian/price-history-change-widget';

import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

export function getDealType(offer: IOfferSchema): TDealType {
  if (offer.category.includes('daily')) {
    return 'rentDaily';
  }

  return offer.dealType;
}
