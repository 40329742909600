/* eslint-disable react/display-name, @typescript-eslint/no-non-null-assertion, @typescript-eslint/member-ordering */
import * as React from 'react';
import { throttle } from 'throttle-debounce';

import { MAP_CONTAINER_ID } from '../../../constants/map';
import { getOffsetTop } from '../../../utils/navigation';
import { IOfferCardNavigationProps, NavigationTab } from '../types';

/**
 * Слушает скролл у window, проверяет позиции на странице элементов description, map, author и
 * в зависимости от них выставляет в хранилище выбранное меню
 * @param {React.ComponentClass} ReactComponent
 */
export const fixedWithListenWindowScroll = (ReactComponent: React.ComponentClass) => {
  return class extends React.Component<IOfferCardNavigationProps, {}> {
    private descriptionElement: HTMLElement | null;
    private mapElement: HTMLElement | null;
    private servicePlaceOverMapElement: HTMLElement | null;
    private servicePlaceOverEvaluationElement: HTMLElement | null;
    private servicePlaceUnderMapElement: HTMLElement | null;
    private valuation: HTMLElement | null;
    private authorElement: HTMLElement | null;
    private similarOffers: HTMLElement | null;

    public componentDidMount() {
      this.descriptionElement = document.querySelector('#description') as HTMLElement | null;
      this.mapElement = document.getElementById(MAP_CONTAINER_ID) as HTMLElement | null;
      this.servicePlaceOverMapElement = document.querySelector('#servicePlaceOverMap') as HTMLElement | null;
      this.servicePlaceOverEvaluationElement = document.querySelector(
        '#servicePlaceOverEvaluation',
      ) as HTMLElement | null;
      this.servicePlaceUnderMapElement = document.querySelector('#servicePlaceUnderMap') as HTMLElement | null;
      this.valuation = document.querySelector('#valuation') as HTMLElement | null;
      this.authorElement = document.querySelector('#author') as HTMLElement | null;
      this.similarOffers = document.querySelector('#similarOffers') as HTMLElement | null;

      window.addEventListener('scroll', throttle(100, this.handleScroll));
      this.handleScroll();
    }

    public componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    }

    private setAnchor(anchor: string) {
      const { changeNavigationAnchor } = this.props;

      if (changeNavigationAnchor) {
        changeNavigationAnchor(anchor);
      }
    }

    private handleScroll = () => {
      const { isBottomAgentBlockAvailable, isActive, toggleNavigation, isSimilarOffersAvailable } = this.props;
      if (!this.descriptionElement || (isBottomAgentBlockAvailable && !this.authorElement) || !this.mapElement) {
        return;
      }

      const offsetTop = getOffsetTop(this.descriptionElement as HTMLElement, 52, 10);

      if (window.pageYOffset >= offsetTop) {
        const isAuthorSection =
          isBottomAgentBlockAvailable &&
          window.pageYOffset >=
            getOffsetTop(this.authorElement!, 0, 0) +
              this.authorElement!.getBoundingClientRect().height +
              10 -
              Math.max(document.documentElement.clientHeight, window.innerHeight || 0) / 1.5;

        const isMapSection = window.pageYOffset >= getOffsetTop(this.mapElement, 52, 10);

        let isValuation = false;
        if (this.valuation) {
          isValuation =
            window.pageYOffset >=
            getOffsetTop(this.valuation, 52, 10) +
              this.valuation.getBoundingClientRect().height +
              10 -
              Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        }

        let isSimilarOffers = false;
        if (this.similarOffers && isSimilarOffersAvailable) {
          isSimilarOffers = window.pageYOffset >= getOffsetTop(this.similarOffers, 52, 10);
        }

        let isServicePlaceOverMapSelection = false;
        if (this.servicePlaceOverMapElement) {
          isServicePlaceOverMapSelection = window.pageYOffset >= getOffsetTop(this.servicePlaceOverMapElement, 52, 10);
        }

        let isServicePlaceOverEvaluationSelection = false;
        if (this.servicePlaceOverEvaluationElement) {
          isServicePlaceOverEvaluationSelection =
            window.pageYOffset >= getOffsetTop(this.servicePlaceOverEvaluationElement, 52, 10);
        }

        let isServicePlaceUnderMapSelection = false;
        if (this.servicePlaceUnderMapElement) {
          isServicePlaceUnderMapSelection =
            window.pageYOffset >= getOffsetTop(this.servicePlaceUnderMapElement, 52, 10);
        }

        if (!isActive && toggleNavigation) {
          toggleNavigation(true);
        }

        if (isSimilarOffers) {
          if (this.props.activeAnchor !== NavigationTab.SimilarOffers) {
            this.setAnchor(NavigationTab.SimilarOffers);
          }

          return;
        } else if (isAuthorSection) {
          if (this.props.activeAnchor !== NavigationTab.Author) {
            this.setAnchor(NavigationTab.Author);
          }

          return;
        } else if (isValuation) {
          if (this.props.activeAnchor !== NavigationTab.Valuation) {
            this.setAnchor(NavigationTab.Valuation);
          }

          return;
        } else if (isServicePlaceOverEvaluationSelection) {
          if (this.props.activeAnchor !== NavigationTab.ServicePlaceOverEvaluation) {
            this.setAnchor(NavigationTab.ServicePlaceOverEvaluation);
          }

          return;
        } else if (isServicePlaceUnderMapSelection) {
          if (this.props.activeAnchor !== NavigationTab.ServicePlaceUnderMap) {
            this.setAnchor(NavigationTab.ServicePlaceUnderMap);
          }

          return;
        } else if (isMapSection) {
          if (this.props.activeAnchor !== NavigationTab.Map) {
            this.setAnchor(NavigationTab.Map);
          }

          return;
        } else if (isServicePlaceOverMapSelection) {
          if (this.props.activeAnchor !== NavigationTab.ServicePlaceOverMap) {
            this.setAnchor(NavigationTab.ServicePlaceOverMap);
          }

          return;
        }

        if (this.props.activeAnchor !== NavigationTab.Description) {
          this.setAnchor(NavigationTab.Description);
        }

        return;
      }

      if (isActive && toggleNavigation) {
        toggleNavigation(false);
      }
      this.setAnchor(NavigationTab.Photos);
    };

    public render() {
      const props = {
        ...this.props,
        isFixed: true,
        showRightContent: true,
      };

      return <ReactComponent {...props} />;
    }
  };
};
