// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  TCheckAccessToAdminApiModel,
  IMappers,
  TCheckAccessToAdminApiResponse,
  ICheckAccessToAdminApiResponse,
  ICheckAccessToAdminApiResponseError,
} from './types';

export const defaultConfig: TCheckAccessToAdminApiModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'GET',
  microserviceName: 'price-estimator',
  pathApi: '/v1/check-access-to-admin-api/',
} as TCheckAccessToAdminApiModel;

export function createCheckAccessToAdminApiModel(): TCheckAccessToAdminApiModel {
  return {
    ...defaultConfig,
  };
}

export interface ICheckAccessToAdminApiOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

export async function fetchCheckAccessToAdminApi<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
}: ICheckAccessToAdminApiOptions<TResponse200, TResponse400>): Promise<
  TCheckAccessToAdminApiResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createCheckAccessToAdminApiModel(), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ICheckAccessToAdminApiResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as ICheckAccessToAdminApiResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TCheckAccessToAdminApiResponse;
}
