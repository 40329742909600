import { Spinner } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './index.css';

interface ILayoutProps {
  children: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  title: string;
}

export function Layout(props: ILayoutProps) {
  const { children, className, isLoading, title } = props;

  return (
    <div className={cx([styles['container'], className])}>
      {isLoading && (
        <div className={styles['preloader-wrapper']}>
          <Spinner size={24} />
        </div>
      )}

      <h3 className={styles['title']}>{title}</h3>

      {children}
    </div>
  );
}
