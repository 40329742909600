import * as React from 'react';

import * as styles from './mapAnchor.css';

interface IMapAnchorProps {
  text: string;
  onClick(): void;
}

export const MapAnchor = ({ text, onClick }: IMapAnchorProps) => {
  return (
    <span className={styles['anchor']} onClick={onClick}>
      {text}
    </span>
  );
};
