import { ca } from '@cian/analytics';

import { IOfferSchema, IAgentSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';
import { getCustomUrl } from 'shared/utils/tracking/getCustomUrl';

export function trackOpenTradingModal(offer: IOfferSchema, agent: IAgentSchema | null): void {
  ca('eventEbc', {
    name: 'oldevent',
    category: 'Phones',
    action: 'Open_card_rosimuschestvo',
    label: getCustomUrl(offer, agent),
    useLastProducts: true,
  });
}

export function trackJoinTrade(offer: IOfferSchema): void {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card',
    action: 'Phones_Rosimuschestvo_popup_click',
    products: [offer.id],
  });
}
