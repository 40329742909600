import { connect } from 'react-redux';

import { ILegendModalProps, LegendModal } from './index';
import { toggle } from '../../../store/popups/legend';
import { getLegendData, ILegendItem } from '../../../utils/legend';
import { getOfferType } from '../../../utils/offer_type';

import type { DispatchReduxAction, IState } from '../../../store';

type TStoreProps = Pick<ILegendModalProps, 'isOpen' | 'legendData'>;
type TDispatchProps = Pick<ILegendModalProps, 'close'>;

function mapStateToProps(state: IState): TStoreProps {
  let legendData: ILegendItem[] = [];
  const offerType = getOfferType(state.offerData.offer);

  if (state.mapSimilar?.data?.points) {
    legendData = getLegendData(state.mapSimilar.data.points, offerType);
  }

  return {
    isOpen: state.legend.isOpen,
    legendData,
  };
}

function mapDispatchToProps(dispatch: DispatchReduxAction): TDispatchProps {
  return {
    close: () => dispatch(toggle(false)),
  };
}

export const LegendModalContainer = connect<TStoreProps, TDispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps,
)(LegendModal);
