import * as React from 'react';

import * as styles from './OnlineStatus.css';

interface IOnlineStatusProps {
  onShow(): void;
}

export const OnlineStatus: React.FC<IOnlineStatusProps> = ({ onShow }) => {
  React.useEffect(() => onShow(), [onShow]);

  return <span className={styles['status']}>Напишите, пока пользователь в сети</span>;
};
