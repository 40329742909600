import { UIText3 } from '@cian/ui-kit';
import { IconFunctionalClock16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './QuickReplyStatus.css';

export const QuickReplyStatus: React.FC = () => {
  return (
    <span className={styles['status']}>
      <IconFunctionalClock16 color="icon-main-default" />
      <UIText3 color="black_60">Быстро отвечает на сообщения</UIText3>
    </span>
  );
};
