import { connect } from 'react-redux';

import { DealRentEntryToShowModal } from './DealRentEntryToShowModal';
import { IState } from '../../store';
import { selectEntryToShowAction, closeEntryToShow } from '../../store/deal-rent';

export function mapStateToProps(state: IState) {
  return {
    isLoading: state.dealRent.isPrefetching,
    isOpen: state.dealRent.isEntryToShowOpen,
  };
}

export const DealRentEntryToShowModalContainer = connect(mapStateToProps, {
  selectEntryToShow: selectEntryToShowAction,
  close: closeEntryToShow,
})(DealRentEntryToShowModal);
