import * as React from 'react';

type IBubblesProps = React.SVGProps<SVGSVGElement>;

export function Bubbles(props: IBubblesProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="66" height="42" viewBox="0 0 66 42">
      <g fill="none" fillRule="evenodd" strokeWidth="2">
        <path
          stroke="#2B87DB"
          d="M29.681 31.885c-2.43 3.996-7.359 6.678-13.08 6.598a17.221 17.221 0 0 1-6.807-1.487l-4.146 2.626-.209-5.529c-2.374-2.27-3.84-5.239-3.885-8.473-.097-6.99 6.482-12.563 14.695-12.448 2.485.034 4.836.586 6.91 1.534"
        />
        <path
          stroke="#E7526E"
          d="M58.782 29.021c3.308-2.967 5.32-6.878 5.319-11.165.002-9.266-9.387-16.774-20.973-16.772-11.585.004-20.979 7.518-20.981 16.783-.004 9.268 9.387 16.777 20.972 16.773 3.45 0 6.704-.667 9.575-1.848l5.9 3.557.188-7.328z"
        />
      </g>
    </svg>
  );
}
