import cx from 'clsx';
import * as React from 'react';

import * as styles from './asideItem.css';

export interface IAsideItemProps {
  children: React.ReactNode;
  isRedesign?: boolean;
}

export const AsideItem = ({ children, isRedesign }: IAsideItemProps) => {
  const classNames = cx(styles['item'], isRedesign && styles['item--redesign']);

  return <div className={classNames}>{children}</div>;
};
