import { type TTgbNativedescGeneralContext } from '@cian/adfox-templates';

import type { INewbuildingInfoForTgbSchema } from 'shared/repositories/newbuilding-search/entities/get_newbuildings_info_for_tgb/NewbuildingInfoForTgbSchema';

import type { TTgbNativedescGeneralProps } from '../components/TgbNativedescGeneralNarrow';

type TGetTgbNativedescGeneralNarrowPropsParams = {
  context: TTgbNativedescGeneralContext;
  newbuidingInfoForTgb: null | INewbuildingInfoForTgbSchema;
};

export function getTgbNativedescGeneralNarrowProps({
  context: { url, label, image, title, annotation, description, logoImageUrl },
  newbuidingInfoForTgb,
}: TGetTgbNativedescGeneralNarrowPropsParams): TTgbNativedescGeneralProps {
  return {
    annotation,
    description,
    image,
    label,
    logoUrl: newbuidingInfoForTgb?.publisher.logo.url || logoImageUrl,
    title,
    url,
  };
}
