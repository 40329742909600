import { ca } from '@cian/analytics';

import { EModelTypes } from 'shared/store/similar';

const TRACK_TAB_LABELS = {
  [EModelTypes.UserBased]: 'user-based',
  [EModelTypes.ItemBased]: 'offer-based',
  [EModelTypes.HistoryBased]: 'history-based',
  [EModelTypes.NewItemBased]: 'new-item-based',
  [EModelTypes.NewobjectItemBased]: 'newobject-item-based',
};

export function trackTabClick(type: EModelTypes) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'similar_objects',
    action: 'click_tab_similar',
    label: TRACK_TAB_LABELS[type],
  });
}
