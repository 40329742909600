import { UIText1, Button } from '@cian/ui-kit';
import * as React from 'react';

import { NBSP } from 'shared/constants';

import styles from './TourLayout.css';

interface ITourLayoutProps {
  tourBannerImageUrl: string;
  onClick: () => void;
}

export const TourLayout: React.FC<ITourLayoutProps> = ({ tourBannerImageUrl, onClick }) => {
  return (
    <div
      className={styles['layout']}
      style={{ backgroundImage: `url(${tourBannerImageUrl})` }}
      data-tour="layout"
      data-testid="tour"
      onClick={onClick}
    >
      <div className={styles['text']}>
        <UIText1 userSelect="none" color="text-inverted-default">
          Посмотрите 3D-тур,{NBSP}чтобы оценить объект
        </UIText1>
      </div>
      <Button theme="fill_white_primary" size="XS">
        Посмотреть
      </Button>
    </div>
  );
};
