/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import cx from 'clsx';
import * as React from 'react';
import { useEffect } from 'react';

import { IAgentSchema, IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as styles from './SimilarOffers.css';
import { SimilarOfferItem } from './components/SimilarOfferItem';
import { getSettings as getSlickSettings } from './slick/getSettings';
import './slick/slickCustomStyles.css';
import { trackEntityTeaserShow } from './tracking';
import { getAnalyticsLabel } from './utils/getAnalyticsLabel';
import * as Analytics from '../../ebc/track_similar';
import { ISimilarOffer, TModelType } from '../../store/similar';
import { isArrayWithItems } from '../../utils';
import { getVariants } from '../../utils/ebc';
import { CustomSlider } from '../Slider';

export interface ISimilarOffersProps {
  title?: string | React.ReactNode;
  agent: IAgentSchema | null;
  offer: IOfferSchema;
  similarOffers: ISimilarOffer[];
  withoutCompetitors: boolean | null;
  modelVersion: number | string | null;
  modelType: TModelType;
  user: Analytics.IUserEBC;
  page: Analytics.IPageEBC;
  disableExternalContainerStyles?: boolean;
  slidesToShow?: number;

  requestSimilarOffers?(): void;
}

export const SimilarOffers = (props: ISimilarOffersProps) => {
  const { title, similarOffers, disableExternalContainerStyles, slidesToShow } = props;
  const slickSettings = getSlickSettings({ totalSlides: similarOffers.length, slidesToShow });
  const firstLabelIndex = similarOffers.findIndex(({ photoLabel }) => photoLabel && photoLabel.name);

  useEffect(() => {
    const { requestSimilarOffers } = props;
    /* istanbul ignore else */
    if (requestSimilarOffers) {
      requestSimilarOffers();
    }
  }, []);

  /* istanbul ignore next */
  const handleClickOffer = (curOffer: ISimilarOffer, position: number) => {
    const { agent, offer, withoutCompetitors, modelType, modelVersion: propsModelVersion, user, page } = props;
    const products: Analytics.TProductPartial[] = [
      {
        id: curOffer.cianId,
        position,
        ownerId: curOffer.publishedUserId || undefined,
        variant: curOffer.gaVariant ? getVariants(curOffer.gaVariant) : [],
      },
    ];
    const modelVersion = String(propsModelVersion || 0);

    Analytics.trackOfferClick({
      action: withoutCompetitors
        ? Analytics.EtrackOfferClicksActions.click_others_offers
        : Analytics.EtrackOfferClicksActions.click_similar_offers,
      label: getAnalyticsLabel(modelType, withoutCompetitors),
      products,
      agent,
      offer,
      modelVersion,
      user,
      page,
    });
  };

  const handleEntityTrackTeaserShow = React.useCallback(
    (offerId: number, position: number) => {
      const { modelType } = props;
      trackEntityTeaserShow({ offerId, position, modelType });
    },
    [props],
  );

  if (!isArrayWithItems<ISimilarOffer>(similarOffers)) {
    return null;
  }

  return (
    <section
      className={cx(styles['container'], {
        [styles['disable-external-container-styles']]: disableExternalContainerStyles,
      })}
    >
      {typeof title === 'string' ? <h2 className={styles['title_block']}>{title}</h2> : title}
      <CustomSlider {...slickSettings} className="similar-offers-slider">
        {similarOffers.map((similarOffer, index) => (
          <ActionAfterViewObserver
            key={`similarOffer_${index}`}
            percentVisible={0.5}
            callback={() => handleEntityTrackTeaserShow(similarOffer.cianId, index + 1)}
            triggerOnce={false}
            callbackDelay={1000}
          >
            <SimilarOfferItem
              offer={similarOffer}
              withPhotoLabel={firstLabelIndex === index}
              onClick={handleClickOffer}
              position={index + 1}
            />
          </ActionAfterViewObserver>
        ))}
      </CustomSlider>
    </section>
  );
};
