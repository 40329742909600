import { IState } from '../../store';
import { IRosreestrInfo } from '../../types/offerData';

export const getRosreestrInfo = (state: IState): IRosreestrInfo | null => {
  const {
    offerData: { rosreestrInfo },
  } = state;

  return rosreestrInfo ? { ...rosreestrInfo, deck: '' } : null;
};
