import * as React from 'react';

import { RenterFormContainer } from '../../renterForm/containers/RenterForm';
import { DealRentButtonContainer } from '../DealRentButton';
import { DealRentEntryToShowButtonContainer } from '../DealRentEntryToShowButton';
import { DealRentEntryToShowModalContainer } from '../DealRentEntryToShowModal';
import { DealRentFailureModalContainer } from '../DealRentFailureModal';
import { DealRentModalContainer } from '../DealRentModal';
import { DealRentSendSimilarModalContainer } from '../DealRentSendSimilarModal';
import { DealRentSimilarSuccessModalContainer } from '../DealRentSimilarSuccessModal';
import { DealRentSuccessModalContainer } from '../DealRentSuccessModal';
import { EntryToShowSuccessModalContainer } from '../EntryToShowSuccessModal';

export const DealRent = () => (
  <>
    <DealRentButtonContainer />
    <DealRentEntryToShowButtonContainer />
    <DealRentModalContainer />
    <DealRentSuccessModalContainer />
    <DealRentFailureModalContainer />
    <DealRentSendSimilarModalContainer />
    <DealRentSimilarSuccessModalContainer />
    <DealRentEntryToShowModalContainer />
    <EntryToShowSuccessModalContainer />
    <RenterFormContainer />
  </>
);
