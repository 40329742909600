import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setIsTooltipOpen } from '../../actions/flatTourBooking';
import { FlatViewOrderTooltip } from '../../components/FlatViewOrderTooltip';
import { getFlatViewOrderDescription } from '../../selectors';
import { getIsTooltipOpen } from '../../selectors/flatTourBooking';

export function FlatViewOrderTooltipContainer() {
  const isOpen = useSelector(getIsTooltipOpen);
  const description = useSelector(getFlatViewOrderDescription);
  const dispatch = useDispatch();

  const handleClose = React.useCallback(() => {
    dispatch(setIsTooltipOpen(false));
  }, [dispatch]);

  if (!isOpen) {
    return null;
  }

  return <FlatViewOrderTooltip onClose={handleClose} description={description} />;
}
