export const extractNumber = (str?: string): number => {
  if (!str) {
    return 0;
  }

  const normalized = str.replace(',', '.');

  const match = normalized.match(/-?\d+(\.\d+)?/);

  return match ? parseFloat(match[0]) : 0;
};
