import * as React from 'react';
import { useSelector } from 'react-redux';

import { DealRentBlock } from '../../components/DealRentBlock';
import { NewSafeRentBlock } from '../../components/NewSafeRentBlock';
import { EDealRentVersion } from '../../repositories/offer-card/entities/offer/OfferSchema';
import { dealVersionSelector } from '../../selectors/offerData/offer/dealVersion';

export const RentBlockContainer = () => {
  const dealVersion = useSelector(dealVersionSelector);

  switch (dealVersion) {
    case EDealRentVersion.V2:
      return <NewSafeRentBlock />;
    case EDealRentVersion.V1:
    default:
      return <DealRentBlock />;
  }
};
