import * as React from 'react';

import * as styles from './newbuildingBuildersFacts.css';

export function NewbuildingBuildersFacts({ children }: React.PropsWithChildren<{}>) {
  return (
    <div data-testid="newbuilding-builders-facts" className={styles['container']}>
      {children}
    </div>
  );
}
