import * as React from 'react';

import { useObjectSummaryTitleSelector } from './internal/hooks/useObjectSummaryTitleSelector';
import { ObjectSummaryTitle } from '../../components/ObjectSummaryTitle/ObjectSummaryTitle';

export const ObjectSummaryTitleContainer = () => {
  const title = useObjectSummaryTitleSelector();

  if (!title) {
    return null;
  }

  return <ObjectSummaryTitle title={title} />;
};
