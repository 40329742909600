import * as React from 'react';

import * as styles from './styles.css';

interface INewbuildingBuilderBackgroundProps {
  imageUrl: string;
}

export function NewbuildingBuilderBackground({ imageUrl }: INewbuildingBuilderBackgroundProps) {
  return <div className={styles['container']} style={{ backgroundImage: `url(${imageUrl})` }} />;
}
