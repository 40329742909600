import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectAsideCompactMode } from 'shared/selectors/aside';
import { isDailyrent as checkIsDailyrent } from 'shared/utils/offer_helpers';

import { getTitle, getDealType, getValuation } from './utils';
import { getOfferType, isCommercial as checkIsCommercial } from '../../../shared/utils/offer_type';
import { PriceHistoryButton } from '../../components/PriceHistoryButton';
import { PriceHistory } from '../../components/price_history';
import { selectOffer } from '../../selectors';
import { selectPriceChanges } from '../../selectors/price/selectPriceChanges';
import { selectAgent } from '../../selectors/selectAgent';
import { selectOfferValuationData } from '../../store/offerValuation';
import { getCustomUrl } from '../../utils/tracking/getCustomUrl';

export const PriceHistoryContainer = () => {
  const offer = useSelector(selectOffer);
  const agent = useSelector(selectAgent);
  const priceChanges = useSelector(selectPriceChanges);
  const offerValuationData = useSelector(selectOfferValuationData);
  const offerType = getOfferType(offer);
  // Коммерческой историей изменения цены в объявке, которая:
  // - имеет тип коммерческая
  // - не имеет цены в виде целого числа (вместо этого от-до)
  // - не является коммерческой землей
  /* istanbul ignore next */
  const isCommercial = Boolean(checkIsCommercial(offerType) && offer.canParts);
  const isCompactModeEnabled = useSelector(selectAsideCompactMode);
  // не показываем изменение цены для посуточных объявок
  const isDailyrent = checkIsDailyrent(offer.category);

  if (!priceChanges || priceChanges.length < 2 || isCompactModeEnabled || isDailyrent) {
    return null;
  }

  return (
    <PriceHistory
      customURL={getCustomUrl(offer, agent)}
      events={priceChanges}
      title={getTitle(offer.dealType, isCommercial)}
      dealType={getDealType(offer)}
      valuation={getValuation(offer, offerValuationData)}
      priceHistoryUpIcon={<PriceHistoryButton trend="inc" />}
      priceHistoryDownIcon={<PriceHistoryButton trend="dec" />}
      withoutMargins={true}
    />
  );
};

PriceHistoryContainer.displayName = 'PriceHistoryContainer';
