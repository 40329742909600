import { connect } from 'react-redux';

import { AdminButtons, IAdminButtonsProps } from './index';
import { IState } from '../../store';

function mapStateToProps(state: IState): IAdminButtonsProps {
  return {
    offer: state.offerData.offer,
    pageData: state.pageData,
    serviceBaseUrl: state.serviceBaseUrl,
    user: state.offerData.user,
  };
}

export const AdminButtonsContainer = connect<IAdminButtonsProps, {}, {}>(mapStateToProps)(AdminButtons);
