import React from 'react';
import { useSelector } from 'react-redux';

import { SeoBlock } from '../../components/SeoLinks/SeoBlock';
import { SeoLink } from '../../components/SeoLinks/SeoLink';
import { SeoSection } from '../../components/SeoLinks/SeoSection';
import { trackClick } from '../../components/seo_links/tracking';
import { seoLinksSelector } from '../../selectors/seoLinksSelector';

export const SeoLinksContainer = () => {
  const seoLinks = useSelector(seoLinksSelector);

  if (!seoLinks || !seoLinks.seoBlocks || !seoLinks.seoBlocks.length) {
    return null;
  }

  return (
    <SeoSection>
      {seoLinks.seoBlocks.map((seoBlock, blockIndex) => {
        return (
          <SeoBlock key={`seo-block-${blockIndex}`} blockIndex={blockIndex} title={seoBlock.title}>
            {seoBlock.links.map((seoLink, linkIndex) => {
              return (
                <SeoLink
                  key={`seo-link-${linkIndex}`}
                  linkIndex={linkIndex}
                  uri={seoLink.uri}
                  name={seoLink.name}
                  onClick={() => trackClick(seoLink.uri)}
                />
              );
            })}
          </SeoBlock>
        );
      })}
    </SeoSection>
  );
};
