import { Button, UIHeading4 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './SubscriptionAuthorActions.css';
import { ISubscriptionAuthorActionsProps } from './types';

const BUTTONS_SIZE = 'XS';

export const SubscriptionAuthorActions: React.FC<ISubscriptionAuthorActionsProps> = props => {
  const {
    isPhonesVisible,
    phones,
    isMessageButtonVisible,
    messageButtonText,
    isElectronicTradingOffer,
    handleShowPhonesClick,
    handleSendMessageClick,
    phonesOpenedInfo,
    electronicTradingContactButton,
  } = props;

  return (
    <div className={styles['buttons_wrapper']}>
      <div className={styles['buttons']}>
        {isElectronicTradingOffer && electronicTradingContactButton}

        {!isElectronicTradingOffer && isPhonesVisible && <UIHeading4>{phones.join(', ')}</UIHeading4>}

        {!isElectronicTradingOffer && !isPhonesVisible && (
          <Button theme="fill_primary" size={BUTTONS_SIZE} onClick={handleShowPhonesClick}>
            Показать телефон
          </Button>
        )}

        {isMessageButtonVisible && (
          <div className={styles['button_send_message']}>
            <Button theme="fill_secondary" size={BUTTONS_SIZE} onClick={handleSendMessageClick}>
              {messageButtonText}
            </Button>
          </div>
        )}
      </div>

      {!isElectronicTradingOffer && isPhonesVisible && <div className={styles['phones_hint']}>{phonesOpenedInfo}</div>}
    </div>
  );
};
