import { numberToPrettyString } from '@cian/utils/lib/shared/number_helpers';
import { plural } from '@cian/utils/lib/shared/plural';
import cx from 'clsx';
import { isNil } from 'ramda';
import * as React from 'react';

import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { getPriceCurrencies } from './helper';
import * as styles from './index.css';
import { Currency, LeaseType } from '../../store/offer';
import { FDealType, getDealType } from '../../utils/deal_type';
import { CurrencySymbol, getCurrencySymbol, getPriceRentTimeSuffix } from '../../utils/formatters';
import { FOfferType, getOfferType, isCommercial } from '../../utils/offer_type';

export interface IPriceDropdownProps {
  className?: string;
  offer: IOfferSchema;
}

export function PriceDropdown(props: IPriceDropdownProps) {
  const tooltip = renderTooltip(props.offer);

  if (tooltip) {
    return (
      <span className={cx([styles['container'], props.className])}>
        <i className={styles['icon']}>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6">
            {/* tslint:disable-next-line:max-line-length */}
            <path
              fill="#2B87DB"
              fillRule="evenodd"
              d="M3.525 4.927l-3-2.89a.75.75 0 0 1 1-1.07l2.52 2.37 2.43-2.37a.75.75 0 0 1 1 1.07l-3 2.89a.75.75 0 0 1-.43.21.75.75 0 0 1-.52-.21z"
            />
          </svg>
        </i>
        {tooltip}
      </span>
    );
  }

  return null;
}

function renderTooltip(offer: IOfferSchema) {
  const { bargainTerms } = offer;
  const { currency } = bargainTerms;

  const dealType = getDealType(offer);
  const offerType = getOfferType(offer);

  if (offerType & FOfferType.Residential && currency && currency !== Currency.Rur) {
    if (bargainTerms.prices[currency]) {
      return (
        <div className={cx([styles['tooltip'], styles['tooltip-value']])}>
          {/* tslint:disable-next-line:max-line-length */}
          {`${numberToPrettyString(bargainTerms.prices[currency])} ${getCurrencySymbol(
            currency,
          )}${getPriceRentTimeSuffix(offer)}`}
        </div>
      );
    }
  } else if (isCommercial(offerType)) {
    if (dealType === FDealType.Sale) {
      if (offer.priceTotalRur) {
        if (!isNil(offer.minArea) && !isNil(offer.minPriceTotalRur)) {
          if ((offer.minPriceTotalUsd && offer.priceTotalUsd) || (offer.minPriceTotalEur && offer.priceTotalEur)) {
            const description = getDescription(offer);

            return (
              <div className={styles['tooltip']}>
                {description && <p className={styles['description']}>{description}</p>}
                {offer.minPriceTotalUsd && offer.priceTotalUsd && (
                  <div className={styles['price']}>
                    {/* tslint:disable-next-line:max-line-length */}
                    от {numberToPrettyString(Math.round(offer.minPriceTotalUsd))} до{' '}
                    {numberToPrettyString(Math.round(offer.priceTotalUsd))} {CurrencySymbol.Usd}
                  </div>
                )}
                {offer.minPriceTotalEur && offer.priceTotalEur && (
                  <div className={styles['price']}>
                    {/* tslint:disable-next-line:max-line-length */}
                    от {numberToPrettyString(Math.round(offer.minPriceTotalEur))} до{' '}
                    {numberToPrettyString(Math.round(offer.priceTotalEur))} {CurrencySymbol.Eur}
                  </div>
                )}
              </div>
            );
          } else if (offer.pricePerUnitAreaUsd && offer.pricePerUnitAreaEur && offer.minArea && offer.maxArea) {
            const description = getDescription(offer);
            const priceCurrencies = getPriceCurrencies(offer);

            return (
              <div className={styles['tooltip']}>
                {description && <p className={styles['description']}>{description}</p>}
                {priceCurrencies.usd && <div className={styles['price']}>{priceCurrencies.usd}</div>}
                {priceCurrencies.eur && <div className={styles['price']}>{priceCurrencies.eur}</div>}
              </div>
            );
          }
        } else {
          if (offer.priceTotalUsd || offer.priceTotalEur) {
            const description = getDescription(offer);

            return (
              <div className={styles['tooltip']}>
                {description && <p className={styles['description']}>{description}</p>}
                {offer.priceTotalUsd && (
                  <div className={styles['price']}>
                    {/* tslint:disable-next-line:max-line-length */}
                    {numberToPrettyString(Math.round(offer.priceTotalUsd))} {CurrencySymbol.Usd}
                  </div>
                )}
                {offer.priceTotalEur && (
                  <div className={styles['price']}>
                    {/* tslint:disable-next-line:max-line-length */}
                    {numberToPrettyString(Math.round(offer.priceTotalEur))} {CurrencySymbol.Eur}
                  </div>
                )}
              </div>
            );
          }
        }
      }
    } else if (dealType & FDealType.Rent) {
      if (!isNil(offer) && !isNil(offer.priceTotalPerMonthRur)) {
        if (!isNil(offer.minArea) && !isNil(offer.minPriceTotalPerMonthRur)) {
          if (
            offer.minArea &&
            ((offer.pricePerUnitAreaUsd && offer.priceTotalPerMonthUsd) ||
              (offer.pricePerUnitAreaEur && offer.priceTotalPerMonthEur))
          ) {
            const description = getDescription(offer);
            const priceCurrencies = getPriceCurrencies(offer);

            return (
              <div className={styles['tooltip']}>
                {description && <p className={styles['description']}>{description}</p>}
                {priceCurrencies.usd && (
                  <div className={styles['price']}>
                    {priceCurrencies.usd}
                    {getPriceRentTimeSuffix(offer)}
                  </div>
                )}
                {priceCurrencies.eur && (
                  <div className={styles['price']}>
                    {priceCurrencies.eur}
                    {getPriceRentTimeSuffix(offer)}
                  </div>
                )}
              </div>
            );
          }
        } else {
          if (offer.priceTotalPerMonthUsd || offer.priceTotalPerMonthEur) {
            const description = getDescription(offer);

            const priceTotalPerMonthUsd = offer.priceTotalPerMonthUsd
              ? offer.priceTotalPerMonthUsd +
                (offer.bargainTerms.utilitiesTerms && !offer.bargainTerms.utilitiesTerms.includedInPrice
                  ? offer.bargainTerms.utilitiesTermsPrices.usd
                  : 0)
              : null;
            const priceTotalPerMonthEur = offer.priceTotalPerMonthEur
              ? offer.priceTotalPerMonthEur +
                (offer.bargainTerms.utilitiesTerms && !offer.bargainTerms.utilitiesTerms.includedInPrice
                  ? offer.bargainTerms.utilitiesTermsPrices.eur
                  : 0)
              : null;

            return (
              <div className={styles['tooltip']}>
                {description && <p className={styles['description']}>{description}</p>}
                {priceTotalPerMonthUsd && (
                  <div className={styles['price']}>
                    {numberToPrettyString(Math.round(priceTotalPerMonthUsd))} {CurrencySymbol.Usd}
                    {getPriceRentTimeSuffix(offer)}
                  </div>
                )}
                {priceTotalPerMonthEur && (
                  <div className={styles['price']}>
                    {numberToPrettyString(Math.round(priceTotalPerMonthEur))} {CurrencySymbol.Eur}
                    {getPriceRentTimeSuffix(offer)}
                  </div>
                )}
              </div>
            );
          }
        }
      }
    }
  }

  return null;
}

function getDescription(offer: IOfferSchema) {
  const { bargainTerms } = offer;

  let descriptionArr: (string | null)[] = [
    (bargainTerms.leaseType &&
      ((bargainTerms.leaseType === LeaseType.Direct &&
        `прямая\u00a0аренда${
          (bargainTerms.leaseTermType &&
            `\u00a0(${bargainTerms.leaseTermType === 'fewMonths' ? 'несколько\u00a0месяцев' : 'от\u00a0года'})`) ||
          ''
        }`) ||
        (bargainTerms.leaseType === LeaseType.Sublease &&
          `субаренда${
            (bargainTerms.leaseTermType &&
              `\u00a0(${bargainTerms.leaseTermType === 'fewMonths' ? 'несколько\u00a0месяцев' : 'от\u00a0года'})`) ||
            ''
          }`) ||
        (bargainTerms.leaseType === LeaseType.JointVenture &&
          `договор\u00a0совместной\u00a0деятельности${
            (bargainTerms.leaseTermType &&
              `\u00a0(${bargainTerms.leaseTermType === 'fewMonths' ? 'несколько\u00a0месяцев' : 'от\u00a0года'})`) ||
            ''
          }`))) ||
      null,
    (bargainTerms.minLeaseTerm &&
      `минимальный\u00a0срок\u00a0аренды\u00a0${bargainTerms.minLeaseTerm}\u00a0${plural(bargainTerms.minLeaseTerm, [
        'месяц',
        'месяца',
        'месяцев',
      ])}`) ||
      null,
    (bargainTerms.hasGracePeriod && 'доступны\u00a0арендные\u00a0каникулы') || null,
    (bargainTerms.securityDepositPrices &&
      bargainTerms.securityDepositPrices.rur &&
      `обеспечительный\u00a0платёж\u00a0${bargainTerms.securityDepositPrices.rur}\u00a0\u20bd`) ||
      null,
    !isNil(bargainTerms.prepayMonths)
      ? (bargainTerms.prepayMonths &&
          `предоплата\u00a0за\u00a0${bargainTerms.prepayMonths}\u00a0${plural(bargainTerms.prepayMonths, [
            'месяц',
            'месяца',
            'месяцев',
          ])}`) ||
        'без\u00a0предоплаты'
      : null,
  ];

  descriptionArr = descriptionArr.filter(value => !!value);

  if (descriptionArr.length > 0) {
    const description = descriptionArr.join(', ');

    return description.charAt(0).toUpperCase() + description.slice(1);
  }

  return null;
}
