import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectHasVerifiedDocuments } from 'shared/containers/ApprovalContainer/selectors/selectHasVerifiedDocuments';
import { IOfferSchema } from 'shared/repositories/offer-card/entities/offer/OfferSchema';
import { CommercialOwner } from 'shared/utils/agent/CommercialOwner';

import { AgentProofsTitle } from '../../../components/AuthorAsideNew';
import { offerSelector, selectOfferAgent } from '../../../selectors';
import { getAgentType } from '../../../utils/getAgentType';
import { getProofMessage } from '../../../utils/getProofMessage';

export const AgentProofsTitleContainer: React.FC = () => {
  const agent = useSelector(selectOfferAgent, shallowEqual);

  const agentType = agent && getAgentType(agent);
  const offer = useSelector(offerSelector);
  const isCommercialHonestWork = useSelector(selectHasVerifiedDocuments, shallowEqual);

  const commercialOwner = CommercialOwner.getInstanceIfIsCommercial(offer as unknown as IOfferSchema, agent);

  const message = (() => {
    if (isCommercialHonestWork) {
      return 'Документы проверены';
    }

    if (commercialOwner?.shield.showRosreestrVerified) {
      return 'Проверено в Росреестре';
    }

    return agent.isHonest && getProofMessage(agentType);
  })();

  if (!agentType || !message) {
    return null;
  }

  return (
    <li>
      <AgentProofsTitle size="medium" title={message} />
    </li>
  );
};
