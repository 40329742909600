import { connect } from 'react-redux';

import { IRemoveModalProps, RemoveModal } from './index';
import { toggle } from '../../../store/popups/remove';

import type { DispatchReduxAction, IState } from '../../../store';

type TStoreProps = Pick<IRemoveModalProps, 'activeFrame' | 'isOpen' | 'offer' | 'status'>;
type TDispatchProps = Pick<IRemoveModalProps, 'close'>;

function mapStateToProps(state: IState): TStoreProps {
  return {
    activeFrame: state.remove.activeFrame,
    isOpen: state.remove.isOpen,
    offer: state.offerData.offer,
    status: state.remove.status,
  };
}

function mapDispatchToProps(dispatch: DispatchReduxAction): TDispatchProps {
  return {
    close: () => dispatch(toggle(false)),
  };
}

export const RemoveModalContainer = connect<TStoreProps, TDispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps,
)(RemoveModal);
