export class SimpleBotDetector {
  private mouseMoveCount = 0;
  private muted = false;
  private timeoutId?: number;
  private handler = () => {
    if (!this.muted) {
      this.mouseMoveCount += 1;
      if (this.mouseMoveCount > 3) {
        this.removeEvent();
      }
      this.muted = true;
      if (!this.timeoutId) {
        this.timeoutId = window.setTimeout(() => {
          this.timeoutId = undefined;
          this.muted = false;
        }, 5);
      }
    }
  };

  private handleTouch = () => {
    this.mouseMoveCount = 5;
    this.removeEvent();
  };

  public init() {
    if (this.hasDocument) {
      this.setEvent();
    }
  }

  public destroy() {
    if (this.hasDocument) {
      this.removeEvent();
    }
  }

  public isHuman() {
    return this.mouseMoveCount > 3;
  }

  private setEvent() {
    if (this.hasDocument) {
      document.addEventListener('mousemove', this.handler);
      document.addEventListener('touchstart', this.handleTouch);
    }
  }

  private removeEvent() {
    if (this.hasDocument) {
      document.removeEventListener('mousemove', this.handler);
      document.removeEventListener('touchstart', this.handleTouch);
    }
  }

  private get hasDocument() {
    return typeof document !== 'undefined';
  }
}
