import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EUserTrustLevel } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { WARNING_STATUS, WarningsStatic } from '../../components/WarningsStatic';
import { IState } from '../../store';
import { setComplaintPopupOpenValue } from '../../store/complaintPopupOpen';
import { getDealType } from '../../utils/deal_type';

function getWarningId(state: IState) {
  const {
    offerData: {
      offer: { userTrustLevel },
    },
  } = state;

  if (userTrustLevel === EUserTrustLevel.Danger) {
    return WARNING_STATUS.RECIDIVIST;
  } else if (userTrustLevel === EUserTrustLevel.Excluded) {
    return WARNING_STATUS.ERRORS;
  } else if (userTrustLevel === EUserTrustLevel.New) {
    return WARNING_STATUS.NEW;
  }

  return undefined;
}

export const WarningsStaticContainer = () => {
  const dealType = useSelector((state: IState) => getDealType(state.offerData.offer));
  const warningId = useSelector((state: IState) => getWarningId(state));
  const dispatch = useDispatch();
  const setComplaintPopupOpen = React.useCallback(
    (value: boolean) => dispatch(setComplaintPopupOpenValue(value)),
    [dispatch],
  );
  const props = {
    dealType,
    warningId,
    setComplaintPopupOpen,
  };

  return <WarningsStatic {...props} />;
};
