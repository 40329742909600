import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ISendFormToSimilarOffersRequest } from 'shared/repositories/deal-rent-announcements/v1/send-form-to-similar-offers';

import { DealRentSendSimilarModal } from './DealRentSendSimilarModal';
import { selectDealRent, selectOfferId } from '../../selectors';
import { closeSendSimilar, sendFormToSimilarOfferAction as sendFormAction } from '../../store/deal-rent';
import { trackSafeRentSimilarFormClick } from '../../store/deal-rent/tracking';

export const DealRentSendSimilarModalContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { isSendSimilarModal } = useSelector(selectDealRent);
  const offerId = useSelector(selectOfferId);

  const onCloseModal = React.useCallback(() => {
    trackSafeRentSimilarFormClick();
    dispatch(closeSendSimilar());
  }, [dispatch]);

  const onSendForm = React.useCallback(
    (form: ISendFormToSimilarOffersRequest) => dispatch(sendFormAction(form)),
    [dispatch],
  );

  return (
    <DealRentSendSimilarModal
      offerId={offerId}
      isOpen={isSendSimilarModal}
      closeModal={onCloseModal}
      sendForm={onSendForm}
    />
  );
};
