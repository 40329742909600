import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectIsInfrastructureMicrofrontendEnabled } from 'shared/selectors/infrastructure';

import { MapsSectionContainer } from '../MapsSectionContainer';

export const OfferMapContainer = () => {
  const isInfrastructureMicrofrontendEnabled = useSelector(selectIsInfrastructureMicrofrontendEnabled);

  if (isInfrastructureMicrofrontendEnabled) {
    return null;
  }

  return <MapsSectionContainer />;
};
