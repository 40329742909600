import { RadioButtonGroup, UIHeading5 } from '@cian/ui-kit';
import { IControlGroupProps, IControlOption, TControlOptionValue } from '@cian/ui-kit/types';
import * as React from 'react';

import * as styles from './styles.css';

export interface IChartTimeFilterProps<V extends TControlOptionValue> {
  onChange: IControlGroupProps<V>['onChange'];
  options: IControlOption<V>[];
  value: V;
}

export function ChartTimeFilter<V extends TControlOptionValue>(props: IChartTimeFilterProps<V>) {
  const { onChange, value, options } = props;

  return (
    <div className={styles['container']} data-testid="valuation_chart_time_filter">
      <UIHeading5>Изменение цены</UIHeading5>
      <RadioButtonGroup options={options} value={value} onChange={onChange} />
    </div>
  );
}

ChartTimeFilter.displayName = 'ChartTimeFilter';
