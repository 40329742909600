import { createSelector } from '@reduxjs/toolkit';

import { ECategory } from '../../../node/repositories/offer-card/entities/offer/OfferSchema';
import { EOfferType } from '../../repositories/offer-card/entities/offer/OfferSchema';
import { EDealType } from '../../store/offerHistory';
import { selectOffer, selectOfferAgent, selectUser } from '../offerData';

export const selectIsMortgageBannerEnabledIgnoreAgent = createSelector(
  selectOffer,
  selectOfferAgent,
  (offer, agent) => {
    const { dealType, offerType, category } = offer;

    return (
      !agent?.bank &&
      dealType !== EDealType.Rent &&
      offerType !== EOfferType.Commercial &&
      category !== ECategory.LandSale
    );
  },
);

export const selectIsMortgageBannerEnabled = createSelector(
  [selectIsMortgageBannerEnabledIgnoreAgent, selectUser],
  (isBaseValid, user) => isBaseValid && !user?.isAgent,
);
