import loadable from '@loadable/component';

export const SubsidisedMortgageModalContainer = loadable(() => {
  /* istanbul ignore else  */
  if (process.env.IS_BROWSER_BUILD) {
    return import('./SubsidisedMortgageModalContainer');
  }

  /* istanbul ignore next */
  return Promise.resolve({
    default: /* istanbul ignore next */ () => null,
  });
});
