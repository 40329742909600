import { useApplicationContext } from 'shared/containers/ApplicationContext';
import { getIsCommercialByOffer } from 'shared/utils/getIsCommercialByOffer';

import { useOfferSelector } from './selectors/useOfferSelector';

/**
 * @todo Удалить RS
 * @deprecated
 * @description Данный функционал появился в задаче CD-189160, будет удалён в задаче CD-190741
 * Удалить хук полностью
 */
export function useIsElectronicTradingEnabled(): boolean {
  const { config } = useApplicationContext();

  const offer = useOfferSelector();

  if (getIsCommercialByOffer(offer)) {
    return !!config.get<boolean>('Commercial.ElectronicTrading.Enabled');
  }

  return !!config.get<boolean>('Audience.ElectronicTrading.Enabled');
}

export function useIsElectronicTradingOffer(): boolean {
  const { tradingLink } = useOfferSelector();

  const isElectronicTradingEnabled = useIsElectronicTradingEnabled();

  if (!isElectronicTradingEnabled) {
    return false;
  }

  return !!tradingLink;
}
